import { useIntl } from "react-intl";

import { useNotification } from "@utils/notifications/notification-context";

import { DashboardLayout } from "@components/DashboardLayout";
import { OutlinedSection } from "@components/OutlinedSection";
import { AlertVariant } from "@components/Alert/Alert.types";

import { Container } from "../Dashboard.styles";
import { ChangePasswordForm } from "./ChangePasswordForm";
import { UserDetailsForm } from "./UserDetailsForm";
import { MyAccountRow } from "./MyAccount.styles";

export function MyAccount() {
  const { formatMessage } = useIntl();
  const { addNotification } = useNotification();

  const handleSubmitSuccess = () => {
    addNotification({
      id: "changePasswordSuccess",
      title: "Your changes are saved!",
      text: "Changes that you’ve made in My account are saved now.",
      variant: AlertVariant.Success,
    });
  };

  return (
    <DashboardLayout
      title={formatMessage({
        id: "myAccount.title",
        defaultMessage: "My account",
      })}
      description={formatMessage({
        id: "myAccount.description",
        defaultMessage: "Here you can find all of the account settings.",
      })}
      backgroundImage="/img/my-account-background.svg"
    >
      <Container>
        <MyAccountRow>
          <OutlinedSection
            title={formatMessage({
              id: "myAccount.myDetails",
              defaultMessage: "My details",
            })}
          >
            <UserDetailsForm handleSubmitSuccess={handleSubmitSuccess} />
          </OutlinedSection>

          <OutlinedSection
            title={formatMessage({
              id: "myAccount.changePassword",
              defaultMessage: "Change password",
            })}
          >
            <ChangePasswordForm handleSubmitSuccess={handleSubmitSuccess} />
          </OutlinedSection>
        </MyAccountRow>
      </Container>
    </DashboardLayout>
  );
}
