import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormattedMessage, useIntl } from "react-intl";

import { LoginFormProps } from "./LoginForm.types";
import { UserLoginData } from "../../api/auth/Auth.types";
import { Input } from "../Input";
import { Button, ButtonLink, ButtonVariants } from "../Button";
import { Paths } from "../../utils/router";
import { ButtonsBox, StyledForm } from "./LoginForm.styles";
import { Box } from "../Box";
import { FieldCaption } from "../FieldCaption";
import {
  FieldCaptionSize,
  FieldCaptionVariant,
} from "../FieldCaption/FieldCaption.types";
import { PasswordInput } from "../PasswordInput";
import { useLoginValidation } from "./LoginForm.validation";

export function LoginForm({ onSubmit }: LoginFormProps) {
  const { formatMessage } = useIntl();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<UserLoginData>({
    resolver: yupResolver(useLoginValidation()),
  });

  const catchSubmitionErrors = (callback: () => Promise<unknown>) => {
    callback().catch((err) => {
      setError("email", {
        type: "custom",
      });
      setError("password", {
        type: "custom",
        message: err.response.data.detail,
      });
    });
  };

  return (
    <StyledForm
      noValidate
      onSubmit={handleSubmit((data) =>
        catchSubmitionErrors(() => onSubmit(data))
      )}
    >
      <Box>
        <Input
          {...register("email", { required: true })}
          type="email"
          label={formatMessage({
            id: "loginForm.email",
            defaultMessage: "Email address",
          })}
          placeholder={formatMessage({
            id: "loginForm.emailPlaceholder",
            defaultMessage: "Enter your email",
          })}
          isError={!!errors.email?.type}
          errorMessage={errors.email?.message}
        />
      </Box>

      <Box>
        <PasswordInput
          {...register("password", { required: true })}
          label={formatMessage({
            id: "loginForm.password",
            defaultMessage: "Password",
          })}
          placeholder={formatMessage({
            id: "loginForm.passwordPlaceholder",
            defaultMessage: "Enter password",
          })}
          isError={!!errors.password?.type}
          errorMessage={errors.password?.message}
        />
      </Box>

      <Box mt="m">
        <FieldCaption
          variant={FieldCaptionVariant.Question}
          size={FieldCaptionSize.Big}
        >
          <Link to={Paths.ForgotPassword}>
            <FormattedMessage
              id="loginForm.forgotPasswordLink"
              defaultMessage="Forgot password?"
            />
          </Link>
        </FieldCaption>
      </Box>

      <ButtonsBox>
        <Button
          block
          type="submit"
          variant={ButtonVariants.Primary}
          iconRight="ArrowRight"
        >
          <FormattedMessage
            id="loginForm.submitButton"
            defaultMessage="Log in"
          />
        </Button>

        <ButtonLink to={Paths.Home} variant={ButtonVariants.Secondary} block>
          <FormattedMessage
            id="loginForm.cancelButton"
            defaultMessage="Cancel"
          />
        </ButtonLink>
      </ButtonsBox>
    </StyledForm>
  );
}
