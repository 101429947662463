import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { authApi } from "../authApi";
import { PaperEndpoints } from "./endpoints";
import {
  SolutionsFilters,
  AddPaperData,
  QuestionsFilters,
  QuestionsFiltersFilters,
  QuestionsFiltersResponse,
  QuestionsResponse,
  SolutionsResponse,
  UserSubjectsRequestType,
  UserSubjectsResponse,
  SolutionsFiltersFilters,
  SolutionsFiltersResponse,
  ExamGuideResponse,
  SearchResultResponse,
  Question,
  SearchPapersData,
  Solution,
} from "./Paper.types";

const getQuestions = async (filters?: Partial<SolutionsFilters>) => {
  const { data } = await authApi.get(PaperEndpoints.Questions, {
    params: filters,
  });

  return data;
};

export const useGetQuestions = (
  filters?: Partial<SolutionsFilters>,
  options?: UseQueryOptions<
    QuestionsResponse,
    Error,
    QuestionsResponse,
    [string, string, Partial<QuestionsFilters> | undefined]
  >
) =>
  useQuery(
    ["questions", "list", filters],
    () => getQuestions(filters),
    options
  );

const getQuestion = async (questionId?: string | null) => {
  const { data } = await authApi.get(
    `${PaperEndpoints.Questions}${questionId}/`
  );

  return data;
};

export const useGetQuestion = (
  questionId?: string | null,
  options?: UseQueryOptions<
    Question,
    Error,
    Question,
    ["questions", string | undefined]
  >
) =>
  useQuery(["questions", questionId!], () => getQuestion(questionId), options);

const getQuestionsFilters = async (
  filters?: Partial<QuestionsFiltersFilters>
) => {
  const { data } = await authApi.get(PaperEndpoints.QuestionsFilters, {
    params: filters,
  });

  return data;
};

export const useGetQuestionsFilters = (
  filters?: Partial<QuestionsFiltersFilters>,
  options?: UseQueryOptions<
    QuestionsFiltersResponse,
    Error,
    QuestionsFiltersResponse,
    ["questions", "filters", Partial<QuestionsFiltersFilters> | undefined]
  >
) =>
  useQuery(
    ["questions", "filters", filters],
    () => getQuestionsFilters(filters),
    options
  );

const getUserSubjects = async (requestType: UserSubjectsRequestType) => {
  const { data } = await authApi.get(
    `${PaperEndpoints.UserSubjects}${requestType}/`
  );

  return data;
};

export const useGetUserSubjects = (
  requestType: UserSubjectsRequestType,
  options?: UseQueryOptions<
    UserSubjectsResponse,
    Error,
    UserSubjectsResponse,
    [string, UserSubjectsRequestType]
  >
) =>
  useQuery(
    ["userSubjects", requestType],
    () => getUserSubjects(requestType),
    options
  );

const getSolution = async (solutionId?: string | null) => {
  const { data } = await authApi.get(
    `${PaperEndpoints.Solutions}${solutionId}`
  );

  return data;
};

export const useGetSolution = (
  solutionId?: string | null,
  options?: UseQueryOptions<
    Solution,
    AxiosError<{ detail: string }>,
    Solution,
    ["solutions", string | undefined | null]
  >
) =>
  useQuery(["solutions", solutionId], () => getSolution(solutionId), options);

const getSolutions = async (filters?: Partial<SolutionsFilters>) => {
  const { data } = await authApi.get(PaperEndpoints.Solutions, {
    params: filters,
  });

  return data;
};

export const useGetSolutions = (
  filters?: Partial<SolutionsFilters>,
  options?: UseQueryOptions<
    SolutionsResponse,
    Error,
    SolutionsResponse,
    [string, Partial<SolutionsFilters> | undefined]
  >
) => useQuery(["solutions", filters], () => getSolutions(filters), options);

const getSolutionsFilters = async (
  filters?: Partial<SolutionsFiltersFilters>
) => {
  const { data } = await authApi.get(PaperEndpoints.SolutionsFilters, {
    params: filters,
  });

  return data;
};

export const useGetSolutionsFilters = (
  filters?: Partial<SolutionsFiltersFilters>,
  options?: UseQueryOptions<
    SolutionsFiltersResponse,
    Error,
    SolutionsFiltersResponse,
    ["solutions", "filters", Partial<SolutionsFiltersFilters> | undefined]
  >
) =>
  useQuery(
    ["solutions", "filters", filters],
    () => getSolutionsFilters(filters),
    options
  );

const addPaper = async (code: AddPaperData) => {
  const { data } = await authApi.post<AddPaperData, AxiosResponse<null>>(
    PaperEndpoints.MatchExamCode,
    code
  );

  return data;
};

export const useAddPaper = (
  options?: UseMutationOptions<
    null,
    AxiosError<{ detail?: string }>,
    AddPaperData
  >
) => {
  const queryClient = useQueryClient();

  return useMutation(["papers"], addPaper, {
    ...options,
    onSettled: (data, error, variables, context) => {
      queryClient.invalidateQueries(["userSubjects"]);

      options?.onSettled?.(data, error, variables, context);
    },
  });
};

const getExamGuide = async (subjectLevelId: string) => {
  const { data } = await authApi.get(
    `${PaperEndpoints.ExamGuide}${subjectLevelId}/`
  );

  return data;
};

export const useGetExamGuide = (
  subjectLevelId: string,
  options?: UseQueryOptions<
    ExamGuideResponse,
    AxiosError,
    ExamGuideResponse,
    ["exam-guide", string]
  >
) =>
  useQuery(
    ["exam-guide", subjectLevelId],
    () => getExamGuide(subjectLevelId),
    options
  );

const searchPapers = async (searchPapersData: SearchPapersData) => {
  const { query, ...params } = searchPapersData;

  const { data } = await authApi.get(`${PaperEndpoints.Search}${query}/`, {
    params,
  });

  return data;
};

export const useSearchPapers = (
  data: SearchPapersData,
  options?: UseQueryOptions<
    SearchResultResponse,
    AxiosError,
    SearchResultResponse,
    ["papers-search", SearchPapersData]
  >
) => useQuery(["papers-search", data], () => searchPapers(data), options);
