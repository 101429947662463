import styled, { css } from "styled-components";

export const StyledPanel = styled.div<{ isOpen: boolean; }>`
  z-index: 5;
  overflow: hidden;
  position: fixed;
  top: 100%;
  left: 0;
  display: flex;
  width: 100%;
  max-height: calc(100% - ${({ theme }) => theme.spacing.xl});
  border-top-left-radius: ${({ theme }) => theme.borderRadius.m};
  border-top-right-radius: ${({ theme }) => theme.borderRadius.m};
  background-color: ${({ theme }) => theme.color.solid.white};

  transform: translateY(0);
  transition: transform 0.25s ease-in-out;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: translateY(-100%);
    `}
`;

export const PanelContent = styled.div`
  overflow: auto;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.l} ${({ theme }) => theme.spacing.m} ${({ theme }) => theme.spacing.m};
`;

export const CloseButton = styled.button.attrs({ type: "button" })`
  position: absolute;
  top: ${({ theme }) => theme.spacing.m};
  right: ${({ theme }) => theme.spacing.m};
  width: 20px;
  height: 20px;
  border: none;
  padding: 0;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 5L5 15' stroke='%2388899F' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5 5L15 15' stroke='%2388899F' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
`;