import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { yupResolver } from "@hookform/resolvers/yup";

import { CreateExamData } from "@api/exam-creator/ExamCreator.types";
import { useBreakpoints } from "@utils/use-breakpoints";
import { Paths } from "@utils/router";

import { Button, ButtonVariants } from "@components/Button";
import { Heading } from "@components/Typography";
import { HeadingVariant } from "@components/Typography/Heading/Heading.types";
import { Box } from "@components/Box";
import { Input } from "@components/Input";
import { TextArea } from "@components/TextArea";
import { TopicSelect } from "@components/TopicSelect";
import { useBackToTop } from "@components/BackToTop";

import { Question } from "./Question";
import { useEditExamFormValidation } from "./EditExamForm.validation";
import { EditExamFormProps } from "./EditExamForm.types";
import {
  AddQuestionLink,
  AddQuestionRow,
  ButtonsRow,
  Container,
} from "./EditExamForm.styles";

export function EditExamForm({
  onSubmit,
  questions,
  examId,
  defaultValues,
  onCancel,
}: EditExamFormProps) {
  const { formatMessage } = useIntl();
  const { isMobile, isTablet, isDesktop } = useBreakpoints();
  const { setBottomOffset } = useBackToTop();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<CreateExamData>({
    mode: "onChange",
    defaultValues: {
      description: defaultValues.description,
      teacherExamQuestion: defaultValues.teacherExamQuestion,
      title: defaultValues.title,
      topic: defaultValues.topic,
    },
    resolver: yupResolver(useEditExamFormValidation()),
  });

  useEffect(() => {
    if (isDesktop) {
      setBottomOffset(false);
    } else if (isTablet) {
      setBottomOffset(87);
    } else {
      setBottomOffset(68);
    }
  }, [isMobile, isTablet, isDesktop, setBottomOffset]);

  return (
    <>
      <Container>
        <Heading as={HeadingVariant.H5} color="primary">
          <FormattedMessage
            id="editExamForm.title"
            defaultMessage="Exam information"
          />
        </Heading>

        <Box>
          <Controller
            name="topic"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange } }) => (
              <TopicSelect
                defaultValues={{
                  examType: defaultValues.examType,
                  level: defaultValues.level,
                  subject: defaultValues.subject,
                  topic: defaultValues.topic,
                }}
                onChange={onChange}
              />
            )}
          />
        </Box>

        <form id="edit-exam-form" onSubmit={handleSubmit(onSubmit)}>
          <Box style={{ maxWidth: 519 }}>
            <Input
              isRequired
              {...register("title", { required: true })}
              label={formatMessage({
                id: "editExamForm.title",
                defaultMessage: "Exam name (max 200)",
              })}
              errorMessage={errors.title?.message}
            />
          </Box>
          <Box>
            <TextArea
              rows={3}
              isRequired
              {...register("description", { required: true })}
              label={formatMessage({
                id: "editExamForm.description",
                defaultMessage: "Exam description (max 200)",
              })}
              error={errors.description?.message}
            />
          </Box>
        </form>
      </Container>

      <Box>
        {questions?.map(({ id, question, order }) => (
          <Question
            key={`${id}-${order}`}
            questionId={id}
            examId={examId}
            question={question}
            order={order}
            maxOrderNumber={questions.length}
          />
        ))}
      </Box>

      <AddQuestionRow>
        <AddQuestionLink
          to={`${Paths.Questions}?${new URLSearchParams({
            examId: examId!,
            examType: defaultValues.examType!,
            level: defaultValues.level!,
            subject: defaultValues.subject!,
            topic: defaultValues.topic!,
          })}`}
          state={{ disabledFields: ["examType", "subject"] }}
        >
          <FormattedMessage
            id="editExamForm.addNewQuestion"
            defaultMessage="Add new question"
          />
        </AddQuestionLink>
      </AddQuestionRow>

      <ButtonsRow gap="m" justify="center" reversed>
        <Button
          type="submit"
          form="edit-exam-form"
          variant={ButtonVariants.Primary}
          isSmall={isMobile}
          disabled={!isValid}
          {...(isDesktop && { iconRight: "ArrowRight" })}
        >
          <FormattedMessage
            id="editExamForm.submitButton"
            defaultMessage="Save Exam"
          />
        </Button>

        <Button
          variant={ButtonVariants.Secondary}
          isSmall={isMobile}
          onClick={() => onCancel(isDirty)}
        >
          <FormattedMessage
            id="editExamForm.cancelButton"
            defaultMessage="Cancel"
          />
        </Button>
      </ButtonsRow>
    </>
  );
}
