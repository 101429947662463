import styled from "styled-components";

export const StyledForm = styled.form`
  width: 100%;
`;

export const LinkWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.s};
  margin-left: 28px;
  text-align: left;
`;
