import styled from "styled-components";

export const FiltersContainer = styled.div`
  margin: ${({ theme }) => `0 -${theme.spacing.m}`};
  padding-right: ${({ theme }) => theme.spacing.m};
  padding-bottom: ${({ theme }) => theme.spacing.m};
  padding-left: ${({ theme }) => theme.spacing.m};
  background-color: ${({ theme }) => theme.color.solid.white};
`;

export const FiltersInnerContainer = styled.div`
  margin: 0 auto;
  max-width: ${({ theme }) => theme.containerWidth};
`;

export const Container = styled.div`
  margin: 0 auto;
  max-width: ${({ theme }) => theme.containerWidth};
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    padding-top: ${({ theme }) => theme.spacing.xs};
  }
`;
