import styled from "styled-components";

import { StrapiImage } from "@components/StrapiImage";

export const ImageColumn = styled.div<{ stickLeft: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex: 1.5;
  padding: ${({ theme }) => theme.spacing.l};

  @media (min-width: ${({ theme }) =>
      parseInt(theme.containerWidth, 10) +
      2 * parseInt(theme.spacing.xl, 10)}px) {
    padding-right: 0;
  }
`;

export const StyledImage = styled(StrapiImage)`
  & > img {
    object-fit: cover;
    border-top-right-radius: ${({ theme }) => theme.borderRadius.l};
    border-bottom-left-radius: ${({ theme }) => theme.borderRadius.l};
    width: 100%;
    height: 100%;
  }
  height: calc(0.7 * 470px);
  width: calc(33% - 12px);

  @media (${({ theme }) => theme.breakpoints.desktopPlus}) {
    width: calc(33% - 24px);
    height: 470px;
  }
`;

export const StyledImageLeft = styled(StyledImage)`
  position: relative;
  top: calc(-81px / 2);

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    top: -81px;
  }
`;

export const StyledImageCenter = styled(StyledImage)`
  position: relative;
  top: calc(111px / 2);
  @media (${({ theme }) => theme.breakpoints.tablet}) {
    top: 111px;
  }
`;
