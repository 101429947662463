import styled, { css } from "styled-components";
import {
  TextAreaProps,
  TextAreaWrapperProps,
} from "./TextArea.types";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextAreaWrapper = styled.div<TextAreaWrapperProps>`
  display: flex;
  position: relative;
  border: 1px solid ${({ theme }) => theme.color.stroke.lightGrey};
  border-radius: ${({ theme }) => theme.borderRadius.m};

  ${({ isError }) =>
    isError &&
    css`
      border: 2px solid ${({ theme }) => theme.color.informative.alert};
    `}
`;

export const StyledTextArea = styled.textarea`
  width: 100%;
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  padding: 11px ${({ theme }) => theme.spacing.s};

  font-size: 14px;
  font-family: inherit;
  color: ${({ theme }) => theme.color.solid.darkGrey};

  outline-offset: 1px;

  resize: vertical;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    padding: ${({ theme }) => theme.spacing.s};
    font-size: 16px;
  }

  &::placeholder {
    color: ${({ theme }) => theme.color.solid.grey};
  }
`;

const IconWrapper = styled.span<Pick<TextAreaProps, "onClick">>`
  height: 20px;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
`;

export const IconRightWrapper = styled(IconWrapper)<
  Pick<TextAreaProps, "onClick">
>`
  right: 0;

  padding-right: ${({ theme }) => theme.spacing.s};

  ${({ onClick }) =>
    onClick &&
    css`
      &,
      & > * {
        cursor: pointer;
      }
    `}
`;

export const IconLeftWrapper = styled(IconWrapper)<
  Pick<TextAreaProps, "onClick">
>`
  left: 0;

  padding-left: ${({ theme }) => theme.spacing.s};

  ${({ onClick }) =>
    onClick &&
    css`
      &,
      & > * {
        cursor: pointer;
      }
    `}
`;
