import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const SubmitRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  padding: ${({ theme }) => theme.spacing.s};
  background-color: ${({ theme }) => theme.color.background.primary};

  > * + * {
    margin-left: ${({ theme }) => theme.spacing.mt};
  }

  @media (${({ theme }) => theme.breakpoints.mobile}) {
    > * + * {
      margin-left: ${({ theme }) => theme.spacing.s};
    }

    button {
      white-space: nowrap;
    }
  }
`;
