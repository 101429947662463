import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SolidColors } from "styled-components";
import { Icons } from "../../../icons";
import { Icon } from "../../Icon";
import { Image } from "../../Image";
import { QuestionTabs } from "../../QuestionTabs";
import { Heading } from "../../Typography";
import { HeadingVariant } from "../../Typography/Heading/Heading.types";
import { Audio } from "./Audio";
import { QuestionHeader, TabContent } from "./Solution.styles";
import { SolutionProps, SolutionTabs } from "./Solution.types";

export function Solution({
  solutionFile,
  question,
  defaultTab = SolutionTabs.Solution,
}: SolutionProps) {
  const { formatMessage } = useIntl();

  const tabs = useMemo(
    () => [
      {
        key: SolutionTabs.Solution,
        title: formatMessage({
          id: "solutionList.solutions",
          defaultMessage: "Solutions",
        }),
        icon: "LightBulb" as Icons,
        color: "orange" as SolidColors,
        children: (
          <TabContent key="solution">
            {solutionFile?.map(({ id, file }) => (
              <Image key={id} src={file} />
            ))}
          </TabContent>
        ),
      },
      {
        key: SolutionTabs.Question,
        title: formatMessage({
          id: "solutionList.questions",
          defaultMessage: "Questions",
        }),
        icon: "QuestionMark" as Icons,
        color: "primary" as SolidColors,
        children: (
          <TabContent key="questions">
            {question &&
              question.questionFile?.map(({ id, file }) => (
                <Image key={id} src={file} />
              ))}
            {question.audioFile && <Audio src={question.audioFile} />}
          </TabContent>
        ),
      },
      {
        key: SolutionTabs.MarkingScheme,
        title: formatMessage({
          id: "solutionList.markingScheme",
          defaultMessage: "Marking scheme",
        }),
        icon: "Clipboard" as Icons,
        color: "purple" as SolidColors,
        children: (
          <TabContent key="markingScheme">
            {question.markingSchema &&
              question.markingSchema.markingSchemaFile?.map(({ id, file }) => (
                <Image key={id} src={file} />
              ))}
          </TabContent>
        ),
      },
    ],
    [formatMessage, question, solutionFile]
  );

  return (
    <div>
      <QuestionHeader gap="m">
        <Icon icon="Solution" noBackground />
        <div>
          <Heading as={HeadingVariant.H5}>
            <FormattedMessage
              id="solutionList.solution"
              defaultMessage="Solution to Question {number}, Paper {paper}, Section {section}, {year}"
              values={{
                number: question.number,
                paper: question.paper,
                section: question.section,
                year: question.year,
              }}
            />
          </Heading>
          <FormattedMessage
            id="solutionList.marks"
            defaultMessage="Marks: {marks}"
            values={{ marks: question.marks }}
          />
        </div>
      </QuestionHeader>
      <QuestionTabs
        tabs={tabs}
        defaultTab={tabs.findIndex(({ key }) => key === defaultTab)}
      />
    </div>
  );
}
