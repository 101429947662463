import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { api } from "../api";
import { authApi } from "../authApi";
import {
  RegisterData,
  RegisterResponse,
  ResendPasswordTokenData,
  ResendPasswordTokenErrorResponse,
  ResetPasswordConfirmData,
  ResetPasswordConfirmResponse,
  ResetPasswordData,
  ResetPasswordResponse,
  SetPasswordData,
  UserData,
} from "./Auth.types";
import { AuthEndpoints } from "./endpoints";

const register = async ({
  email,
  parentEmail,
  ...registerData
}: RegisterData) => {
  const { data } = await api.post<
    RegisterData,
    AxiosResponse<RegisterResponse>
  >(AuthEndpoints.Register, {
    ...registerData,
    email: email.toLocaleLowerCase(),
    parentEmail: parentEmail?.toLocaleLowerCase(),
  });

  return data;
};

export const useRegister = (
  options?: UseMutationOptions<
    RegisterResponse,
    AxiosError<{ detail: string }>,
    RegisterData
  >
) => useMutation(["register"], register, options);

const resetPassword = async (resetPasswordData: ResetPasswordData) => {
  try {
    const { data } = await api.post<
      ResetPasswordData,
      AxiosResponse<ResetPasswordResponse>
    >(AuthEndpoints.ResetPassword, resetPasswordData);

    return data;
  } catch (e: any) {
    return e.response;
  }
};

export const useResetPassword = (
  options?: UseMutationOptions<ResetPasswordResponse, Error, ResetPasswordData>
) => useMutation(["resetPassword"], resetPassword, options);

const resetPasswordConfirm = async (
  resetPasswordConfirmData: ResetPasswordConfirmData
) => {
  const { data } = await api.post<
    ResetPasswordConfirmData,
    AxiosResponse<ResetPasswordConfirmResponse>
  >(AuthEndpoints.ResetPasswordConfirm, resetPasswordConfirmData);

  return data;
};

export const useResetPasswordConfirm = (
  options?: UseMutationOptions<
    ResetPasswordConfirmResponse,
    Error,
    ResetPasswordConfirmData
  >
) =>
  useMutation(["resetPassword", "confirmation"], resetPasswordConfirm, options);

const getCurrentUser = async () => {
  const { data } = await authApi.get(AuthEndpoints.User);

  return data;
};

export const useGetCurrentUser = (
  options?: UseQueryOptions<UserData, Error, UserData, ["currentUser"]>
) => useQuery(["currentUser"], getCurrentUser, options);

const verifyResetPasswordToken = async (
  resendPasswordTokenData: ResendPasswordTokenData
) => {
  const { data } = await api.post<ResendPasswordTokenData, AxiosResponse<null>>(
    AuthEndpoints.ResetPasswordToken,
    resendPasswordTokenData
  );

  return data;
};

export const useVerifyResetPasswordToken = (
  options?: UseMutationOptions<
    null,
    AxiosError<ResendPasswordTokenErrorResponse>,
    ResendPasswordTokenData
  >
) =>
  useMutation(["verifyResetPasswordToken"], verifyResetPasswordToken, options);

const setPassword = async (setPasswordData: SetPasswordData) => {
  const { data } = await authApi.post<SetPasswordData, AxiosResponse<null>>(
    AuthEndpoints.SetPassword,
    setPasswordData
  );

  return data;
};

export const useSetPassword = (
  options?: UseMutationOptions<
    null,
    AxiosError<Partial<SetPasswordData>>,
    SetPasswordData
  >
) => useMutation(["password"], setPassword, options);

const editUser = async (userData: UserData) => {
  const { data } = await authApi.patch<UserData, AxiosResponse<null>>(
    AuthEndpoints.User,
    userData
  );

  return data;
};

export const useEditUser = (
  options?: UseMutationOptions<
    null,
    AxiosError<Record<Partial<keyof UserData>, string>>,
    UserData
  >
) => useMutation(["currentUser"], editUser, options);
