import { useMemo } from "react";
import {
  BrowserRouter,
  Routes as ReactRouterRoutes,
  Route,
  Navigate,
} from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import AuthService from "@api/auth/Auth.service";
import { UserRoles } from "@api/auth/Auth.types";
import { UserProvider, useUser } from "@utils/user/user-context";

import { BackToTop } from "@components/BackToTop";

import { Paths, routes } from "./routes";

type RouterProps = {
  children: JSX.Element | JSX.Element[];
};

type ProtectedRouteProps = {
  roles?: UserRoles[];
  children: JSX.Element;
};

function ProtectedRoute({ roles, children }: ProtectedRouteProps): JSX.Element {
  const isProtected = !!roles?.length;
  const isLoggedIn = AuthService.isLoggedIn();

  if (isProtected && !isLoggedIn) {
    return <Navigate to={Paths.Login} replace />;
  }

  return children;
}

function Routes() {
  const { currentUser } = useUser();

  const userRoutes = useMemo(
    () =>
      routes.filter(({ roles }) => {
        if (roles && currentUser?.role) {
          return roles?.includes(currentUser?.role);
        }

        return true;
      }),
    [currentUser?.role]
  );

  return (
    <ReactRouterRoutes>
      {userRoutes.map(({ roles, element, ...route }) => (
        <Route
          key={route.path}
          {...route}
          element={
            <ProtectedRoute roles={roles}>
              {element as JSX.Element}
            </ProtectedRoute>
          }
        />
      ))}
    </ReactRouterRoutes>
  );
}

export function Router({ children }: RouterProps) {
  return (
    <BrowserRouter>
      <UserProvider>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <BackToTop>
            <Routes />
            {children}
          </BackToTop>
        </QueryParamProvider>
      </UserProvider>
    </BrowserRouter>
  );
}
