import { exportEndpointsEnum } from "../../utils/helpers";

enum AuthEndpointsEnum {
  CreateToken = `/jwt/create/`,
  RefreshToken = `/jwt/refresh/`,
  VerifyToken = `/jwt/verify/`,
  Blacklist = `/jwt/blacklist/`,
  Register = `/register/`,
  ResetPassword = `/reset-password/`,
  ResetPasswordConfirm = `/reset-password-confirm/`,
  Activation = `/activation/`,
  ParentActivation = `/parent-activation/`,
  User = "/user/",
  ResetPasswordToken = `/reset-password-token/`,
  SetPassword = "/user/set-password/",
}

export const AuthEndpoints = exportEndpointsEnum<typeof AuthEndpointsEnum>(
  "auth",
  AuthEndpointsEnum
);
