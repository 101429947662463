import { UserRoles } from "@api/auth/Auth.types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { api } from "../api";
import { SchoolEndpoints } from "./endpoints";
import { SchoolParams, SchoolResponse } from "./School.types";

type SchoolSearchParam = Pick<SchoolParams, "search"> | undefined;

const getSchools = async (userRole: UserRoles, search?: SchoolSearchParam) => {
  if (!search) {
    return undefined;
  }

  const { data } = await api.get(
    `${SchoolEndpoints.School}?role=${userRole}&search=${search}`
  );

  return data;
};

export const useGetSchools = (
  userRole: UserRoles,
  search?: SchoolSearchParam,
  options?: UseQueryOptions<
    SchoolResponse,
    Error,
    SchoolResponse,
    ["schools", UserRoles, SchoolSearchParam]
  >
) =>
  useQuery(
    ["schools", userRole, search],
    () => getSchools(userRole, search),
    options
  );
