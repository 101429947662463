import axios from "axios";
import { camelCase, snakeCase } from "change-case";

import AuthService from "./auth/Auth.service";
import { caseConverter } from "../utils/case-converter";

export const authApi = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    Authorization: `Bearer ${AuthService.getAccessToken()}`,
  },
});

let failedQueue: Array<any> = [];
let isRefreshing = false;

const processQueue = (error: any, token = null) => {
  failedQueue.forEach((promise: any) => {
    if (error) {
      promise.reject(error);
    } else {
      promise.resolve(token);
    }
  });

  failedQueue = [];
};

authApi.interceptors.request.use(
  (config) => ({
    ...config,
    data: config.data ? caseConverter(config.data, snakeCase) : config.data,
    params: config.params
      ? caseConverter(config.params, snakeCase)
      : config.params,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${AuthService.getAccessToken()}`,
    },
  }),
  (error) => Promise.reject(error)
);

authApi.interceptors.response.use(
  (response) => ({
    ...response,
    data: caseConverter(response.data, camelCase),
  }),
  async (error) => {
    const originalRequest = error.config;
    const statusCode = error?.response.status;

    if (statusCode === 401 && !originalRequest.retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            authApi(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      originalRequest.retry = true;
      isRefreshing = true;

      return new Promise((resolve, reject) => {
        AuthService.refreshToken()
          .then((data: any) => {
            processQueue(null, data.refresh);
            resolve(authApi(originalRequest));
          })
          .catch((refreshTokenError) => {
            processQueue(refreshTokenError, null);
            reject(refreshTokenError);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      ...error,
      response: {
        ...error.response,
        data: caseConverter(error.response.data, camelCase),
      },
    });
  }
);
