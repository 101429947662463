import styled, { css } from "styled-components";

import { ProgressBarProps } from "./ProgressBar.types";

export const Bar = styled.div`
  height: 8px;
  border-radius: ${({ theme }) => theme.borderRadius.s};
  position: relative;
  background: ${({ theme }) => theme.color.solid.lightGrey}};
`;

export const Progress = styled.span<ProgressBarProps>`
  display: block;
  height: 100%;

  border-radius: ${({ theme }) => theme.borderRadius.s};

  transition: width 0.3s ease-in;

  ${({ progress }) =>
    css`
      width: ${progress >= 100 ? 100 : progress}%;
    `};

  background-color: ${({ theme, color, progress }) => {
    if (color) {
      return color;
    }

    if (progress === 100) {
      return theme.color.informative.accept;
    }

    if (progress > 33) {
      return theme.color.informative.info;
    }

    return theme.color.informative.alert;
  }};
`;
