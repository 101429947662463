import { useCallback, useMemo } from "react";
import type {
  TableProps,
  TableColumn,
  SortOrder as RDTSortOrder,
} from "react-data-table-component";
import { useIntl } from "react-intl";

import { Exam } from "@api/exam-creator/ExamCreator.types";
import { SortOrder } from "@utils/types";

import { DataTable } from "@components/DataTable";

import { ActionButtons } from "../ActionButtons";
import { ExamBadge } from "../ExamBadge";
import { ExamsListProps } from "./ExamsTable.types";

const sortOrderMap: Record<RDTSortOrder, SortOrder> = {
  asc: SortOrder.Ascending,
  desc: SortOrder.Descending,
};

export function ExamsTable({
  exams,
  sortProps: { defaultColumn, defaultOrder, onSort } = {},
}: ExamsListProps) {
  const { formatMessage, formatDate } = useIntl();

  const columns: TableColumn<Exam>[] = useMemo(
    () => [
      {
        id: "title",
        name: formatMessage({ id: "examsTable.name", defaultMessage: "Name" }),
        sortable: true,
        selector: ({ title }) => title,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: ({ title }) => <strong>{title}</strong>,
        sortField: "title",
      },
      {
        name: formatMessage({ id: "examsTable.exam", defaultMessage: "Exam" }),
        selector: ({ topic }) => topic.subjectLevel.subject.examType.shortName,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: ({ topic }) => (
          <ExamBadge
            isDark={topic.subjectLevel.subject.examType.shortName === "LC"}
          >
            {topic.subjectLevel.subject.examType.shortName}
          </ExamBadge>
        ),
        grow: 0,
        minWidth: "73px",
      },
      {
        id: "topic__subject_level__subject__name",
        name: formatMessage({
          id: "examsTable.examPaper",
          defaultMessage: "Exam paper",
        }),
        sortable: true,
        selector: ({ topic }) => topic.subjectLevel.subject.name,
        grow: 1,
        maxWidth: "167px",
      },
      {
        id: "topic__name",
        name: formatMessage({
          id: "examsTable.topic",
          defaultMessage: "Topic",
        }),
        sortable: true,
        selector: ({ topic }) => topic.name,
        maxWidth: "223px",
      },
      {
        name: formatMessage({
          id: "examsTable.level",
          defaultMessage: "Level",
        }),
        selector: ({ topic }) => topic.subjectLevel.level.shortName!,
        grow: 0,
      },
      {
        name: formatMessage({
          id: "examsTable.lastChange",
          defaultMessage: "Last change",
        }),
        cell: ({ updatedAt, createdAt }) => formatDate(updatedAt || createdAt),
        grow: 0,
        minWidth: "130px",
      },
      {
        cell: ActionButtons,
        button: true,
        width: "312px",
      },
    ],
    [formatDate, formatMessage]
  );

  const handleSort = useCallback<Required<TableProps<Exam>>["onSort"]>(
    ({ id }, sortOrder) => {
      if (
        !onSort ||
        (id === defaultColumn && sortOrderMap[sortOrder] === defaultOrder)
      ) {
        return;
      }

      onSort({
        ordering: `${id}`,
        sortOrder:
          sortOrder === "desc" ? SortOrder.Descending : SortOrder.Ascending,
      });
    },
    [defaultColumn, defaultOrder, onSort]
  );

  return (
    <DataTable
      data={exams.data}
      columns={columns}
      sortServer
      defaultSortFieldId={defaultColumn}
      defaultSortAsc={defaultOrder !== SortOrder.Descending}
      onSort={handleSort}
    />
  );
}
