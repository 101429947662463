import styled from "styled-components";

import { ButtonLink } from "@components/Button";

export const StyledLink = styled.a`
  display: flex;
  padding-left: ${({ theme }) => theme.spacing.s};
  text-align: center;
  text-transform: capitalize;
`;

export const Container = styled.div`
  margin: 0 auto;
  padding-top: ${({ theme }) => theme.spacing.l};
  padding-right: ${({ theme }) => theme.spacing.m};
  padding-left: ${({ theme }) => theme.spacing.m};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    padding-right: ${({ theme }) => theme.spacing.l};
    padding-left: ${({ theme }) => theme.spacing.l};
  }

  @media (${({ theme }) => theme.breakpoints.desktopPlus}) {
    padding-left: ${({ theme }) => theme.spacing.xl};
    padding-right: ${({ theme }) => theme.spacing.xl};
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: ${({ theme }) => theme.borderRadius.l};
  border-top-right-radius: ${({ theme }) => theme.borderRadius.l};

  padding-top: ${({ theme }) => theme.spacing.xl};
  padding-right: ${({ theme }) => theme.spacing.m};
  padding-bottom: ${({ theme }) => theme.spacing.xl};
  padding-left: ${({ theme }) => theme.spacing.m};

  background-color: ${({ theme }) => theme.color.background.light};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    flex-wrap: nowrap;
    justify-content: flex-end;
    padding-right: ${({ theme }) => theme.spacing.l};
    padding-left: ${({ theme }) => theme.spacing.l};
  }

  @media (${({ theme }) => theme.breakpoints.desktopPlus}) {
    justify-content: flex-start;
    padding: 56px 109px 118px;
  }

  & a:nth-child(1) {
    display: flex;
  }
`;

export const LinksContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  padding-left: ${({ theme }) => theme.spacing.m};
`;

export const ButtonsContainer = styled.div`
  order: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.l};

  > * + * {
    margin-left: ${({ theme }) => theme.spacing.m};
  }

  @media (min-width: 570px) {
    order: unset;
    flex-direction: row;
    width: auto;
    margin-top: 0;

    > * + * {
      margin-left: ${({ theme }) => theme.spacing.s};
    }
  }

  @media (min-width: 600px) {
    margin-right: ${({ theme }) => theme.spacing.m};
  }

  @media (${({ theme }) => theme.breakpoints.desktopPlus}) {
    margin-right: 0;
  }
`;

export const MenuButton = styled(ButtonLink)`
  width: 140px;
  min-width: auto;
`;
