import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import { NotificationContainer } from "./notification-context.styles";
import { Notification } from "../../components/Alert";
import { NotificationProps } from "../../components/Alert/Alert.types";

type NotificationContextType = {
  addNotification: (item: NotificationProps) => void;
};

interface NotificationProviderProps {
  children: ReactNode;
}

export const NotificationContext = createContext<NotificationContextType>(
  {} as NotificationContextType
);

function NotificationProvider({ children }: NotificationProviderProps) {
  const [notifications, setNotifications] = useState<NotificationProps[]>([]);

  const deleteNotification = useCallback((id: string) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((n) => n.id !== id)
    );
  }, []);

  const addNotification = useCallback((item: NotificationProps) => {
    setNotifications((prevNotifications) => {
      const match = prevNotifications.find(
        (existing) => existing.id === item.id
      )!;

      if (match) {
        return prevNotifications;
      }

      return [...prevNotifications, item];
    });
  }, []);

  const providerValue = useMemo(
    () => ({
      addNotification,
    }),
    [addNotification]
  );

  return (
    <NotificationContext.Provider value={providerValue}>
      <>
        <NotificationContainer>
          {notifications.map((notification) => (
            <Notification
              key={notification.id}
              id={notification.id}
              variant={notification.variant}
              onClick={deleteNotification}
              autoRemove={notification.autoRemove}
              autoRemoveDelay={notification.autoRemoveDelay}
              text={notification.text}
              title={notification.title}
            />
          ))}
        </NotificationContainer>
        {children}
      </>
    </NotificationContext.Provider>
  );
}

const useNotification = () => useContext(NotificationContext);

export { NotificationProvider, useNotification };
