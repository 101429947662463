import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useResetPassword } from "../../api/auth/Auth.queries";
import { Box } from "../../components/Box";
import { ButtonLink, ButtonVariants } from "../../components/Button";
import { ForgotPasswordForm } from "../../components/ForgotPasswordForm";
import { ForgotPasswordFormData } from "../../components/ForgotPasswordForm/ForgotPasswordForm.types";
import { TwoColumnsLayout } from "../../components/TwoColumnsLayout";
import { Heading, Paragraph } from "../../components/Typography";
import { HeadingVariant } from "../../components/Typography/Heading/Heading.types";
import { ParagraphVariants } from "../../components/Typography/Paragraph/Paragraph.types";
import { Paths } from "../../utils/router";

export function ForgotPassword() {
  const [isEmailSent, setEmailSent] = useState<boolean>(false);

  const { mutate } = useResetPassword({
    onSuccess: () => {
      setEmailSent(true);
    },
  });

  const handleSubmit = (data: ForgotPasswordFormData) => {
    mutate(data);
  };

  if (isEmailSent) {
    return (
      <TwoColumnsLayout backgroundImage="/img/email-sent.svg">
        <Box mb="s">
          <Heading as={HeadingVariant.H4} align="center">
            <FormattedMessage
              id="forgotPassword.emailSentTitle"
              defaultMessage="Check your Email"
            />
          </Heading>
        </Box>
        <Box my="l">
          <Paragraph align="center">
            <FormattedMessage
              id="forgotPassword.emailSentDescription"
              defaultMessage="If you have an account on ExamHub, you will receive an email with the confirmation link to reset your password within the next few moments. Please click on the link to create a new password."
            />
          </Paragraph>
        </Box>
        <Box mt="m" mb="m" mx="auto">
          <ButtonLink to={Paths.Login} variant={ButtonVariants.Primary}>
            <FormattedMessage
              id="forgotPassword.emailSentButton"
              defaultMessage="Ok"
            />
          </ButtonLink>
        </Box>
      </TwoColumnsLayout>
    );
  }

  return (
    <TwoColumnsLayout>
      <Box mb="s">
        <Heading as={HeadingVariant.H3} align="center">
          <FormattedMessage
            id="forgotPassword.title"
            defaultMessage="Reset your password"
          />
        </Heading>
      </Box>
      <Box mt="s" mb="s">
        <Paragraph variant={ParagraphVariants.Big} align="center">
          <FormattedMessage
            id="forgotPassword.description"
            defaultMessage="Please enter the email address you used to register, so we can send you a link to reset the password."
          />
        </Paragraph>
      </Box>
      <Box mt="s" mb="l">
        <ForgotPasswordForm onSubmit={handleSubmit} />
      </Box>
      <Box mt="xs">
        <Paragraph>
          <FormattedMessage
            id="forgotPassword.backToLogin"
            defaultMessage="Go to {loginPageLink}"
            values={{
              loginPageLink: (
                <Link to={Paths.Login}>
                  <FormattedMessage
                    id="forgotPassword.loginLink"
                    defaultMessage="Log in Page"
                  />
                </Link>
              ),
            }}
          />
        </Paragraph>
      </Box>
      <Box mt="xs">
        <Paragraph>
          <FormattedMessage
            id="forgotPassword.register"
            defaultMessage="Don't have an account? {registerLink}"
            values={{
              registerLink: (
                <Link to={Paths.StudentRegistration}>
                  <FormattedMessage
                    id="forgotPassword.registerLink"
                    defaultMessage="Register"
                  />
                </Link>
              ),
            }}
          />
        </Paragraph>
      </Box>
    </TwoColumnsLayout>
  );
}
