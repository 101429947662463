import { forwardRef } from "react";
import { Link, LinkProps } from "react-router-dom";
import { Icon } from "../Icon";

import { StyledButton } from "./Button.styles";
import { ButtonLinkProps, ButtonProps, ButtonVariants } from "./Button.types";

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      variant = ButtonVariants.Primary,
      type = "button",
      iconRight,
      ...props
    },
    ref
  ) => (
    <StyledButton ref={ref} type={type} variant={variant} {...props}>
      {children}
      {iconRight && <Icon icon={iconRight} noBackground />}
    </StyledButton>
  )
);

export const ButtonLink = forwardRef<
  HTMLAnchorElement,
  ButtonLinkProps & LinkProps
>(
  (
    { children, variant = ButtonVariants.Primary, iconRight, ...props },
    ref
  ) => (
    <StyledButton as={Link} ref={ref} variant={variant} {...props}>
      {children}
      {iconRight && <Icon icon={iconRight} noBackground />}
    </StyledButton>
  )
);

export const ButtonAnchor = forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  (
    { children, variant = ButtonVariants.Primary, iconRight, ...props },
    ref
  ) => (
    <StyledButton as="a" ref={ref} variant={variant} {...props}>
      {children}
      {iconRight && <Icon icon={iconRight} noBackground />}
    </StyledButton>
  )
);
