import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useEditUser } from "@api/auth/Auth.queries";

import { useUser } from "@utils/user/user-context";

import { Button, ButtonVariants } from "@components/Button";
import { Input } from "@components/Input";
import { SchoolSelect } from "@components/SchoolSelect";

import { ButtonsRow } from "../MyAccount.styles";
import {
  UserDetailsFormProps,
  UserDetailsFormValues,
} from "./UserDetailsForm.types";
import { useUserDetailsValidation } from "./UserDetailsForm.validation";

export function UserDetailsForm({ handleSubmitSuccess }: UserDetailsFormProps) {
  const { formatMessage } = useIntl();
  const { currentUser, isTeacher } = useUser();
  const [formValues, setFormValues] = useState();

  const {
    register,
    control,
    handleSubmit,
    setError,
    formState: { errors, isDirty, isValid },
    reset,
  } = useForm<UserDetailsFormValues>({
    mode: "onChange",
    resolver: yupResolver(useUserDetailsValidation()),
    defaultValues: {
      ...currentUser,
      school: currentUser?.school && {
        label: `${currentUser.school.rollNo}, ${currentUser.school.name}`,
        value: currentUser.school.id,
      },
    },
  });

  const { mutate, isLoading } = useEditUser({
    onSuccess: handleSubmitSuccess,
    onError: (error) => {
      const [firstFieldErrorEntry] = error?.response?.data
        ? Object.entries(error?.response?.data)
        : [[]];
      const [fieldName, errorMessages] = firstFieldErrorEntry;

      if (fieldName && errorMessages[0]) {
        setError(fieldName as keyof UserDetailsFormValues, {
          message: errorMessages[0],
        });
      }
    },
  });

  useEffect(() => {
    reset(formValues);
  }, [formValues, reset]);

  const onSubmit = (data: any) => {
    setFormValues(data);

    mutate({
      ...data,
      phone: data.phone || null,
      school: data.school?.value || null,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        {...register("firstName", { required: true })}
        label={formatMessage({
          id: "userDetails.firstNameLabel",
          defaultMessage: "First name",
        })}
        errorMessage={errors.firstName?.message}
        disabled
      />

      <Input
        {...register("lastName", { required: true })}
        label={formatMessage({
          id: "userDetails.lastNameLabel",
          defaultMessage: "Surname",
        })}
        errorMessage={errors.lastName?.message}
        disabled
      />

      <Input
        {...register("email", { required: true })}
        required
        type="email"
        label={formatMessage({
          id: "userDetails.emailLabel",
          defaultMessage: "Email address",
        })}
        errorMessage={errors.email?.message}
        disabled
      />

      {currentUser?.under_16 && (
        <Input
          {...register("parentEmail", { required: true })}
          required
          type="parentEmail"
          label={formatMessage({
            id: "userDetails.parentEmailLabel",
            defaultMessage: "Parent/Guardian Email address",
          })}
          errorMessage={errors.parentEmail?.message}
          disabled
          isRequired={!!currentUser?.under_16}
        />
      )}

      {isTeacher ? (
        <Input
          {...register("teachingCouncilNumber", { required: true })}
          required
          isRequired
          label={formatMessage({
            id: "userDetails.teachingCouncilNumberLabel",
            defaultMessage: "Teaching Council Number",
          })}
          errorMessage={errors.teachingCouncilNumber?.message}
        />
      ) : null}

      <Controller
        name="school"
        control={control}
        render={({
          field: { onChange, value, ref, name },
          fieldState: { error },
        }) => (
          <SchoolSelect
            name={name}
            label={
              isTeacher
                ? formatMessage({
                    id: "userDetails.schoolTeacherLabel",
                    defaultMessage: "School Roll Number",
                  })
                : formatMessage({
                    id: "userDetails.schoolStudentLabel",
                    defaultMessage: "School name",
                  })
            }
            placeholder={
              isTeacher ? "Enter your number" : "Enter your school name"
            }
            onChange={onChange}
            value={value}
            ref={ref}
            userRole={currentUser!.role}
            errorMessage={error?.message}
            isRequired={isTeacher}
          />
        )}
      />

      {isTeacher ? (
        <Input
          {...register("phone")}
          type="phone"
          label={formatMessage({
            id: "userDetails.phoneLabel",
            defaultMessage: "Phone number",
          })}
          errorMessage={errors.phone?.message}
        />
      ) : null}

      <ButtonsRow>
        <Button
          type="submit"
          variant={ButtonVariants.Primary}
          disabled={isLoading || !(isDirty && isValid)}
        >
          <FormattedMessage
            id="userDetails.submitButton"
            defaultMessage="Save"
          />
        </Button>
      </ButtonsRow>
    </form>
  );
}
