import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import { useGetExamsList } from "@api/exam-creator/ExamCreator.queries";
import { useQuestionsFilters } from "@utils/questions-filters/questions-filters-context";
import { Box } from "@components/Box";
import { Button, ButtonVariants } from "@components/Button";
import { Select } from "@components/Select";
import { Heading, Paragraph } from "@components/Typography";
import { HeadingVariant } from "@components/Typography/Heading/Heading.types";
import { ButtonsRow } from "@components/ButtonsRow";

import {
  AddQuestionFormData,
  AddQuestionFormProps,
} from "./AddQuestionForm.types";

export function AddQuestionForm({
  onSubmit,
  onClose,
  questions,
  defaultValues,
}: AddQuestionFormProps) {
  const { formatMessage } = useIntl();

  const { filters } = useQuestionsFilters();

  const { data } = useGetExamsList({ subject: filters.subject! });

  const createExamOption = useMemo(
    () => ({
      value: "-1",
      label: formatMessage({
        id: "addQuestionToExam.createExamOption",
        defaultMessage: "New exam",
      }),
    }),
    [formatMessage]
  );

  const options = useMemo(
    () => [
      createExamOption,
      ...(data
        ? data.map(({ id: value, title: label }) => ({ value, label }))
        : []),
    ],
    [createExamOption, data]
  );

  const { control, handleSubmit } = useForm<AddQuestionFormData>({
    defaultValues: defaultValues || {
      examId: options[0].value,
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mb="ml">
        <Heading as={HeadingVariant.H3} align="center">
          <FormattedMessage
            id="addQuestionToExam.title"
            defaultMessage="Add to exam"
          />
        </Heading>
      </Box>

      <Box mb="s">
        <Paragraph align="center">
          <FormattedMessage
            id="addQuestionToExam.selectedQuestions"
            defaultMessage="You’ve selected {questionsAmount} questions."
            values={{
              questionsAmount: questions.length,
            }}
          />
        </Paragraph>
        <Paragraph align="center">
          <FormattedMessage
            id="addQuestionToExam.chooseAnExam"
            defaultMessage="Choose an exam that you want to add this questions to."
          />
        </Paragraph>
      </Box>

      <Box pt="s">
        <Controller
          name="examId"
          control={control}
          rules={{ required: true }}
          render={({
            field: { onChange, name, value, ref },
            fieldState: { error },
          }) => (
            <Select
              ref={ref}
              name={name}
              value={options?.find((option) => option.value === value) || null}
              onChange={({ value: newValue }) => onChange(newValue)}
              errorMessage={error?.message}
              label={formatMessage({
                id: "addQuestionToExam.examSelectLabel",
                defaultMessage: "Exam",
              })}
              options={options}
              isSearchable={false}
              placeholder="-"
              isDisabled={!options || !!defaultValues?.examId}
            />
          )}
        />
      </Box>

      <ButtonsRow>
        <Button type="submit" variant={ButtonVariants.Primary} block>
          <FormattedMessage
            id="addQuestionToExam.addButton"
            defaultMessage="Add"
          />
        </Button>

        <Button
          type="button"
          onClick={onClose}
          variant={ButtonVariants.Secondary}
          block
        >
          <FormattedMessage
            id="addQuestionToExam.cancelButton"
            defaultMessage="Cancel"
          />
        </Button>
      </ButtonsRow>
    </form>
  );
}
