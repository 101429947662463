import styled from "styled-components";

export const ExamBadge = styled.span<{ isDark?: boolean; }>`
  display: block;
  border: 1px solid ${({ theme }) => theme.color.solid.lightGrey};
  border-radius: ${({ theme }) => `${theme.borderRadius.m} 0`};
  padding: 8px 12px;
  background-color: ${({ isDark, theme }) => isDark
    ? theme.color.solid.lightGrey
    : theme.color.solid.white
  };
  font-size: 12px;
`;