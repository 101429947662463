import { forwardRef, useCallback, useMemo, useState } from "react";
import debounce from "lodash.debounce";

import { SchoolParams } from "@api/school/School.types";
import { useGetSchools } from "@api/school/School.queries";

import { Select, SelectProps } from "@components/Select";
import { UserRoles } from "@api/auth/Auth.types";

type InternalProps = "onInputChange" | "options" | "onChange" | "ref";

interface SchoolSelectProps extends Omit<SelectProps, InternalProps> {
  name: string;
  userRole: UserRoles;
  onChange: (data: any) => void;
}

export const SchoolSelect = forwardRef<typeof Select, SchoolSelectProps>(
  ({ userRole, onChange, ...props }, ref) => {
    const [searchQuery, setSearchQuery] =
      useState<Pick<SchoolParams, "search">>();

    const { data: schools } = useGetSchools(userRole, searchQuery);

    const schoolOptions = useMemo(
      () =>
        schools?.data.map(({ id, rollNo, name, address }) => ({
          label:
            userRole === UserRoles.Teacher
              ? `${rollNo}, ${name}`
              : `${name}, ${address}`,
          value: id,
        })),
      [schools?.data, userRole]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleInputChange = useCallback(
      debounce((newValue) => {
        if (newValue) {
          setSearchQuery(newValue);
        }
      }, 250),
      []
    );

    return (
      <Select
        ref={ref}
        isClearable
        hideButton
        options={schoolOptions}
        onChange={onChange}
        onInputChange={handleInputChange}
        {...props}
      />
    );
  }
);
