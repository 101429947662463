import styled, { css, keyframes } from "styled-components";

import { CoverImageProps } from "./PaperBox.types";

const pointerEvents = keyframes`
  0%, 99% {
    pointer-events: none;
  }

  100% {
    pointer-events: all;
  }
`;

export const HoverMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.s};
  background-color: rgba(67, 101, 233, 0.95);

  overflow: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  transition: opacity 0.25s ease-in;

  > * + * {
    margin-top: ${({ theme }) => theme.spacing.s};
  }
`;

export const Container = styled.div<{ imageToBottom?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 330px;

  position: relative;

  overflow: hidden;

  padding: ${({ theme }) => theme.spacing.m};
  padding-bottom: ${({ imageToBottom, theme }) =>
    imageToBottom ? "150px" : theme.spacing.l};

  border: 1px solid ${({ theme }) => theme.color.stroke.blue};
  border-radius: 0px 24px;

  background-color: ${({ theme }) => theme.color.background.light};

  &:focus,
  &:focus-within,
  &:hover {
    outline: none;

    ${HoverMenu} {
      opacity: 1;
      animation: ease-in ${pointerEvents} 200ms forwards;
    }
  }
`;

export const ExamTypeLabel = styled.span<{ variant: "JC" | "LC" }>`
  position: absolute;
  top: -1px;
  left: -1px;

  background: ${({ theme, variant }) =>
    variant === "JC" ? theme.color.solid.white : "#E7E7E7"};
  border: 1px solid #e7e7e7;
  border-radius: ${({ theme }) => `0 0 ${theme.borderRadius.m} 0`};
  padding: ${({ theme }) => `${theme.spacing.xs} 12px`};

  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.color.solid.darkGrey};
`;

export const CoverImage = styled.div<CoverImageProps>`
  ${({ imageToBottom }) =>
    imageToBottom &&
    `
    position: absolute;
    bottom: -20px;
  `}
  flex-grow: 1;
  width: 170px;

  > figure {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  img {
    display: block;
    -webkit-touch-callout: none;
    /* margin: 0 auto; */
  }

  ${({ isActive }) =>
    !isActive &&
    css`
      filter: grayscale(1);
    `}
`;
