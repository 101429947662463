import { SolidColors } from "styled-components";

export enum ParagraphVariants {
  Big = "big",
  Small = "small",
}

export type TextAlignment = "left" | "center" | "right";

export type ParagraphProps = {
  color?: SolidColors;
  variant?: ParagraphVariants;
  align?: TextAlignment;
};
