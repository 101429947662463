import styled, { css } from "styled-components";
import { ContainerProps } from "./Modal.types";

export const Overlay = styled.div`
  z-index: 5;

  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: rgba(101, 100, 121, 0.75);
`;

export const Container = styled.div`
  z-index: 6;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  padding: ${({ theme }) => theme.spacing.m};
`;

export const InnerContainer = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  position: relative;

  width: 100%;
  max-width: 628px;

  background-color: ${({ theme }) => theme.color.solid.white};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  box-shadow: 0px 0px 1px rgba(50, 103, 241, 0.25),
    0px 24px 48px rgba(50, 103, 241, 0.1);

  padding-top: ${({ theme }) => theme.spacing.m};
  padding-right: ${({ theme }) => theme.spacing.s};
  padding-bottom: ${({ theme }) => theme.spacing.m};
  padding-left: ${({ theme }) => theme.spacing.s};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    padding: ${({ theme }) => theme.spacing.xl} 109px 56px;
  }

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: initial;
      max-width: 100%;
      min-width: 100%;

      @media (${({ theme }) => theme.breakpoints.desktop}) {
        min-width: 0;
      }
    `}

  ${({ fullHeight }) =>
    fullHeight &&
    css`
      height: 100%;
    `}
`;

export const CloseButton = styled.button`
  @media (${({ theme }) => theme.breakpoints.tablet}) {
    display: block;
  }

  display: none;
  position: absolute;
  top: 40px;
  right: 32px;

  width: 20px;
  height: 20px;

  border: none;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 5L5 15' stroke='%2388899F' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5 5L15 15' stroke='%2388899F' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
`;
