import styled from "styled-components";

export const Column = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  margin: ${({ theme }) => theme.spacing.xl};
  background-color: ${({ theme }) => theme.color.background.light};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    height: 250px;
    margin-top: ${({ theme }) => theme.spacing.l};
    margin-bottom: 0;
  }

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
    max-width: 50%;
    height: auto;
    margin: 0;
    padding: ${({ theme }) => theme.spacing.xl};
    background-color: ${({ theme }) => theme.color.background.light};
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    max-width: 600px;
    margin-top: 135px;
  }
`;
