import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { Box } from "../Box";
import { Button, ButtonVariants } from "../Button";
import { PasswordInput } from "../PasswordInput";
import { StyledForm } from "./CreateNewPasswordForm.styles";
import {
  CreateNewPasswordFormData,
  CreateNewPasswordProps,
} from "./CreateNewPasswordForm.types";
import { useCreateNewPasswordFormValidation } from "./CreateNewPasswordForm.validation";

export function CreateNewPasswordForm({ onSubmit }: CreateNewPasswordProps) {
  const { formatMessage } = useIntl();
  const [passwordStrength, setPasswordStrength] = useState(0);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateNewPasswordFormData>({
    mode: "onChange",
    resolver: yupResolver(
      useCreateNewPasswordFormValidation(setPasswordStrength)
    ),
  });

  return (
    <StyledForm noValidate onSubmit={handleSubmit(onSubmit)}>
      <Box pb="m">
        <PasswordInput
          {...register("newPassword", { required: true })}
          label={formatMessage({
            id: "studentRegistrationForm.password",
            defaultMessage: "Password",
          })}
          placeholder={formatMessage({
            id: "studentRegistrationForm.passwordPlaceholder",
            defaultMessage: "Enter password",
          })}
          errorMessage={errors.newPassword?.message}
          caption={formatMessage({
            id: "passwordInput.caption",
            defaultMessage: "Minimum 8 characters (capital letter and number)",
          })}
          progress={passwordStrength}
        />
        <PasswordInput
          {...register("reNewPassword", { required: true })}
          label={formatMessage({
            id: "studentRegistrationForm.password",
            defaultMessage: "Password",
          })}
          placeholder={formatMessage({
            id: "studentRegistrationForm.passwordPlaceholder",
            defaultMessage: "Enter password",
          })}
          errorMessage={errors.reNewPassword?.message}
        />
      </Box>
      <Box pt="m">
        <Button type="submit" variant={ButtonVariants.Primary} block>
          <FormattedMessage
            id="createNewPasswordForm.submitButton"
            defaultMessage="Confirm"
          />
        </Button>
      </Box>
    </StyledForm>
  );
}
