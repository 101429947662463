import { useBreakpoints } from "@utils/use-breakpoints";

import { EmptyListBox } from "@components/EmptyListBox";
import { Loader } from "@components/Loader";
import { Pagination } from "@components/Pagination";

import { ExamsTable } from "./ExamsTable";
import { ExamBox } from "./ExamBox";
import { ExamsListMode, ExamsListProps } from "./ExamsList.types";

export function ExamsList({
  exams,
  mode = ExamsListMode.Mixed,
  isLoading,
  showPagination = true,
  paginationProps,
  sortProps
}: ExamsListProps) {
  const { isDesktop } = useBreakpoints();

  if (isLoading) {
    return <Loader />;
  }

  if (!exams || !exams.data.length) {
    return <EmptyListBox variant="primary" />;
  }

  return (
    <>
      {mode === ExamsListMode.Table || (mode === ExamsListMode.Mixed && isDesktop)
      ? (
        <ExamsTable
          exams={exams}
          sortProps={sortProps}
        />
      ) : (
        exams.data.map((exam) => <ExamBox key={exam.id} {...exam} />)
      )}

      {showPagination && (
        <Pagination
          {...paginationProps!}
        />
      )}
    </>
  );
}
