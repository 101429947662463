import { useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { useGetQuestionsFilters } from "@api/paper/Paper.queries";
import {
  QuestionsFiltersFilters,
  QuestionsFiltersResponse,
} from "@api/paper/Paper.types";
import { useQuestionsFilters } from "@utils/questions-filters/questions-filters-context";

import { Filters } from "@components/Filters";
import { FiltersPanel } from "@components/FiltersPanel";

import { Container } from "./QuestionsFilters.styles";

interface QuestionsFiltersProps {
  disabledFields?: string[];
}

const optionsToFilterMap: Record<
  keyof QuestionsFiltersResponse,
  keyof QuestionsFiltersFilters
> = {
  examTypes: "examType",
  levels: "level",
  subjects: "subject",
  topics: "topic",
};

export function QuestionsFilters({
  disabledFields = [],
}: QuestionsFiltersProps) {
  const { formatMessage } = useIntl();
  const { filters, changeFilters } = useQuestionsFilters();
  const [innerFiltersState, setInnerFiltersState] = useState(filters);

  const { data, isFetching } = useGetQuestionsFilters(innerFiltersState, {
    retry: false,
    keepPreviousData: true,
    onSuccess: (response) => {
      const defaultFilters = Object.fromEntries(
        Object.entries(response)
          .filter(([, value]) => value?.length === 1)
          .map(([filter, value]) => [
            optionsToFilterMap[filter as keyof QuestionsFiltersResponse],
            value[0].id,
          ])
      );

      setInnerFiltersState({ ...innerFiltersState, ...defaultFilters });
    },
  });

  const fields = useMemo(
    () => [
      {
        name: "examType",
        label: formatMessage({
          id: "questionsFilters.exam",
          defaultMessage: "Exam",
        }),
        options: data?.examTypes?.map(({ id, shortName }) => ({
          value: id,
          label: shortName,
        })),
        required: true,
        maxWidth: 93,
        disabled: disabledFields?.includes("examType"),
      },
      {
        name: "subject",
        label: formatMessage({
          id: "questionsFilters.examPaper",
          defaultMessage: "Exam paper",
        }),
        options: data?.subjects?.map(({ id, name }) => ({
          value: id,
          label: name,
        })),
        required: true,
        maxWidth: 219,
        disabled: disabledFields?.includes("subject"),
      },
      {
        name: "level",
        label: formatMessage({
          id: "questionsFilters.level",
          defaultMessage: "Exam level",
        }),
        options: data?.levels?.map(({ id, name }) => ({
          value: id,
          label: name,
        })),
        required: true,
        maxWidth: 128,
        disabled: disabledFields?.includes("level"),
      },
      {
        name: "topic",
        label: formatMessage({
          id: "questionsFilters.topic",
          defaultMessage: "Topic",
        }),
        options: data?.topics?.map(({ id, name }) => ({
          value: id,
          label: name,
        })),
        required: true,
        maxWidth: 449,
        disabled: disabledFields?.includes("topic"),
      },
    ],
    [
      data?.examTypes,
      data?.levels,
      data?.subjects,
      data?.topics,
      disabledFields,
      formatMessage,
    ]
  );

  return (
    <FiltersPanel>
      <Container>
        <Filters
          values={innerFiltersState}
          fields={fields}
          isFetching={isFetching}
          onChange={setInnerFiltersState}
          onSubmit={changeFilters}
          showMandatoryHint
        />
      </Container>
    </FiltersPanel>
  );
}
