import styled from "styled-components";

import { PaperBox } from "@components/PaperBox";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const OtherPaperBox = styled(PaperBox)`
  & + & {
    margin-top: ${({ theme }) => theme.spacing.m};
  }
`;
