import styled, { css } from "styled-components";
import { StyledHeaderProps } from "./Header.types";

export const StyledHeader = styled.button<StyledHeaderProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  background: none;
  border: none;

  & * {
    cursor: pointer;
  }

  padding-top: ${({ theme }) => theme.spacing.s};
  padding-right: ${({ theme }) => theme.spacing.xs};
  padding-bottom: ${({ theme }) => theme.spacing.s};
  padding-left: ${({ theme }) => theme.spacing.xs};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    padding-top: ${({ theme }) => theme.spacing.l};
    padding-right: ${({ theme }) => theme.spacing.m};
    padding-bottom: ${({ theme }) => theme.spacing.l};
    padding-left: ${({ theme }) => theme.spacing.m};
  }

  ${({ isActive, theme }) =>
    isActive &&
    css`
      color: ${theme.color.solid.primary};
    `}
`;
