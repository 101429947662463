import { exportEndpointsEnum } from "../../utils/helpers";

enum HomeEndpointsEnum {
  Home = "homepage",
  Image = "",
}

export const HomeEndpoints = exportEndpointsEnum<typeof HomeEndpointsEnum>(
  "",
  HomeEndpointsEnum,
  "cms",
  process.env.REACT_APP_CMS_BACKEND_URL
);
