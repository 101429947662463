import styled, { css } from "styled-components";
import ReactSelect from "react-select";

import {
  IconWrapperProps,
  StyledSelectProps,
} from "./Select.types";
import { FieldVariant } from "../FieldCaption";

export const SelectWrapper = styled.div<{ maxWidth?: number }>`
  display: flex;
  flex-direction: column;

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      width: ${maxWidth}px;
    `}
`;

export const RequiredIndicator = styled.span`
  color: ${({ theme }) => theme.color.informative.alert};
`;

export const StyledSelect = styled(ReactSelect)<StyledSelectProps>`
  font-size: 14px;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 16px;
  }

  .select {
    &__control {
      border: 2px solid transparent;
      border-radius: ${({ theme }) => theme.borderRadius.m};
      box-shadow: 0 0 0 1px ${({ theme }) => theme.color.solid.lightGrey};
      background-color: ${({ theme }) => theme.color.solid.white};
      text-align: left;

      ${({ theme, variant }) =>
        variant === FieldVariant.Disabled &&
        css`
          box-shadow: 0 0 0 1px ${theme.color.solid.lightGrey};
        `}

      ${({ theme, variant }) =>
        variant === FieldVariant.Error &&
        css`
          box-shadow: none;
          border: 2px solid ${theme.color.informative.alert};
        `}

      &:hover {
        border-color: ${({ theme, variant }) =>
          variant === FieldVariant.Error
            ? theme.color.informative.alert
            : "transparent"};
      }

      &--is-focused {
        box-shadow: none;
        border: ${({ theme }) => `2px solid ${theme.color.solid.primary}`};

        &:hover {
          border: ${({ theme }) => `2px solid ${theme.color.solid.primary}`};
        }
      }
    }

    &__value-container {
      padding: 10px 8px 10px 14px;
      color: ${({ theme }) => theme.color.solid.grey};

      @media (${({ theme }) => theme.breakpoints.tablet}) {
        min-height: 52px;
        padding-top: ${({ theme }) => `calc(${theme.spacing.s} - 6px)`};
        padding-right: ${({ theme }) => `calc(${theme.spacing.s} - 2px)`};
        padding-bottom: ${({ theme }) => `calc(${theme.spacing.s} - 6px)`};
        padding-left: ${({ theme }) => `calc(${theme.spacing.s} - 4px)`};
      }
    }

    &__single-value {
      margin: 0;
    }

    &__placeholder {
      color: ${({ theme }) => theme.color.solid.grey};

      ${({ theme, variant }) =>
        variant === FieldVariant.Disabled &&
        css`
          color: ${theme.color.solid.lightGrey};
        `}
    }

    &__single-value {
      color: ${({ theme }) => theme.color.solid.darkGrey};
    }

    &__indicator-separator {
      display: none;
    }

    &__indicators {
      ${({ hideButton, isDisabled }) =>
        (hideButton || isDisabled) &&
        css`
          display: none;
        `}
    }

    &__menu {
      border-radius: ${({ theme }) => theme.borderRadius.m};
      z-index: 2;
      border: 1px solid ${({ theme }) => theme.color.solid.lightGrey};
      overflow: hidden;
    }
  }
`;

export const StyledOption = styled.div<{
  isSelected: boolean;
  isFocused: boolean;
}>`
  display: flex;

  padding-top: ${({ theme }) => theme.spacing.xs};
  padding-right: ${({ theme }) => theme.spacing.s};
  padding-bottom: ${({ theme }) => theme.spacing.xs};
  padding-left: ${({ theme }) => theme.spacing.s};

  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.color.solid.darkGrey};

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${({ theme }) => theme.color.solid.primary};
      color: ${({ theme }) => theme.color.solid.white};
    `}

  ${({ isFocused, isSelected }) =>
    isFocused &&
    !isSelected &&
    css`
      color: ${({ theme }) => theme.color.solid.primary};
    `}
`;

export const IconWrapper = styled.span<IconWrapperProps>`
  display: inline-flex;
  height: 20px;
  width: 20px;
  margin-right: 8px;

  & > svg {
    stroke: ${({ theme }) => theme.color.solid.grey};

    ${({ isSelected }) =>
      isSelected &&
      css`
        stroke: ${({ theme }) => theme.color.solid.lightGrey};
      `}

    ${({ isFocused }) =>
      isFocused &&
      css`
        stroke: ${({ theme }) => theme.color.solid.primary};
      `}
  }
`;
