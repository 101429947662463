import styled from "styled-components";

import { PaperBox } from "@components/PaperBox";

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.l};
`;

export const SliderWrapper = styled.div`
  .carousel {
    &__slider-tray-wrapper {
      margin: 0 -12px;
    }

    &__inner-slide {
      padding: 0 12px;
    }
  }

  @media (${({ theme }) => theme.breakpoints.mobile}) {
    max-width: 330px;
    width: 100%;
    margin: 0 auto;
  }
`;

export const PaginationButtons = styled.div`
  display: flex;

  & > button {
    background: none;
    border: none;

    & > svg {
      stroke: ${({ theme }) => theme.color.solid.grey};
    }

    &[disabled] {
      cursor: default;

      & > svg {
        stroke: ${({ theme }) => theme.color.solid.lightGrey};

        &,
        & * {
          cursor: default;
        }
      }
    }
  }
`;

export const ButtonsDivider = styled.span`
  border-right: 1px solid ${({ theme }) => theme.color.solid.lightGrey};
  margin: 0 ${({ theme }) => theme.spacing.m};
`;

export const SlidePaperBox = styled(PaperBox)`
  height: 100%;
`;
