import { FormattedMessage } from "react-intl";

import { Paths } from "@utils/router";

import { Box } from "@components/Box";
import { ButtonLink, ButtonVariants } from "@components/Button";
import { Heading, Paragraph } from "@components/Typography";
import { HeadingVariant } from "@components/Typography/Heading/Heading.types";

import {
  DescriptionContainer,
  TitleBox,
  TitleColumnLeft,
} from "./PageHeader.styles";

interface PageHeaderProps {
  examsCount?: number;
  showDescription?: boolean;
  showMyExams?: boolean;
}

export function PageHeader({
  examsCount,
  showDescription = true,
  showMyExams = false,
}: PageHeaderProps) {
  return (
    <>
      <TitleBox>
        <TitleColumnLeft>
          <Heading as={HeadingVariant.H3}>
            <FormattedMessage
              id="exams.title"
              defaultMessage="My{nbsp}Exams ({examsCount})"
              values={{
                nbsp: "\u00A0",
                examsCount: examsCount || 0,
              }}
            />
          </Heading>

          <div>
            <ButtonLink to={Paths.ExamCreator} isSmall>
              <FormattedMessage
                id="exams.addExamButton"
                defaultMessage="Create Exam"
              />
            </ButtonLink>
          </div>
        </TitleColumnLeft>

        {showMyExams && (
          <Box ml="m">
            <ButtonLink
              to={Paths.MyExams}
              variant={ButtonVariants.Tertiary}
              iconRight="ArrowRight"
              isSmall
            >
              <FormattedMessage
                id="exams.addExamButton"
                defaultMessage="See all exams"
              />
            </ButtonLink>
          </Box>
        )}
      </TitleBox>

      {showDescription ? (
        <DescriptionContainer>
          <Paragraph>
            <FormattedMessage
              id="exams.description"
              defaultMessage="Here you can find all of your created exams."
            />
          </Paragraph>
        </DescriptionContainer>
      ) : (
        <Box pt="m" />
      )}
    </>
  );
}
