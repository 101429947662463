import { FormattedMessage } from "react-intl";

import { useGetUserSubjects } from "@api/paper/Paper.queries";
import { Paths } from "@utils/router";
import { Box } from "@components/Box";
import { ButtonLink, ButtonVariants } from "@components/Button";
import { PaperBox } from "@components/PaperBox";

import { PapersGrid } from "@components/PapersGrid";

export function MyPapers() {
  const { data } = useGetUserSubjects("active");

  return (
    <Box>
      <PapersGrid>
        {data?.map(({ id, cover, subject, level }) => (
          <PaperBox
            key={`${id}-${level.id}`}
            paper={subject.name}
            type={subject.examType.shortName}
            level={level.name}
            coverImage={cover}
          >
            <ButtonLink
              to={`${Paths.Questions}?${new URLSearchParams({
                examType: subject.examType.id,
                level: level.id,
                subject: subject.id,
              })}`}
              variant={ButtonVariants.SecondaryWhite}
            >
              <FormattedMessage
                id="myPapers.questionsByTopicButton"
                defaultMessage="Questions by Topic"
              />
            </ButtonLink>

            <ButtonLink
              to={`${Paths.Solutions}?${new URLSearchParams({
                examType: subject.examType.id,
                level: level.id,
                subject: subject.id,
              })}`}
              variant={ButtonVariants.SecondaryWhite}
            >
              <FormattedMessage
                id="myPapers.solutionsButton"
                defaultMessage="Solutions"
              />
            </ButtonLink>

            <ButtonLink
              to={`${Paths.ExamGuideList}/${id}`}
              variant={ButtonVariants.SecondaryWhite}
            >
              <FormattedMessage
                id="myPapers.examGuideButton"
                defaultMessage="Exam Guide"
              />
            </ButtonLink>
          </PaperBox>
        ))}
      </PapersGrid>
    </Box>
  );
}
