import { FormattedMessage } from "react-intl";
import { Icon } from "../../../Icon";
import { Container, InnerContainer, StyledAudio } from "./Audio.styles";
import { AudioProps } from "./Audio.types";
import { Paragraph } from "../../../Typography";
import { ParagraphVariants } from "../../../Typography/Paragraph/Paragraph.types";
import { Box } from "../../../Box";
import { useBreakpoints } from "../../../../utils/use-breakpoints";

export function Audio({ src }: AudioProps) {
  const { isTablet } = useBreakpoints();

  return (
    <Container>
      <InnerContainer>
        <Paragraph variant={ParagraphVariants.Small}>
          <FormattedMessage
            id="audio.title"
            defaultMessage="Listen to the file"
          />
        </Paragraph>
        <Box mt="xs">
          <>
            {isTablet && <Icon icon="AudioFile" />}
            <StyledAudio controls controlsList="nodownload" src={src}>
              <track default kind="captions" srcLang="en" src={src} />
              <a href={src}>
                <FormattedMessage
                  id="audio.downloadFile"
                  defaultMessage="Download audio"
                />
              </a>
            </StyledAudio>
          </>
        </Box>
      </InnerContainer>
    </Container>
  );
}
