import { useIntl } from "react-intl";
import * as yup from "yup";

export const useEditExamFormValidation = () => {
  const { formatMessage } = useIntl();

  return yup.object({
    topic: yup.string().required(
      formatMessage({
        id: "createExamForm.topicRequired",
        defaultMessage: "Email is a required field",
      })
    ),
    title: yup
      .string()
      .trim()
      .max(
        200,
        formatMessage({
          id: "createExamForm.titleTooLong",
          defaultMessage: "Title name is too long max. 200 characters",
        })
      )
      .required(
        formatMessage({
          id: "createExamForm.titleRequired",
          defaultMessage: "Title is a required field",
        })
      ),
    description: yup
      .string()
      .trim()
      .max(
        200,
        formatMessage({
          id: "createExamForm.descriptionTooLong",
          defaultMessage: "Description name is too long max. 200 characters",
        })
      )
      .required(
        formatMessage({
          id: "createExamForm.descriptionRequired",
          defaultMessage: "Description is a required field",
        })
      ),
  });
};
