import styled from "styled-components";
import { Link } from "react-router-dom";

import { Row } from "@components/Row";

export const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.color.solid.primary};
  border-radius: 0px 24px;
  padding: ${({ theme }) => theme.spacing.l};
`;

export const ButtonsRow = styled(Row)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  flex-direction: row-reverse;
  padding: ${({ theme }) => theme.spacing.xs};
  background-color: ${({ theme }) => theme.color.background.primary};

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    position: relative;
    margin: ${({ theme }) => theme.spacing.l};
    background-color: ${({ theme }) => theme.color.solid.white};
  }
`;

export const AddQuestionRow = styled.div`
  display: flex;
  justify-content: center;

  margin-top: ${({ theme }) => theme.spacing.m};
  margin-bottom: ${({ theme }) => theme.spacing.m};
`;

export const AddQuestionLink = styled(Link)`
  font-weight: ${({ theme }) => theme.typography.weights.bold};
  color: ${({ theme }) => theme.color.solid.darkGrey};

  &::before {
    content: "+";
    margin-right: 4px;
  }
`;
