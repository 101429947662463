import styled from "styled-components";

import { Paragraph } from "@components/Typography";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing.m};
  padding: 0 ${({ theme }) => `${theme.spacing.m} ${theme.spacing.m}`};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: ${({ theme }) => theme.spacing.l};
  }

  @media (${({ theme }) => theme.breakpoints.desktopPlus}) {
    margin-top: ${({ theme }) => theme.spacing.xl};
    padding-bottom: ${({ theme }) => theme.spacing.l};
  }
`;

export const BackgroundContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: ${({ theme }) => theme.containerWidth};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  margin-bottom: ${({ theme }) => theme.spacing.m};
  background-color: ${({ theme }) => theme.color.background.light};

  @media (${({ theme }) => theme.breakpoints.desktopPlus}) {
    margin-bottom: ${({ theme }) => theme.spacing.l};
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${({ theme }) => theme.containerWidth};
  padding: ${({ theme }) => `${theme.spacing.l} ${theme.spacing.m}`};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row;
    justify-content: space-between;
    padding: ${({ theme }) => theme.spacing.xl};
  }

  @media (${({ theme }) => theme.breakpoints.desktopPlus}) {
    padding: ${({ theme }) => `${theme.spacing.xl} ${theme.spacing.xxl}`};
  }
`;

export const LinkList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${({ theme }) => theme.spacing.m} 0 0;
  padding: 0;
  order: 2;
  text-align: end;
  list-style: none;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    flex: 1;
    align-items: flex-end;
    order: 0;
    margin-top: 0;
    margin-left: ${({ theme }) => theme.spacing.m};
  }
`;

export const LinkItem = styled.li`
  & + & {
    margin-top: ${({ theme }) => theme.spacing.xs};
  }
`;

export const StyledLink = styled.a`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.color.solid.darkGrey};

  &:hover {
    color: ${({ theme }) => theme.color.solid.grey};
  }
`;

export const MiddleColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > * + * {
    margin-top: ${({ theme }) => theme.spacing.m};
  }

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    flex: 1;
    margin-left: ${({ theme }) => theme.spacing.m};
  }
`;

export const AddressColumn = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  order: 1;
  margin-top: ${({ theme }) => theme.spacing.m};

  > * + * {
    margin-top: ${({ theme }) => theme.spacing.s};
  }

  & a {
    color: ${({ theme }) => theme.color.solid.darkGrey};
    font-weight: 400;
  }

  & strong {
    font-weight: 600;
    padding-right: ${({ theme }) => theme.spacing.xs};
  }

  & > strong,
  & > ${Paragraph} {
    text-align: inherit;
  }

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    flex: 1;
    order: 0;
    margin-top: 0;
    text-align: left;
  }
`;

export const SocialItemsContainer = styled.div`
  display: flex;
`;

export const IconLink = styled.a`
  * {
    cursor: pointer;
  }
`;
