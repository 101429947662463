import styled, { css } from "styled-components";
import { InputProps } from "./Input.types";

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputWrapper = styled.div`
  display: flex;
  position: relative;
`;

export const StyledInput = styled.input<{ $isError?: boolean }>`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.color.stroke.lightGrey};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  padding: 11px ${({ theme }) => parseInt(theme.spacing.s, 10) - 1}px;
  box-shadow: none;
  appearance: none;
  font-size: 14px;
  font-family: inherit;
  color: ${({ theme }) => theme.color.solid.darkGrey};

  outline-offset: -1px;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    padding: ${({ theme }) => parseInt(theme.spacing.s, 10) - 1}px;
    font-size: 16px;
  }

  ${({ $isError }) =>
    $isError &&
    css`
      outline: 2px solid ${({ theme }) => theme.color.informative.alert};
    `}

  &::placeholder {
    opacity: 1;
    color: ${({ theme }) => theme.color.solid.grey};
  }

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.color.solid.primary};
  }

  &[type="number"] {
    appearance: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
`;

const IconWrapper = styled.span<Pick<InputProps, "onClick">>`
  height: 20px;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
`;

export const IconRightWrapper = styled(IconWrapper)<
  Pick<InputProps, "onClick">
>`
  right: 0;

  padding-right: ${({ theme }) => theme.spacing.s};

  ${({ onClick }) =>
    onClick &&
    css`
      &,
      & > * {
        cursor: pointer;
      }
    `}
`;

export const IconLeftWrapper = styled(IconWrapper)<Pick<InputProps, "onClick">>`
  left: 0;

  padding-left: ${({ theme }) => theme.spacing.s};

  ${({ onClick }) =>
    onClick &&
    css`
      &,
      & > * {
        cursor: pointer;
      }
    `}
`;
