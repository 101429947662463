import { FormattedMessage } from "react-intl";

import { Subject } from "@api/paper/Paper.types";

import { ButtonAnchor, ButtonVariants } from "@components/Button";

import { Container, OtherPaperBox } from "./MobileGrid.styles";

interface MobileGridProps {
  papers?: Subject[];
  otherPapersUrl?: string;
}

export function MobileGrid({ papers, otherPapersUrl }: MobileGridProps) {
  return (
    <Container>
      {papers?.map(({ id, cover, subject, level }) => (
        <OtherPaperBox
          key={id}
          paper={subject.name}
          type={subject.examType.shortName}
          level={level.name}
          coverImage={cover}
          isActive={false}
        >
          <ButtonAnchor
            href={otherPapersUrl}
            target="_blank"
            rel="noreferrer"
            variant={ButtonVariants.SecondaryWhite}
          >
            <FormattedMessage
              id="otherPapers.moreInformationButton"
              defaultMessage="More information"
            />
          </ButtonAnchor>
        </OtherPaperBox>
      ))}
    </Container>
  );
}
