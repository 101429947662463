import styled, { css } from "styled-components";

import type { FieldLabelProps } from "./FieldLabel.types";

export const FieldLabel = styled.label<FieldLabelProps>`
  margin-top: ${({ theme }) => theme.spacing.xs};
  margin-bottom: 2px;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.color.solid.darkGrey};
  text-align: left;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: ${({ theme }) => theme.spacing.s};
    margin-bottom: ${({ theme }) => theme.spacing.xxs};
  }

  ${({ isRequired }) =>
    isRequired &&
    css`
      &:after {
        content: "*";
        position: relative;
        top: -3px;
        margin-left: 2px;
        color: ${({ theme }) => theme.color.informative.alert};
      }
    `}
`;