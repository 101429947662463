import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormattedMessage, useIntl } from "react-intl";

import { SetPasswordData } from "@api/auth/Auth.types";
import { useSetPassword } from "@api/auth/Auth.queries";

import { Input } from "@components/Input";
import { PasswordInput } from "@components/PasswordInput";
import { Button, ButtonVariants } from "@components/Button";
import { Loader, LoaderPositions } from "@components/Loader";

import { ButtonsRow } from "./ChangePasswordForm.styles";
import { useChangePasswordFormValidation } from "./ChangePasswordForm.validation";

interface ChangePasswordFormProps {
  handleSubmitSuccess: () => void;
}

export function ChangePasswordForm({
  handleSubmitSuccess,
}: ChangePasswordFormProps) {
  const { formatMessage } = useIntl();
  const [passwordStrength, setPasswordStrength] = useState(0);
  const {
    register,
    handleSubmit,
    watch,
    trigger,
    formState: { errors, isValid },
    setError,
  } = useForm<SetPasswordData>({
    mode: "onChange",
    resolver: yupResolver(useChangePasswordFormValidation(setPasswordStrength)),
  });
  const { mutate, isLoading } = useSetPassword({
    onSuccess: handleSubmitSuccess,
    onError: (error) => {
      const [firstFieldErrorEntry] = error?.response?.data
        ? Object.entries(error?.response?.data)
        : [[]];
      const [fieldName, errorMessages] = firstFieldErrorEntry;

      if (fieldName && errorMessages[0]) {
        setError(fieldName as keyof SetPasswordData, {
          message: errorMessages[0],
        });
      }
    },
  });

  const onSubmit = (data: SetPasswordData) => {
    mutate(data);
  };

  const newPasswordWatch = watch("newPassword");

  useEffect(() => {
    if (newPasswordWatch) {
      trigger("reNewPassword");
    }
  }, [newPasswordWatch, trigger]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isLoading && <Loader position={LoaderPositions.Absolute} />}
      <Input
        {...register("currentPassword", { required: true })}
        required
        isRequired
        type="password"
        label={formatMessage({
          id: "changePasswordForm.currentPasswordLabel",
          defaultMessage: "Current password",
        })}
        errorMessage={errors.currentPassword?.message}
      />
      <PasswordInput
        {...register("newPassword", { required: true })}
        required
        isRequired
        label={formatMessage({
          id: "changePasswordForm.newPasswordLabel",
          defaultMessage: "New password",
        })}
        placeholder={formatMessage({
          id: "changePasswordForm.newPasswordPlaceholder",
          defaultMessage: "Enter password",
        })}
        caption={formatMessage({
          id: "changePasswordForm.newPasswordCaption",
          defaultMessage: "Minimum 8 characters (capital letter and number)",
        })}
        errorMessage={errors.newPassword?.message}
        progress={passwordStrength}
      />
      <PasswordInput
        {...register("reNewPassword", { required: true })}
        required
        isRequired
        label={formatMessage({
          id: "changePasswordForm.newPasswordConfirmLabel",
          defaultMessage: "Confirm new password",
        })}
        placeholder={formatMessage({
          id: "changePasswordForm.newPasswordConfirmPlaceholder",
          defaultMessage: "Enter password",
        })}
        errorMessage={errors.reNewPassword?.message}
      />
      <ButtonsRow>
        <Button
          type="submit"
          variant={ButtonVariants.Primary}
          disabled={isLoading || !isValid}
        >
          <FormattedMessage
            id="changePasswordForm.submitButton"
            defaultMessage="Save"
          />
        </Button>
      </ButtonsRow>
    </form>
  );
}
