import styled, { css } from "styled-components";
import {
  ButtonVariants,
  StyledButtonProps,
  STYLED_BUTTON_PROPS_KEYS,
} from "./Button.types";

export const StyledButton = styled.button.withConfig({
  shouldForwardProp: (prop) => !STYLED_BUTTON_PROPS_KEYS.includes(prop),
})<StyledButtonProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  ${({ autoWidth }) => !autoWidth && "min-width: 150px;"}
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.l};
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  font-weight: ${({ theme }) => theme.typography.weights.bold};
  letter-spacing: 1px;

  transition: background-color, border-color, color 0.25s ease-in-out;

  > svg {
    margin-left: ${({ theme }) => theme.spacing.xs};
  }

  ${({ block }) =>
    block &&
    css`
      width: 100%;
    `}

  &:disabled {
    color: ${({ theme }) => theme.color.solid.grey};
    cursor: default;

    & > svg {
      stroke: ${({ theme }) => theme.color.solid.grey};
    }

    & * {
      cursor: default;
    }
  }

  ${({ isSmall }) =>
    isSmall &&
    css`
      font-size: 14px;
      line-height: 1.72;
    `}

  ${({ variant, isSmall }) => {
    switch (variant) {
      case ButtonVariants.Primary:
        return css`
          padding: ${isSmall
            ? css`
                ${({ theme }) => theme.spacing.xs} ${({ theme }) =>
                  theme.spacing.s}
              `
            : css`
                ${({ theme }) => theme.spacing.s} ${({ theme }) =>
                  theme.spacing.m}
              `};
          background-color: ${({ theme }) => theme.color.solid.primary};
          color: ${({ theme }) => theme.color.solid.white};

          & > svg {
            stroke: ${({ theme }) => theme.color.solid.white};
          }

          &:hover:not(:disabled),
          &:focus-visible {
            background-color: ${({ theme }) => theme.color.solid.hover};
          }

          &:disabled {
            background-color: ${({ theme }) => theme.color.solid.lightGrey};
          }
        `;

      case ButtonVariants.Secondary:
        return css`
          border: 2px solid ${({ theme }) => theme.color.solid.darkGrey};
          padding: ${isSmall
            ? css`
                ${({ theme }) => parseInt(theme.spacing.xs, 10) - 2}px ${({
                  theme,
                }) => parseInt(theme.spacing.s, 10) - 2}px
              `
            : css`
                ${({ theme }) => parseInt(theme.spacing.s, 10) - 2}px ${({
                  theme,
                }) => parseInt(theme.spacing.m, 10) - 2}px
              `};
          background-color: transparent;
          color: ${({ theme }) => theme.color.solid.darkGrey};

          & > svg {
            color: ${({ theme }) => theme.color.solid.darkGrey};
          }

          &:hover:not(:disabled),
          &:focus-visible {
            color: ${({ theme }) => theme.color.solid.hover};
            border-color: ${({ theme }) => theme.color.solid.hover};

            & > svg {
              color: ${({ theme }) => theme.color.solid.hover};
            }
          }

          &:disabled {
            border-color: ${({ theme }) => theme.color.solid.lightGrey};
          }
        `;

      case ButtonVariants.SecondaryWhite:
        return css`
          border: 2px solid ${({ theme }) => theme.color.solid.white};
          padding: ${isSmall
            ? css`
                ${({ theme }) => parseInt(theme.spacing.xs, 10) - 2}px ${({
                  theme,
                }) => parseInt(theme.spacing.s, 10) - 2}px
              `
            : css`
                ${({ theme }) => parseInt(theme.spacing.s, 10) - 2}px ${({
                  theme,
                }) => parseInt(theme.spacing.m, 10) - 2}px
              `};
          background-color: transparent;
          color: ${({ theme }) => theme.color.solid.white};

          & > svg {
            color: ${({ theme }) => theme.color.solid.white};
          }

          &:hover:not(:disabled),
          &:focus-visible {
            border-color: ${({ theme }) => theme.color.solid.white};
            outline: none;
            background: ${({ theme }) => theme.color.solid.white};
            color: ${({ theme }) => theme.color.solid.primary};

            & > svg {
              color: ${({ theme }) => theme.color.solid.primary};
            }
          }
        `;

      case ButtonVariants.Tertiary:
        return css`
          padding: ${isSmall
            ? css`
                ${({ theme }) => theme.spacing.xs} ${({ theme }) =>
                  theme.spacing.s}
              `
            : css`
                ${({ theme }) => theme.spacing.s} ${({ theme }) =>
                  theme.spacing.m}
              `};
          background-color: transparent;
          color: ${({ theme }) => theme.color.solid.darkGrey};
          min-width: 0;

          & > svg {
            stroke: ${({ theme }) => theme.color.solid.darkGrey};
          }

          &:hover:not(:disabled),
          &:focus-visible {
            color: ${({ theme }) => theme.color.solid.hover};

            & > svg {
              stroke: ${({ theme }) => theme.color.solid.hover};
            }
          }

          &:disabled {
            color: ${({ theme }) => theme.color.solid.lightGrey};

            & > svg {
              stroke: ${({ theme }) => theme.color.solid.lightGrey};
            }
          }
        `;

      default:
        return "";
    }
  }}
`;
