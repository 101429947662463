import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { Box } from "../Box";
import { Button, ButtonVariants } from "../Button";
import { Input } from "../Input";
import { StyledForm } from "./ForgotPasswordForm.styles";
import {
  ForgotPasswordFormData,
  ForgotPasswordFormProps,
} from "./ForgotPasswordForm.types";
import { useForgotPasswordValidation } from "./ForgotPasswordForm.validation";

export function ForgotPasswordForm({ onSubmit }: ForgotPasswordFormProps) {
  const { formatMessage } = useIntl();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordFormData>({
    mode: "onBlur",
    resolver: yupResolver(useForgotPasswordValidation()),
  });

  return (
    <StyledForm noValidate onSubmit={handleSubmit(onSubmit)}>
      <Input
        {...register("email", { required: true })}
        type="email"
        label={formatMessage({
          id: "studentRegistrationForm.email",
          defaultMessage: "Email address",
        })}
        placeholder={formatMessage({
          id: "studentRegistrationForm.emailPlaceholder",
          defaultMessage: "Enter email",
        })}
        errorMessage={errors.email?.message}
      />
      <Box mt="l">
        <Button type="submit" variant={ButtonVariants.Primary} block>
          <FormattedMessage
            id="forgotPasswordForm.submitButton"
            defaultMessage="Confirm"
          />
        </Button>
      </Box>
    </StyledForm>
  );
}
