import { useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import {
  useCreateExam,
  useUpdateExam,
} from "../../../api/exam-creator/ExamCreator.queries";
import { useQuestionsFilters } from "../../../utils/questions-filters/questions-filters-context";
import { Paths } from "../../../utils/router";
import { Modal } from "../../Modal";
import { AddQuestionError } from "./AddQuestionError";
import { AddQuestionForm, AddQuestionFormData } from "./AddQuestionForm";
import { AddQuestionSuccess } from "./AddQuestionSuccess";
import {
  AddQuestionToExamProps,
  SubmissionState,
  SubmissionStatus,
} from "./AddQuestionToExam.types";

export function AddQuestionToExam({
  isOpen,
  questions,
  onClose,
  examId,
  afterUpdate,
}: AddQuestionToExamProps) {
  const navigate = useNavigate();

  const [submissionState, setSubmissionState] = useState<SubmissionState>(null);

  const { filters } = useQuestionsFilters();

  const { mutate: createExam } = useCreateExam({
    onSuccess: ({ id }) => {
      navigate(generatePath(Paths.EditExam, { examId: id }), {
        state: {
          cancelRedirection: `${Paths.Questions}?${new URLSearchParams(
            filters
          )}`,
        },
      });
    },
  });

  const { mutate: updateExam } = useUpdateExam({
    onSuccess: () => {
      setSubmissionState({ status: SubmissionStatus.SUCCESS });
      afterUpdate();
    },
    onError(error) {
      if (error.response?.status !== 400 || !error.response?.data?.detail) {
        return;
      }

      setSubmissionState({
        status: SubmissionStatus.ERROR,
        detail: error.response.data.detail,
      });
    },
  });

  const onSubmit = (data: AddQuestionFormData) => {
    if (data.examId === "-1") {
      createExam({
        topic: filters.topic,
        teacherExamQuestion: questions.map((question) => ({ question })),
      });
    } else if (data.examId) {
      updateExam({
        id: data.examId,
        teacherExamQuestion: questions.map((question) => ({ question })),
      });
    }
  };

  const handleOnClose = () => {
    setSubmissionState(null);
    onClose();
  };

  let content = (
    <AddQuestionForm
      defaultValues={{ examId }}
      onSubmit={onSubmit}
      onClose={handleOnClose}
      questions={questions}
    />
  );

  if (submissionState?.status === SubmissionStatus.ERROR) {
    content = (
      <AddQuestionError
        error={submissionState.detail!}
        multiple={questions.length > 1}
        onClose={handleOnClose}
      />
    );
  }

  if (submissionState?.status === SubmissionStatus.SUCCESS) {
    content = <AddQuestionSuccess onClose={handleOnClose} />;
  }

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose}>
      {content}
    </Modal>
  );
}
