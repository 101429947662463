import { useIntl } from "react-intl";
import * as yup from "yup";

export const useForgotPasswordValidation = () => {
  const { formatMessage } = useIntl();

  return yup.object({
    email: yup
      .string()
      .email()
      .required(
        formatMessage({
          id: "forgotPasswordForm.emailRequired",
          defaultMessage: "Email is a required field",
        })
      ),
  });
};
