import { HomeSectionAlign } from "@api/home/Home.types";
import { useBreakpoints } from "@utils/use-breakpoints";

import {
  StickToSideBackgroundContainer,
  StickToSideColumn,
  StickToSideContainer,
  StickToSideInnerContainer,
} from "@components/HomeLayout/HomeLayout.styles";
import { Box } from "@components/Box";
import { MarkdownParser } from "@components/MarkdownParser";
import { Heading, Overline, Paragraph } from "@components/Typography";
import { HeadingVariant } from "@components/Typography/Heading/Heading.types";

import { ThreeImagesSectionProps } from "./ThreeImagesSection.types";
import {
  ImageColumn,
  StyledImage,
  StyledImageCenter,
  StyledImageLeft,
} from "./ThreeImagesSection.styles";

export function ThreeImagesSectionComponent({ data }: ThreeImagesSectionProps) {
  const { isDesktopPlus } = useBreakpoints();
  const stickLeft = data.align === HomeSectionAlign.left;

  return (
    <StickToSideContainer stickLeft={stickLeft}>
      <StickToSideBackgroundContainer stickLeft={stickLeft}>
        <StickToSideInnerContainer stickLeft={stickLeft}>
          <StickToSideColumn stickLeft={stickLeft}>
            <Overline>{data.label}</Overline>

            <Box pb="m" />

            <Heading as={isDesktopPlus ? HeadingVariant.H2 : HeadingVariant.H4}>
              {data.title}
            </Heading>

            <Box pb="m" />

            <Paragraph as="div">
              <MarkdownParser>{data.description}</MarkdownParser>
            </Paragraph>
          </StickToSideColumn>

          <ImageColumn stickLeft={stickLeft}>
            <StyledImageLeft
              alt={data.imageLeft.data.attributes.alternativeText}
              formats={data.imageLeft.data.attributes.formats}
              url={data.imageLeft.data.attributes.url}
            />

            <Box pr={isDesktopPlus ? "m" : "s"} />

            <StyledImageCenter
              alt={data.imageCenter.data.attributes.alternativeText}
              formats={data.imageCenter.data.attributes.formats}
              url={data.imageCenter.data.attributes.url}
            />

            <Box pr={isDesktopPlus ? "m" : "s"} />

            <StyledImage
              alt={data.imageRight.data.attributes.alternativeText}
              formats={data.imageRight.data.attributes.formats}
              url={data.imageRight.data.attributes.url}
            />
          </ImageColumn>
        </StickToSideInnerContainer>
      </StickToSideBackgroundContainer>
    </StickToSideContainer>
  );
}
