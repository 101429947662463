export interface PaginatedList<T> {
  total: number;
  page: number;
  perPage: number;
  data: T[];
}

export type DeepNever<T> = {
  [P in keyof T]?: T[P] extends object ? DeepNever<T[P]> : never;
};

export enum SortOrder {
  Ascending = "",
  Descending = "-",
}
