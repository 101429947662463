import { useIntl } from "react-intl";

import { useGetExamCreator } from "@api/exam-creator/ExamCreator.queries";
import { useBreakpoints } from "@utils/use-breakpoints";
import { useUser } from "@utils/user/user-context";
import { SortOrder } from "@utils/types";

import { PageHeader } from "@pages/dashboard/exams/PageHeader";

import { DashboardLayout } from "@components/DashboardLayout";
import { ExamsList } from "@components/ExamsList";
import { MyPapers } from "@components/MyPapers/MyPapers";
import { OtherPapers } from "@components/OtherPapers";
import { Tab, Tabs } from "@components/Tabs";
import { Box } from "@components/Box";

import { Container, TabsWrapper } from "./Dashboard.styles";
import { DashboardMobileTabs } from "./Dashboard.types";

const examFilters = {
  perPage: 5,
  page: 1,
  ordering: "title",
  sortOrder: SortOrder.Ascending,
  subject: "",
};

export function Dashboard() {
  const { formatMessage } = useIntl();
  const { isMobile, isTablet } = useBreakpoints();
  const { isTeacher } = useUser();

  const { data: examCreatorData, isLoading: isExamsLoading } =
    useGetExamCreator(examFilters, {
      retry: false,
      enabled: isTeacher,
    });

  return (
    <DashboardLayout
      title={formatMessage({
        id: "dashboard.title",
        defaultMessage: "Dashboard",
      })}
      backgroundImage="/img/dashboard-background.svg"
    >
      <Container>
        <>
          {isMobile && (
            <TabsWrapper>
              <Tabs defaultTab={DashboardMobileTabs.MyPapers}>
                <Tab
                  index={DashboardMobileTabs.MyPapers}
                  title={formatMessage({
                    id: "dashboard.myPapersTabTitle",
                    defaultMessage: "My papers",
                  })}
                >
                  <Box mx="m">
                    <MyPapers />
                  </Box>
                </Tab>

                <Tab
                  index={DashboardMobileTabs.OtherPapers}
                  title={formatMessage({
                    id: "dashboard.otherPapersTabTitle",
                    defaultMessage: "Other papers",
                  })}
                >
                  <Box mx="m">
                    <OtherPapers />
                  </Box>
                </Tab>

                {isTeacher ? (
                  <Tab
                    index={DashboardMobileTabs.MyExams}
                    title={formatMessage({
                      id: "dashboard.myExamsTabTitle",
                      defaultMessage: "My exams",
                    })}
                  >
                    <Box mx="m">
                      <Box mt="m">
                        <PageHeader
                          examsCount={examCreatorData?.total}
                          showDescription={false}
                          showMyExams
                        />
                      </Box>

                      <ExamsList
                        exams={examCreatorData}
                        isLoading={isExamsLoading}
                        showPagination={false}
                      />
                    </Box>
                  </Tab>
                ) : null}
              </Tabs>
            </TabsWrapper>
          )}

          {isTablet && (
            <>
              <MyPapers />
              <OtherPapers />
              {isTeacher ? (
                <>
                  <Box mt="m">
                    <PageHeader
                      examsCount={examCreatorData?.total}
                      showDescription={false}
                      showMyExams
                    />
                  </Box>

                  <ExamsList
                    exams={examCreatorData}
                    isLoading={isExamsLoading}
                    showPagination={false}
                  />
                </>
              ) : null}
            </>
          )}
        </>
      </Container>
    </DashboardLayout>
  );
}
