import { FormattedMessage } from "react-intl";
import CookieConsent from "react-cookie-consent";
import { createGlobalStyle } from "styled-components";

import { useGetFooter } from "@api/configuration/Configuration.queries";
import { ConsentButton } from "./ConsentButton";

const GlobalStyle = createGlobalStyle`
  .accept-cookies {
    &:disabled {
      background-color: ${({ theme }) =>
        theme.color.solid.lightGrey} !important;

      &,
      & * {
        cursor: default !important;
      }
    }
  }
`;

export function CookiePrivacyConsent() {
  const { data: { dataProtectionNotice } = {} } = useGetFooter();

  const isLoading = !dataProtectionNotice;

  const privacyPolicy = (
    <FormattedMessage
      id="cookieBox.privacyPolicy"
      defaultMessage="Privacy Policy"
    />
  );

  return (
    <>
      <GlobalStyle />

      <CookieConsent
        buttonClasses="accept-cookies"
        buttonText={<ConsentButton isLoading={isLoading} />}
        customButtonProps={{
          disabled: isLoading,
        }}
      >
        <FormattedMessage
          id="cookieBox.content"
          defaultMessage="We use cookies and other similar technologies to enhance your browsing experience and analyze and measure your engagement with our content. By clicking 'I understand' or by continuing to browse this site, you consent to the use of cookies and similar technologies as described in our {privacyPolicy}."
          values={{
            privacyPolicy: dataProtectionNotice ? (
              <a href={dataProtectionNotice} target="_blank" rel="noreferrer">
                {privacyPolicy}
              </a>
            ) : (
              privacyPolicy
            ),
          }}
        />
      </CookieConsent>
    </>
  );
}
