import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;

  padding: ${({ theme }) => theme.spacing.l};

  border: 1px solid ${({ theme }) => theme.color.solid.primary};
  border-radius: 0px 24px;
`;
