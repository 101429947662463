import styled from "styled-components";
import { StyledIconProps } from "./Icon.types";

export const StyledIcon = styled.span<StyledIconProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: ${({ $width }) => `${$width}px`};
  height: ${({ $height }) => `${$height}px`};
  border-radius: 100%;

  svg {
    stroke: ${({ $color }) => $color};
    transition: stroke 0.25s ease-in-out;
  }
`;
