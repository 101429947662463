import styled from "styled-components";

export const ExamsFiltersWrapper = styled.div`
  form > * {
    @media (${({ theme }) => theme.breakpoints.tabletOnly}) {
      width: 100%;
    }

    @media (${({ theme }) => theme.breakpoints.desktop}) {
      flex-grow: 0;
    }
  }

  button[type="submit"] {
    @media (${({ theme }) => theme.breakpoints.tabletOnly}) {
      margin-top: ${({ theme }) => theme.spacing.m};
    }
  }
`;
