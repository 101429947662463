import { useTheme } from "styled-components";
import { IconProps } from "./Icon.types";
import icons from "../../icons";
import { StyledIcon } from "./Icon.styles";

export function Icon({
  icon,
  color = "primary",
  noBackground,
  width = 56,
  height = 56,
  className,
  ...rest
}: IconProps) {
  const IconElement = icons({ name: icon });

  const theme = useTheme();
  const iconColor = { ...theme.color.solid, ...theme.color.informative }[color];

  if (!IconElement) {
    return null;
  }

  if (noBackground) {
    return <IconElement className={className} {...rest} />;
  }

  return (
    <StyledIcon
      className={className}
      $color={iconColor}
      $width={width}
      $height={height}
    >
      <IconElement {...rest} />
    </StyledIcon>
  );
}
