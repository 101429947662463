import styled from "styled-components";

export const DropdownContainer = styled.div`
  position: relative;
`;

export const StyledDropdown = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  padding: ${({ theme }) => theme.spacing.s};

  background-color: ${({ theme }) => theme.color.solid.white};

  border: 1px solid ${({ theme }) => theme.color.stroke.lightGrey};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  box-shadow: ${({ theme }) => theme.shadow.s};

  font-size: 14px;
  letter-spacing: 0.25px;
`;

export const EmptyStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-top: ${({ theme }) => theme.spacing.xl};
  padding-bottom: ${({ theme }) => theme.spacing.xxl};
`;

export const StyledResult = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: ${({ theme }) => theme.spacing.s};

  a {
    color: ${({ theme }) => theme.color.solid.darkGrey};
    font-weight: ${({ theme }) => theme.typography.weights.normal};
    cursor: pointer;
    transition: color 0.25s ease-in-out;

    &:hover {
      color: ${({ theme }) => theme.color.solid.primary};
    }
  }
`;

export const QuestionCaption = styled.span`
  font-size: 12px;
  letter-spacing: 0.4px;

  color: ${({ theme }) => theme.color.solid.grey};
`;

export const SeeAllResultsWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: ${({ theme }) => theme.spacing.s};
  border-top: 1px solid ${({ theme }) => theme.color.stroke.lightGrey};
`;
