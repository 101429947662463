import { ReactNode } from "react";
import { Heading } from "../Typography";
import { HeadingVariant } from "../Typography/Heading/Heading.types";
import { Container } from "./OutlinedSection.styles";
import { Box } from "../Box";

interface OutlinedSectionProps {
  children: ReactNode;
  title: string;
}

export function OutlinedSection({ title, children }: OutlinedSectionProps) {
  return (
    <Container>
      <Box mb="m">
        <Heading as={HeadingVariant.H5} color="primary">
          {title}
        </Heading>
      </Box>
      {children}
    </Container>
  );
}
