import { RefObject, createRef, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useParams } from "react-router-dom";

import { Accordion } from "@components/Accordion";
import { DashboardLayout } from "@components/DashboardLayout";
import { Heading, Paragraph } from "@components/Typography";
import { Image } from "@components/Image";
import { PDFPreview } from "@components/PDFPreview";
import { useGetExamGuide } from "@api/paper/Paper.queries";
import { HeadingVariant } from "@components/Typography/Heading/Heading.types";
import { useBreakpoints } from "@utils/use-breakpoints";
import { Box } from "@components/Box";
import { Paths } from "@utils/router";
import { Icon } from "@components/Icon";
import { Row } from "@components/Row";

import { Container } from "../../Dashboard.styles";

import {
  ContentWrapper,
  GoBackLink,
  DescriptionContainer,
} from "./ExamGuideDetails.styles";

const PAGE_PADDING = 48 + 32 + 8;

export function ExamGuideDetails() {
  const { isMobile } = useBreakpoints();

  const [panelRefs, setPanelRefs] = useState<
    Array<RefObject<HTMLButtonElement>>
  >([]);
  const { subjectLevelId = "" } = useParams();
  const { data } = useGetExamGuide(subjectLevelId, {
    retry: false,
  });

  useEffect(() => {
    setPanelRefs((elRefs) =>
      Array(data?.length)
        .fill(false)
        .map((_, i) => elRefs[i] || createRef())
    );
  }, [data?.length]);

  const handleOnLoad = (index: number) => {
    panelRefs[index].current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  };

  return (
    <DashboardLayout
      backgroundImage="/img/exam-creator-background.svg"
      headerComponent={
        <>
          <Box>
            <Box {...(!isMobile && { mb: "l" })}>
              <Link to={Paths.ExamGuideList}>
                <GoBackLink>
                  <Icon
                    icon="ArrowLeft"
                    color="darkGrey"
                    height={20}
                    width={20}
                  />
                  <FormattedMessage
                    id="examGuideDetails.goBackButton"
                    defaultMessage="Go back"
                  />
                </GoBackLink>
              </Link>
            </Box>
            <Heading as={HeadingVariant.H3}>
              {`${data?.[0].subjectLevel.subject.examType.shortName}, ${data?.[0]?.subjectLevel.subject.name}, ${data?.[0].subjectLevel.level.shortName}`}
            </Heading>
          </Box>
          <DescriptionContainer>
            <Paragraph>
              <FormattedMessage
                id="examGuideDetails.description"
                defaultMessage="Here you can check the Exam Guide for this Paper."
              />
            </Paragraph>
          </DescriptionContainer>
        </>
      }
    >
      <Container>
        <Accordion.Default>
          {data?.map(({ id, file, topic }, index) => (
            <Accordion.Panel.Default key={id}>
              <Accordion.Panel.Header headerRef={panelRefs[index]}>
                {({ isActive }) => (
                  <Row gap="m">
                    <Image src={topic.icon} />
                    <Heading
                      as={isMobile ? HeadingVariant.H6 : HeadingVariant.H5}
                      color={isActive ? "primary" : "darkGrey"}
                    >
                      {topic.name}
                    </Heading>
                  </Row>
                )}
              </Accordion.Panel.Header>
              <Accordion.Panel.Content>
                <ContentWrapper>
                  <PDFPreview
                    pdf={file}
                    onLoad={() => handleOnLoad(index)}
                    renderMode={isMobile ? "svg" : "canvas"}
                    pageWidth={
                      isMobile ? window.innerWidth - PAGE_PADDING : undefined
                    }
                  />
                </ContentWrapper>
              </Accordion.Panel.Content>
            </Accordion.Panel.Default>
          ))}
        </Accordion.Default>
      </Container>
    </DashboardLayout>
  );
}
