import { Children, ReactElement, cloneElement, isValidElement } from "react";
import { PanelProps } from "./Panel.types";
import { useAccordion } from "../Accordion";
import { StyledPanel } from "./Panel.styles";

export function Panel({ children, index = 0 }: PanelProps) {
  const { activeIndices } = useAccordion();

  return (
    <StyledPanel isActive={activeIndices[index]}>
      {Children.map(children, (child) => {
        if (isValidElement(child) && typeof child.type === "function") {
          return cloneElement(child as ReactElement<{ index: number }>, {
            index,
          });
        }

        return child;
      })}
    </StyledPanel>
  );
}
