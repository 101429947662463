import styled from "styled-components";

export const StyledList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing.l};

  > * + * {
    margin-top: ${({ theme }) => theme.spacing.m};
  }

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row;
    justify-content: center;

    > * + * {
      margin-top: 0;
      margin-left: ${({ theme }) => theme.spacing.m};
    }
  }
`;
