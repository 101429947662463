import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate, Link, useLocation } from "react-router-dom";

import AuthService from "@api/auth/Auth.service";
import {
  AccountActivationResultType,
  isAccountActivationResultState,
  UserLoginData,
} from "@api/auth/Auth.types";
import { useNotification } from "@utils/notifications/notification-context";
import { Paths } from "@utils/router";

import { AlertVariant, NotificationProps } from "@components/Alert/Alert.types";
import { Box } from "@components/Box";
import { LoginForm } from "@components/LoginForm";
import { TwoColumnsLayout } from "@components/TwoColumnsLayout";
import { Heading, Overline, Paragraph } from "@components/Typography";
import { HeadingVariant } from "@components/Typography/Heading/Heading.types";

const accountActivationNotificationVariant = {
  [AccountActivationResultType.ERROR]: AlertVariant.Warning,
  [AccountActivationResultType.SELF_SUCCESS]: AlertVariant.Success,
  [AccountActivationResultType.PARENT_SUCCESS]: AlertVariant.Warning,
  [AccountActivationResultType.STUDENT_UNDER16_SUCCESS]: AlertVariant.Success,
};

const defaultErrorNotification = {
  title: "Your activation link has expired!",
  text: (
    <>
      Please contact{" "}
      <a href="mailto:support@edcolearning.ie">support@edcolearning.ie</a> for
      help activating your ExamHub account.
    </>
  ),
  variant: AlertVariant.Warning,
  autoRemoveDelay: 7000,
} as Omit<NotificationProps, "id">;

export function Login() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { addNotification } = useNotification();

  useEffect(() => {
    if (!isAccountActivationResultState(state)) {
      return;
    }

    const { type, title, message } = state.accountActivation;

    if (type === AccountActivationResultType.ERROR && !title && !message) {
      addNotification({
        id: "accountActivationResult",
        ...defaultErrorNotification,
      });

      return;
    }

    addNotification({
      id: "accountActivationResult",
      title,
      text: message,
      variant:
        accountActivationNotificationVariant[type] || AlertVariant.Success,
    });
  }, [addNotification, state]);

  const handleOnSubmit = (data: UserLoginData) =>
    AuthService.login(data).then(() => {
      navigate(Paths.Dashboard);
    });

  return (
    <TwoColumnsLayout>
      <Box mb="m">
        <Heading as={HeadingVariant.H3} align="center">
          <FormattedMessage id="login.title" defaultMessage="Log in" />
        </Heading>
      </Box>

      <LoginForm onSubmit={handleOnSubmit} />

      <Box mt="m" mb="m">
        <Paragraph align="center">
          <Overline>
            <FormattedMessage id="login.orSeparator" defaultMessage="Or" />
          </Overline>
        </Paragraph>
      </Box>

      <Box>
        <Paragraph align="center">
          <FormattedMessage
            id="login.signIn"
            defaultMessage="Don't have an account? {registerLink}"
            values={{
              registerLink: (
                <Link to={Paths.StudentRegistration}>
                  <FormattedMessage
                    id="login.registerLink"
                    defaultMessage="Register"
                  />
                </Link>
              ),
            }}
          />
        </Paragraph>
      </Box>
    </TwoColumnsLayout>
  );
}
