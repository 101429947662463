import { isValidElement, useMemo } from "react";
import { useAccordion } from "../../Accordion";

import { Icon } from "../../../Icon";
import { StyledHeader } from "./Header.styles";
import { PanelHeaderProps } from "./Header.types";

export function PanelHeader({
  children,
  index = 0,
  headerRef,
  ...props
}: PanelHeaderProps) {
  const { activeIndices, setActiveIndices } = useAccordion();
  const isActive = activeIndices[index];

  const content = useMemo(() => {
    if (typeof children === "function" && !isValidElement(children)) {
      return children({ isActive });
    }

    return children;
  }, [children, isActive]);

  const handleOnClick = () => {
    setActiveIndices(
      activeIndices.map((value, currentIndex) =>
        currentIndex === index ? !value : false
      )
    );
  };

  return (
    <StyledHeader onClick={handleOnClick} ref={headerRef} {...props}>
      {content}
      <Icon icon={isActive ? "Minus" : "Plus"} />
    </StyledHeader>
  );
}
