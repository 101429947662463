import { createGlobalStyle } from "styled-components";

import { Overlay } from "./GlobalOverlay.styles";

const GlobalStyle = createGlobalStyle<{ isOpen: boolean; }>`
  ${({ isOpen }) => isOpen && `
    html {
      overflow: hidden;
    }

    body {
      overflow: visible;
    }
  `}
`;

interface GlobalOverlayProps {
  isOpen: boolean;
  onClick: () => void;
}

export function GlobalOverlay ({
  isOpen,
  onClick,
}: GlobalOverlayProps) {
  return (
    <>
      <GlobalStyle isOpen={isOpen} />
      <Overlay
        isOpen={isOpen}
        onClick={onClick}
      />
    </>
  );
}
