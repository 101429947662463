import styled from "styled-components";

import { Icon } from "@components/Icon";

export const FilterIcon = styled(Icon)`
  margin-right: ${({ theme }) => theme.spacing.xs};

  svg {
    stroke: none;
  }
`;
