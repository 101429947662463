import { useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import { Select } from "@components/Select";
import { useFiltersPanel } from "@components/FiltersPanel";

import { FiltersProps } from "./Filters.types";
import {
  FilterLoader,
  MandatoryHint,
  StyledForm,
  SubmitButton,
} from "./Filters.styles";

const parseOptionValues =
  (onChange: (data: any) => void) => (data: Record<string, any>) => {
    onChange(
      Object.fromEntries(Object.entries(data).filter(([_, value]) => value))
    );
  };

export function Filters({
  noDependentFields = false,
  fields,
  values,
  onChange = () => {},
  onSubmit = () => {},
  isFetching,
  hideSubmitButton = false,
  showMandatoryHint = false,
}: FiltersProps) {
  const { close: closeFiltersPanel } = useFiltersPanel();
  const {
    handleSubmit,
    control,
    getValues,
    reset,
    trigger: validateForm,
    formState: { isValid },
  } = useForm({
    defaultValues: values,
  });

  useEffect(() => {
    if (values && Object.keys(values).length > 0) {
      reset(values);
    }
  }, [values, validateForm, reset]);

  const onSubmitInner = useCallback(
    (data: unknown) => {
      onSubmit(data);
      closeFiltersPanel();
    },
    [closeFiltersPanel, onSubmit]
  );

  const handleFieldChange: (
    name: string,
    option: { name: string; value: string }
  ) => void = (name, option) => {
    let newFormValues = {
      ...getValues(),
      [name]: option.value,
    };

    if (!noDependentFields) {
      const fieldIndex = fields.findIndex((field) => field.name === name);

      const dependentFields = fields
        .slice(fieldIndex + 1, fields.length)
        .map(({ name: fieldName }) => fieldName);

      newFormValues = Object.fromEntries(
        Object.entries(newFormValues)
          .map(([key, fieldValue]) => [
            key,
            dependentFields.includes(key) ? null : fieldValue,
          ])
      );
    }

    reset(newFormValues);
    parseOptionValues(onChange)(newFormValues);
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmitInner)}>
      {fields.map(({ name, label, options, required, maxWidth, disabled }) => (
        <Controller
          key={name}
          name={name}
          control={control}
          rules={{ required }}
          render={({
            field: { value, ref },
            fieldState: { isTouched, error },
          }) => (
            <Select
              ref={ref}
              name={name}
              onChange={(option) => handleFieldChange(name, option)}
              value={options?.find((option) => option.value === value) || null}
              isError={isTouched && !!error}
              label={label}
              options={options}
              isSearchable={false}
              placeholder="-"
              menuPlacement="auto"
              menuPosition="fixed"
              menuShouldScrollIntoView={false}
              isDisabled={disabled || !options}
              isRequired={required}
              maxWidth={maxWidth}
            />
          )}
        />
      ))}

      {showMandatoryHint && (
        <MandatoryHint>
          <FormattedMessage
            id="questionsFilters.mandatoryFilterMessage"
            defaultMessage="This filter is mandatory"
          />
        </MandatoryHint>
      )}

      {!hideSubmitButton && (
        <SubmitButton type="submit" disabled={!isValid}>
          {isFetching ? (
            <FilterLoader size={21} width={2} variant="white" />
          ) : (
            <FormattedMessage
              id="questionsFilters.submitButton"
              defaultMessage="Filter"
            />
          )}
        </SubmitButton>
      )}
    </StyledForm>
  );
}
