import { useState } from "react";
import { Page, Document } from "react-pdf/dist/esm/entry.webpack5.js";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { PDFDocumentProxy } from "pdfjs-dist/types/src/display/api";

import { PDFWrapper } from "./PDFPreview.styles";

interface PDFPreviewProps {
  pdf: string;
  onLoad?: (data: PDFDocumentProxy) => void;
  renderMode?: "svg" | "canvas";
  pageWidth?: number;
}

export function PDFPreview({
  pdf,
  onLoad,
  renderMode,
  pageWidth,
}: PDFPreviewProps) {
  const [numPages, setNumPages] = useState(0);

  const handleOnLoadSuccess = (data: any) => {
    // eslint-disable-next-line no-underscore-dangle
    setNumPages(data._pdfInfo.numPages);

    if (typeof onLoad === "function") {
      onLoad(data);
    }
  };

  return (
    <PDFWrapper data-body-scroll-lock-ignore>
      <Document
        file={pdf}
        onLoadSuccess={handleOnLoadSuccess}
        renderMode={renderMode}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            width={pageWidth}
          />
        ))}
      </Document>
    </PDFWrapper>
  );
}
