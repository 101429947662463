import styled from "styled-components";

import { Paragraph } from "@components/Typography";
import { ParagraphVariants } from "@components/Typography/Paragraph/Paragraph.types";

import { ActionButtons as DefaultActionButtons } from "../ActionButtons";

export const Container = styled.article`
  overflow: hidden;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  border: 1px solid ${({ theme }) => theme.color.background.primary};

  & + & {
    margin-top: ${({ theme }) => theme.spacing.s};
  }
`;

export const Header = styled(Paragraph).attrs({
  variant: ParagraphVariants.Small,
})`
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  padding: ${({ theme }) => theme.spacing.s};
  font-weight: ${({ theme }) => theme.typography.weights.bold};
  background-color: ${({ theme }) => theme.color.background.light};
`;

export const DataRow = styled.div`
  display: flex;
  align-items: center;
  min-height: 44px;
  padding: ${({ theme }) => theme.spacing.xxs} 0;

  :nth-of-type(even) {
    background-color: ${({ theme }) => theme.color.stroke.light};
  }
`;

export const DataHeader = styled(Paragraph).attrs({
  variant: ParagraphVariants.Small,
  color: "dark",
})`
  flex-shrink: 0;
  width: 50%;
  max-width: 163px;
  padding: 0 ${({ theme }) => theme.spacing.s};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    width: 180px;
  }
`;

export const DataValue = styled(Paragraph).attrs({
  variant: ParagraphVariants.Small,
})`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding: 0 10px;
  text-overflow: ellipsis;
`;

export const ActionButtons = styled(DefaultActionButtons)`
  justify-content: flex-end;
  border-top: 1px solid ${({ theme }) => theme.color.background.primary};
  margin-top: ${({ theme }) => theme.spacing.xs};
  padding: 0 ${({ theme }) => theme.spacing.s};

  > * {
    flex: 0 1 56px;

    & + * {
      margin-left: ${({ theme }) => theme.spacing.xs};
    }

    span {
      width: 100%;
    }
  }
`;
