import { Link } from "react-router-dom";
import { Paths } from "../../utils/router";
import { useBreakpoints } from "../../utils/use-breakpoints";
import { ImageColumn } from "./ImageColumn";
import {
  Column,
  Container,
  ContentInner,
  ContentWrapper,
  Header,
  HeaderRow,
  Logo,
} from "./TwoColumnsLayout.styles";
import { TwoColumnsLayoutProps } from "./TwoColumnsLayout.types";

export function TwoColumnsLayout({
  children,
  backgroundImage,
}: TwoColumnsLayoutProps) {
  const { isDesktop, isTablet } = useBreakpoints();

  return (
    <Container>
      <Column>
        <Header>
          <HeaderRow>
            <Link to={Paths.Home}>
              <Logo alt="Edco ExamHub" />
            </Link>
          </HeaderRow>

          {isTablet && !isDesktop && (
            <ImageColumn backgroundImage={backgroundImage} />
          )}
        </Header>

        <ContentWrapper>
          <ContentInner>{children}</ContentInner>
        </ContentWrapper>
      </Column>

      {isDesktop && <ImageColumn backgroundImage={backgroundImage} />}
    </Container>
  );
}
