import { useCallback } from "react";

import { Icon } from "../Icon";
import {
  ChildrenWrapper,
  StyledAlert,
  StyledCloseButton,
} from "./Alert.styles";
import { AlertProps, AlertVariant } from "./Alert.types";

export function Alert({
  variant,
  showCloseButton,
  onClose,
  children,
  hideIcon,
  type = "polite",
  ...props
}: AlertProps) {
  const renderIcon = useCallback(() => {
    switch (variant) {
      case AlertVariant.Warning:
        return <Icon icon="Alert" noBackground />;
      case AlertVariant.Danger:
        return <Icon icon="QuestionMark" noBackground />;
      default:
        return <Icon icon="Info" noBackground />;
    }
  }, [variant]);

  return (
    <StyledAlert
      role={type === "assertive" ? "alert" : "status"}
      aria-live={type}
      variant={variant}
      {...props}
    >
      {!hideIcon && renderIcon()}
      <ChildrenWrapper>{children}</ChildrenWrapper>
      {showCloseButton && (
        <StyledCloseButton type="button" onClick={onClose}>
          <Icon icon="Close" color="darkGrey" noBackground />
        </StyledCloseButton>
      )}
    </StyledAlert>
  );
}
