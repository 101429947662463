import { forwardRef } from "react";
import { useId } from "@reach/auto-id";

import { getVariant } from "@utils/style-utils";

import { FieldCaption, FieldVariant } from "@components/FieldCaption";
import { Icon } from "@components/Icon";
import { ProgressBar } from "@components/ProgressBar";
import { Box } from "@components/Box";
import { FieldLabel } from "@components/FieldLabel";

import { TextAreaProps } from "./TextArea.types";
import {
  IconLeftWrapper,
  IconRightWrapper,
  Container,
  TextAreaWrapper,
  StyledTextArea,
} from "./TextArea.styles";

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      label,
      error,
      caption,
      prefilled,
      isValid,
      disabled,
      iconLeft,
      progress,
      onIconClick,
      iconRight,
      isRequired,
      ...props
    },
    ref
  ) => {
    const id = useId(props.id);
    const variant = getVariant<FieldVariant>({
      [FieldVariant.Primary]: !!prefilled,
      [FieldVariant.Disabled]: !!disabled,
      [FieldVariant.Error]: !!error,
      [FieldVariant.Valid]: !!isValid,
      [FieldVariant.Question]: false,
    });

    return (
      <Container>
        {label && (
          <FieldLabel htmlFor={id} isRequired={isRequired}>
            {label}
          </FieldLabel>
        )}

        <TextAreaWrapper isError={!!error}>
          {iconLeft && (
            <IconLeftWrapper onClick={onIconClick}>
              <Icon aria-hidden icon={iconLeft} color="grey" />
            </IconLeftWrapper>
          )}
          <StyledTextArea ref={ref} id={id} {...props} />
          {iconRight && (
            <IconRightWrapper onClick={onIconClick}>
              {typeof iconRight === "string" ? (
                <Icon aria-hidden icon={iconRight} color="grey" />
              ) : (
                iconRight
              )}
            </IconRightWrapper>
          )}
        </TextAreaWrapper>

        {(caption || error) && (
          <>
            {!!progress && (
              <Box mt="s">
                <ProgressBar progress={progress} />
              </Box>
            )}
            <FieldCaption variant={variant}>{error || caption}</FieldCaption>
          </>
        )}
      </Container>
    );
  }
);
