import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Link, NavLink, useNavigate } from "react-router-dom";

import AuthService from "@api/auth/Auth.service";
import { UserRoles } from "@api/auth/Auth.types";
import { Paths } from "@utils/router";
import { useBreakpoints } from "@utils/use-breakpoints";
import { useUser } from "@utils/user/user-context";

import { Avatar } from "@components/Avatar";
import { Dropdown } from "@components/Dropdown";
import { Row } from "@components/Row";

import {
  Container,
  HamburgerButton,
  HamburgerButtonWrapper,
  InnerContainer,
  LinksWrapper,
  MenuContent,
  MenuLink,
  StyledLogo,
} from "./Menu.styles";
import { MobileMenu } from "./MobileMenu";
import { GlobalSearch } from "../GlobalSearch";

function Logo() {
  return (
    <Link to={Paths.Dashboard}>
      <StyledLogo src="/img/logo-big.svg" alt="ExamHub Created by Edco" />
    </Link>
  );
}

export function Menu() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { currentUser } = useUser();
  const { formatMessage } = useIntl();
  const { isDesktop, isTablet } = useBreakpoints();
  const navigate = useNavigate();

  const menuItems = useMemo(
    () =>
      [
        {
          roles: [UserRoles.Teacher, UserRoles.Student],
          to: Paths.Dashboard,
          label: formatMessage({
            id: "menu.dashboard",
            defaultMessage: "Dashboard",
          }),
        },
        {
          roles: [UserRoles.Teacher, UserRoles.Student],
          to: Paths.Questions,
          label: formatMessage({
            id: "menu.questions",
            defaultMessage: "Questions by Topic",
          }),
        },
        {
          roles: [UserRoles.Teacher, UserRoles.Student],
          to: Paths.Solutions,
          label: formatMessage({
            id: "menu.solutions",
            defaultMessage: "Solutions",
          }),
        },
        {
          roles: [UserRoles.Teacher],
          to: Paths.MyExams,
          label: formatMessage({
            id: "menu.myExams",
            defaultMessage: "My exams",
          }),
        },
        {
          roles: [UserRoles.Teacher],
          to: Paths.ExamCreator,
          label: formatMessage({
            id: "menu.examCreator",
            defaultMessage: "Exam creator",
          }),
        },
        {
          roles: [UserRoles.Teacher, UserRoles.Student],
          to: Paths.ExamGuideList,
          label: formatMessage({
            id: "menu.examCreator",
            defaultMessage: "Exam guide",
          }),
        },
      ].filter(({ roles }) => currentUser && roles.includes(currentUser?.role)),
    [currentUser, formatMessage]
  );

  if (isDesktop) {
    return (
      <Container>
        <InnerContainer>
          <Logo />

          <MenuContent>
            <LinksWrapper gap="xs" justify="space-around">
              {menuItems.map(({ to, label }) => (
                <NavLink to={to} key={to}>
                  {({ isActive }) => (
                    <MenuLink isActive={isActive}>{label}</MenuLink>
                  )}
                </NavLink>
              ))}
            </LinksWrapper>

            <Row gap="m">
              <GlobalSearch />

              <Dropdown
                menuItems={[
                  {
                    key: "myAccount",
                    children: formatMessage({
                      id: "menu.myAccount",
                      defaultMessage: "My account",
                    }),
                    onSelect: () => navigate(Paths.MyAccount),
                  },
                  {
                    key: "logout",
                    children: formatMessage({
                      id: "menu.logout",
                      defaultMessage: "Log out",
                    }),
                    onSelect: AuthService.logout,
                  },
                ]}
              >
                <Avatar />
              </Dropdown>
            </Row>
          </MenuContent>
        </InnerContainer>
      </Container>
    );
  }

  if (isTablet) {
    return (
      <>
        <Container>
          <InnerContainer>
            <Logo />

            <MenuContent>
              <GlobalSearch isOpen />
            </MenuContent>

            <HamburgerButtonWrapper>
              <HamburgerButton onClick={() => setIsMobileMenuOpen(true)} />
            </HamburgerButtonWrapper>
          </InnerContainer>
        </Container>

        <MobileMenu
          isOpen={isMobileMenuOpen && !isDesktop}
          onClose={() => setIsMobileMenuOpen(false)}
          menuItems={menuItems}
        />
      </>
    );
  }

  return (
    <>
      <Container>
        <InnerContainer>
          <GlobalSearch />

          <Link to={Paths.Dashboard}>
            <StyledLogo src="/img/logo-big.svg" alt="ExamHub Created by Edco" />
          </Link>

          <HamburgerButton onClick={() => setIsMobileMenuOpen(true)} />
        </InnerContainer>
      </Container>

      <MobileMenu
        isOpen={isMobileMenuOpen && !isDesktop}
        onClose={() => setIsMobileMenuOpen(false)}
        menuItems={menuItems}
      />
    </>
  );
}
