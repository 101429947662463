import { ReactNode, useMemo, useState } from "react";

import { useBreakpoints } from "@utils/use-breakpoints";

import { Button } from "@components/Button";

import { Panel } from "./Panel";
import { FiltersPanelContext } from "./FiltersPanel.context";
import { FilterIcon } from "./FiltersPanel.styles";

interface FilterPanelProps {
  children: ReactNode;
  forcePanel?: boolean;
}

export function FiltersPanel({
  children,
  forcePanel,
}: FilterPanelProps) {
  const { isMobile } = useBreakpoints();
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const contextValue = useMemo(() => ({
    close: () => setIsPanelOpen(false),
  }), []);

  return (
    <FiltersPanelContext.Provider value={contextValue}>
      {forcePanel || isMobile ? (
        <>
          <Button
            isSmall
            autoWidth
            onClick={() => setIsPanelOpen(true)}
          >
            <FilterIcon icon="Filter" width={20} height={20} />
            Filters
          </Button>

          <Panel
            isOpen={isPanelOpen}
            onClose={() => setIsPanelOpen(false)}
          >
            {children}
          </Panel>
        </>
    ) : (
      children
    )}
  </FiltersPanelContext.Provider>
  );
}
