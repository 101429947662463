import { Solution as SolutionType } from "../../api/paper/Paper.types";
import { Container } from "./SolutionsList.styles";
import { Solution, SolutionProps } from "./Solution";

interface SolutionsListProps extends Pick<SolutionProps, "defaultTab"> {
  solutions?: SolutionType[];
}

export function SolutionsList({ solutions, defaultTab }: SolutionsListProps) {
  return (
    <Container>
      {solutions?.map((solution) => (
        <Solution key={solution.id} defaultTab={defaultTab} {...solution} />
      ))}
    </Container>
  );
}
