import { Bar, Progress } from "./ProgressBar.styles";
import { ProgressBarProps } from "./ProgressBar.types";

export function ProgressBar({
  color,
  progress = 0,
  ...props
}: ProgressBarProps) {
  return (
    <Bar {...props}>
      <Progress color={color} progress={progress} />
    </Bar>
  );
}
