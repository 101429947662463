import { Button } from "@components/Button";
import { Loader } from "@components/Loader";

import styled from "styled-components";

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-width: 100%;
  flex-wrap: wrap;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row;
    align-items: flex-end;
    margin: -${({ theme }) => parseInt(theme.spacing.s, 10) / 2}px;
  }

  & > * {
    flex-grow: 1;

    @media (${({ theme }) => theme.breakpoints.tablet}) {
      margin: ${({ theme }) => parseInt(theme.spacing.s, 10) / 2}px;
    }

    @media (${({ theme }) => theme.breakpoints.mobile}) {
      width: 100% !important;
    }
  }
`;

export const MandatoryHint = styled.p`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.s};
  margin-bottom: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.color.solid.darkGrey};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    order: 1;
    margin: ${({ theme }) => parseInt(theme.spacing.s, 10) / 2}px;
  }

  &::before {
    content: "*";
    color: ${({ theme }) => theme.color.informative.alert};
    padding-right: 4px;
  }
`;

export const SubmitButton = styled(Button)`
  @media (${({ theme }) => theme.breakpoints.mobile}) {
    margin-top: ${({ theme }) => theme.spacing.m};
  }
`;

export const FilterLoader = styled(Loader)`
  position: static;
`;
