import { useIntl } from "react-intl";

import { Paths } from "@utils/router";

import { Image } from "@components/Image";
import { ErrorView } from "@components/ErrorView";

export function NotFound() {
  const { formatMessage } = useIntl();

  return (
    <ErrorView
      title={formatMessage({
        id: "notFound.notFoundTitle",
        defaultMessage: "Sorry! \nPage not found",
      })}
      message={formatMessage({
        id: "notFound.description",
        defaultMessage:
          "The requested page is not found on this server. You can use the button below to return to the Homepage.",
      })}
      linkTo={Paths.Home}
      linkToTitle={formatMessage({
        id: "notFound.homepageLink",
        defaultMessage: "Go to the Homepage",
      })}
      image={<Image src="/img/not-found.svg" alt="Page not found" />}
    />
  );
}
