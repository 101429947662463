import { Box } from "../Box";
import { Image } from "../Image";
import { Heading, Overline } from "../Typography";
import { HeadingVariant } from "../Typography/Heading/Heading.types";
import {
  Container,
  CoverImage,
  ExamTypeLabel,
  HoverMenu,
} from "./PaperBox.styles";
import { PaperBoxProps } from "./PaperBox.types";

export function PaperBox({
  children,
  coverImage,
  level,
  paper,
  type,
  className,
  disableHover,
  isActive = true,
  imageToBottom = true,
}: PaperBoxProps) {
  return (
    <Container className={className} tabIndex={0} imageToBottom={imageToBottom}>
      <ExamTypeLabel variant={type}>{type}</ExamTypeLabel>

      <Box mt="s" mb="s">
        <Heading as={HeadingVariant.H5} align="center">
          {paper}
        </Heading>
      </Box>

      <Box mt="xxs" mb={imageToBottom ? "l" : "m"}>
        <Overline>{level}</Overline>
      </Box>

      {coverImage ? (
        <CoverImage isActive={isActive} imageToBottom={imageToBottom}>
          <Image src={coverImage} />
        </CoverImage>
      ) : null}

      {!disableHover && <HoverMenu>{children}</HoverMenu>}
    </Container>
  );
}
