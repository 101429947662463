import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { useSearchPapers } from "@api/paper/Paper.queries";
import { useNotification } from "@utils/notifications/notification-context";
import { Paths } from "@utils/router";

import { DashboardLayout } from "@components/DashboardLayout";
import { AlertVariant } from "@components/Alert/Alert.types";
import { Heading } from "@components/Typography";
import { HeadingVariant } from "@components/Typography/Heading/Heading.types";
import { Filters } from "@components/Pagination/Pagination.types";

import { Container } from "../Dashboard.styles";

import { SearchResultsList } from "./SearchResultsList";
import { TitleQuery } from "./SearchResults.styles";

export function SearchResults() {
  const navigate = useNavigate();
  const { addNotification } = useNotification();
  const [pagination, setPagination] = useState<Filters>();
  const { query } = useParams();
  const { data } = useSearchPapers(
    { ...pagination, query: query || "" },
    {
      enabled: !!query,
      onSuccess: (settledData) => {
        if (!settledData?.total) {
          addNotification({
            id: "queryNotFound",
            text: "Not found",
            variant: AlertVariant.Danger,
            title: undefined,
          });

          navigate(Paths.Questions);
        }
      },
    }
  );

  return (
    <DashboardLayout
      backgroundImage="/img/search-results-background.svg"
      headerComponent={
        <Heading as={HeadingVariant.H3}>
          <FormattedMessage
            id="searchResults.totalResults"
            defaultMessage="{amount} results"
            values={{
              amount: data?.total || 0,
            }}
          />
          <TitleQuery>
            <FormattedMessage
              id="searchResults.forQuery"
              defaultMessage="for &ldquo;{query}&rdquo;"
              values={{ query }}
            />
          </TitleQuery>
        </Heading>
      }
    >
      <Container>
        <SearchResultsList
          searchResults={data}
          handlePaginationChange={(newPagination) =>
            setPagination({ ...pagination, ...newPagination })
          }
        />
      </Container>
    </DashboardLayout>
  );
}
