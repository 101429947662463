import { useMemo } from "react";
import { useIntl } from "react-intl";

import { Exam } from "@api/exam-creator/ExamCreator.types";

import {
  ActionButtons,
  Container,
  DataHeader,
  DataRow,
  DataValue,
  Header,
} from "./ExamBox.styles";
import { ExamBadge } from "../ExamBadge";

type ExamBoxProps = Exam;

export function ExamBox({
  id,
  title,
  topic: {
    name: topicName,
    subjectLevel: {
      subject: {
        name: subjectName,
        examType: { shortName: examTypeName },
      },
      level: { shortName: levelName },
    },
  },
  createdAt,
  updatedAt,
}: ExamBoxProps) {
  const { formatMessage, formatDate } = useIntl();

  const data = useMemo(
    () => [
      {
        name: formatMessage({
          id: "examsTable.exam",
          defaultMessage: "Exam",
        }),
        value: (<ExamBadge>{examTypeName}</ExamBadge>) ,
      },
      {
        name: formatMessage({
          id: "examsTable.examPaper",
          defaultMessage: "Exam paper",
        }),
        value: subjectName,
      },
      {
        name: formatMessage({
          id: "examsTable.topic",
          defaultMessage: "Topic",
        }),
        value: topicName,
      },
      {
        name: formatMessage({
          id: "examsTable.level",
          defaultMessage: "Level",
        }),
        value: levelName,
      },
      {
        name: formatMessage({
          id: "examsTable.lastChange",
          defaultMessage: "Last change",
        }),
        value: formatDate(updatedAt || createdAt),
      },
    ],
    [
      createdAt,
      examTypeName,
      formatDate,
      formatMessage,
      levelName,
      subjectName,
      topicName,
      updatedAt,
    ]
  );

  return (
    <Container>
      <Header>{title}</Header>

      {data.map(({ name, value }) => (
        <DataRow key={name}>
          <DataHeader>{name}</DataHeader>
          <DataValue>{value}</DataValue>
        </DataRow>
      ))}

      <ActionButtons id={id} />
    </Container>
  );
}
