import { ReactComponent as AddCode } from "./add-code.svg";
import { ReactComponent as Alert } from "./alert.svg";
import { ReactComponent as ArrowUp } from "./arrow-up.svg";
import { ReactComponent as ArrowDown } from "./arrow-down.svg";
import { ReactComponent as ArrowLeft } from "./arrow-left.svg";
import { ReactComponent as ArrowRight } from "./arrow-right.svg";
import { ReactComponent as AudioFile } from "./audio-file.svg";
import { ReactComponent as ChevronLeft } from "./chevron-left.svg";
import { ReactComponent as ChevronRight } from "./chevron-right.svg";
import { ReactComponent as Clipboard } from "./clipboard.svg";
import { ReactComponent as Close } from "./close.svg";
import { ReactComponent as Download } from "./download.svg";
import { ReactComponent as Eye } from "./eye.svg";
import { ReactComponent as EyeOff } from "./eye-off.svg";
import { ReactComponent as Facebook } from "./facebook.svg";
import { ReactComponent as File } from "./file.svg";
import { ReactComponent as Filter } from "./filter.svg";
import { ReactComponent as Info } from "./info.svg";
import { ReactComponent as Instagram } from "./instagram.svg";
import { ReactComponent as LightBulb } from "./light-bulb.svg";
import { ReactComponent as LinkedIn } from "./linkedin.svg";
import { ReactComponent as Lock } from "./lock.svg";
import { ReactComponent as Minus } from "./minus.svg";
import { ReactComponent as Pencil } from "./pencil.svg";
import { ReactComponent as Plus } from "./plus.svg";
import { ReactComponent as Printer } from "./printer.svg";
import { ReactComponent as Puzzle } from "./puzzle.svg";
import { ReactComponent as QuestionMark } from "./question-mark.svg";
import { ReactComponent as Register } from "./register.svg";
import { ReactComponent as Search } from "./search.svg";
import { ReactComponent as Solution } from "./solution.svg";
import { ReactComponent as Tick } from "./tick.svg";
import { ReactComponent as Trash } from "./trash.svg";
import { ReactComponent as Twitter } from "./twitter.svg";

const svgList = {
  AddCode,
  Alert,
  ArrowUp,
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  AudioFile,
  ChevronLeft,
  ChevronRight,
  Clipboard,
  Close,
  Download,
  Eye,
  EyeOff,
  Facebook,
  File,
  Filter,
  Info,
  Instagram,
  LightBulb,
  LinkedIn,
  Lock,
  Minus,
  Pencil,
  Plus,
  Printer,
  Puzzle,
  QuestionMark,
  Register,
  Search,
  Solution,
  Tick,
  Trash,
  Twitter,
};

export type Icons = keyof typeof svgList;

export default ({ name }: { name: Icons }) => svgList[name];
