import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormattedMessage, useIntl } from "react-intl";

import { StudentRegistrationFormProps } from "./StudentRegistrationForm.types";
import { Input } from "../Input";
import { Button, ButtonVariants } from "../Button";
import { Checkbox } from "../Checkbox";
import { LinkWrapper, StyledForm } from "./StudentRegistrationForm.styles";
import { RegisterData, UserRoles } from "../../api/auth/Auth.types";
import { Box } from "../Box";
import { Paragraph, Subtitle } from "../Typography";
import { SubtitleVariant } from "../Typography/Subtitle/Subtitle.types";
import { ParagraphVariants } from "../Typography/Paragraph/Paragraph.types";
import { PasswordInput } from "../PasswordInput";
import { useStudentRegistrationValidation } from "./StudentRegistrationForm.validation";
import { useGetPoliciesAndRegulations } from "../../api/configuration/Configuration.queries";

export function StudentRegistrationForm({
  onSubmit,
  apiError,
}: StudentRegistrationFormProps) {
  const { formatMessage } = useIntl();
  const [passwordStrength, setPasswordStrength] = useState(0);
  const { data: policiesAndRegulations } = useGetPoliciesAndRegulations();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, dirtyFields, isValid },
    resetField,
    setError,
  } = useForm<{ age: number } & RegisterData>({
    mode: "onChange",
    resolver: yupResolver(
      useStudentRegistrationValidation(setPasswordStrength)
    ),
    defaultValues: {
      role: UserRoles.Student,
      parentEmail: null,
      gdprPolicy: false,
    },
  });

  const watchAge = watch("age");
  const isAgeDirty = dirtyFields?.age;
  const isAgeValid = !errors?.age;

  useEffect(() => {
    if (apiError) {
      setError("email", {
        type: "custom",
        message: apiError.response?.data.detail,
      });
    }
  }, [apiError, setError]);

  useEffect(() => {
    if (isAgeValid && isAgeDirty) {
      setValue("under_16", watchAge < 16);
      resetField("parentEmail");
    }
  }, [watchAge, isAgeValid, isAgeDirty, setValue, resetField]);

  return (
    <StyledForm noValidate onSubmit={handleSubmit(onSubmit)}>
      <Input
        {...register("firstName", { required: true })}
        label={formatMessage({
          id: "studentRegistrationForm.firstName",
          defaultMessage: "First name",
        })}
        placeholder={formatMessage({
          id: "studentRegistrationForm.firstNamePlaceholder",
          defaultMessage: "Enter your name",
        })}
        errorMessage={errors.firstName?.message}
        isRequired
      />

      <Input
        {...register("lastName", { required: true })}
        label={formatMessage({
          id: "studentRegistrationForm.lastName",
          defaultMessage: "Surname",
        })}
        placeholder={formatMessage({
          id: "studentRegistrationForm.lastNamePlaceholder",
          defaultMessage: "Enter your surname",
        })}
        errorMessage={errors.lastName?.message}
        isRequired
      />

      <Input
        {...register("email", { required: true })}
        type="email"
        label={formatMessage({
          id: "studentRegistrationForm.email",
          defaultMessage: "Email address",
        })}
        placeholder={formatMessage({
          id: "studentRegistrationForm.emailPlaceholder",
          defaultMessage: "Enter email",
        })}
        errorMessage={errors.email?.message}
        isRequired
      />

      <PasswordInput
        {...register("password", { required: true })}
        label={formatMessage({
          id: "studentRegistrationForm.password",
          defaultMessage: "Password",
        })}
        placeholder={formatMessage({
          id: "studentRegistrationForm.passwordPlaceholder",
          defaultMessage: "Enter password",
        })}
        errorMessage={errors.password?.message}
        caption={formatMessage({
          id: "passwordInput.caption",
          defaultMessage: "Minimum 8 characters (capital letter and number)",
        })}
        progress={passwordStrength}
        isRequired
      />

      <Box mt="m">
        <Subtitle variant={SubtitleVariant.First} align="left">
          <FormattedMessage
            id="studentRegistrationForm.yourAgeTitle"
            defaultMessage="How old are you?"
          />
        </Subtitle>
      </Box>

      <Box mb="s">
        <Input
          {...register("age", { required: true })}
          type="number"
          min="0"
          max="100"
          step="1"
          label={formatMessage({
            id: "studentRegistrationForm.age",
            defaultMessage: "Your age",
          })}
          placeholder={formatMessage({
            id: "studentRegistrationForm.agePlaceholder",
            defaultMessage: "Enter a number",
          })}
          errorMessage={errors.age?.message}
          isRequired
        />
      </Box>

      {isAgeDirty && isAgeValid && watchAge < 16 && (
        <Box>
          <Paragraph variant={ParagraphVariants.Small}>
            <FormattedMessage
              id="studentRegistrationForm.under16Message"
              defaultMessage="Because you&apos;re under 16, please enter your Parent/Guardian email address to confirm your registration."
            />
          </Paragraph>
          <Input
            {...register("parentEmail", { required: true })}
            type="email"
            label={formatMessage({
              id: "studentRegistrationForm.parentEmail",
              defaultMessage: "Your Parent/Guardian email address",
            })}
            errorMessage={errors.parentEmail?.message}
            isRequired
          />
        </Box>
      )}

      <Box mt="m" mb="m">
        <Checkbox
          {...register("gdprPolicy", { required: true })}
          error={errors.gdprPolicy?.message}
          isRequired
        >
          <FormattedMessage
            id="studentRegistrationForm.gdpr"
            defaultMessage="I agree to the Edco privacy policy"
          />
        </Checkbox>

        <LinkWrapper>
          <a
            href={policiesAndRegulations?.gdprPolicy}
            target="_blank"
            rel="noreferrer"
          >
            <Button variant={ButtonVariants.Tertiary}>
              <FormattedMessage
                id="studentRegistrationForm.privacyPolicyLink"
                defaultMessage="Read privacy policy"
              />
            </Button>
          </a>
        </LinkWrapper>
      </Box>

      <Box mb="xl">
        <Checkbox
          {...register("marketingPolicy")}
          error={errors.marketingPolicy?.message}
        >
          <FormattedMessage
            id="studentRegistrationForm.marketingPolicyAgree"
            defaultMessage="I agree to the Edco marketing policy"
          />
        </Checkbox>

        <LinkWrapper>
          <a
            href={policiesAndRegulations?.marketingPolicy}
            target="_blank"
            rel="noreferrer"
          >
            <Button variant={ButtonVariants.Tertiary}>
              <FormattedMessage
                id="studentRegistrationForm.privacyPolicyLink"
                defaultMessage="Read marketing policy"
              />
            </Button>
          </a>
        </LinkWrapper>
      </Box>

      <Box>
        <Button
          type="submit"
          variant={ButtonVariants.Primary}
          block
          iconRight="ArrowRight"
          disabled={!isValid}
        >
          <FormattedMessage
            id="studentRegistrationForm.submitButton"
            defaultMessage="Register"
          />
        </Button>
      </Box>
    </StyledForm>
  );
}
