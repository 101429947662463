import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { Paths } from "@utils/router";

import { Box } from "@components/Box";
import { Button, ButtonVariants } from "@components/Button";
import { Modal } from "@components/Modal";
import { ModalProps } from "@components/Modal/Modal.types";
import { Heading, Paragraph } from "@components/Typography";
import { HeadingVariant } from "@components/Typography/Heading/Heading.types";
import { ButtonsRow } from "@components/ButtonsRow";

import { IllustrationBox } from "./CancelCreation.styles";

import { ReactComponent as CancelCreationIcon } from "./CancelCreationIcon.svg";

interface CancelCreationProps extends Pick<ModalProps, "isOpen" | "onClose"> {}

export function CancelCreation({ isOpen, onClose }: CancelCreationProps) {
  const navigate = useNavigate();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Box mb="m" ml="l" mr="l">
        <Heading as={HeadingVariant.H3} align="center">
          <FormattedMessage
            id="cancelCreation.title"
            defaultMessage="Cancel creation"
          />
        </Heading>
      </Box>
      <IllustrationBox pt="m" pb="m">
        <CancelCreationIcon />
      </IllustrationBox>
      <Box>
        <Paragraph align="center">
          <FormattedMessage
            id="cancelCreation.description"
            defaultMessage="Are you sure you want to cancel creation process? All changes you made won’t be saved."
          />
        </Paragraph>
      </Box>
      <ButtonsRow>
        <Button
          variant={ButtonVariants.Primary}
          iconRight="ArrowRight"
          block
          onClick={() => navigate(Paths.MyExams)}
        >
          <FormattedMessage
            id="cancelCreation.cancelButton"
            defaultMessage="Yes, cancel"
          />
        </Button>
        <Button
          type="button"
          onClick={onClose}
          variant={ButtonVariants.Secondary}
          block
        >
          <FormattedMessage
            id="cancelCreation.stayButton"
            defaultMessage="Stay"
          />
        </Button>
      </ButtonsRow>
    </Modal>
  );
}
