import styled from "styled-components";

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const InputWrapper = styled.label`
  display: flex;
  align-items: center;

  padding-top: 1px;
  padding-right: ${({ theme }) => parseInt(theme.spacing.s, 10) + 1 + 20}px;
  padding-bottom: 1px;
  padding-left: ${({ theme }) => `calc(${theme.spacing.s} + 1px)`};

  border: 1px solid ${({ theme }) => theme.color.solid.lightGrey};
  border-radius: ${({ theme }) => theme.borderRadius.m};

  &:focus-within {
    padding-top: 0;
    padding-right: ${({ theme }) => parseInt(theme.spacing.s, 10) + 20}px;
    padding-bottom: 0;
    padding-left: ${({ theme }) => theme.spacing.s};

    border: 2px solid ${({ theme }) => theme.color.stroke.primary};
  }
`;

export const ClearButton = styled.button.attrs({
  type: "reset",
})`
  opacity: 0;
  position: absolute;
  right: ${({ theme }) => theme.spacing.s};
  top: 50%;
  border: none;
  margin: 0;
  padding: 0;
  background: none;
  pointer-events: none;

  transform: translateY(-50%);

  * {
    cursor: pointer;
  }

  > * {
    display: block;
  }
`;

export const StyledInput = styled.input`
  appearance: none;
  width: 100%;
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  outline-offset: 1px;
  outline: none;
  margin-left: ${({ theme }) => theme.spacing.xs};
  padding-top: ${({ theme }) => theme.spacing.xs};
  padding-bottom: ${({ theme }) => theme.spacing.xs};
  font-size: inherit;
  line-height: 1.5em;
  font-family: inherit;
  color: ${({ theme }) => theme.color.solid.darkGrey};

  &::placeholder {
    color: ${({ theme }) => theme.color.solid.grey};
  }

  ::-webkit-search-cancel-button {
    appearance: none;
  }

  &:not(:placeholder-shown) + ${ClearButton} {
    opacity: 1;
    pointer-events: auto;
  }
`;
