import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { Box } from "@components/Box";
import { Modal } from "@components/Modal";
import { ModalProps } from "@components/Modal/Modal.types";
import { Heading, Paragraph } from "@components/Typography";
import { HeadingVariant } from "@components/Typography/Heading/Heading.types";

import { AddPaperForm } from "./AddPaperForm";
import { AddPaperSuccess } from "./AddPaperSuccess";

interface AddPaperModalProps extends Omit<ModalProps, "children"> {}

export function AddPaperModal({ isOpen, onClose }: AddPaperModalProps) {
  const [isSuccess, setIsSuccess] = useState(false);

  const handleClose = () => {
    onClose();
    setIsSuccess(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      {isSuccess ? (
        <AddPaperSuccess onClose={handleClose} />
      ) : (
        <>
          <Box mb="m">
            <Heading as={HeadingVariant.H3} align="center">
              <FormattedMessage
                id="addPaper.title"
                defaultMessage="Add a new paper"
              />
            </Heading>
          </Box>
          <Box pt="m" pb="m">
            <Paragraph align="center">
              <FormattedMessage
                id="addPaper.description"
                defaultMessage="Enter the code from your Exam Paper booklet below."
              />
            </Paragraph>
          </Box>
          <AddPaperForm
            onSuccess={() => setIsSuccess(true)}
            onClose={handleClose}
          />
        </>
      )}
    </Modal>
  );
}
