import { FormattedMessage } from "react-intl";
import Skeleton from "react-loading-skeleton";

import {
  useGetFooter,
  useGetSocialMedia,
} from "@api/configuration/Configuration.queries";

import { Icons } from "@icons";

import { Paragraph, Subtitle } from "@components/Typography";
import { ParagraphVariants } from "@components/Typography/Paragraph/Paragraph.types";
import { Icon } from "@components/Icon";
import { SubtitleVariant } from "@components/Typography/Subtitle/Subtitle.types";

import {
  AddressColumn,
  BackgroundContainer,
  Container,
  IconLink,
  InnerContainer,
  LinkItem,
  LinkList,
  MiddleColumn,
  SocialItemsContainer,
  StyledLink,
} from "./PageFooter.styles";

function hasUrl({ url }: { url?: string }) {
  return !!url;
}

export function PageFooter() {
  const {
    data: footerData,
    isLoading: isFooterDataLoading,
    isSuccess: isFooterDataSuccess,
  } = useGetFooter();
  const {
    data: socialMediaData,
    isLoading: isSocialMediaDataLoading,
    isSuccess: isSocialMediaDataSuccess,
  } = useGetSocialMedia();

  const footerLinks = [
    {
      url: footerData?.termAndConditions,
      label: (
        <FormattedMessage
          id="footer.termsAndConditions"
          defaultMessage="Terms & Conditions"
        />
      ),
    },
    {
      url: footerData?.onlineDisputeResolutionUrl,
      label: (
        <FormattedMessage
          id="footer.onlineDisputeResolutions"
          defaultMessage="Online Dispute Resolutions"
        />
      ),
    },
    {
      url: footerData?.dataProtectionNotice,
      label: (
        <FormattedMessage
          id="footer.dataProtectionNotice"
          defaultMessage="Data Protection Notice"
        />
      ),
    },
  ].filter(hasUrl);

  const socialMediaLinks = [
    {
      url: socialMediaData?.facebookUrl,
      icon: "Facebook",
    },
    {
      url: socialMediaData?.twitterUrl,
      icon: "Twitter",
    },
    {
      url: socialMediaData?.instagramUrl,
      icon: "Instagram",
    },
    {
      url: socialMediaData?.linkedinUrl,
      icon: "LinkedIn",
    },
  ].filter(hasUrl);

  return (
    <Container>
      <BackgroundContainer>
        <InnerContainer>
          <AddressColumn>
            <Subtitle variant={SubtitleVariant.Second} as="strong">
              The Educational Company of Ireland
            </Subtitle>
            <Paragraph variant={ParagraphVariants.Small}>
              Ballymount Road, Walkinstown, D12
            </Paragraph>
            <Paragraph variant={ParagraphVariants.Small}>
              <strong>Email: </strong>
              <a href="mailto:info@examhub.ie">info@examhub.ie</a>
            </Paragraph>
            <Paragraph variant={ParagraphVariants.Small}>
              <strong>Phone: </strong>
              (01) 254 6080
            </Paragraph>
          </AddressColumn>

          <MiddleColumn>
            <img src="/logo.svg" alt="ExamHub Created by Edco" />

            {isSocialMediaDataLoading && !isSocialMediaDataSuccess && (
              <Skeleton width={170} />
            )}

            <SocialItemsContainer>
              {socialMediaLinks.map(({ url, icon }) => (
                <IconLink href={url} target="_blank" key={url} rel="noreferrer">
                  <Icon icon={icon as Icons} color="grey" />
                </IconLink>
              ))}
            </SocialItemsContainer>

            <a href="https://examhub.ie/" target="_blank" rel="noreferrer">
              examhub.ie
            </a>
          </MiddleColumn>

          <LinkList>
            {isFooterDataLoading && !isFooterDataSuccess && (
              <Skeleton width={150} count={footerLinks.length} />
            )}
            {footerLinks.map(({ url, label }) => (
              <LinkItem key={url}>
                <StyledLink href={url} target="_blank">
                  {label}
                </StyledLink>
              </LinkItem>
            ))}
          </LinkList>
        </InnerContainer>
      </BackgroundContainer>

      <Paragraph variant={ParagraphVariants.Small}>
        © {new Date().getFullYear()} Edco. All rights reserved.
      </Paragraph>
    </Container>
  );
}
