import styled from "styled-components";
import {
  StickToSideColumn,
  StickToSideInnerContainer,
} from "../../../components/HomeLayout/HomeLayout.styles";

export const InnerContainer = styled(StickToSideInnerContainer)`
  display: flex;
  flex: 1;
  margin-right: ${({ theme, stickLeft }) => (stickLeft ? 0 : theme.spacing.l)};
  margin-left: ${({ theme, stickLeft }) => (stickLeft ? theme.spacing.l : 0)};
  padding-bottom: ${({ theme }) => theme.spacing.l};
  padding-top: ${({ theme }) => theme.spacing.xl};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
  }

  @media (${({ theme }) => theme.breakpoints.desktopPlus}) {
    margin-right: ${({ theme, stickLeft }) =>
      stickLeft ? 0 : theme.spacing.xl};
    margin-left: ${({ theme, stickLeft }) =>
      stickLeft ? theme.spacing.xl : 0};
    flex-direction: ${({ stickLeft }) => (stickLeft ? "row-reverse" : "row")};
  }
`;

export const TextColumn = styled(StickToSideColumn)`
  padding-bottom: 0;
  padding-top: 0;
  padding-right: ${({ theme, stickLeft }) => (stickLeft ? theme.spacing.l : 0)};
  padding-left: ${({ theme, stickLeft }) => (stickLeft ? 0 : theme.spacing.l)};
  display: flex;
  flex: 1;

  @media (${({ theme }) => theme.breakpoints.desktopPlus}) {
    padding-right: ${({ theme, stickLeft }) =>
      stickLeft ? theme.spacing.l : theme.spacing.xl};
    padding-left: ${({ theme, stickLeft }) =>
      stickLeft ? theme.spacing.xl : theme.spacing.l};
  }
`;

export const VideoColumn = styled(StickToSideColumn)`
  justify-content: center;
  display: flex;
  flex: 2;
  padding-right: ${({ theme, stickLeft }) => (stickLeft ? theme.spacing.l : 0)};
  padding-left: ${({ theme, stickLeft }) => (stickLeft ? 0 : theme.spacing.l)};
  @media (${({ theme }) => theme.breakpoints.desktopPlus}) {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const VimeoPlayerContainer = styled.div`
  & iframe {
    border-radius: 0 50px 0 50px;
    width: 100vw;
  }
`;
