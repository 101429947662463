import { Link } from "react-router-dom";

import { Paths } from "@utils/router";

import { Wrapper } from "./Logo.styles";

export function Logo() {
  return (
    <Wrapper>
      <Link to={Paths.Home}>
        <img src="logo.svg" alt="ExamHub Created by Edco" />
      </Link>
    </Wrapper>
  );
}
