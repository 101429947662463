import ReactMarkdown from "react-markdown";
import { SpecialComponents } from "react-markdown/lib/ast-to-react";
import { NormalComponents } from "react-markdown/lib/complex-types";
import rehypeRaw from "rehype-raw";

const components:
  | Partial<Omit<NormalComponents, keyof SpecialComponents> & SpecialComponents>
  | undefined = {
  u: ({ node, ...props }) => (
    <u style={{ textDecoration: "underline" }} {...props} />
  ),
};

export function MarkdownParser({ children }: { children: string }) {
  return (
    <ReactMarkdown components={components} rehypePlugins={[rehypeRaw]}>
      {children}
    </ReactMarkdown>
  );
}
