/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from "@components/Box";
import { Button, ButtonVariants } from "@components/Button";
import { MarkdownParser } from "@components/MarkdownParser";
import { Heading, Overline, Paragraph } from "@components/Typography";
import { HeadingVariant } from "@components/Typography/Heading/Heading.types";
import { ParagraphVariants } from "@components/Typography/Paragraph/Paragraph.types";

import { ParagraphRowSectionProps } from "./ParagraphRowSection.types";
import {
  BenefitsWrapper,
  BookImage,
  CenterImage,
  Container,
  HeaderLeft,
  HeaderRight,
  HeaderWrapper,
  ImageBackgroundContainer,
  InnerContainer,
  LineImage,
  PenImage,
  StyledParagraph,
} from "./ParagraphRowSection.styles";

export function ParagraphRowSectionComponent({
  data,
}: ParagraphRowSectionProps) {
  return (
    <Container>
      <InnerContainer>
        <HeaderWrapper>
          <HeaderLeft>
            <Box mb="s">
              <Overline>{data.label}</Overline>
            </Box>
            <Heading as={HeadingVariant.H2}>{data.title}</Heading>
            <Box mt="m" mb="m">
              <Paragraph variant={ParagraphVariants.Big} as="div">
                <MarkdownParser>{data.description}</MarkdownParser>
              </Paragraph>
            </Box>
            {data.actionButtonTitle && data.actionButtonURL && (
              <a href={data.actionButtonURL}>
                <Button variant={ButtonVariants.Primary}>
                  {data.actionButtonTitle}
                </Button>
              </a>
            )}
          </HeaderLeft>

          <HeaderRight>
            <ImageBackgroundContainer>
              <LineImage src="/img/curly-line.svg" alt="Curly dashed line" />
              <PenImage src="/img/pen.svg" alt="Curly dashed line" />
              <BookImage src="/img/book.svg" alt="Opened book" />
            </ImageBackgroundContainer>
            <CenterImage
              url={data.image.data.attributes.url}
              formats={data.image.data.attributes.formats}
              width={data.image.data.attributes.width}
            />
          </HeaderRight>
        </HeaderWrapper>

        <BenefitsWrapper>
          {data.paragraphs.map((paragraph) => (
            <StyledParagraph key={paragraph.id}>
              <Heading as={HeadingVariant.H6}>{paragraph.title}</Heading>
              <Paragraph variant={ParagraphVariants.Small}>
                {paragraph.description}
              </Paragraph>
            </StyledParagraph>
          ))}
        </BenefitsWrapper>
      </InnerContainer>
    </Container>
  );
}
