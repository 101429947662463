import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useQueryClient } from "@tanstack/react-query";

import {
  useEditQuestion,
  useRemoveQuestionFromExam,
} from "@api/exam-creator/ExamCreator.queries";
import { EditQuestionData } from "@api/exam-creator/ExamCreator.types";
import { Question as QuestionType } from "@api/paper/Paper.types";

import { ButtonVariants } from "@components/Button";
import { Icon } from "@components/Icon";
import { Image } from "@components/Image";
import { Input } from "@components/Input";
import { Heading } from "@components/Typography";
import { HeadingVariant } from "@components/Typography/Heading/Heading.types";

import { DeleteConfirmation } from "./DeleteConfirmation";
import {
  Container,
  Header,
  HeadingContainer,
  ImageContainer,
  RemoveButton,
  StyledForm,
  SubmitButton,
} from "./Question.styles";

interface QuestionProps {
  question: QuestionType;
  order: number;
  maxOrderNumber: number;
  questionId: string;
  examId?: string;
}

interface QuestionOrderForm {
  order: number;
}

export function Question({
  question,
  questionId,
  order,
  maxOrderNumber,
  examId,
}: QuestionProps) {
  const queryClient = useQueryClient();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const { mutate: removeQuestion } = useRemoveQuestionFromExam(
    questionId,
    examId,
    {
      onSettled: () => {
        setShowDeleteConfirmation(false);
      },
    }
  );

  const {
    handleSubmit,
    register,
    formState: { isValid, errors },
    getValues,
    clearErrors,
    reset,
  } = useForm<QuestionOrderForm>({
    mode: "onChange",
    resolver: yupResolver(
      yup.object({
        order: yup.number().min(1).max(maxOrderNumber).required(),
      })
    ),
  });

  const { mutate: editQuestion } = useEditQuestion(questionId, {
    onSuccess: () => {
      queryClient.invalidateQueries(["exams", examId]);
      reset();
    },
  });

  const handleQuestionDelete = () => {
    setShowDeleteConfirmation(true);
  };

  const moveToInputProps = register("order", { required: true });

  const handleMoveToBlur = (e: React.FormEvent<HTMLInputElement>) => {
    moveToInputProps.onBlur(e);
    const formValues = getValues();

    if (`${formValues.order}` === "") {
      clearErrors();
    }
  };

  return (
    <>
      <Container>
        <Header>
          <HeadingContainer gap="m">
            <Icon icon="File" noBackground />
            <Heading as={HeadingVariant.H5}>
              <FormattedMessage
                id="question.title"
                defaultMessage="Question {questionNumber}"
                values={{
                  questionNumber: order,
                }}
              />
            </Heading>
          </HeadingContainer>

          <StyledForm
            onSubmit={handleSubmit(
              editQuestion as SubmitHandler<EditQuestionData>
            )}
          >
            <FormattedMessage
              id="question.moveTo"
              defaultMessage="Move to"
              tagName="span"
            />

            <Input
              {...moveToInputProps}
              type="number"
              inputMode="numeric"
              min="1"
              style={{ maxWidth: 58 }}
              isError={!!errors.order?.message}
              onBlur={handleMoveToBlur}
            />

            <FormattedMessage
              id="question.position"
              defaultMessage="position"
              tagName="span"
            />

            <SubmitButton
              type="submit"
              variant={ButtonVariants.Secondary}
              disabled={!isValid}
              autoWidth
              isSmall
            >
              <FormattedMessage
                id="question.moveButton"
                defaultMessage="Move"
              />
            </SubmitButton>
          </StyledForm>

          <RemoveButton type="button" onClick={handleQuestionDelete}>
            <Icon icon="Trash" noBackground />
          </RemoveButton>
        </Header>

        <ImageContainer>
          {question.questionFile?.map(({ id, file }) => (
            <Image key={id} src={file} />
          ))}
        </ImageContainer>
      </Container>

      <DeleteConfirmation
        isOpen={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onDelete={removeQuestion}
      />
    </>
  );
}
