import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useGetQuestionsFilters } from "../../api/paper/Paper.queries";
import {
  QuestionsFiltersFilters,
  QuestionsFiltersResponse,
} from "../../api/paper/Paper.types";
import { Filters } from "../Filters";
import { FiltersType, TopicSelectProps } from "./TopicSelect.types";

const optionsToFilterMap: Record<
  keyof QuestionsFiltersResponse,
  keyof QuestionsFiltersFilters
> = {
  examTypes: "examType",
  levels: "level",
  subjects: "subject",
  topics: "topic",
};

export function TopicSelect({
  onChange,
  defaultValues = {},
}: TopicSelectProps) {
  const { formatMessage } = useIntl();

  const [innerFiltersState, setInnerFiltersState] =
    useState<FiltersType>(defaultValues);

  const { data, isFetching } = useGetQuestionsFilters(innerFiltersState, {
    retry: false,
    onSuccess: (response) => {
      const defaultFilters = Object.fromEntries(
        Object.entries(response)
          .filter(([, value]) => value?.length === 1)
          .map(([filter, value]) => [
            optionsToFilterMap[filter as keyof QuestionsFiltersResponse],
            value[0].id,
          ])
      );

      const filters = { ...innerFiltersState, ...defaultFilters };
      setInnerFiltersState(filters);

      if (filters.topic && filters.topic !== innerFiltersState?.topic) {
        onChange(filters.topic);
      }
    },
  });

  const fields = useMemo(
    () => [
      {
        name: "examType",
        label: formatMessage({
          id: "topicSelect.exam",
          defaultMessage: "Exam",
        }),
        options: data?.examTypes?.map(({ id, shortName }) => ({
          value: id,
          label: shortName,
        })),
        required: true,
        maxWidth: 93,
      },
      {
        name: "subject",
        label: formatMessage({
          id: "topicSelect.examPaper",
          defaultMessage: "Exam paper",
        }),
        options: data?.subjects?.map(({ id, name }) => ({
          value: id,
          label: name,
        })),
        required: true,
        maxWidth: 219,
      },
      {
        name: "level",
        label: formatMessage({
          id: "topicSelect.level",
          defaultMessage: "Exam level",
        }),
        options: data?.levels?.map(({ id, name }) => ({
          value: id,
          label: name,
        })),
        required: true,
        maxWidth: 128,
      },
      {
        name: "topic",
        label: formatMessage({
          id: "topicSelect.topic",
          defaultMessage: "Topic",
        }),
        options: data?.topics?.map(({ id, name }) => ({
          value: id,
          label: name,
        })),
        required: true,
        maxWidth: 449,
      },
    ],
    [data?.examTypes, data?.levels, data?.subjects, data?.topics, formatMessage]
  );

  const handleChange = (filters: FiltersType) => {
    setInnerFiltersState(filters);

    if (filters.topic !== innerFiltersState?.topic) {
      onChange(filters.topic);
    }
  };

  return (
    <Filters
      values={innerFiltersState}
      fields={fields}
      onChange={handleChange}
      isFetching={isFetching}
      hideSubmitButton
    />
  );
}
