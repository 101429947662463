import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { cmsApi } from "../cmsApi";
import { HomeEndpoints } from "./endpoints";
import { HomeResponse } from "./Home.types";

export const getHome = async (
  params: { [key: string]: string } = {
    populate: "deep",
  }
) => {
  const { data } = await cmsApi.get(HomeEndpoints.Home, {
    params,
  });

  return data;
};

export const useGetHome = (
  params?: { [key: string]: string },
  options?: UseQueryOptions<
    HomeResponse,
    Error,
    HomeResponse,
    ["home", string?]
  >
) =>
  useQuery(["home"], () => getHome(params), {
    staleTime: 1000 * 60 * 1,
    ...options,
  });
