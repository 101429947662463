import styled, { css } from "styled-components";

import { Row } from "@components/Row";

import {
  MenuLinkProps,
  StyledMenuProps,
} from "./MobileMenu.types";

export const StyledMenu = styled.div<StyledMenuProps>`
  z-index: 5;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 100%;
  display: flex;
  flex-direction: column;
  width: calc(100% - ${({ theme }) => theme.spacing.xl});
  max-width: 548px;
  height: 100%;
  border-top-left-radius: ${({ theme }) => theme.borderRadius.m};
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.m};
  padding: ${({ theme }) => theme.spacing.l};
  background-color: ${({ theme }) => theme.color.solid.white};

  transform: translateX(0);
  transition: transform 0.25s ease-in-out;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: translateX(-100%);
    `}
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.l};
`;

export const MobileLogo = styled.img`
  max-width: 112px;
  width: 100%;
`;

export const CloseButton = styled.button`
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: none;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 5L5 15' stroke='%2388899F' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5 5L15 15' stroke='%2388899F' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
`;

export const NavWrapper = styled.nav`
  margin-top: ${({ theme }) => theme.spacing.m};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: ${({ theme }) => theme.spacing.xl};
  }

  > * {
    display: block;

    + * {
      margin-top: ${({ theme }) => theme.spacing.l};
    }
  }
`;

export const MenuLink = styled.span<MenuLinkProps>`
  font-size: 16px;
  font-weight: ${({ theme }) => theme.typography.weights.normal};
  line-height: 24px;
  color: ${({ theme }) => theme.color.solid.darkGrey};
  text-transform: capitalize;

  &:hover {
    color: ${({ theme }) => theme.color.solid.primary};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      font-weight: ${({ theme }) => theme.typography.weights.bold};
      color: ${({ theme }) => theme.color.solid.primary};
    `}
`;

export const AvatarWrapper = styled(Row)`
  margin-bottom: ${({ theme }) => theme.spacing.m};
`;

export const LinkButton = styled.button`
  border: none;
  background: transparent;
  font: inherit;
  color: ${({ theme }) => theme.color.solid.darkGrey};
  line-height: inherit;
  padding: 0;
  font-weight: ${({ theme }) => theme.typography.weights.normal};

  &:hover {
    color: ${({ theme }) => theme.color.solid.grey};
  }
`;
