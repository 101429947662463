import ReactMarkdown from "react-markdown";
import Vimeo from "@u-wave/react-vimeo";

import { HomeSectionAlign } from "@api/home/Home.types";

import {
  StickToSideContainer,
  StickToSideBackgroundContainer,
} from "@components/HomeLayout/HomeLayout.styles";
import { Box } from "@components/Box";
import { Heading, Overline, Paragraph } from "@components/Typography";
import { HeadingVariant } from "@components/Typography/Heading/Heading.types";

import { VideoSectionProps } from "./VideoSection.types";
import {
  InnerContainer,
  TextColumn,
  VideoColumn,
  VimeoPlayerContainer,
} from "./VideoSection.styles";

export function VideoSectionComponent({ data }: VideoSectionProps) {
  const stickLeft = data.align === HomeSectionAlign.left;

  return (
    <StickToSideContainer stickLeft={stickLeft}>
      <StickToSideBackgroundContainer stickLeft={stickLeft}>
        <InnerContainer stickLeft={stickLeft}>
          <TextColumn stickLeft={stickLeft}>
            <Box mb="s">
              <Overline>{data.label}</Overline>
            </Box>
            <Box mb="m">
              <Heading as={HeadingVariant.H2}>{data.title}</Heading>
            </Box>
            {data.description && (
              <Paragraph as="div">
                <ReactMarkdown>{data.description}</ReactMarkdown>
              </Paragraph>
            )}
          </TextColumn>
          <VideoColumn stickLeft={stickLeft}>
            <VimeoPlayerContainer>
              <Vimeo video={data.vimeoVideoID} responsive />
            </VimeoPlayerContainer>
          </VideoColumn>
        </InnerContainer>
      </StickToSideBackgroundContainer>
    </StickToSideContainer>
  );
}
