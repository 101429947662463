import { ImgHTMLAttributes } from "react";
import { useTheme } from "styled-components";

import {
  StrapiImageProps,
  SupportedStrapiImageFormats,
} from "./StrapiImage.types";
import { StyledImage } from "./StrapiImage.styles";

export function StrapiImage({
  className,
  alt,
  formats,
  url,
  ...props
}: StrapiImageProps & ImgHTMLAttributes<HTMLImageElement>) {
  const theme = useTheme();

  const breakpoints = {
    small: `(${theme.breakpoints.mobile})`,
    medium: `(${theme.breakpoints.tablet})`,
    large: `(${theme.breakpoints.desktop})`,
  };

  const sources = (
    Object.keys(formats || {}) as SupportedStrapiImageFormats[]
  ).reduce((acc, size) => {
    if (size in breakpoints) {
      acc.push(
        <source
          key={formats?.[size].hash}
          srcSet={formats?.[size].url}
          media={breakpoints[size]}
        />
      );
    }

    return acc;
  }, [] as React.ReactElement[]);

  return (
    <picture className={className}>
      {sources}
      <StyledImage src={url} alt={alt} {...props} />
    </picture>
  );
}
