/* eslint-disable @typescript-eslint/no-unused-vars */
import { FormattedMessage } from "react-intl";

import { Button, ButtonVariants } from "@components/Button";
import { ModalProps } from "@components/Modal/Modal.types";
import { PDFPreview } from "@components/PDFPreview";

import { StickyButtonsRow, StyledModal } from "./PreviewModal.styles";

interface PreviewModalProps extends Pick<ModalProps, "onClose"> {
  pdf: string;
  onDownload: () => void;
}

export function PreviewModal({ pdf, onClose, onDownload }: PreviewModalProps) {
  return (
    <StyledModal
      isOpen={!!pdf}
      onClose={onClose}
      fullWidth
      fullHeight
      hideCloseButton
    >
      <PDFPreview pdf={pdf} />

      <StickyButtonsRow>
        <Button variant={ButtonVariants.Primary} onClick={onDownload}>
          <FormattedMessage
            id="previewPDF.downloadButton"
            defaultMessage="Download"
          />
        </Button>
        <Button variant={ButtonVariants.Secondary} onClick={onClose}>
          <FormattedMessage
            id="previewPDF.cancelButton"
            defaultMessage="Cancel"
          />
        </Button>
      </StickyButtonsRow>
    </StyledModal>
  );
}
