import { SolidColors } from "styled-components";

export enum SubtitleVariant {
  First = "subtitle1",
  Second = "subtitle2",
}

export type TextAlignment = "left" | "center" | "right";

export type StyledSubtitleProps = {
  variant: SubtitleVariant;
  align?: TextAlignment;
  color?: SolidColors;
  bold?: boolean;
};
