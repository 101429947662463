import styled, { css, DefaultTheme } from "styled-components";

import {
  FieldCaptionSize,
  FieldCaptionVariant,
  StyledCaptionProps,
} from "./FieldCaption.types";

const captionVariantStyle = (
  theme: DefaultTheme,
  variant: FieldCaptionVariant
) =>
  ({
    [FieldCaptionVariant.Primary]: css`
      color: ${theme.color.solid.primary};

      svg {
        stroke: ${theme.color.solid.primary};
      }
    `,
    [FieldCaptionVariant.Question]: css`
      color: ${theme.color.solid.primary};

      svg {
        stroke: ${theme.color.solid.primary};
      }
    `,
    [FieldCaptionVariant.Disabled]: css`
      color: ${theme.color.solid.lightGrey};

      svg {
        stroke: ${theme.color.solid.lightGrey};
      }
    `,
    [FieldCaptionVariant.Error]: css`
      color: ${theme.color.informative.alert};

      svg {
        stroke: ${theme.color.informative.alert};
      }
    `,
    [FieldCaptionVariant.Valid]: css`
      color: ${theme.color.informative.accept};

      svg {
        stroke: ${theme.color.informative.accept};
      }
    `,
  }[variant]);

const captionSizeStyle = (theme: DefaultTheme, size: FieldCaptionSize) =>
  ({
    [FieldCaptionSize.Small]: css`
      font-size: 12px;
    `,
    [FieldCaptionSize.Big]: css`
      font-size: 14px;
    `,
  }[size]);

export const StyledCaption = styled.div<StyledCaptionProps>`
  font-size: 12px;
  letter-spacing: 0.4px;

  margin-top: ${({ theme }) => theme.spacing.xs};

  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.color.solid.darkGrey};

  svg {
    stroke: ${({ theme }) => theme.color.solid.darkGrey};
    flex-shrink: 0;
  }

  ${({ theme, variant }) => variant && captionVariantStyle(theme, variant)}
  ${({ theme, size }) => size && captionSizeStyle(theme, size)}
`;

export const CaptionTextWrapper = styled.span`
  letter-spacing: 1px;
  margin-left: ${({ theme }) => theme.spacing.xs};
  text-align: left;
`;
