import { SolidColors } from "styled-components";

export enum HeadingVariant {
  H1 = "h1",
  H2 = "h2",
  H3 = "h3",
  H4 = "h4",
  H5 = "h5",
  H6 = "h6",
}

export type TextAlignment = "left" | "center" | "right";

export type StyledHeadingProps = {
  as: HeadingVariant;
  align?: TextAlignment;
  color?: SolidColors;
};
