import styled, { DefaultTheme, SolidColors } from "styled-components";
import { Link } from "react-router-dom";

import { Box } from "@components/Box";

function twoLinesClamp({ theme }: { theme: DefaultTheme }) {
  return `
    @media (${theme.breakpoints.mobile}) {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `;
}

export const ListContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.color.stroke.lightGrey};
  border-radius: 8px;
`;

export const ResultRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  min-width: 100%;
  padding: ${({ theme }) => theme.spacing.s};

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.stroke.grey};
  }

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    flex-wrap: nowrap;
    padding: ${({ theme }) => theme.spacing.m};
  }
`;

export const ColumnWithDivider = styled.div`
  flex: 0 0 50%;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    position: relative;
    display: flex;
    align-items: center;
    flex: 0 0 auto;

    &:before {
      content: "";
      display: block;
      width: 1px;
      height: 40px;
      margin: 0 ${({ theme }) => theme.spacing.s};
      background: ${({ theme }) => theme.color.stroke.lightGrey};
    }
  }

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    flex: 0 0 auto;

    &:before {
      margin: 0 ${({ theme }) => theme.spacing.m};
    }
  }
`;

export const IconWrapper = styled.div`
  display: none;
  margin-right: ${({ theme }) => theme.spacing.xs};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    display: inline-flex;
  }
`;

export const TitleColumn = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 1;
  padding-bottom: ${({ theme }) => theme.spacing.m};

  ${IconWrapper} {
    display: inline-flex;
  }

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    padding-bottom: 0;
  }
`;

export const TopicName = styled(Box)`
  ${twoLinesClamp}
`;

export const StyledLink = styled(Link)<{ $color: SolidColors }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 14px;
  color: ${({ theme, $color }) => theme.color.solid[$color]};

  @media (${({ theme }) => theme.breakpoints.tabletOnly}) {
    ${IconWrapper} {
      display: flex;
      justify-content: center;
      flex: 0 0 100%;
      margin-right: 0;
      margin-bottom: ${({ theme }) => theme.spacing.xs};
    }
  }
`;

export const ResultTitle = styled(StyledLink)`
  justify-content: flex-start;

  ${twoLinesClamp}
`;
