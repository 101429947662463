import styled from "styled-components";

import { Button } from "@components/Button";
import { Row } from "@components/Row";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing.m};
  margin-bottom: ${({ theme }) => theme.spacing.m};
`;

export const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.spacing.s};
  margin-bottom: ${({ theme }) => theme.spacing.m};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    flex-wrap: nowrap;
  }
`;

export const HeadingContainer = styled(Row)`
  margin-left: 0;
  margin-right: auto;
`;

export const StyledForm = styled.form`
  order: 3;
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 14px;
  margin-top: ${({ theme }) => theme.spacing.s};

  > * + * {
    margin-left: ${({ theme }) => theme.spacing.s};
  }

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    order: unset;
    width: auto;
    margin-top: 0;

    &:after {
      content: "";
      width: 1px;
      height: 48px;
      margin: 0 ${({ theme }) => theme.spacing.mt};
      background-color: ${({ theme }) => theme.color.solid.lightGrey};
    }
  }
`;

export const SubmitButton = styled(Button)`
  margin-right: 0;
  margin-left: auto;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    margin-left: ${({ theme }) => theme.spacing.s};
  }
`;

export const RemoveButton = styled.button`
  border: none;
  padding: 0;
  background: none;

  & * {
    cursor: pointer;
  }

  svg {
    display: block;
    stroke: ${({ theme }) => theme.color.solid.grey};
  }

  &:hover,
  &:focus-visible {
    svg {
      stroke: ${({ theme }) => theme.color.solid.hover};
    }
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  padding: ${({ theme }) => theme.spacing.l};
  border: 2px solid ${({ theme }) => theme.color.stroke.lightGrey};
  border-radius: ${({ theme }) => theme.borderRadius.m};
`;
