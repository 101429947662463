import styled from "styled-components";

import { Box } from "@components/Box";

export const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.ml};
`;

export const IllustrationBox = styled(Box)`
  display: flex;
  justify-content: center;
`;
