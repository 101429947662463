import styled from "styled-components";

import { Heading, Paragraph } from "@components/Typography";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing.mt};
  padding-right: ${({ theme }) => theme.spacing.m};
  padding-left: ${({ theme }) => theme.spacing.m};
  max-width: ${({ theme }) => theme.containerWidth};
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    padding-top: 76px;
  }

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    flex-direction: row;
    margin-right: auto;
    margin-left: auto;
  }
`;

export const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 411px;
  max-width: 100%;

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    align-items: flex-start;
    width: auto;
  }

  > * + * {
    margin-top: ${({ theme }) => theme.spacing.m};
  }
`;

export const ErrorHeading = styled(Heading)`
  white-space: pre-line;
  text-align: center;

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    text-align: left;
  }
`;

export const ErrorMessage = styled(Paragraph)`
  text-align: center;

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    text-align: left;
  }
`;

export const ImageColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 250px;
  margin-top: ${({ theme }) => theme.spacing.ml};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    width: 328px;
  }

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    width: 100%;
    margin-top: 0;
    margin-left: ${({ theme }) => theme.spacing.xxl};
  }
`;
