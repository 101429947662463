import styled from "styled-components";

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  max-height: calc(100vh - 296px);
`;

export const GoBackLink = styled.span`
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.color.solid.darkGrey};

  > *:first-child {
    margin-right: ${({ theme }) => theme.spacing.s};
  }
`;

export const DescriptionContainer = styled.div`
  display: none;
  margin-top: ${({ theme }) => theme.spacing.m};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    display: block;
  }
`;
