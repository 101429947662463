import { useUser } from "../../utils/user/user-context";
import { StyledAvatar } from "./Avatar.styles";
import { AvatarProps } from "./Avatar.types";

const getFirstLetter = (word?: string) => word?.slice(0, 1);

export function Avatar({ size = 40 }: AvatarProps) {
  const { currentUser } = useUser();

  const initials = `${getFirstLetter(currentUser?.firstName)}${getFirstLetter(
    currentUser?.lastName
  )}`;

  return <StyledAvatar size={size}>{initials}</StyledAvatar>;
}
