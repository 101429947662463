import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { Paths } from "@utils/router";

import { Box } from "@components/Box";
import { Button, ButtonVariants } from "@components/Button";
import { Modal } from "@components/Modal";
import { ModalProps } from "@components/Modal/Modal.types";
import { Heading, Paragraph } from "@components/Typography";
import { HeadingVariant } from "@components/Typography/Heading/Heading.types";
import { ButtonsRow } from "@components/ButtonsRow";

import { IllustrationBox } from "./CancelCreationModal.styles";

interface CancelCreationModalProps
  extends Pick<ModalProps, "isOpen" | "onClose"> {}

export function CancelCreationModal({
  isOpen,
  onClose,
}: CancelCreationModalProps) {
  const navigate = useNavigate();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Box mb="l" ml="l" mr="l">
        <Heading as={HeadingVariant.H3} align="center">
          <FormattedMessage
            id="cancelCreationModal.title"
            defaultMessage="Cancel creation"
          />
        </Heading>
      </Box>
      <IllustrationBox>
        <img src="/img/cancel-creation.svg" alt="Cancel creation" />
      </IllustrationBox>
      <Box mt="m">
        <Paragraph align="center">
          <FormattedMessage
            id="cancelCreationModal.description"
            defaultMessage="Are you sure you want to cancel creation process? All changes you made won’t be saved."
          />
        </Paragraph>
      </Box>
      <ButtonsRow>
        <Button
          variant={ButtonVariants.Primary}
          onClick={() => navigate(Paths.MyExams)}
          block
        >
          <FormattedMessage
            id="cancelCreationModal.cancelButton"
            defaultMessage="Yes, cancel"
          />
        </Button>
        <Button variant={ButtonVariants.Secondary} onClick={onClose} block>
          <FormattedMessage
            id="cancelCreationModal.stayButton"
            defaultMessage="Stay"
          />
        </Button>
      </ButtonsRow>
    </Modal>
  );
}
