import { FeatureBox } from "../../../../components/FeatureBox";
import { StyledList } from "./FeatureBoxesList.styles";
import { FeatureBoxesListProps } from "./FeatureBoxesList.types";

export function FeatureBoxesList({ features }: FeatureBoxesListProps) {
  return (
    <StyledList>
      {features.map(({ icon, title, description, id }) => (
        <FeatureBox
          key={id}
          icon={icon.data.attributes}
          title={title}
          description={description}
        />
      ))}
    </StyledList>
  );
}
