import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from "pure-react-carousel";
import { FormattedMessage } from "react-intl";
import "pure-react-carousel/dist/react-carousel.es.css";

import { useGetUserSubjects } from "@api/paper/Paper.queries";
import {
  useGetOtherPapersUrl,
  useGetWhereToBuyUrl,
} from "@api/configuration/Configuration.queries";
import { useBreakpoints } from "@utils/use-breakpoints";
import { Box } from "@components/Box";
import { ButtonAnchor, ButtonVariants } from "@components/Button";
import { Heading } from "@components/Typography";
import { HeadingVariant } from "@components/Typography/Heading/Heading.types";
import { Icon } from "@components/Icon";
import { Row } from "@components/Row";

import { MobileGrid } from "./MobileGrid";
import {
  ButtonsDivider,
  Header,
  PaginationButtons,
  SlidePaperBox,
  SliderWrapper,
} from "./OtherPapers.styles";

export function OtherPapers() {
  const { isMobile, isTablet, isDesktop } = useBreakpoints();
  const { data } = useGetUserSubjects("inactive");
  const { data: otherPapersUrl } = useGetOtherPapersUrl();
  const { data: whereToBuyUrl } = useGetWhereToBuyUrl();

  const visibleSlides = (isDesktop && 4) || (isTablet && 3) || undefined;

  return (
    <CarouselProvider
      visibleSlides={visibleSlides}
      naturalSlideWidth={302}
      naturalSlideHeight={299}
      totalSlides={data?.length || 4}
      isIntrinsicHeight
    >
      <Box mt="m">
        <Header>
          <Row gap="m">
            <Heading as={HeadingVariant.H4}>
              <FormattedMessage
                id="otherPapers.title"
                defaultMessage="Other papers"
              />
            </Heading>

            <ButtonAnchor
              href={whereToBuyUrl?.whereToBuyUrl}
              target="_blank"
              rel="noreferrer"
              isSmall
            >
              <FormattedMessage
                id="otherPapers.buyPaperButton"
                defaultMessage="Buy a paper"
              />
            </ButtonAnchor>
          </Row>

          {isTablet && (
            <PaginationButtons>
              <ButtonBack>
                <Icon icon="ChevronLeft" noBackground color="grey" />
              </ButtonBack>

              <ButtonsDivider />

              <ButtonNext>
                <Icon icon="ChevronRight" noBackground color="grey" />
              </ButtonNext>
            </PaginationButtons>
          )}
        </Header>

        {isMobile && (
          <MobileGrid
            papers={data}
            otherPapersUrl={otherPapersUrl?.otherPapersUrl}
          />
        )}

        {isTablet && (
          <SliderWrapper>
            <Slider>
              {data?.map(({ id, cover, subject, level }, index) => (
                <Slide key={`${id}-${level.id}`} index={index}>
                  <SlidePaperBox
                    paper={subject.name}
                    type={subject.examType.shortName}
                    level={level.name}
                    coverImage={cover}
                    isActive={false}
                  >
                    <ButtonAnchor
                      href={otherPapersUrl?.otherPapersUrl}
                      target="_blank"
                      rel="noreferrer"
                      variant={ButtonVariants.SecondaryWhite}
                    >
                      <FormattedMessage
                        id="otherPapers.moreInformationButton"
                        defaultMessage="More information"
                      />
                    </ButtonAnchor>
                  </SlidePaperBox>
                </Slide>
              ))}
            </Slider>
          </SliderWrapper>
        )}
      </Box>
    </CarouselProvider>
  );
}
