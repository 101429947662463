import { Dispatch, useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";

import { useGetSubjectExams } from "@api/exam-creator/ExamCreator.queries";
import { ExamCreatorParams } from "@api/exam-creator/ExamCreator.types";
import { SortOrder } from "@utils/types";
import { useBreakpoints } from "@utils/use-breakpoints";

import { FiltersPanel } from "@components/FiltersPanel";
import { Filters } from "@components/Filters";
import { Field } from "@components/Filters/Filters.types";
import { ExamsFiltersWrapper } from "./ExamsFilters.styles";

export const SelectBox = styled.div`
  max-width: 412px;
`;

const sortByOptions = [
  ['title', 'Name'],
  ['topic__subject_level__subject__name', 'Exam paper'],
  ['topic__name', 'Topic'],
].map(([field, name]) => [
  {
    label: (<>{name} &uarr;</>),
    value: `${SortOrder.Ascending}${field}`,
  },
  {
    label: (<>{name} &darr;</>),
    value: `${SortOrder.Descending}${field}`,
  },
]).flat()

interface ExamsFiltersProps {
  values: Pick<ExamCreatorParams, 'ordering' | 'sortOrder' | 'subject'>;
  onChange: Dispatch<React.SetStateAction<ExamCreatorParams>>;
}

export function ExamsFilters({
  values,
  onChange,
}: ExamsFiltersProps) {
  const { formatMessage } = useIntl();
  const { isDesktop } = useBreakpoints();
  const {
    data: subjects = [],
    isFetching: isSubjectsFetching,
  } = useGetSubjectExams();

  const [innerValues, setInnerValues] = useState({});

  useEffect(() => {
    setInnerValues({
      subject: values.subject,
      sortBy: `${values.sortOrder}${values.ordering}`,
    });
  }, [values]);

  const subjectOptions = useMemo(
    () =>
      [
        {
          label: formatMessage({
            id: "examsTable.allOption",
            defaultMessage: "All",
          }),
          value: "",
        },
        subjects.map(({ name, id }) => ({
          label: name,
          value: id,
        })),
      ].flat(),
    [formatMessage, subjects]
  );

  const fields = useMemo(() =>
    [
      {
        name: "subject",
        label: formatMessage({
          id: "examsTable.subjectSelectLabel",
          defaultMessage: "Exam paper",
        }),
        options: subjectOptions,
        required: false,
        maxWidth: 412,
      },
      !isDesktop && {
        name: "sortBy",
        label: formatMessage({
          id: "examsTable.sortBy",
          defaultMessage: "Sort by",
        }),
        options: sortByOptions,
        required: false,
        maxWidth: 277,
      }
    ].filter(Boolean) as Field[],
    [subjectOptions, isDesktop, formatMessage]
  );

  const handleChange = useCallback(({ subject, sortBy }: any) => {
    const [_, sortOrder, ordering] = sortBy?.match(/(-?)(.+)/) || [];

    onChange(currentValues => ({
      ...currentValues,
      page: 1,
      subject: subject || '',
      ordering: ordering || currentValues.ordering,
      sortOrder: sortOrder ?? currentValues.sortOrder,
    }));
  }, [onChange]);

  return (
    <FiltersPanel forcePanel={!isDesktop}>
      <ExamsFiltersWrapper>
        <Filters
          fields={fields}
          values={innerValues}
          isFetching={isSubjectsFetching}
          onChange={isDesktop ? handleChange : undefined}
          onSubmit={isDesktop ? undefined : handleChange}
          hideSubmitButton={isDesktop}
          noDependentFields
        />
      </ExamsFiltersWrapper>
    </FiltersPanel>
  );
}
