import styled from "styled-components";

export const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: -24px;
`;

export const DescriptionContainer = styled.div`
  display: none;
  margin-top: ${({ theme }) => theme.spacing.m};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    display: block;
  }
`;

export const TitleColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  > * + * {
    margin-top: ${({ theme }) => theme.spacing.s};
  }

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row;
    align-items: center;

    > * + * {
      margin-top: 0;
      margin-left: ${({ theme }) => theme.spacing.m};
    }
  }
`;
