import { FormattedMessage } from "react-intl";

import { Paths } from "@utils/router";
import { useBreakpoints } from "@utils/use-breakpoints";

import { Pagination } from "@components/Pagination";
import { Icon } from "@components/Icon";
import { Paragraph } from "@components/Typography";
import { ParagraphVariants } from "@components/Typography/Paragraph/Paragraph.types";

import { SearchResultsListProps } from "./SearchResults.types";
import {
  ColumnWithDivider,
  IconWrapper,
  ListContainer,
  ResultRow,
  ResultTitle,
  StyledLink,
  TitleColumn,
  TopicName,
} from "./SearchResultsList.styles";

export function SearchResultsList({
  searchResults,
  handlePaginationChange,
}: SearchResultsListProps) {
  const { isMobile } = useBreakpoints();

  return (
    <>
      <ListContainer>
        {searchResults?.data.map(
          ({
            id,
            number,
            paper,
            section,
            year,
            solution,
            markingSchema,
            topic: { name: topicName, subjectLevel },
          }) => (
            <ResultRow key={id}>
              <TitleColumn>
                <IconWrapper>
                  <Icon icon="QuestionMark" width={20} height={20} />
                </IconWrapper>

                <div>
                  <ResultTitle
                    to={`${Paths.Questions}?${new URLSearchParams({
                      questionId: id,
                    })}`}
                    $color="primary"
                  >
                    <FormattedMessage
                      id="searchResultsList.questionTitle"
                      defaultMessage="{subjectLevelShortName} {subjectLevelName} {subjectLevelLevelName} Level Question {number}, Paper {paper}, Section {section}, {year}"
                      values={{
                        number,
                        paper,
                        section,
                        year,
                        subjectLevelShortName: subjectLevel.shortName,
                        subjectLevelName: subjectLevel.name,
                        subjectLevelLevelName: subjectLevel.levelName,
                      }}
                    />
                  </ResultTitle>

                  <TopicName mt="xxs">
                    <Paragraph variant={ParagraphVariants.Small}>
                      <FormattedMessage
                        id="searchResultsList.topic"
                        defaultMessage="Topic: {topic}"
                        values={{ topic: topicName }}
                      />
                    </Paragraph>
                  </TopicName>
                </div>
              </TitleColumn>

              {solution ? (
                <ColumnWithDivider>
                  <StyledLink
                    to={`${Paths.Solutions}?${new URLSearchParams({
                      solutionId: solution ? solution.id : "",
                    })}`}
                    $color="orange"
                  >
                    <IconWrapper>
                      <Icon
                        icon="LightBulb"
                        color="orange"
                        width={24}
                        height={24}
                      />
                    </IconWrapper>

                    <FormattedMessage
                      id="searchResultsList.solutionLink"
                      defaultMessage="See Solution"
                    />
                  </StyledLink>
                </ColumnWithDivider>
              ) : null}

              {markingSchema ? (
                <ColumnWithDivider>
                  <StyledLink
                    to={`${Paths.Solutions}?${new URLSearchParams({
                      markingSchemaId: solution ? solution.id : "",
                    })}`}
                    $color="purple"
                  >
                    <IconWrapper>
                      <Icon
                        icon="Clipboard"
                        color="purple"
                        width={24}
                        height={24}
                      />
                    </IconWrapper>

                    {isMobile ? (
                      <FormattedMessage
                        id="searchResultsList.markingSchemeMobileLink"
                        defaultMessage="Marking Scheme"
                      />
                    ) : (
                      <FormattedMessage
                        id="searchResultsList.markingSchemeDesktopLink"
                        defaultMessage="See Marking Scheme"
                      />
                    )}
                  </StyledLink>
                </ColumnWithDivider>
              ) : null}
            </ResultRow>
          )
        )}
      </ListContainer>

      {searchResults ? (
        <Pagination
          perPage={searchResults?.perPage}
          page={searchResults?.page}
          total={searchResults?.total}
          onChange={handlePaginationChange}
        />
      ) : null}
    </>
  );
}
