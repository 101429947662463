import {
  useMemo,
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { useGetCurrentUser } from "../../api/auth/Auth.queries";
import AuthService from "../../api/auth/Auth.service";
import { UserData, UserRoles } from "../../api/auth/Auth.types";
import { Loader } from "../../components/Loader";
import { LoaderPositions } from "../../components/Loader/Loader.types";

type UserContextType = {
  currentUser?: UserData;
  isTeacher: boolean;
  isStudent: boolean;
};

interface UserProviderProps {
  children?: ReactElement | ReactElement[];
}

export const UserContext = createContext<UserContextType>(
  {} as UserContextType
);

function UserProvider({ children }: UserProviderProps) {
  const { pathname } = useLocation();
  const [currentUser, setCurrentUser] = useState<UserData | undefined>(
    undefined
  );
  const isLoggedIn = AuthService.isLoggedIn();

  const { refetch, isFetching } = useGetCurrentUser({
    enabled: false,
    onSuccess: (data) => {
      setCurrentUser(data);
    },
    onError: AuthService.logout,
  });

  useEffect(() => {
    if (!currentUser && isLoggedIn) {
      refetch();
    }
  }, [pathname, isLoggedIn, refetch, currentUser]);

  const providerValues = useMemo(
    () => ({
      currentUser,
      isTeacher: currentUser?.role === UserRoles.Teacher,
      isStudent: currentUser?.role === UserRoles.Student,
    }),
    [currentUser]
  );

  if (isFetching || (isLoggedIn && !currentUser)) {
    return <Loader position={LoaderPositions.Fixed} />;
  }

  return (
    <UserContext.Provider value={providerValues}>
      {children}
    </UserContext.Provider>
  );
}

const useUser = () => useContext(UserContext);

export { UserProvider, useUser };
