import styled from "styled-components";
import { Button } from "../../../components/Button";
import { StrapiImage } from "../../../components/StrapiImage";

export const Container = styled.div`
  display: flex;
  padding-right: ${({ theme }) => theme.spacing.m};
  padding-left: ${({ theme }) => theme.spacing.m};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    padding-right: ${({ theme }) => theme.spacing.l};
    padding-left: ${({ theme }) => theme.spacing.l};
  }

  @media (${({ theme }) => theme.breakpoints.desktopPlus}) {
    padding-left: ${({ theme }) => theme.spacing.xl};
    padding-right: ${({ theme }) => theme.spacing.xl};
  }
`;

export const ColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  flex-grow: 1;
  padding-right: ${({ theme }) => theme.spacing.m};
  padding-left: ${({ theme }) => theme.spacing.m};
  align-items: center;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    padding-right: ${({ theme }) => theme.spacing.l};
    padding-left: ${({ theme }) => theme.spacing.l};
    align-items: flex-start;
  }

  @media (${({ theme }) => theme.breakpoints.desktopPlus}) {
    padding-left: 109px;
    padding-right: 0;
  }
`;

export const HeroHeading = styled.h1`
  margin: 0;
  font-weight: 600;
  font-size: 34px;
  line-height: 1.29;

  @media (${({ theme }) => theme.breakpoints.desktopPlus}) {
    font-size: 48px;
    line-height: 1.5;
  }
`;

export const ColumnRight = styled.div`
  z-index: 2;
  display: none;
  flex-direction: column;
  width: 50%;
  padding-right: 0;
  text-align: right;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    display: block;
    width: 42.5%;
  }

  @media (${({ theme }) => theme.breakpoints.desktopPlus}) {
    width: 50%;
  }
`;

export const HeroContainer = styled.div`
  position: relative;
  overflow: hidden;
  background-color: ${({ theme }) => theme.color.background.light};
  display: flex;
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.l};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius.l};
  padding-bottom: ${({ theme }) => theme.spacing.l};
`;

export const HeroImage = styled(StrapiImage)`
  & > img {
    width: 100%;

    @media (${({ theme }) => theme.breakpoints.tablet}) {
      width: auto;
      max-width: 100%;
    }
  }
`;

export const HeroImageMobileContainer = styled.div`
  position: relative;
  right: ${({ theme }) => `-${theme.spacing.m}`};
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-top: ${({ theme }) => theme.spacing.l};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }
`;

export const DevicesImage = styled.img`
  max-width: 100%;
  z-index: 1;
  position: relative;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    width: 294px;
  }

  @media (${({ theme }) => theme.breakpoints.desktopPlus}) {
    width: 404px;
  }
`;

export const DevicesImageContainer = styled.div`
  max-height: 67px;
  margin-top: ${({ theme }) => theme.spacing.m};
  margin-bottom: ${({ theme }) => theme.spacing.l};
`;

export const ScrollDownButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: ${({ theme }) => theme.spacing.m};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: unset;
  }
`;

export const ScrollDownButton = styled(Button)`
  flex-direction: row-reverse;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.solid.grey};
  font-size: 14px;
  padding-left: 0;

  > svg {
    margin-left: 0;
    margin-right: ${({ theme }) => theme.spacing.xs};
  }
`;

export const PaperPlaneImage = styled.img`
  display: none;
  position: absolute;
  z-index: 1;
  width: 60%;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    top: 30px;
    right: 0;
    display: block;
  }

  @media (${({ theme }) => theme.breakpoints.desktopPlus}) {
    top: 120px;
  }
`;
