import { ChangeEvent, forwardRef, useMemo, useState } from "react";
import debounce from "lodash.debounce";
import { useIntl } from "react-intl";

import { useSearchPapers } from "@api/paper/Paper.queries";

import { Icon } from "@components/Icon";

import { SuggestionsDropdown } from "./SuggestionsDropdown";
import {
  InputContainer,
  InputWrapper,
  StyledInput,
  ClearButton,
} from "./SearchInput.styles";

interface SearchInputProps {
  onClose: () => void;
}

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  ({ onClose, ...props }, ref) => {
    const { formatMessage } = useIntl();
    const [query, setQuery] = useState("");

    const { data } = useSearchPapers(
      { perPage: 4, query },
      {
        enabled: !!query,
      }
    );

    const handleInputChange = useMemo(
      () =>
        debounce((event: ChangeEvent<HTMLInputElement>) => {
          setQuery(event.target.value);
        }, 250),
      [setQuery]
    );

    const handleClearClick = () => {
      setQuery("");

      onClose();
    };

    return (
      <InputContainer>
        <InputWrapper>
          <Icon icon="Search" color="grey" width={20} height={20} />

          <StyledInput
            type="search"
            ref={ref}
            onChange={handleInputChange}
            placeholder={formatMessage({
              id: "searchInput.placeholder",
              defaultMessage: "Search",
            })}
            autoCapitalize="off"
            {...props}
          />

          <ClearButton
            onClick={handleClearClick}
            aria-label="Clear query and close search"
          >
            <Icon icon="Close" color="darkGrey" width={20} height={20} />
          </ClearButton>
        </InputWrapper>

        <SuggestionsDropdown
          isOpen={!!query}
          suggestions={data}
          query={query}
        />
      </InputContainer>
    );
  }
);
