import styled from "styled-components";

export const StyledEyeButton = styled.button`
  background: transparent;
  border: none;

  cursor: pointer;

  svg {
    stroke: ${({ theme }) => theme.color.solid.grey};
  }
`;
