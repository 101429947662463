import { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { StringParam, useQueryParams } from "use-query-params";

import {
  useGetSolution,
  useGetSolutions,
} from "@api/paper/Paper.queries";
import {
  SolutionsFilters as SolutionsFiltersType,
  SolutionsFiltersFilters,
} from "@api/paper/Paper.types";
import { useBreakpoints } from "@utils/use-breakpoints";

import { DashboardLayout } from "@components/DashboardLayout";
import { Pagination } from "@components/Pagination";
import { EmptyListBox } from "@components/EmptyListBox";
import { SolutionsFilters } from "@components/SolutionsFilters";
import { FiltersType } from "@components/SolutionsFilters/SolutionsFilters.types";
import { SolutionsList } from "@components/SolutionsList";
import { SolutionTabs } from "@components/SolutionsList/Solution";

import { Container, FiltersContainer, FiltersInnerContainer } from "./Solutions.styles";

export function Solutions() {
  const { isDesktop } = useBreakpoints();
  const { formatMessage } = useIntl();

  const [{ solutionId, markingSchemaId, ...query }] = useQueryParams({
    markingSchemaId: StringParam,
    solutionId: StringParam,
    examType: StringParam,
    subject: StringParam,
    level: StringParam,
    topic: StringParam,
  });

  const defaultFilters = Object.fromEntries(
    Object.entries(query).filter(([_, value]) => value)
  );

  const [filters, setFilters] = useState<FiltersType>(
    defaultFilters as FiltersType
  );

  const [pagination, setPagination] = useState<
    Partial<Pick<SolutionsFiltersType, "page" | "perPage">>
  >({
    perPage: isDesktop ? 10 : 5,
  });

  const { data: singleSolution } = useGetSolution(
    markingSchemaId || solutionId,
    {
      enabled: !!markingSchemaId || !!solutionId,
    }
  );

  const { data: solutions, isFetching } = useGetSolutions(
    { ...filters, ...pagination },
    { enabled: !!filters, retry: false }
  );

  const handlePaginationChange = (
    newPagination: Partial<SolutionsFiltersType>
  ) => setPagination({ ...pagination, ...newPagination });

  const handleFiltersChange = (
    newFilters: Partial<SolutionsFiltersFilters>
  ) => {
    setFilters(newFilters);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pagination]);

  const solutionsList = useMemo(() => {
    if (singleSolution) {
      return {
        total: 1,
        data: [singleSolution],
      };
    }

    return solutions;
  }, [solutions, singleSolution]);

  return (
    <DashboardLayout
      title={formatMessage({
        id: "solutions.title",
        defaultMessage: "Solutions",
      })}
      description={formatMessage({
        id: "solutions.description",
        defaultMessage:
          "Here you can find the solutions and marking schemes for the questions.",
      })}
      backgroundImage="/img/solutions-background.svg"
    >
      <>
        <FiltersContainer>
          <FiltersInnerContainer>
            <SolutionsFilters
              onSubmit={handleFiltersChange}
              defaultValues={defaultFilters as FiltersType}
            />
          </FiltersInnerContainer>
        </FiltersContainer>

        <Container>
          <SolutionsList
            solutions={solutionsList?.data}
            defaultTab={
              markingSchemaId
                ? SolutionTabs.MarkingScheme
                : SolutionTabs.Solution
            }
          />

          {!solutionsList?.total && !isFetching && (
            <EmptyListBox
              variant="orange"
              subtitle={
                <FormattedMessage
                  id="solutions.emptyListSubtitle"
                  defaultMessage="Please use the filters above to search for Solutions."
                />
              }
            />
          )}

          {!!solutionsList?.total && (
            <Pagination
              total={solutionsList?.total}
              page={pagination?.page}
              perPage={pagination?.perPage}
              onChange={handlePaginationChange}
            />
          )}
        </Container>
      </>
    </DashboardLayout>
  );
}
