import { forwardRef, useState } from "react";
import VisuallyHidden from "@reach/visually-hidden";
import { FormattedMessage } from "react-intl";

import { Input } from "../Input";
import { StyledEyeButton } from "./PasswordInput.styles";
import { Icon } from "../Icon";
import { InputProps } from "../Input/Input.types";

export const PasswordInput = forwardRef<
  HTMLInputElement,
  Omit<InputProps, "type">
>(({ errorMessage, caption, progress, ...props }, ref) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Input
      type={showPassword ? "text" : "password"}
      caption={caption}
      progress={progress}
      ref={ref}
      errorMessage={errorMessage}
      iconRight={
        <StyledEyeButton
          type="button"
          onClick={() => setShowPassword(!showPassword)}
        >
          <Icon
            aria-hidden
            icon={showPassword ? "Eye" : "EyeOff"}
            color="orange"
            noBackground
          />
          <VisuallyHidden>
            <FormattedMessage
              id="showPasswordButton"
              defaultMessage="Show password"
            />
          </VisuallyHidden>
        </StyledEyeButton>
      }
      {...props}
    />
  );
});
