import styled from "styled-components";

export const SearchButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  border: 0;
  background: none;

  & svg {
    transition: stroke 0.25s ease-in-out;
    stroke: ${({ theme }) => theme.color.solid.grey};
  }

  &:hover {
    & svg {
      stroke: ${({ theme }) => theme.color.solid.primary};
    }
  }

  & * {
    cursor: pointer;
  }
`;

export const SearchInputWrapper = styled.form`
  position: absolute;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: ${({ theme }) => theme.color.solid.white};
`;

export const SearchInputInnerWrapper = styled.div`
  max-width: 878px;
`;
