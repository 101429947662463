import { Exam } from "@api/exam-creator/ExamCreator.types";
import { PaginatedList } from "@utils/types";

import type { PaginationProps } from "@components/Pagination/Pagination.types";
import type { ExamsTableSortProps } from "./ExamsTable/ExamsTable.types";

export enum ExamsListMode {
  Table = "table",
  Boxes = "boxes",
  Mixed = "mixed",
};

interface ExamsListBaseProps {
  exams?: PaginatedList<Exam>;
  mode?: ExamsListMode;
  isLoading?: boolean;
  sortProps?: ExamsTableSortProps;
}

interface ExamsListWithoutPaginationProps extends ExamsListBaseProps {
  showPagination: false;
  paginationProps?: never;
}

interface ExamsListWithPaginationProps extends ExamsListBaseProps {
  showPagination?: true;
  paginationProps: PaginationProps;
}

export type ExamsListProps =
  ExamsListWithoutPaginationProps |
  ExamsListWithPaginationProps;
