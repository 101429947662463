import {
  HomeSections,
  HomeSectionTypes,
  ThreeImagesSection,
  TilesSection,
  VideoSection,
  ParagraphColumnSection,
  ParagraphRowSection,
} from "@api/home/Home.types";

import { TilesSectionComponent } from "../TilesSection";
import { ThreeImagesSectionComponent } from "../ThreeImagesSection";
import { VideoSectionComponent } from "../VideoSection";
import { ParagraphRowSectionComponent } from "../ParagraphRowSection";
import { ParagraphColumnSectionComponent } from "../ParagraphColumnSection";

interface SectionListProps {
  sections: HomeSections;
}

export function SectionList({ sections }: SectionListProps) {
  return (
    <>
      {sections.map((section) => {
        // eslint-disable-next-line no-underscore-dangle
        switch (section.__component) {
          case HomeSectionTypes.TilesSection:
            return (
              <div
                key={`${section.id}-${section.title}`}
                id={section.menu?.HTMLTagId}
              >
                <TilesSectionComponent data={section as TilesSection} />
              </div>
            );

          case HomeSectionTypes.ThreeImagesSection:
            return (
              <div
                key={`${section.id}-${section.title}`}
                id={section.menu?.HTMLTagId}
              >
                <ThreeImagesSectionComponent
                  data={section as ThreeImagesSection}
                  key={`${section.id}-${section.title}`}
                />
              </div>
            );

          case HomeSectionTypes.ParagraphColumnSection:
            return (
              <div
                key={`${section.id}-${section.title}`}
                id={section.menu?.HTMLTagId}
              >
                <ParagraphColumnSectionComponent
                  data={section as ParagraphColumnSection}
                  key={`${section.id}-${section.title}`}
                />
              </div>
            );

          case HomeSectionTypes.ParagraphRowSection:
            return (
              <div
                key={`${section.id}-${section.title}`}
                id={section.menu?.HTMLTagId}
              >
                <ParagraphRowSectionComponent
                  data={section as ParagraphRowSection}
                  key={`${section.id}-${section.title}`}
                />
              </div>
            );

          case HomeSectionTypes.VideoSection:
            return (
              <div
                key={`${section.id}-${section.title}`}
                id={section.menu?.HTMLTagId}
              >
                <VideoSectionComponent
                  data={section as VideoSection}
                  key={`${section.id}-${section.title}`}
                />
              </div>
            );

          default:
            return null;
        }
      })}
    </>
  );
}
