import { PathRouteProps } from "react-router-dom";
import { UserRoles } from "../../../api/auth/Auth.types";

export interface Routes extends PathRouteProps {
  roles?: UserRoles[];
}

export enum Paths {
  Home = "/",
  StudentRegistration = "/registration/student",
  TeacherRegistration = "/registration/teacher",
  Registration = "/registration/:userRole",
  AccountActivation = "/registration/activate/:uid/:token",
  ParentActivation = "/registration/parent-activate/:uid/:token",
  ForgotPassword = "/forgot-password",
  ConfirmForgotPassword = "/forgot-password/:uid/:token",
  Login = "/login",
  Dashboard = "/dashboard",
  Questions = "/questions",
  TermsAndConditions = "/terms-and-conditions",
  MyExams = "/my-exams",
  Solutions = "/solutions",
  ExamCreator = "/exam-creator",
  EditExam = "/exam-creator/:examId",
  MyAccount = "/my-account",
  PaperExamGuide = "/exam-guide/:subjectLevelId",
  ExamGuideList = "/exam-guide",
  SearchResults = "/search-results/:query",
  NotFound = "*",
}
