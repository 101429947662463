import { ReactNode } from "react";
import { createPortal } from "react-dom";

import { GlobalOverlay } from "@components/GlobalOverlay";
import { Heading } from "@components/Typography";
import { HeadingVariant } from "@components/Typography/Heading/Heading.types";
import { Box } from "@components/Box";

import { CloseButton, PanelContent, StyledPanel } from "./Panel.styles";

interface PanelProps {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

export function Panel({
  children,
  isOpen,
  onClose,
}: PanelProps) {
  return createPortal(
    <>
      <GlobalOverlay
        isOpen={isOpen}
        onClick={onClose}
      />

      <StyledPanel isOpen={isOpen}>
        <PanelContent>
          <CloseButton onClick={onClose} />

          <Heading
            as={HeadingVariant.H6}
            align="center"
          >
            Filters
          </Heading>

          <Box mt="s">
            {children}
          </Box>
        </PanelContent>
      </StyledPanel>
    </>,
    document.body
  );
}