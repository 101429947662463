import { Solution as SolutionType } from "../../../api/paper/Paper.types";

export enum SolutionTabs {
  Solution = "solution",
  Question = "question",
  MarkingScheme = "markingScheme",
}

export interface SolutionProps extends Required<SolutionType> {
  defaultTab?: SolutionTabs;
}
