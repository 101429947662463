import { FormattedMessage } from "react-intl";

import { Box } from "@components/Box";
import { Button, ButtonVariants } from "@components/Button";
import { Heading, Paragraph } from "@components/Typography";
import { HeadingVariant } from "@components/Typography/Heading/Heading.types";

import { Container } from "./AddPaperSuccess.styles";

interface AddPaperSuccessProps {
  onClose: () => void;
}

export function AddPaperSuccess({ onClose }: AddPaperSuccessProps) {
  return (
    <Container>
      <Box mb="m">
        <Heading as={HeadingVariant.H3} align="center">
          <FormattedMessage
            id="addPaper.successTitle"
            defaultMessage="New paper added"
          />
        </Heading>
      </Box>
      <Box mt="m">
        <img src="/img/success-tick.svg" alt="Paper added" />
      </Box>
      <Box mt="m" mb="xs">
        <Paragraph align="center">
          <FormattedMessage
            id="addPaper.successDescription"
            defaultMessage="New paper has been added successfully."
          />
        </Paragraph>
      </Box>
      <Box mt="l">
        <Button onClick={onClose} variant={ButtonVariants.Primary}>
          <FormattedMessage id="addPaper.successButton" defaultMessage="Ok" />
        </Button>
      </Box>
    </Container>
  );
}
