import styled, { css, SolidColors } from "styled-components";

import { StyledTabProps } from "./QuestionTabs.types";

export const Container = styled.div`
  margin-top: ${({ theme }) => theme.spacing.l};
  margin-bottom: ${({ theme }) => theme.spacing.l};
`;

export const TabList = styled.div`
  display: flex;
  justify-content: flex-start;
  overflow-x: auto;

  margin-right: ${({ theme }) => `-${theme.spacing.m}`};
  margin-left: ${({ theme }) => `-${theme.spacing.m}`};

  padding-right: ${({ theme }) => theme.spacing.m};
  padding-left: ${({ theme }) => theme.spacing.m};

  > * + * {
    margin-left: ${({ theme }) => theme.spacing.xxs};
  }

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    > * + * {
      margin-left: ${({ theme }) => theme.spacing.xs};
    }
  }
`;

export const Tab = styled.div<StyledTabProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid ${({ theme }) => theme.color.solid.lightGrey};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.s};
  font-size: 12px;
  letter-spacing: 1.5px;
  color: ${({ theme }) => theme.color.solid.darkGrey};
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;

  transition: border, background, color 0.25s ease-in-out;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    border-bottom-width: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 0;
    padding: ${({ theme }) => theme.spacing.s} ${({ theme }) => theme.spacing.l};
    font-size: 14px;

    &:first-child {
      margin-right: ${({ theme }) => theme.spacing.xs};

      & + * {
        margin-left: auto;
      }
    }
  }

  ${({ isActive, color, theme }) =>
    isActive &&
    css`
      background: ${theme.color.solid[color]};
      border-color: ${theme.color.solid[color]};
      color: ${theme.color.solid.white};
    `}
`;

export const TabIcon = styled.div`
  display: none;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    display: unset;
    height: 20px;
    margin-right: ${({ theme }) => theme.spacing.s};
  }
`;

export const ContentWrapper = styled.div<{ color: SolidColors }>`
  display: flex;
  justify-content: center;

  padding: ${({ theme }) => theme.spacing.s};

  border-style: solid;
  border-color: ${({ theme, color }) => theme.color.solid[color]};
  border-width: 2px;
  border-radius: 0px 0px 12px 12px;

  transition: border 0.25s ease-in-out;
`;
