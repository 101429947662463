import { useEffect, useRef, useState } from "react";
import { Loader } from "../Loader";
import { LoaderPositions } from "../Loader/Loader.types";
import { StyledFigure, StyledImage } from "./Image.styles";

interface ImageProps extends React.ComponentPropsWithoutRef<"img"> {}

export function Image(props: ImageProps) {
  const [isLoading, setIsLoading] = useState(true);
  const imageRef = useRef<HTMLImageElement>(null);

  const handleOnLoad = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    if (imageRef.current && imageRef.current.complete) {
      handleOnLoad();
    }
  });

  return (
    <StyledFigure>
      {isLoading && <Loader position={LoaderPositions.Absolute} />}
      <StyledImage
        ref={imageRef}
        onLoad={handleOnLoad}
        isLoading={isLoading}
        {...props}
      />
    </StyledFigure>
  );
}
