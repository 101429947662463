import { useMemo } from "react";
import { useIsFetching } from "@tanstack/react-query";

import { PageFooter } from "@components/PageFooter";
import { Loader } from "@components/Loader";
import { LoaderPositions } from "@components/Loader/Loader.types";
import { Menu } from "@components/Menu";

import { DashboardLayoutProps } from "./DashboardLayout.types";
import {
  Container,
  ContentContainer,
  HeaderColumn,
  HeaderContainer,
  HeaderInnerContainer,
} from "./DashboardLayout.styles";
import { DefaultHeader } from "./DefaultHeader";

export function DashboardLayout({
  children,
  title,
  description,
  backgroundImage,
  headerComponent,
  disableLoader = false,
}: DashboardLayoutProps) {
  const isFetching = useIsFetching({
    predicate: ({ queryKey }) => !queryKey.includes("papers-search"),
  });

  const header = useMemo(
    () =>
      headerComponent !== undefined ? (
        headerComponent
      ) : (
        <DefaultHeader title={title} description={description} />
      ),
    [title, description, headerComponent]
  );

  return (
    <Container>
      <Menu />

      <HeaderContainer backgroundImage={backgroundImage}>
        <HeaderInnerContainer>
          <HeaderColumn>{header}</HeaderColumn>
        </HeaderInnerContainer>
      </HeaderContainer>

      <ContentContainer>
        {(!disableLoader && isFetching > 0) ? <Loader position={LoaderPositions.Fixed} /> : null}
        {children}
      </ContentContainer>

      <PageFooter />
    </Container>
  );
}
