import { ErrorBoundary } from "react-error-boundary";

import { UserRoles } from "@api/auth/Auth.types";

import { CreateNewPassword } from "@pages/forgotPassword/createNewPassword";
import { Dashboard } from "@pages/dashboard";
import { ForgotPassword } from "@pages/forgotPassword";
import { Home } from "@pages/home";
import { Login } from "@pages/login";
import { Registration } from "@pages/registration";
import { AccountActivation } from "@pages/registration/accountActivation";
import { ParentActivation } from "@pages/registration/parentActivation";
import { Questions } from "@pages/dashboard/questions";
import { Solutions } from "@pages/dashboard/solutions/Solutions";
import { Exams } from "@pages/dashboard/exams/Exams";
import { ExamCreator } from "@pages/dashboard/exam-creator";
import { ExamEdit } from "@pages/dashboard/exam-edit";
import { MyAccount } from "@pages/dashboard/my-account";
import { ExamGuide } from "@pages/dashboard/exam-guide";
import { ExamGuideDetails } from "@pages/dashboard/exam-guide/ExamGuideDetails";
import { SearchResults } from "@pages/dashboard/search-results";
import { NotFound } from "@pages/notFound";

import { ErrorBoundaryFallback } from "@components/ErrorBoundaries";

import { Paths, Routes } from "./routes.types";

export const routes: Array<Routes> = [
  {
    path: Paths.Home,
    element: <Home />,
  },
  {
    path: Paths.Registration,
    element: (
      <ErrorBoundary
        FallbackComponent={ErrorBoundaryFallback}
      >
        <Registration />
      </ErrorBoundary>
    ),
  },
  {
    path: Paths.AccountActivation,
    element: <AccountActivation />,
  },
  {
    path: Paths.ParentActivation,
    element: <ParentActivation />,
  },
  {
    path: Paths.ConfirmForgotPassword,
    element: <CreateNewPassword />,
  },
  {
    path: Paths.ForgotPassword,
    element: <ForgotPassword />,
  },
  {
    path: Paths.Login,
    element: <Login />,
  },
  {
    path: Paths.Dashboard,
    element: <Dashboard />,
    roles: [UserRoles.Teacher, UserRoles.Student],
  },
  {
    path: Paths.Questions,
    element: <Questions />,
    roles: [UserRoles.Teacher, UserRoles.Student],
  },
  {
    path: Paths.Solutions,
    element: <Solutions />,
    roles: [UserRoles.Teacher, UserRoles.Student],
  },
  {
    path: Paths.MyExams,
    element: <Exams />,
    roles: [UserRoles.Teacher],
  },
  {
    path: Paths.ExamCreator,
    element: <ExamCreator />,
    roles: [UserRoles.Teacher],
  },
  {
    path: Paths.EditExam,
    element: <ExamEdit />,
    roles: [UserRoles.Teacher],
  },
  {
    path: Paths.MyAccount,
    element: <MyAccount />,
    roles: [UserRoles.Teacher, UserRoles.Student],
  },
  {
    path: Paths.PaperExamGuide,
    element: <ExamGuideDetails />,
    roles: [UserRoles.Teacher, UserRoles.Student],
  },
  {
    path: Paths.ExamGuideList,
    element: <ExamGuide />,
    roles: [UserRoles.Teacher, UserRoles.Student],
  },
  {
    path: Paths.SearchResults,
    element: <SearchResults />,
    roles: [UserRoles.Teacher, UserRoles.Student],
  },
  {
    path: Paths.NotFound,
    element: <NotFound />,
  },
];
