import { AxiosError } from "axios";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  AccountActivationResultState,
  AccountActivationResultType,
} from "../../../api/auth/Auth.types";
import { api } from "../../../api/api";
import { AuthEndpoints } from "../../../api/auth/endpoints";
import { Paths } from "../../../utils/router";

export function AccountActivation() {
  const navigate = useNavigate();
  const { uid, token } = useParams();

  useEffect(() => {
    api
      .post(AuthEndpoints.Activation, {
        uid,
        token,
      })
      .then(() => {
        navigate(Paths.Login, {
          state: {
            accountActivation: {
              type: AccountActivationResultType.SELF_SUCCESS,
              title: "Activation Successful!",
              message: "You may now log into your ExamHub account.",
            },
          } as AccountActivationResultState,
        });
      })
      .catch((error: AxiosError) => {
        navigate(Paths.Login, {
          state: {
            accountActivation: {
              type: AccountActivationResultType.ERROR,
              message:
                typeof error.response?.data === "string"
                  ? error.response?.data
                  : undefined,
            },
          } as AccountActivationResultState,
        });
      });
  }, [navigate, token, uid]);

  return null;
}
