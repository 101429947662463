import styled from "styled-components";

import { Row } from "@components/Row";

export const Header = styled(Row)`
  margin-bottom: ${({ theme }) => theme.spacing.l};
`;

export const StyledPapersGrid = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing.m};
  gap: ${({ theme }) => theme.spacing.m};
  grid-template-columns: 1fr;
  justify-items: center;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    grid-template-columns: repeat(4, 1fr);
  }
`;
