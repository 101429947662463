import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, useParams, Link } from "react-router-dom";

import { useRegister } from "@api/auth/Auth.queries";
import { UserRoles } from "@api/auth/Auth.types";
import { Paths } from "@utils/router";
import { ErrorWithStatusCode } from "@utils/custom-error";

import { TeacherRegistrationForm } from "@components/TeacherRegistrationForm";
import { TwoColumnsLayout } from "@components/TwoColumnsLayout";
import { Box } from "@components/Box";
import { Tab, Tabs } from "@components/Tabs";
import { StudentRegistrationForm } from "@components/StudentRegistrationForm";
import { TabIndex } from "@components/Tabs/Tab";
import { Heading, Overline, Paragraph } from "@components/Typography";
import { HeadingVariant } from "@components/Typography/Heading/Heading.types";
import { ButtonLink, ButtonVariants } from "@components/Button";

export function Registration() {
  const [isSuccessfullyRegistered, setIsSuccessfullyRegistered] =
    useState(false);
  const [
    isStudentUnder16SuccessfullyRegistered,
    setIsStudentUnder16SuccessfullyRegistered,
  ] = useState(false);
  const { userRole } = useParams();
  const isValidRole = Object.values(UserRoles).find(
    (role) => role.toLowerCase() === userRole?.toLowerCase()
  );

  if (!isValidRole) {
    throw new ErrorWithStatusCode('Unknown role', 404);
  }

  const navigate = useNavigate();

  const { formatMessage } = useIntl();

  const {
    mutate: handleSubmit,
    error,
    reset,
  } = useRegister({
    onSuccess: (response) => {
      setIsSuccessfullyRegistered(true);

      if (response.under_16 && response.role === UserRoles.Student) {
        setIsStudentUnder16SuccessfullyRegistered(true);
      }
    },
  });

  const defaultTab = userRole?.toUpperCase() || UserRoles.Teacher;

  const handleTabChange = (index: TabIndex) => {
    reset();

    if (index === UserRoles.Teacher) {
      return navigate(Paths.TeacherRegistration);
    }

    return navigate(Paths.StudentRegistration);
  };

  if (isStudentUnder16SuccessfullyRegistered) {
    return (
      <TwoColumnsLayout backgroundImage="/img/email-sent.svg">
        <Box pb="l">
          <Heading as={HeadingVariant.H4} align="center">
            <FormattedMessage
              id="parentActivationSuccess.title"
              defaultMessage="Check Email addresses"
            />
          </Heading>
        </Box>

        <Box pt="s" pb="s">
          <Paragraph align="center">
            <FormattedMessage
              id="parentActivationSuccess.emailSentMessage"
              defaultMessage="We sent an email to your email address and parent/guardian email address with the confirmation link."
            />
          </Paragraph>
        </Box>

        <Box mb="l">
          <Paragraph align="center">
            <FormattedMessage
              id="parentActivationSuccess.click"
              defaultMessage="Please click on the link and ask your parent/guardian to click on
              the link in the ExamHub email, in order to use your account."
            />
          </Paragraph>
        </Box>

        <Box mt="s" mx="auto">
          <ButtonLink to={Paths.Login} variant={ButtonVariants.Primary}>
            <FormattedMessage
              id="parentActivationSuccess.confirmationButton"
              defaultMessage="Ok"
            />
          </ButtonLink>
        </Box>
      </TwoColumnsLayout>
    );
  }

  if (isSuccessfullyRegistered) {
    return (
      <TwoColumnsLayout backgroundImage="/img/email-sent.svg">
        <Box mb="s">
          <Heading as={HeadingVariant.H4} align="center">
            <FormattedMessage
              id="registration.successTitle"
              defaultMessage="Check your Email"
            />
          </Heading>
        </Box>

        <Box my="l">
          <Paragraph align="center">
            <FormattedMessage
              id="registration.successDescription"
              defaultMessage="We sent you an email with the confirmation link. Please click on it to create your account."
            />
          </Paragraph>
        </Box>

        <Box mt="s" mx="auto">
          <ButtonLink to={Paths.Login} variant={ButtonVariants.Primary}>
            <FormattedMessage
              id="registration.successButton"
              defaultMessage="Ok"
            />
          </ButtonLink>
        </Box>
      </TwoColumnsLayout>
    );
  }

  return (
    <TwoColumnsLayout>
      <Box mb="s">
        <Heading as={HeadingVariant.H3} align="center">
          <FormattedMessage
            id="teacherRegistration.title"
            defaultMessage="Register"
          />
        </Heading>
      </Box>

      <Box mt="s">
        <Tabs defaultTab={defaultTab} onChange={handleTabChange}>
          <Tab
            index={UserRoles.Teacher}
            title={formatMessage({
              id: "teacherRegistration.teacher",
              defaultMessage: "Teacher",
            })}
          >
            <Box mt="s">
              <TeacherRegistrationForm
                onSubmit={handleSubmit}
                apiError={error}
              />
            </Box>
          </Tab>

          <Tab
            index={UserRoles.Student}
            title={formatMessage({
              id: "teacherRegistration.student",
              defaultMessage: "Student",
            })}
          >
            <Box mt="s">
              <StudentRegistrationForm
                onSubmit={handleSubmit}
                apiError={error}
              />
            </Box>
          </Tab>
        </Tabs>
      </Box>

      <Box mt="m" mb="m">
        <Paragraph align="center">
          <Overline>
            <FormattedMessage
              id="registration.orSeparator"
              defaultMessage="Or"
            />
          </Overline>
        </Paragraph>
      </Box>

      <Box>
        <Paragraph align="center">
          <FormattedMessage
            id="registration.signIn"
            defaultMessage="You already have an account? {signInLink}"
            values={{
              signInLink: (
                <Link to={Paths.Login}>
                  <FormattedMessage
                    id="registration.signInLink"
                    defaultMessage="Log&nbsp;in"
                  />
                </Link>
              ),
            }}
          />
        </Paragraph>
      </Box>
    </TwoColumnsLayout>
  );
}
