import { createTheme } from "react-data-table-component/dist/react-data-table-component.dev";

import { Icon } from "../Icon";
import { edcoTheme, StyledTable } from "./DataTable.styles";
import { DataTableProps } from "./DataTable.types";

const ThemeName = "Edco";

createTheme(ThemeName, edcoTheme, "light");

export function DataTable({ ...props }: DataTableProps) {
  return (
    <StyledTable sortIcon={<Icon icon="ArrowDown" color="dark" />} {...props} />
  );
}
