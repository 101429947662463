import { DefaultTheme, Spacing } from "styled-components";

export const defaultTheme: DefaultTheme = {
  containerWidth: "1280px",
  color: {
    solid: {
      primary: "#4365E9",
      orange: "#FF8C4D",
      blue: "#76D6FF",
      dark: "#323232",
      darkGrey: "#656479",
      pink: "#FB6895",
      purple: "#C596FA",
      grey: "#88899F",
      lightGrey: "#E5E5E5",
      white: "#FFFFFF",
      hover: "#1F41C6",
    },
    background: {
      primary: "#D6DFFF",
      orange: "#FFF2E7",
      blue: "#EDFAFF",
      light: "#F0F3FF",
      pink: "#FFEAF0",
      purple: "#F6EEFF",
    },
    stroke: {
      primary: "#6E8AF7",
      grey: "#9E9E9E",
      lightGrey: "#E5E5E5",
      blue: "#D6DDF8",
      light: "#F5F5F5",
    },
    informative: {
      accept: "#70D089",
      acceptLight: "#D3EBD9",
      alert: "#F46359",
      alertLight: "#F0C4C1",
      info: "#FFC600",
      infoLight: "#F6E8B7",
    },
  },
  borderRadius: {
    s: "3px",
    m: "12px",
    l: "50px",
    xl: "100px",
  },
  spacing: {
    xxs: "4px",
    xs: "8px",
    s: "16px",
    m: "24px",
    mt: "32px",
    l: "40px",
    ml: "48px",
    xl: "64px",
    xxl: "120px",
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
    bodyFontSize: "16px",
    bodyLineHeight: "normal",
    weights: {
      normal: 400,
      semiBold: 500,
      bold: 600,
    },
  },
  breakpoints: {
    mobile: "max-width: 767px",
    tablet: "min-width: 768px",
    tabletOnly: "(min-width: 768px) and (max-width: 1023px)",
    desktop: "min-width: 1024px",
    desktopPlus: "min-width: 1081px", // an endpoint that does not include iPads
  },
  shadow: {
    s: "0px 2px 6px rgba(110, 138, 247, 0.15)",
    m: "0px 6px 12px rgba(110, 138, 247, 0.15)",
    l: "0px 12px 24px rgba(110, 138, 247, 0.15)",
    xl: "0px 24px 48px rgba(110, 138, 247, 0.15)",
  },
};

export function isSpacing(val: any): val is Spacing {
  return val in defaultTheme.spacing;
}
