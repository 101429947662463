import styled from "styled-components";

export const Overline = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;

  letter-spacing: 1.5px;
  text-transform: uppercase;

  color: ${({ theme }) => theme.color.solid.grey};
`;
