import { useIntl } from "react-intl";
import * as yup from "yup";

export const useStudentRegistrationValidation = (
  setPasswordStrength: (value: number) => void
) => {
  const { formatMessage } = useIntl();

  return yup.object({
    firstName: yup
      .string()
      .trim()
      .max(
        150,
        formatMessage({
          id: "studentRegistrationForm.firstNameTooLong",
          defaultMessage: "Name is too long max. 150 characters",
        })
      )
      .required(
        formatMessage({
          id: "studentRegistrationForm.firstNameRequired",
          defaultMessage: "First name is a required field",
        })
      ),
    lastName: yup
      .string()
      .trim()
      .max(
        150,
        formatMessage({
          id: "studentRegistrationForm.lastNameTooLong",
          defaultMessage: "Last name is too long max. 150 characters",
        })
      )
      .required(
        formatMessage({
          id: "studentRegistrationForm.lastNameRequired",
          defaultMessage: "Last name is a required field",
        })
      ),
    email: yup
      .string()
      .trim()
      .max(
        254,
        formatMessage({
          id: "studentRegistrationForm.emailTooLong",
          defaultMessage: "Email is too long max. 254 characters",
        })
      )
      .email(
        formatMessage({
          id: "studentRegistrationForm.emailInvalid",
          defaultMessage: "Please enter a valid email address.",
        })
      )
      .required(
        formatMessage({
          id: "studentRegistrationForm.emailRequired",
          defaultMessage: "Email is a required field",
        })
      ),
    password: yup
      .string()
      .max(
        30,
        formatMessage({
          id: "studentRegistrationForm.passwordTooLong",
          defaultMessage: "Password is too long, max 30 characters.",
        })
      )
      .test({
        message: formatMessage({
          defaultMessage:
            "Password must contain min 8 characters (capital letter and number) ",
          id: "studentRegistrationForm.passwordFieldHint",
        }),
        name: "strength",
        test: (value) => {
          if (!value) {
            setPasswordStrength(0);

            return false;
          }

          const tests = ["[a-z]+", "[A-Z]+", "[0-9]+", "^.{8,}"];
          let passed = 0;

          tests.forEach((test, index) => {
            if (new RegExp(tests[index]).test(value)) {
              passed += 1;
            }
          });

          setPasswordStrength((passed / tests.length) * 100);

          return passed === tests.length;
        },
      }),
    gdprPolicy: yup.bool().oneOf(
      [true],
      formatMessage({
        id: "studentRegistrationForm.gdprPolicyRequired",
        defaultMessage: "Edco privacy policy must be checked.",
      })
    ),
    age: yup
      .number()
      .min(
        0,
        formatMessage({
          id: "studentRegistrationForm.ageMin",
          defaultMessage: "Please enter the correct age.",
        })
      )
      .max(
        100,
        formatMessage({
          id: "studentRegistrationForm.ageMin",
          defaultMessage: "Please enter the correct age.",
        })
      )
      .typeError(
        formatMessage({
          id: "studentRegistrationForm.ageRequired",
          defaultMessage: "Age is a required field",
        })
      ),
    parentEmail: yup
      .string()
      .nullable()
      .max(
        254,
        formatMessage({
          id: "studentRegistrationForm.parentEmailTooLong",
          defaultMessage:
            "Parent/Guardian email is too long max. 254 characters",
        })
      )
      .email(
        formatMessage({
          id: "studentRegistrationForm.parentEmailInvalid",
          defaultMessage: "Please enter a valid email address.",
        })
      )
      .when("age", {
        is: (age: number) => age < 16,
        then: (schema) =>
          schema.required(
            formatMessage({
              id: "studentRegistrationForm.parentEmailRequired",
              defaultMessage: "Please enter parent/guardian email address.",
            })
          ),
        otherwise: (schema) => schema,
      })
      .test({
        name: "sameAsEmail",
        message: formatMessage({
          id: "studentRegistrationForm.parentEmailEqualToEmail",
          defaultMessage: "Parent e-mail can't be equal to e-mail.",
        }),
        test(value) {
          return this.parent.email !== value;
        },
      }),
  });
};
