import ReactDataTable from "react-data-table-component/dist/react-data-table-component.dev";
import styled from "styled-components";

import { defaultTheme } from "../../themes";
import { DataTableProps } from "./DataTable.types";

export const edcoTheme = {
  text: {
    primary: defaultTheme.color.solid.darkGrey,
  },
};

export const StyledTable = styled(ReactDataTable)<DataTableProps>`
  .rdt {
    &_Table {
      max-width: none;
    }

    &_TableHead {
      font-weight: 400;
      color: ${({ theme }) => theme.color.solid.dark};
    }

    &_TableCol {
      padding: 0 ${({ theme }) => theme.spacing.xs};

      @media (${({ theme }) => theme.breakpoints.desktopPlus}) {
        padding: 0 ${({ theme }) => theme.spacing.s};
      }
    }

    &_TableCol_Sortable {
      &:hover {
        & .__rdt_custom_sort_icon__ {
          opacity: 1 !important;
        }
      }

      &:not([disabled]) {
        &,
        & * {
          cursor: pointer;
        }
      }
    }

    &_TableHeadRow {
      border: none;
    }

    &_TableBody {
      overflow: hidden;
      border: 1px solid ${({ theme }) => theme.color.background.primary};
      border-radius: ${({ theme }) => theme.borderRadius.m};
    }

    &_TableRow {
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
      font-weight: 400;
      color: ${({ theme }) => theme.color.solid.darkGrey};
      min-height: 88px;
      border: none;

      &:nth-child(even) {
        background: ${({ theme }) => theme.color.background.light};
      }
    }

    &_TableCell {
      padding: ${({ theme }) => theme.spacing.xs};

      @media (${({ theme }) => theme.breakpoints.desktopPlus}) {
        padding: ${({ theme }) => theme.spacing.s};
      }

      strong {
        font-weight: 600;
      }
    }

    &_TableCell,
    &_TableCol_Sortable {
      div:first-child {
        white-space: unset;
        text-overflow: unset;
      }
    }

    .rdt_Pagination {
      border: 2px solid red;
    }
  }
`;
