import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Box } from "../../../../components/Box";
import { Button, ButtonVariants } from "../../../../components/Button";
import { Modal } from "../../../../components/Modal";
import { ModalProps } from "../../../../components/Modal/Modal.types";
import { Heading, Paragraph } from "../../../../components/Typography";
import { HeadingVariant } from "../../../../components/Typography/Heading/Heading.types";
import { Paths } from "../../../../utils/router";

import { useBreakpoints } from "../../../../utils/use-breakpoints";

import { ButtonRow, IllustrationBox } from "./EditExamSuccess.styles";

interface EditExamSuccessProps extends Pick<ModalProps, "isOpen" | "onClose"> {}

export function EditExamSuccess({ isOpen, onClose }: EditExamSuccessProps) {
  const { isMobile } = useBreakpoints();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Box mb="m">
        <Heading as={HeadingVariant.H3} align="center">
          <FormattedMessage
            id="editExamSuccess.title"
            defaultMessage="Exam saved"
          />
        </Heading>
      </Box>
      <IllustrationBox pt="m" pb="m">
        <img src="/img/success-tick.svg" alt="Paper added" />
      </IllustrationBox>
      <Box>
        <Paragraph align="center">
          <FormattedMessage
            id="editExamSuccess.description"
            defaultMessage="Exam changes saved successfully."
          />
        </Paragraph>
      </Box>
      <ButtonRow>
        <Link to={Paths.MyExams}>
          <Button
            variant={ButtonVariants.Primary}
            iconRight="ArrowRight"
            block={isMobile}
          >
            <FormattedMessage
              id="editExamSuccess.goToMyExams"
              defaultMessage="Go to My Exams"
            />
          </Button>
        </Link>
      </ButtonRow>
    </Modal>
  );
}
