import { exportEndpointsEnum } from "../../utils/helpers";

enum SchoolEndpointsEnum {
  School = `/`,
}

export const SchoolEndpoints = exportEndpointsEnum<typeof SchoolEndpointsEnum>(
  "school",
  SchoolEndpointsEnum
);
