import styled, { css } from "styled-components";

export const StyledLabel = styled.label<{
  error?: boolean;
  isRequired?: boolean;
}>`
  display: inline-flex;
  align-items: center;
  font-size: inherit;
  color: ${({ theme }) => theme.color.solid.darkGrey};
  cursor: pointer;
  text-align: left;

  ${({ isRequired }) =>
    isRequired &&
    css`
      &:after {
        content: "*";
        position: relative;
        top: -5px;
        margin-left: 2px;
        font-size: 12px;
        color: ${({ theme }) => theme.color.informative.alert};
      }
    `}

  &::before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='15' height='15' rx='1.5' stroke='%234365E9'/%3E%3C/svg%3E%0A");
    background-position: center center;
    background-repeat: no-repeat;
    transition: background-image 0.2s ease-in-out;

    ${({ error }) =>
      error &&
      css`
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='15' height='15' rx='1.5' stroke='%23F46359'/%3E%3C/svg%3E%0A");
        border-radius: ${({ theme }) => theme.borderRadius.s};
      `}
  }
`;

export const HiddenInput = styled.input`
  opacity: 0;
  margin: 0;
  height: 0;
  width: 0;

  &:focus-visible {
    & + ${StyledLabel}::before {
      outline: blue auto 2px;
    }
  }

  &:checked + ${StyledLabel}::before {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='16' height='16' rx='2' fill='%234365E9'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.82436 11.3985L6.4001 10.9742L5.97583 11.3985C6.21015 11.6328 6.59005 11.6328 6.82436 11.3985ZM6.4001 10.1257L3.62436 7.34996C3.39005 7.11564 3.01015 7.11564 2.77583 7.34996C2.54152 7.58427 2.54152 7.96417 2.77583 8.19848L5.97583 11.3985L6.4001 10.9742C6.82436 11.3985 6.82432 11.3985 6.82436 11.3985L12.9984 5.22447C13.2327 4.99015 13.2327 4.61026 12.9984 4.37594C12.7641 4.14163 12.3842 4.14163 12.1498 4.37594L6.4001 10.1257Z' fill='white'/%3E%3C/svg%3E%0A");
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Error = styled.small`
  margin-top: ${({ theme }) => theme.spacing.xs};
  margin-left: 28px;
  color: ${({ theme }) => theme.color.informative.alert};

  font-size: 12px;
  letter-spacing: 1px;
  text-align: left;
`;
