import styled from "styled-components";

export const PaperBoxWrapper = styled.a`
  display: flex;
  justify-content: center;
  width: 100%;
  border-radius: 0px 24px;
  transition: box-shadow 0.25s ease-in-out;
  cursor: pointer;

  &:hover {
    box-shadow: ${({ theme }) => theme.shadow.l};
  }
`;
