import { useEffect } from "react";
import { createPortal } from "react-dom";
import { FormattedMessage } from "react-intl";
import { Link, NavLink } from "react-router-dom";

import AuthService from "@api/auth/Auth.service";
import { Paths } from "@utils/router";
import { useUser } from "@utils/user/user-context";

import { Avatar } from "@components/Avatar";
import { Box } from "@components/Box";
import { GlobalOverlay } from "@components/GlobalOverlay/GlobalOverlay";

import {
  CloseButton,
  Header,
  MobileLogo,
  StyledMenu,
  MenuLink,
  NavWrapper,
  AvatarWrapper,
  LinkButton,
} from "./MobileMenu.styles";

type MobileMenuProps = {
  isOpen: boolean;
  onClose: () => void;
  menuItems: { to: string; label: string }[];
  useAnchorTags?: boolean;
  BottomSection?: JSX.Element;
};

export function MobileMenu({
  isOpen,
  onClose,
  menuItems,
  useAnchorTags,
  BottomSection,
}: MobileMenuProps) {
  const { currentUser } = useUser();

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [onClose]);

  return createPortal(
    <>
      <GlobalOverlay
        isOpen={isOpen}
        onClick={onClose}
      />

      <StyledMenu isOpen={isOpen}>
        <Header>
          <MobileLogo src="/img/logo-big.svg" alt="ExamHub Created by Edco" />
          <CloseButton tabIndex={isOpen ? 0 : -1} onClick={onClose} />
        </Header>

        <NavWrapper>
          {menuItems.map(({ to, label }) => {
            if (useAnchorTags) {
              return (
                <a href={to} key={to} onClick={onClose}>
                  <MenuLink isActive={false}>{label}</MenuLink>
                </a>
              );
            }

            return (
              <NavLink to={to} key={to} tabIndex={isOpen ? 0 : -1}>
                {({ isActive }) => (
                  <MenuLink isActive={isActive}>{label}</MenuLink>
                )}
              </NavLink>
            );
          })}
        </NavWrapper>

        {BottomSection ||
          (currentUser && (
            <Box mt="l">
              <AvatarWrapper gap="s">
                <Avatar />

                <Link to={Paths.MyAccount} tabIndex={isOpen ? 0 : -1}>
                  <LinkButton>
                    <FormattedMessage
                      id="mobileMenu.myAccount"
                      defaultMessage="My account"
                    />
                  </LinkButton>
                </Link>
              </AvatarWrapper>

              <Box>
                <LinkButton
                  type="button"
                  onClick={AuthService.logout}
                  tabIndex={isOpen ? 0 : -1}
                >
                  <FormattedMessage
                    id="mobileMenu.logout"
                    defaultMessage="Log out"
                  />
                </LinkButton>
              </Box>
            </Box>
          ))}
      </StyledMenu>
    </>,
    document.body
  );
}
