import styled from "styled-components";
import { StrapiImage } from "../../../components/StrapiImage";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: ${({ theme }) => theme.spacing.xl};
  padding-right: ${({ theme }) => theme.spacing.m};
  padding-left: ${({ theme }) => theme.spacing.m};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    padding-top: ${({ theme }) => theme.spacing.xxl};
    padding-right: ${({ theme }) => theme.spacing.l};
    padding-left: ${({ theme }) => theme.spacing.l};
  }

  @media (${({ theme }) => theme.breakpoints.desktopPlus}) {
    padding-left: ${({ theme }) => theme.spacing.xl};
    padding-right: ${({ theme }) => theme.spacing.xl};
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 628px;
`;

export const BenefitsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${({ theme }) => theme.containerWidth};
  margin-top: ${({ theme }) => theme.spacing.l};

  > * + * {
    margin-top: ${({ theme }) => theme.spacing.l};
  }

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row;

    > * + * {
      margin-top: 0;
      margin-left: ${({ theme }) => theme.spacing.xl};
    }
  }
`;

export const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > * + * {
    margin-top: ${({ theme }) => theme.spacing.l};
  }
`;

export const ImageColumn = styled(Column)`
  flex: 2;
`;

export const StrapiImageStyled = styled(StrapiImage)`
  & > img {
    width: 100%;
  }
`;
