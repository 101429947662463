import styled, { css } from "styled-components";

interface StyledPanelProps {
  isActive?: boolean;
}

export const StyledPanel = styled.div<StyledPanelProps>`
  transition: background-color 0.25s ease-in-out;

  ${({ isActive, theme }) =>
    isActive &&
    css`
      background-color: ${theme.color.background.light};
    `};
`;
