import styled from "styled-components";
import { StrapiImage } from "../../../components/StrapiImage";

export const Container = styled.div`
  padding-right: ${({ theme }) => theme.spacing.m};
  padding-left: ${({ theme }) => theme.spacing.m};
  padding-top: ${({ theme }) => theme.spacing.xl};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    padding-right: ${({ theme }) => theme.spacing.l};
    padding-left: ${({ theme }) => theme.spacing.l};
    padding-top: ${({ theme }) => theme.spacing.xxl};
  }

  @media (${({ theme }) => theme.breakpoints.desktopPlus}) {
    padding-left: ${({ theme }) => theme.spacing.xl};
    padding-right: ${({ theme }) => theme.spacing.xl};
  }
`;

export const InnerContainer = styled.div`
  max-width: ${({ theme }) => theme.containerWidth};
  margin: 0 auto;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing.l};

  > * + * {
    margin-top: ${({ theme }) => theme.spacing.l};
  }

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row;

    > * + * {
      margin-top: 0;
      margin-left: ${({ theme }) => theme.spacing.xl};
    }
  }
`;

export const HeaderLeft = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    align-items: flex-start;
  }
`;

export const HeaderRight = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const BenefitsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    margin-top: ${({ theme }) => theme.spacing.l};
  }

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row;
    margin-top: ${({ theme }) => theme.spacing.xl};

    > * {
      margin-top: 0;

      + * {
        margin-left: ${({ theme }) => theme.spacing.xl};
      }
    }
  }
`;

export const StyledParagraph = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1;
`;

export const ImageBackgroundContainer = styled.div`
  position: relative;
`;

export const LineImage = styled.img`
  position: relative;
  object-fit: cover;
  width: 100%;
`;

export const PenImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
`;

export const BookImage = styled.img`
  position: absolute;
  bottom: -20px;
  right: -20px;
`;

export const CenterImage = styled(StrapiImage)`
  position: absolute;
  height: 100%;
  display: flex;
  max-width: 45%;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
