import { Link } from "react-router-dom";

import { Paths } from "@utils/router";

import { Button, ButtonVariants } from "@components/Button";
import { HeadingVariant } from "@components/Typography/Heading/Heading.types";
import { PageFooter } from "@components/PageFooter";

import { Container, ContentColumn, ErrorHeading, ErrorMessage, ImageColumn } from "./ErrorView.styles";


interface ErrorViewTypes {
  title: string;
  message: string;
  linkTo?: Paths;
  linkToTitle?: string;
  image?: JSX.Element;
};

export function ErrorView({
  title,
  message,
  linkTo,
  linkToTitle,
  image,
}: ErrorViewTypes) {
  return (
    <>
      <Container>
        <ContentColumn>
          <ErrorHeading as={HeadingVariant.H2}>
            {title}
          </ErrorHeading>

          <ErrorMessage>{message}</ErrorMessage>

          {linkTo && (
            <Link to={linkTo}>
              <Button variant={ButtonVariants.Primary}>{linkToTitle}</Button>
            </Link>
          )}
        </ContentColumn>

        {image && <ImageColumn>{image}</ImageColumn>}
      </Container>

      <PageFooter />
    </>
  );
}
