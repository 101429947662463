import styled, { css } from "styled-components";
import { isSpacing } from "../../themes/default";
import { BoxProps } from "./Box.types";

export const StyledBox = styled.div<BoxProps>`
  padding: ${({ theme, p }) => p && theme.spacing[p]};
  padding-right: ${({ theme, pr }) => pr && theme.spacing[pr]};
  padding-left: ${({ theme, pl }) => pl && theme.spacing[pl]};
  padding-top: ${({ theme, pt }) => pt && theme.spacing[pt]};
  padding-bottom: ${({ theme, pb }) => pb && theme.spacing[pb]};

  margin: ${({ theme, m }) => m && theme.spacing[m]};
  margin-right: ${({ theme, mr }) => mr && theme.spacing[mr]};
  margin-left: ${({ theme, ml }) => ml && theme.spacing[ml]};
  margin-top: ${({ theme, mt }) => mt && theme.spacing[mt]};
  margin-bottom: ${({ theme, mb }) => mb && theme.spacing[mb]};

  ${({ theme, mx }) =>
    mx &&
    css`
      margin-left: ${isSpacing(mx) ? theme.spacing[mx] : mx};
      margin-right: ${isSpacing(mx) ? theme.spacing[mx] : mx};
    `};

  ${({ theme, my }) =>
    my &&
    css`
      margin-top: ${isSpacing(my) ? theme.spacing[my] : my};
      margin-bottom: ${isSpacing(my) ? theme.spacing[my] : my};
    `};

  box-shadow: ${({ theme, shadow }) => shadow && theme.shadow[shadow]};
`;
