import styled from "styled-components";

import { Box } from "@components/Box";
import { TabsWrapper as TabsButtonWrapper } from "@components/Tabs/Tabs.styles";

export const Container = styled(Box)`
  max-width: ${({ theme }) => theme.containerWidth};
  width: 100%;
  margin: 0 auto;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    padding-top: ${({ theme }) => theme.spacing.xs};
  }

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    padding-top: ${({ theme }) => theme.spacing.m};
  }
`;

export const TabsWrapper = styled.div`
  margin: 0 ${({ theme }) => `-${theme.spacing.m}`};

  ${TabsButtonWrapper} {
    box-sizing: content-box;
    min-width: max-content;
    padding-right: ${({ theme }) => theme.spacing.m};
    padding-left: ${({ theme }) => theme.spacing.m};
  }
`;
