import styled, { css } from "styled-components";
import { StyledHeadingProps, HeadingVariant } from "./Heading.types";

const headingStyles = {
  [HeadingVariant.H1]: css`
    font-weight: 600;
    font-size: 60px;
    line-height: 80px;
  `,
  [HeadingVariant.H2]: css`
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
  `,
  [HeadingVariant.H3]: css`
    font-weight: 500;
    font-size: 34px;
    line-height: 44px;
  `,
  [HeadingVariant.H4]: css`
    font-weight: 500;
    font-size: 34px;
    line-height: 44px;
  `,
  [HeadingVariant.H5]: css`
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  `,
  [HeadingVariant.H6]: css`
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
  `,
};

const mobileHeadingStyles = {
  [HeadingVariant.H1]: headingStyles[HeadingVariant.H3],
  [HeadingVariant.H2]: headingStyles[HeadingVariant.H4],
  [HeadingVariant.H3]: headingStyles[HeadingVariant.H3],
  [HeadingVariant.H4]: headingStyles[HeadingVariant.H4],
  [HeadingVariant.H5]: headingStyles[HeadingVariant.H5],
  [HeadingVariant.H6]: headingStyles[HeadingVariant.H6],
};

export const Heading = styled.h1<StyledHeadingProps>`
  margin: 0;
  color: ${({ color, theme }) =>
    color ? theme.color.solid[color] : theme.color.solid.dark};
  text-align: ${({ align = "left" }) => align};

  ${({ as }) => mobileHeadingStyles[as]};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    ${({ as }) => headingStyles[as]};
  }
`;
