import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useEffect } from "react";
import { IntlProvider } from "react-intl";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import {
  createGlobalStyle,
  ThemeProvider,
  StyleSheetManager,
} from "styled-components";
import { SkeletonTheme } from "react-loading-skeleton";
import ReactGA from "react-ga4";
import "react-loading-skeleton/dist/skeleton.css";

import AuthService from "@api/auth/Auth.service";
import { Paths, Router, routes } from "@utils/router";
import { NotificationProvider } from "@utils/notifications/notification-context";
import { defaultTheme } from "@themes/index";

import { CookiePrivacyConsent } from "@components/CookiePrivacyConsent";

const GAMeasurementID = process.env.REACT_APP_GA_ID;

const DISABLE_VENDOR_PREFIXES =
  process.env.NODE_ENV === "development" &&
  process.env.REACT_APP_DISABLE_VENDOR_PREFIXES === "true";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
    scroll-behavior: smooth;
  }

  body {
    height: inherit;
    margin: 0;
    font-family:  ${({ theme }) => theme.typography.fontFamily};
    font-size: ${({ theme }) => theme.typography.bodyFontSize};
    line-height: ${({ theme }) => theme.typography.bodyLineHeight};
    color: ${({ theme }) => theme.color.solid.dark};
    background-color: ${({ theme }) => theme.color.solid.white};
  }

  #root {
    height: inherit;
  }

  * {
    box-sizing: border-box;
    cursor: default;

    &:focus {
      outline-color: ${({ theme }) => theme.color.solid.primary} !important;
    }
  }

  input, textarea {
    cursor: initial;
  }

  a, button {
    &,
    & > * {
      cursor: pointer;
      text-decoration: none;
    }
  }

  a {
    color: ${({ theme }) => theme.color.solid.primary};
    font-weight: ${({ theme }) => theme.typography.weights.bold};
  }
`;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function DashboardRedirect() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const isLoggedIn = AuthService.isLoggedIn();
    const matchedPath = routes.find(({ path }) =>
      matchPath(path, location.pathname)
    );
    const isProtected = Array.isArray(matchedPath?.roles);

    if (isLoggedIn && !isProtected && matchedPath?.path !== Paths.NotFound) {
      navigate(Paths.Dashboard);
    }
  }, [location, navigate]);

  return null;
}

function App() {
  useEffect(() => {
    if (GAMeasurementID) {
      ReactGA.initialize(GAMeasurementID);
    } else if (process.env.NODE_ENV === "production") {
      // eslint-disable-next-line no-console
      console.error("Could not find GA measurement ID");
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <IntlProvider locale="en-GB" defaultLocale="en-GB">
        <StyleSheetManager disableVendorPrefixes={DISABLE_VENDOR_PREFIXES}>
          <ThemeProvider theme={defaultTheme}>
            <SkeletonTheme
              baseColor={defaultTheme.color.solid.grey}
              highlightColor={defaultTheme.color.background.light}
            >
              <NotificationProvider>
                <Router>
                  <DashboardRedirect />
                  <ScrollToTop />
                  <GlobalStyle />
                </Router>
              </NotificationProvider>
            </SkeletonTheme>

            <CookiePrivacyConsent />
          </ThemeProvider>
        </StyleSheetManager>
      </IntlProvider>

      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
