import { useBreakpoints } from "@utils/use-breakpoints";

import { Box } from "@components/Box";
import { Button, ButtonVariants } from "@components/Button";
import { Heading, Overline, Paragraph } from "@components/Typography";
import { HeadingVariant } from "@components/Typography/Heading/Heading.types";
import { ParagraphVariants } from "@components/Typography/Paragraph/Paragraph.types";

import { ParagraphColumnSectionProps } from "./ParagraphColumnSection.types";
import {
  BenefitsWrapper,
  Column,
  Container,
  HeaderWrapper,
  ImageColumn,
  StrapiImageStyled,
} from "./ParagraphColumnSection.styles";

export function ParagraphColumnSectionComponent({
  data,
}: ParagraphColumnSectionProps) {
  const { isMobile } = useBreakpoints();

  return (
    <Container>
      <HeaderWrapper>
        <Box mb="xs">
          <Overline>{data.label}</Overline>
        </Box>
        <Heading as={HeadingVariant.H2} align="center">
          {data.title}
        </Heading>
        {data?.actionButtonTitle && data?.actionButtonURL && (
          <Box mt="m" mb="m">
            <a href={data.actionButtonURL}>
              <Button variant={ButtonVariants.Primary}>
                {data.actionButtonTitle}
              </Button>
            </a>
          </Box>
        )}
      </HeaderWrapper>

      <BenefitsWrapper>
        <Column>
          {data.columnLeft.map((paragraph) => (
            <Box key={paragraph.id}>
              <Heading as={HeadingVariant.H6}>{paragraph.title}</Heading>
              <Paragraph variant={ParagraphVariants.Small}>
                {paragraph.description}
              </Paragraph>
            </Box>
          ))}
        </Column>

        {!isMobile && (
          <ImageColumn>
            <StrapiImageStyled
              url={data.image.data.attributes.url}
              formats={data.image.data.attributes.formats}
            />
          </ImageColumn>
        )}

        <Column>
          {data.columnRight.map((paragraph) => (
            <Box key={paragraph.id}>
              <Heading as={HeadingVariant.H6}>{paragraph.title}</Heading>
              <Paragraph variant={ParagraphVariants.Small}>
                {paragraph.description}
              </Paragraph>
            </Box>
          ))}
        </Column>
      </BenefitsWrapper>
    </Container>
  );
}
