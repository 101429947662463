import { useEffect, useRef } from "react";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

import {
  CloseButton,
  Container,
  InnerContainer,
  Overlay,
} from "./Modal.styles";
import { ModalProps } from "./Modal.types";

export function Modal({
  children,
  isOpen,
  onClose,
  fullWidth,
  fullHeight,
  hideCloseButton,
  className,
}: ModalProps) {
  const innerContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isOpen && innerContainerRef.current) {
      disableBodyScroll(innerContainerRef.current, {
        allowTouchMove: (el) =>
          el.getAttribute("data-body-scroll-lock-ignore") !== null,
      });
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [isOpen]);

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [onClose]);

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <Overlay />
      <Container className={className}>
        <InnerContainer
          ref={innerContainerRef}
          fullWidth={fullWidth}
          fullHeight={fullHeight}
        >
          {hideCloseButton ? null : <CloseButton onClick={onClose} />}
          {children}
        </InnerContainer>
      </Container>
    </>
  );
}
