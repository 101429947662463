import { useMediaQuery } from "react-responsive";
import { useTheme } from "styled-components";

export const useBreakpoints = () => {
  const { breakpoints } = useTheme();

  const isDesktopPlus = useMediaQuery({
    query: `(${breakpoints.desktopPlus})`,
  });
  const isDesktop = useMediaQuery({ query: `(${breakpoints.desktop})` });
  const isMobile = useMediaQuery({ query: `(${breakpoints.mobile})` });
  const isTablet = useMediaQuery({ query: `(${breakpoints.tablet})` });

  return {
    isDesktopPlus,
    isDesktop,
    isMobile,
    isTablet,
  };
};
