import styled from "styled-components";
import { TabButtonProps } from "./Tabs.types";

export const TabsWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const TabsScroller = styled.div`
  overflow-x: auto;
  padding-bottom: ${({ theme }) => theme.spacing.s};
`;

export const TabButton = styled.button.attrs({
  type: "button",
})<TabButtonProps>`
  display: inline-flex;
  flex-grow: 1;
  justify-content: center;
  min-width: 136px;
  border: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${({ theme, isActive }) =>
    isActive ? theme.color.solid.primary : theme.color.solid.lightGrey};
  margin: 0;
  padding: ${({ theme }) => theme.spacing.xs};
  background: transparent;
  font-size: inherit;
  font-family: inherit;
  font-weight: ${({ theme }) => theme.typography.weights.bold};
  color: ${({ theme, isActive }) =>
    isActive ? theme.color.solid.primary : theme.color.solid.dark};
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 14px;
  transition: color, border 0.25s ease-in-out;
  white-space: nowrap;
`;
