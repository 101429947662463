import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import { useGetQuestion, useGetQuestions } from "@api/paper/Paper.queries";
import { QuestionsFilters } from "@api/paper/Paper.types";
import { useBreakpoints } from "@utils/use-breakpoints";
import { useQuestionsFilters } from "@utils/questions-filters/questions-filters-context";
import { useUser } from "@utils/user/user-context";

import { useBackToTop } from "@components/BackToTop";
import { Paragraph } from "@components/Typography";
import { Button, ButtonVariants } from "@components/Button";
import { EmptyListBox } from "@components/EmptyListBox";
import { Pagination } from "@components/Pagination";

import { AddQuestionToExam } from "./AddQuestionToExam";
import { Question } from "./Question";
import { Container, SubmitRow } from "./QuestionsList.styles";

interface QuestionsListProps {
  examId?: string | null;
  questionId?: string | null;
}

export function QuestionsList({ examId, questionId }: QuestionsListProps) {
  const { isTeacher } = useUser();
  const { isDesktop } = useBreakpoints();
  const { filters } = useQuestionsFilters();
  const { setBottomOffset } = useBackToTop();

  const [submittedQuestions, setSubmittedQuestions] = useState<string[]>([]);
  const [showAddQuestionModal, setShowAddQuestionModal] = useState(false);
  const [pagination, setPagination] = useState<
    Partial<Pick<QuestionsFilters, "page" | "perPage">>
  >({
    perPage: isDesktop ? 10 : 5,
  });

  const { data: singleQuestion } = useGetQuestion(questionId, {
    enabled: !!questionId,
  });

  const { data: questions, isFetching } = useGetQuestions(
    { ...filters, ...pagination },
    {
      enabled:
        Object.keys(filters).length > 0 &&
        !Object.values(filters).find((filter) => !filter),
      retry: false,
    }
  );

  const methods = useForm<string[]>({
    defaultValues: [],
  });

  const watchAllFields = methods.watch();

  const questionsAmount = useMemo(
    () => Object.values(watchAllFields).filter((value) => value).length,
    [watchAllFields]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pagination]);

  useEffect(() => {
    methods.reset();
  }, [filters, methods]);

  const handlePaginationChange = (newPagination: Partial<QuestionsFilters>) =>
    setPagination({ ...pagination, ...newPagination });

  const onSubmit = (data: any) => {
    const selectedQuestions = Object.entries(data)
      .filter(([, value]) => value)
      .map(([id]) => id);

    setSubmittedQuestions(selectedQuestions);
    setShowAddQuestionModal(true);
  };

  const handleModalClose = () => setShowAddQuestionModal(false);

  const questionsList = useMemo(() => {
    if (singleQuestion) {
      return {
        total: null,
        data: [singleQuestion],
      };
    }

    return questions;
  }, [questions, singleQuestion]);

  useEffect(() => {
    setBottomOffset(!!questionsList?.data?.length);
  }, [questionsList, setBottomOffset]);

  if (!questionsList?.data?.length || isFetching) {
    return (
      <EmptyListBox
        variant="primary"
        subtitle={
          <FormattedMessage
            id="questionsList.emptyListSubtitle"
            defaultMessage="Please use the filters above to search for Questions by Topic."
          />
        }
      />
    );
  }

  return (
    <Container>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          {questionsList.data.map(
            (question) =>
              !methods.formState.isSubmitting && (
                <Question key={question.id} {...question} />
              )
          )}

          {isTeacher && (
            <SubmitRow>
              <Paragraph>
                <FormattedMessage
                  id="questionsList.questionsAmount"
                  defaultMessage="You’ve selected {questionsAmount} questions"
                  values={{
                    questionsAmount: <strong>{questionsAmount}</strong>,
                  }}
                />
              </Paragraph>

              <Button
                type="submit"
                variant={ButtonVariants.Primary}
                isSmall
                disabled={!questionsAmount}
              >
                <FormattedMessage
                  id="questionsList.addToExamButton"
                  defaultMessage="Add to exam"
                />
              </Button>
            </SubmitRow>
          )}
        </form>
      </FormProvider>

      {!!questionsList?.total && (
        <Pagination
          total={questionsList?.total}
          page={pagination?.page}
          perPage={pagination?.perPage}
          onChange={handlePaginationChange}
        />
      )}

      <AddQuestionToExam
        examId={examId}
        isOpen={showAddQuestionModal}
        questions={submittedQuestions}
        onClose={handleModalClose}
        afterUpdate={methods.reset}
      />
    </Container>
  );
}
