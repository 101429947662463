import { AxiosError } from "axios";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { api } from "../../../api/api";
import {
  AccountActivationResultState,
  AccountActivationResultType,
} from "../../../api/auth/Auth.types";
import { AuthEndpoints } from "../../../api/auth/endpoints";
import { Paths } from "../../../utils/router";

export function ParentActivation() {
  const navigate = useNavigate();
  const { uid, token } = useParams();

  useEffect(() => {
    api
      .post(AuthEndpoints.ParentActivation, {
        uid,
        token,
      })
      .then(({ data }) => {
        navigate(Paths.Login, {
          state: {
            accountActivation: {
              type: AccountActivationResultType.PARENT_SUCCESS,
              message: data,
            },
          } as AccountActivationResultState,
        });
      })
      .catch((error: AxiosError) => {
        let state;

        if (error.response?.status === 400) {
          state = (
            typeof error.response.data === "string"
              ? {
                  accountActivation: {
                    type: AccountActivationResultType.PARENT_SUCCESS,
                    message: error.response.data,
                  },
                }
              : {
                  accountActivation: {
                    type: AccountActivationResultType.ERROR,
                  },
                }
          ) as AccountActivationResultState;
        }

        navigate(Paths.Login, {
          state,
        });
      });
  }, [navigate, token, uid]);

  return null;
}
