import { useEffect, useRef, useState } from "react";
import { Icon } from "../Icon";
import {
  SearchButton,
  SearchInputInnerWrapper,
  SearchInputWrapper,
} from "./GlobalSearch.styles";
import { SearchInput } from "../SearchInput";

interface GlobalSearchProps {
  isOpen?: boolean;
}

export function GlobalSearch({ isOpen: isOpenDefault }: GlobalSearchProps) {
  const containerRef = useRef<HTMLFormElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = useState(isOpenDefault);

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setIsOpen(false);
      }
    };

    if (!isOpenDefault) {
      window.addEventListener("keydown", handleEsc);
    }

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [isOpenDefault]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    if (!isOpenDefault) {
      window.addEventListener("click", handleClickOutside, true);
    }

    return () => {
      window.removeEventListener("click", handleClickOutside, true);
    };
  }, [isOpenDefault]);

  useEffect(() => {
    if (isOpen && !isOpenDefault && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen, isOpenDefault]);

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    return false;
  }

  if (isOpen) {
    return (
      <SearchInputWrapper
        ref={containerRef}
        role="search"
        onSubmit={handleFormSubmit}
      >
        <SearchInputInnerWrapper>
          <SearchInput
            ref={inputRef}
            onClose={() => setIsOpen(false)}
          />
        </SearchInputInnerWrapper>
      </SearchInputWrapper>
    );
  }

  return (
    <SearchButton onClick={() => setIsOpen(true)}>
      <Icon icon="Search" noBackground width={21} height={20} />
    </SearchButton>
  );
}
