import { MenuItem, MenuList } from "@reach/menu-button";
import styled, { css } from "styled-components";

type DropdownButtonProps = {
  isExpanded?: boolean;
};

export const StyledMenu = styled.div`
  button {
    background: none;
    border: none;
  }
`;

export const DropdownButton = styled.span<DropdownButtonProps>`
  display: inline-flex;
  align-items: center;
  border: none;
  background-color: transparent;

  &::after {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-left: ${({ theme }) => theme.spacing.xs};
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1338_4672)'%3E%3Cpath d='M5 7.5L10 12.5L15 7.5' stroke='%2388899F' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1338_4672'%3E%3Crect width='20' height='20' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    transition: transform 0.25s ease-in-out;

    ${({ isExpanded }) =>
      isExpanded &&
      css`
        transform: rotate(-180deg);
      `}
  }
`;

export const DropdownList = styled(MenuList)`
  position: relative;
  z-index: 5;
  padding-top: ${({ theme }) => theme.spacing.s};
  padding-bottom: ${({ theme }) => theme.spacing.s};

  margin-top: ${({ theme }) => theme.spacing.xs};

  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  background-color: ${({ theme }) => theme.color.solid.white};
  box-shadow: ${({ theme }) => theme.shadow.s};
`;

export const DropdownListItem = styled(MenuItem)`
  &[data-reach-menu-item] {
    font-family: inherit;
    font-style: normal;
    font-weight: ${({ theme }) => theme.typography.weights.normal};
    font-size: 14px;
    line-height: 24px;
    color: ${({ theme }) => theme.color.solid.grey};

    &:hover {
      background: none;
      color: ${({ theme }) => theme.color.solid.primary};
    }
  }
`;
