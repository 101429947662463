import { ImageColumnProps } from "./ImageColumn.types";

import { Column, Image } from "./ImageColumn.styles";

export function ImageColumn({
  backgroundImage = "/img/register.svg",
}: ImageColumnProps) {
  return (
    <Column>
      <Image src={backgroundImage} />
    </Column>
  );
}
