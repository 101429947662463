import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import {
  useResetPasswordConfirm,
  useVerifyResetPasswordToken,
} from "../../../api/auth/Auth.queries";
import { Box } from "../../../components/Box";
import { ButtonLink, ButtonVariants } from "../../../components/Button";
import { CreateNewPasswordForm } from "../../../components/CreateNewPasswordForm";
import { CreateNewPasswordFormData } from "../../../components/CreateNewPasswordForm/CreateNewPasswordForm.types";
import { Loader } from "../../../components/Loader";
import { TwoColumnsLayout } from "../../../components/TwoColumnsLayout";
import { Heading, Paragraph } from "../../../components/Typography";
import { HeadingVariant } from "../../../components/Typography/Heading/Heading.types";
import { ParagraphVariants } from "../../../components/Typography/Paragraph/Paragraph.types";
import { Paths } from "../../../utils/router";

export function CreateNewPassword() {
  const { token = "", uid = "" } = useParams();
  const [isLinkInactive, setIsLinkInactive] = useState(false);
  const [isPasswordChangedSuccessfully, setPasswordChangedSuccessfully] =
    useState(false);

  const { mutate, isLoading } = useVerifyResetPasswordToken({
    onError: () => {
      setIsLinkInactive(true);
    },
  });
  const { mutate: confirmResetPassword } = useResetPasswordConfirm({
    onSuccess: () => {
      setPasswordChangedSuccessfully(true);
    },
  });

  const handleSubmit = (data: CreateNewPasswordFormData) => {
    if (token && uid) {
      confirmResetPassword({ ...data, token, uid });
    }
  };

  useEffect(() => {
    mutate({ token, uid });
  }, [mutate, token, uid]);

  if (isLoading) {
    return <Loader />;
  }

  if (isLinkInactive) {
    return (
      <TwoColumnsLayout backgroundImage="/img/link-inactive.svg">
        <Box mb="s">
          <Heading as={HeadingVariant.H3} align="center">
            <FormattedMessage
              id="createNewPassword.sorryTitle"
              defaultMessage="Sorry!"
            />
          </Heading>
          <Heading as={HeadingVariant.H3} align="center">
            <FormattedMessage
              id="createNewPassword.linkInactiveTitle"
              defaultMessage="Your link is inactive"
            />
          </Heading>
        </Box>

        <Box my="l">
          <Paragraph variant={ParagraphVariants.Big} align="center">
            <FormattedMessage
              id="createNewPassword.linkInactiveDescription"
              defaultMessage={`We are sorry, but this link is no longer valid. To reset your password, go back to the login screen and click "Forgot password?" button again.`}
            />
          </Paragraph>
        </Box>

        <Box mt="s" mx="auto">
          <ButtonLink to={Paths.Login} variant={ButtonVariants.Primary}>
            <FormattedMessage
              id="createNewPassword.linkInactiveButton"
              defaultMessage="Ok"
            />
          </ButtonLink>
        </Box>
      </TwoColumnsLayout>
    );
  }

  if (isPasswordChangedSuccessfully) {
    return (
      <TwoColumnsLayout backgroundImage="/img/email-sent.svg">
        <Box mb="s">
          <Heading as={HeadingVariant.H3} align="center">
            <FormattedMessage
              id="createNewPassword.passwordChangedTitle"
              defaultMessage="Password changed"
            />
          </Heading>
        </Box>

        <Box my="l">
          <Paragraph variant={ParagraphVariants.Big} align="center">
            <FormattedMessage
              id="createNewPassword.passwordChangedDescription"
              defaultMessage="Your password has been changed. Now you can log in to your account."
            />
          </Paragraph>
        </Box>

        <Box mt="s" mx="auto">
          <ButtonLink to={Paths.Login} variant={ButtonVariants.Primary}>
            <FormattedMessage
              id="createNewPassword.passwordChangedButton"
              defaultMessage="Ok"
            />
          </ButtonLink>
        </Box>
      </TwoColumnsLayout>
    );
  }

  return (
    <TwoColumnsLayout>
      <Box mb="s">
        <Heading as={HeadingVariant.H3} align="center">
          <FormattedMessage
            id="createNewPassword.title"
            defaultMessage="Create new password"
          />
        </Heading>
      </Box>

      <Box mt="s" mb="s">
        <Paragraph variant={ParagraphVariants.Big} align="center">
          <FormattedMessage
            id="createNewPassword.description"
            defaultMessage="Please enter a new password for your account."
          />
        </Paragraph>
      </Box>

      <Box mt="s" mb="s">
        <CreateNewPasswordForm onSubmit={handleSubmit} />
      </Box>
    </TwoColumnsLayout>
  );
}
