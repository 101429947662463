import styled, { css } from "styled-components";

export const Overlay = styled.div<{ isOpen: boolean }>`
  z-index: 5;
  position: fixed;
  background: rgba(101, 100, 121, 0.75);
  transition: opacity 0.2s ease-in;
  transition-delay: 0.15s;
  opacity: 0;

  top: 0;
  bottom: 0;
  right: 0;

  width: 0;
  height: 100%;

  ${({ isOpen }) =>
    isOpen &&
    css`
      opacity: 1;

      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      width: 100%;
    `}
`;
