import { useIntl } from "react-intl";
import * as yup from "yup";

export const useLoginValidation = () => {
  const { formatMessage } = useIntl();

  return yup.object({
    email: yup
      .string()
      .trim()
      .max(
        254,
        formatMessage({
          id: "loginForm.emailTooLong",
          defaultMessage: "Email is too long max. 254 characters",
        })
      )
      .email(
        formatMessage({
          id: "loginForm.emailInvalid",
          defaultMessage: " Please enter a valid email address to use as your Login",
        })
      )
      .required(
        formatMessage({
          id: "loginForm.emailRequired",
          defaultMessage: "This field is required.",
        })
      ),
    password: yup
      .string()
      .required(
        formatMessage({
          id: "loginForm.passwordRequired",
          defaultMessage: "This field is required."
        })
      )
  });
};
