import { Paths } from "@utils/router";
import { Box } from "@components/Box";
import { Button, ButtonLink, ButtonVariants } from "@components/Button";
import { Heading, Paragraph } from "@components/Typography";
import { HeadingVariant } from "@components/Typography/Heading/Heading.types";
import { ButtonsRow } from "@components/ButtonsRow";

interface AddQuestionErrorProps {
  error: string;
  multiple: boolean;
  onClose: () => void;
}

export function AddQuestionError({
  error,
  multiple,
  onClose,
}: AddQuestionErrorProps) {
  return (
    <div>
      <Box mb="ml">
        <Heading as={HeadingVariant.H3} align="center">
          Duplicate Question{multiple && "s"}
        </Heading>
      </Box>

      <Paragraph align="center">
        <img src="/img/cancel-creation.svg" alt="Not allowed sign" />
      </Paragraph>

      <Box mt="m">
        <Paragraph align="center">{error}</Paragraph>
      </Box>

      <ButtonsRow>
        <ButtonLink to={Paths.MyExams} variant={ButtonVariants.Primary}>
          Go to My Exams
        </ButtonLink>

        <Button variant={ButtonVariants.Secondary} onClick={onClose}>
          Cancel
        </Button>
      </ButtonsRow>
    </div>
  );
}
