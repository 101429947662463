import styled from "styled-components";

export const StyledForm = styled.form`
  width: 100%;
`;

export const ButtonsBox = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.spacing.m};
  gap: ${({ theme }) => theme.spacing.m};
  margin-top: ${({ theme }) => theme.spacing.l};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;

    button[type="submit"] {
      order: 1;
    }
  }
`;
