import { useEffect, useMemo } from "react";

import { NotificationProps } from "./Alert.types";
import { Alert } from "./Alert";
import { NotificationContainer } from "./Notification.styles";
import { Paragraph } from "../Typography";
import { ParagraphVariants } from "../Typography/Paragraph/Paragraph.types";

export function Notification({
  id,
  text,
  title,
  variant,
  autoRemove = true,
  autoRemoveDelay = 5000,
  onClick,
}: NotificationProps) {
  useEffect(() => {
    if (autoRemove && onClick) {
      const interval = setInterval(() => {
        onClick(id);
      }, autoRemoveDelay);

      return () => {
        clearInterval(interval);
      };
    }

    return () => false;
  }, [onClick, autoRemove, autoRemoveDelay, id]);

  const handleAlertClick = useMemo(
    () => (onClick ? () => onClick(id) : undefined),
    [id, onClick]
  );

  return (
    <NotificationContainer>
      <Alert onClose={handleAlertClick} showCloseButton variant={variant}>
        {!!title && (
          <Paragraph color="dark" variant={ParagraphVariants.Small}>
            {title}
          </Paragraph>
        )}
        {text}
      </Alert>
    </NotificationContainer>
  );
}
