import { useIntl } from "react-intl";
import { ErrorView } from "./ErrorView";

export function GenericErrorView() {
  const { formatMessage } = useIntl();

  return (
    <ErrorView
      title={formatMessage({
        id: "errorView.errorTitle",
        defaultMessage: "Sorry! \nAn unexpected error has occurred.",
      })}
      message={formatMessage({
        id: "errorView.errorGenericMessage",
        defaultMessage:
          "Please try again later or contact support for assistance.",
      })}
    />
  );
}
