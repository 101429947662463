import styled, { css } from "styled-components";

export const StyledFigure = styled.figure`
  position: relative;
  min-height: 50px;
  margin: 0;
`;

type StyledImageProps = {
  isLoading?: boolean;
};

export const StyledImage = styled.img<StyledImageProps>`
  max-width: 100%;

  ${({ isLoading }) =>
    isLoading &&
    css`
      filter: blur(5px) grayscale(1);
    `}
`;
