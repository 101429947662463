import { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

import { useBreakpoints } from "@utils/use-breakpoints";

import { Box } from "@components/Box";
import { Row } from "@components/Row";
import { Button, ButtonVariants } from "@components/Button";
import { Select } from "@components/Select";
import { Paragraph } from "@components/Typography";
import { ParagraphVariants } from "@components/Typography/Paragraph/Paragraph.types";

import { PaginationProps } from "./Pagination.types";

export function Pagination({
  total = 0,
  page = 1,
  perPage,
  onChange,
  rowsPerPage = [5, 10, 20],
}: PaginationProps) {
  const { isMobile } = useBreakpoints();

  const [currentPage, setCurrentPage] = useState(page);
  const [currentPerPage, setCurrentPerPage] = useState(
    isMobile ? rowsPerPage[0] : perPage
  );
  const pagesTotal = useMemo(
    () => Math.ceil(total / (perPage || 1)),
    [perPage, total]
  );
  const hasPreviousPage = currentPage > 1;
  const hasNextPage = currentPage + 1 <= pagesTotal;

  const options = useMemo(
    () =>
      rowsPerPage.map((amount) => ({
        label: amount,
        value: amount,
      })),
    [rowsPerPage]
  );

  const goToPreviousPage = () => {
    const previousPage = currentPage - 1;

    if (hasPreviousPage) {
      setCurrentPage(previousPage);
      onChange({ page: previousPage });
    }
  };

  const goToNextPage = () => {
    const nextPage = currentPage + 1;

    if (hasNextPage) {
      setCurrentPage(nextPage);
      onChange({ page: nextPage });
    }
  };

  const handlePerPageChange = ({ value }: { value: number; label: string }) => {
    setCurrentPerPage(value);
    setCurrentPage(1);

    onChange({
      perPage: value,
      page: 1,
    });
  };

  if (isMobile) {
    return (
      <Box my="m">
        <Row gap="s" justify="center">
          <Button
            variant={ButtonVariants.Tertiary}
            onClick={goToPreviousPage}
            disabled={!hasPreviousPage}
          >
            {"<"}
          </Button>

          <FormattedMessage
            id="pagination.pages"
            defaultMessage="{currentPage} of {pagesTotal}"
            values={{
              pagesTotal,
              currentPage,
            }}
            tagName="span"
          />

          <Button
            variant={ButtonVariants.Tertiary}
            onClick={goToNextPage}
            disabled={!hasNextPage}
          >
            {">"}
          </Button>
        </Row>
      </Box>
    );
  }

  return (
    <Box my="m">
      <Row gap="s" justify="center">
        <Paragraph variant={ParagraphVariants.Small}>
          <FormattedMessage
            id="pagination.title"
            defaultMessage="Per page"
          />
        </Paragraph>

        <Select
          isSearchable={false}
          options={options}
          onChange={handlePerPageChange}
          value={{
            value: currentPerPage,
            label: currentPerPage,
          }}
        />

        <FormattedMessage
          id="pagination.pages"
          defaultMessage="{currentPage} of {pagesTotal}"
          values={{
            pagesTotal,
            currentPage,
          }}
          tagName="span"
        />

        <Row gap="xs">
          <Button
            variant={ButtonVariants.Tertiary}
            onClick={goToPreviousPage}
            disabled={!hasPreviousPage}
          >
            {"<"}
          </Button>

          <Button
            variant={ButtonVariants.Tertiary}
            onClick={goToNextPage}
            disabled={!hasNextPage}
          >
            {">"}
          </Button>
        </Row>
      </Row>
    </Box>
  );
}
