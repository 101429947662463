import { SolidColors } from "styled-components";

export enum LoaderPositions {
  Absolute = "absolute",
  Fixed = "fixed",
}

export type LoaderProps = {
  position?: LoaderPositions;
  size?: number;
  width?: number;
  variant?: SolidColors;
};
