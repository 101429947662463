import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useGetExam,
  useRemoveExam,
  useUpdateExam,
} from "../../../api/exam-creator/ExamCreator.queries";
import { DashboardLayout } from "../../../components/DashboardLayout";
import { Paths } from "../../../utils/router";
import { Container } from "../Dashboard.styles";
import { CancelCreation } from "./CancelCreation";
import { EditExamForm } from "./EditExamForm/EditExamForm";
import { EditExamSuccess } from "./EditExamSuccess";

export function ExamEdit() {
  const navigate = useNavigate();
  const { examId } = useParams();
  const location = useLocation();
  const queryClient = useQueryClient();
  const state = location.state as { cancelRedirection?: string };
  const { formatMessage } = useIntl();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const { mutate: removeExam, isLoading: isRemovingExam } = useRemoveExam(
    examId as string,
    {
      onSuccess: () => navigate(state?.cancelRedirection!),
    }
  );

  const { data, isSuccess } = useGetExam(examId, { enabled: !isRemovingExam });

  const { mutate: updateExam } = useUpdateExam({
    onSuccess: () => {
      queryClient.invalidateQueries(["exams", examId]);
      setShowSuccessModal(true);
    },
  });

  const handleExamEdit = (exam: any) => {
    updateExam({ id: examId, ...exam });
  };

  const handleCancel = (isFormDirty: boolean) => {
    if (isFormDirty) {
      setShowCancelModal(true);
    } else if (state?.cancelRedirection) {
      queryClient.removeQueries({ queryKey: ["exams"] });
      removeExam(examId as string);
    } else {
      navigate(Paths.MyExams);
    }
  };

  return (
    <DashboardLayout
      title={formatMessage({
        id: "examCreator.title",
        defaultMessage: "Exam Creator",
      })}
      description={formatMessage({
        id: "examCreator.description",
        defaultMessage: "Here you can create a new exam",
      })}
      backgroundImage="/img/exam-creator-background.svg"
    >
      <Container>
        {isSuccess && (
          <EditExamForm
            defaultValues={{
              title: data?.title,
              description: data?.description,
              topic: data?.topic.id,
              examType: data.topic.subjectLevel.subject.examType.id,
              level: data.topic.subjectLevel.level.id,
              subject: data.topic.subjectLevel.subject.id,
            }}
            examId={data?.id}
            questions={data?.teacherExamQuestion}
            onSubmit={handleExamEdit}
            onCancel={handleCancel}
          />
        )}
      </Container>

      <EditExamSuccess
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
      />

      <CancelCreation
        isOpen={showCancelModal}
        onClose={() => setShowCancelModal(false)}
      />
    </DashboardLayout>
  );
}
