import styled, { css } from "styled-components";
import { StyledSubtitleProps, SubtitleVariant } from "./Subtitle.types";

export const Subtitle = styled.div<StyledSubtitleProps>`
  color: ${({ color, theme }) =>
    color ? theme.color.solid[color] : theme.color.solid.dark};

  text-align: ${({ align = "left" }) => align};

  font-weight: ${({ theme, bold }) =>
    bold ? theme.typography.weights.bold : theme.typography.weights.normal};

  ${({ variant }) => {
    switch (variant) {
      case SubtitleVariant.First:
        return css`
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.5px;
        `;
      case SubtitleVariant.Second:
        return css`
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.25px;
        `;
      default:
        return "";
    }
  }}
`;
