import { ReactNode } from "react";

export enum AlertVariant {
  Success = "success",
  Warning = "warning",
  Danger = "danger",
}

export type AlertType = "assertive" | "polite";

export interface AlertProps {
  children: ReactNode;
  variant: AlertVariant;
  showCloseButton?: boolean;
  hideIcon?: boolean;
  type?: AlertType;
  onClose?: () => void;
}

export interface NotificationProps {
  id: string;
  text: ReactNode;
  title?: ReactNode;
  variant: AlertVariant;
  autoRemove?: boolean;
  autoRemoveDelay?: number;
  onClick?: (id: string) => void;
}
