import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { Paths } from "@utils/router";
import { Box } from "@components/Box";
import { Button, ButtonVariants } from "@components/Button";
import { Heading, Paragraph } from "@components/Typography";
import { HeadingVariant } from "@components/Typography/Heading/Heading.types";

import { CenterBox } from "./AddQuestionSuccess.styles";

interface AddQuestionSuccessProps {
  onClose: () => void;
}

export function AddQuestionSuccess({ onClose }: AddQuestionSuccessProps) {
  return (
    <Box>
      <Box mb="l">
        <Heading as={HeadingVariant.H3} align="center">
          <FormattedMessage
            id="addQuestionToExam.questionsAddedTitle"
            defaultMessage="Questions added"
          />
        </Heading>
      </Box>

      <CenterBox>
        <img src="/img/success-tick.svg" alt="Questions added" />
      </CenterBox>

      <Box mt="m">
        <Paragraph align="center">
          <FormattedMessage
            id="addQuestionToExam.questionsAddedDescription"
            defaultMessage="Questions added to the exam successfully."
          />
        </Paragraph>

        <Paragraph align="center">
          <FormattedMessage
            id="addQuestionToExam.questionsAddedLinks"
            defaultMessage="You can {addMoreLink} or go to {myExamsLink}."
            values={{
              addMoreLink: (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a href="#" onClick={onClose}>
                  <FormattedMessage
                    id="addQuestionToExam.addMoreLink"
                    defaultMessage="Add more"
                  />
                </a>
              ),
              myExamsLink: (
                <Link to={Paths.MyExams}>
                  <FormattedMessage
                    id="addQuestionToExam.myExamLink"
                    defaultMessage="My Exams"
                  />
                </Link>
              ),
            }}
          />
        </Paragraph>
      </Box>

      <CenterBox mt="l">
        <Link to={Paths.MyExams}>
          <Button variant={ButtonVariants.Primary}>
            <FormattedMessage
              id="addQuestionToExam.questionsAddedButton"
              defaultMessage="Ok"
            />
          </Button>
        </Link>
      </CenterBox>
    </Box>
  );
}
