import { FormattedMessage } from "react-intl";
import { ReactNode } from "react";
import { Heading, Subtitle } from "@components/Typography";
import { HeadingVariant } from "@components/Typography/Heading/Heading.types";
import { SubtitleVariant } from "@components/Typography/Subtitle/Subtitle.types";
import { Box } from "@components/Box";

import { ReactComponent as EmptyListIcon } from "./EmptyListIcon.svg";
import { ReactComponent as EmptyListOrangeIcon } from "./EmptyListOrangeIcon.svg";
import { EmptyListContainer } from "./EmptyListBox.styles";

interface EmptyListBoxProps {
  variant: "primary" | "orange";
  title?: ReactNode;
  subtitle?: ReactNode;
}

export function EmptyListBox({
  variant,
  title = (
    <FormattedMessage
      id="emptyListBox.title"
      defaultMessage="Nothing in here yet!"
    />
  ),
  subtitle,
}: EmptyListBoxProps) {
  return (
    <EmptyListContainer variant={variant}>
      <Heading as={HeadingVariant.H5} color={variant} align="center">
        {title}
      </Heading>

      {!!subtitle && (
        <Box mt="xs">
          <Subtitle variant={SubtitleVariant.First} color="grey" align="center">
            {subtitle}
          </Subtitle>
        </Box>
      )}

      <Box mt="mt">
        {variant === "orange" ? <EmptyListOrangeIcon /> : <EmptyListIcon />}
      </Box>
    </EmptyListContainer>
  );
}
