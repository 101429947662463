import { useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { useGetSolutionsFilters } from "@api/paper/Paper.queries";
import {
  SolutionsFiltersFilters,
  SolutionsFiltersResponse,
} from "@api/paper/Paper.types";
import { useUser } from "@utils/user/user-context";

import { Filters } from "@components/Filters";
import { FiltersPanel } from "@components/FiltersPanel";

import { FiltersType, QuestionsFiltersProps } from "./SolutionsFilters.types";
import { Container } from "./SolutionsFilters.styles";

const optionsToFilterMap: Record<
  keyof SolutionsFiltersResponse,
  keyof SolutionsFiltersFilters
> = {
  examTypes: "examType",
  levels: "level",
  subjects: "subject",
  years: "year",
  papers: "paper",
  sections: "section",
  numbers: "number",
};

const optionalTeacherFilters = ["sections", "numbers"];

function addEmptyOption(
  arr?: Array<{ [key: string]: string | number }> | null
): Array<{ [key: string]: string | number }> {
  if (Array.isArray(arr) && arr.length > 0) {
    return [{ value: '' }, ...arr];
  }

  return [{ value: '' }];
};

export function SolutionsFilters({
  onSubmit,
  defaultValues,
}: QuestionsFiltersProps) {
  const { formatMessage } = useIntl();
  const { isStudent } = useUser();

  const [filters, setFilters] = useState<FiltersType>({
    ...defaultValues,
  });

  const { data, isFetching } = useGetSolutionsFilters(filters, {
    retry: false,
    onSuccess: (response) => {
      const defaultFilters = Object.fromEntries(
        Object.entries(response)
          .map(([filter, value]) => (
            [
              filter,
              !isStudent && optionalTeacherFilters.includes(filter)
                ? addEmptyOption(value)
                : value
            ]
          ))
          .filter(([_, value]) => value?.length === 1)
          .map(([filter, value]) => [
            optionsToFilterMap[filter as keyof SolutionsFiltersResponse],
            value[0].id || value[0].value,
          ])
      );

      setFilters({ ...filters, ...defaultFilters });
    },
  });

  const fields = useMemo(() => (
    [
      {
        name: "examType",
        label: formatMessage({
          id: "solutionsFilters.exam",
          defaultMessage: "Exam",
        }),
        options: data?.examTypes?.map(({ id, shortName }) => ({
          value: id,
          label: shortName,
        })),
        required: true,
        maxWidth: 85,
      },
      {
        name: "subject",
        label: formatMessage({
          id: "solutionsFilters.examPaper",
          defaultMessage: "Exam paper",
        }),
        options: data?.subjects?.map(({ id, name }) => ({
          value: id,
          label: name,
        })),
        required: true,
        maxWidth: 219,
      },
      {
        name: "level",
        label: formatMessage({
          id: "solutionsFilters.level",
          defaultMessage: "Exam level",
        }),
        options: data?.levels?.map(({ id, name }) => ({
          value: id,
          label: name,
        })),
        required: true,
        maxWidth: 134,
      },
      {
        name: "year",
        label: formatMessage({
          id: "solutionsFilters.year",
          defaultMessage: "Year",
        }),
        options: data?.years?.map(({ value }) => ({
          value,
          label: value,
        })),
        required: true,
        maxWidth: 108,
      },
      {
        name: "paper",
        label: formatMessage({
          id: "solutionsFilters.paper",
          defaultMessage: "Paper",
        }),
        options: data?.papers?.map(({ value }) => ({
          value,
          label: value,
        })),
        required: true,
        maxWidth: 108,
      },
      {
        name: "section",
        label: formatMessage({
          id: "solutionsFilters.section",
          defaultMessage: "Section",
        }),
        options: (isStudent ? data?.sections : addEmptyOption(data?.sections))?.map(({ value }) => ({
          value,
          label: value || "-",
        })),
        required: isStudent,
        maxWidth: 108,
      },
      {
        name: "number",
        label: formatMessage({
          id: "solutionsFilters.questionNumber",
          defaultMessage: "Question number",
        }),
        options: (isStudent ? data?.numbers : addEmptyOption(data?.numbers))?.map(({ value }) => ({
          value,
          label: value || "-",
        })),
        required: isStudent,
        maxWidth: 108,
      },
    ]
  ), [
    data?.examTypes,
    data?.levels,
    data?.numbers,
    data?.papers,
    data?.sections,
    data?.subjects,
    data?.years,
    formatMessage,
    isStudent,
  ]);

  return (
    <FiltersPanel>
      <Container>
        <Filters
          values={filters}
          fields={fields}
          onChange={setFilters}
          onSubmit={onSubmit}
          isFetching={isFetching}
          showMandatoryHint
        />
      </Container>
    </FiltersPanel>
  );
}
