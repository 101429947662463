import { useMemo, useState } from "react";

import { Icon } from "@components/Icon";

import { QuestionTabsProps } from "./QuestionTabs.types";
import {
  Container,
  ContentWrapper,
  Tab,
  TabIcon,
  TabList,
} from "./QuestionTabs.styles";

export function QuestionTabs({ tabs, defaultTab = 0 }: QuestionTabsProps) {
  const [activeTab, setActiveTab] = useState(tabs[defaultTab]);

  const content = useMemo(
    () => tabs.find(({ key }) => key === activeTab.key)?.children,
    [activeTab, tabs]
  );

  return (
    <Container>
      <TabList>
        {tabs.map((tab) => (
          <Tab
            key={tab.key}
            color={tab.color}
            isActive={activeTab.key === tab.key}
            onClick={() => setActiveTab(tab)}
          >
            <TabIcon>
              <Icon
                width={20}
                height={20}
                icon={tab.icon}
                color={activeTab.key === tab.key ? "white" : "darkGrey"}
              />
            </TabIcon>

            {tab.title}
          </Tab>
        ))}
      </TabList>
      <ContentWrapper color={activeTab.color}>{content}</ContentWrapper>
    </Container>
  );
}
