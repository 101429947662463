import { forwardRef } from "react";
import { useId } from "@reach/auto-id";

import { getVariant } from "@utils/style-utils";

import { FieldLabel } from "@components/FieldLabel";
import { FieldCaption, FieldVariant } from "@components/FieldCaption";
import { Icon } from "@components/Icon";
import { ProgressBar } from "@components/ProgressBar";
import { Box } from "@components/Box";

import { InputProps } from "./Input.types";
import {
  IconLeftWrapper,
  IconRightWrapper,
  InputContainer,
  InputWrapper,
  StyledInput,
} from "./Input.styles";

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      isError,
      errorMessage,
      caption,
      prefilled,
      isValid,
      disabled,
      iconLeft,
      progress,
      onIconClick,
      iconRight,
      isRequired,
      ...props
    },
    ref
  ) => {
    const id = useId(props.id);
    const variant = getVariant<FieldVariant>({
      [FieldVariant.Primary]: !!prefilled,
      [FieldVariant.Disabled]: !!disabled,
      [FieldVariant.Error]: isError || !!errorMessage,
      [FieldVariant.Valid]: !!isValid,
      [FieldVariant.Question]: false,
    });

    return (
      <InputContainer>
        {label && (
          <FieldLabel htmlFor={id} isRequired={isRequired}>
            {label}
          </FieldLabel>
        )}

        <InputWrapper>
          {iconLeft && (
            <IconLeftWrapper onClick={onIconClick}>
              <Icon aria-hidden icon={iconLeft} color="grey" />
            </IconLeftWrapper>
          )}

          <StyledInput
            ref={ref}
            id={id}
            disabled={disabled}
            {...props}
            $isError={isError || !!errorMessage}
          />

          {iconRight && (
            <IconRightWrapper onClick={onIconClick}>
              {typeof iconRight === "string" ? (
                <Icon aria-hidden icon={iconRight} color="grey" />
              ) : (
                iconRight
              )}
            </IconRightWrapper>
          )}
        </InputWrapper>

        {(caption || !!errorMessage) && (
          <>
            {!!progress && (
              <Box mt="s">
                <ProgressBar progress={progress} />
              </Box>
            )}
            <FieldCaption variant={variant}>
              {errorMessage || caption}
            </FieldCaption>
          </>
        )}
      </InputContainer>
    );
  }
);
