import styled from "styled-components";

import { HeaderContainerProps } from "./DashboardLayout.types";

export const Container = styled.div`
  padding-top: 90px;
`;

export const HeaderContainer = styled.div<HeaderContainerProps>`
  padding: ${({ theme }) => theme.spacing.m};

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    padding-top: ${({ theme }) => theme.spacing.xl};
    background: url(${({ backgroundImage }) => backgroundImage}) no-repeat
      center right;
  }
`;

export const HeaderInnerContainer = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.containerWidth};
  margin: 0 auto ${({ theme }) => theme.spacing.xs};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: 0;
  }

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    margin-top: ${({ theme }) => theme.spacing.xs};
  }
`;

export const HeaderColumn = styled.div`
  @media (${({ theme }) => theme.breakpoints.desktop}) {
    max-width: 50%;
  }
`;

export const ContentContainer = styled.div`
  position: relative;
  padding-right: ${({ theme }) => theme.spacing.m};
  padding-left: ${({ theme }) => theme.spacing.m};
`;
