import { useCallback, useState } from "react";

import { useGetExamCreator } from "@api/exam-creator/ExamCreator.queries";
import { ExamCreatorParams } from "@api/exam-creator/ExamCreator.types";
import { SortOrder } from "@utils/types";

import { DashboardLayout } from "@components/DashboardLayout";
import type { PaginationChangeHandler } from "@components/Pagination/Pagination.types";
import { ExamsList } from "@components/ExamsList";
import { ExamsTableSortHandler } from "@components/ExamsList/ExamsTable/ExamsTable.types";
import { Box } from "@components/Box";

import { PageHeader } from "./PageHeader";
import { ExamsFilters } from "./ExamsFilters";
import { Container } from "./Exams.styles";

export function Exams() {
  const [filters, setFilters] = useState<ExamCreatorParams>({
    perPage: 10,
    page: 1,
    ordering: "title",
    sortOrder: SortOrder.Ascending,
    subject: "",
  });
  const {
    data: examCreatorData,
    isLoading: isExamsLoading,
  } = useGetExamCreator(filters, {
    retry: false,
  });

  const handlePaginationChange: PaginationChangeHandler = useCallback(({ perPage, page }) => {
    setFilters(currentFilters => ({
      ...currentFilters,
      page,
      perPage: perPage || currentFilters.perPage,
    }));
  }, []);

  const handleSort = useCallback<ExamsTableSortHandler>(({ ordering, sortOrder}) => {
    setFilters(currentFilters => ({
      ...currentFilters,
      page: 1,
      ordering,
      sortOrder,
    }));
  }, []);

  return (
    <DashboardLayout
      backgroundImage="/img/exams-background.svg"
      headerComponent={<PageHeader examsCount={examCreatorData?.total} />}
      disableLoader
    >
      <Container>
        <Box mb="m">
          <ExamsFilters
            values={filters}
            onChange={setFilters}
          />
        </Box>

        <ExamsList
          exams={examCreatorData}
          isLoading={isExamsLoading}
          paginationProps={{
            perPage: filters.perPage,
            page: filters.page,
            total: examCreatorData?.total,
            onChange: handlePaginationChange,
          }}
          sortProps={{
            defaultColumn: filters.ordering,
            defaultOrder: filters.sortOrder,
            onSort: handleSort,
          }}
        />
      </Container>
    </DashboardLayout>
  );
}
