import { FormattedMessage, useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import { useHookFormMask } from "use-mask-input";
import { yupResolver } from "@hookform/resolvers/yup";

import { useAddPaper } from "@api/paper/Paper.queries";
import { Box } from "@components/Box";
import { Button, ButtonVariants } from "@components/Button";
import { ButtonsRow } from "@components/ButtonsRow";

import { AddPaperFormData, AddPaperFormProps } from "./AddPaperForm.types";
import { useAddPaperFormValidation } from "./AddPaperForm.validation";
import { CodeInput } from "./AddPaperForm.styles";

const maskOptions = {
  showMaskOnHover: false,
  showMaskOnFocus: false,
  jitMasking: true,
};

export function AddPaperForm({ onClose, onSuccess }: AddPaperFormProps) {
  const { formatMessage } = useIntl();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<AddPaperFormData>({
    mode: "onChange",
    resolver: yupResolver(useAddPaperFormValidation()),
  });
  const registerWithMask = useHookFormMask(register);

  const { mutate } = useAddPaper({
    onSuccess,
    onError: (error) => {
      setError("code", { message: error.response?.data.detail });
    },
  });

  const onSubmit = ({ code }: AddPaperFormData) => {
    mutate({ code: code.toLocaleUpperCase() });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <CodeInput
          {...registerWithMask("code", "****-****-****-****", maskOptions)}
          label={formatMessage({
            id: "addPaperForm.examPaperCodeLabel",
            defaultMessage: "Exam Paper Code",
          })}
          placeholder={formatMessage({
            id: "addPaperForm.examPaperCodePlaceholder",
            defaultMessage: "Type the code from your paper",
          })}
          errorMessage={errors?.code?.message}
        />
        <ButtonsRow>
          <Button type="submit" variant={ButtonVariants.Primary} block>
            <FormattedMessage
              id="addPaperForm.submitButton"
              defaultMessage="Add"
            />
          </Button>
          <Button variant={ButtonVariants.Secondary} onClick={onClose} block>
            <FormattedMessage
              id="addPaperForm.cancelButton"
              defaultMessage="Cancel"
            />
          </Button>
        </ButtonsRow>
      </Box>
    </form>
  );
}
