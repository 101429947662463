import { ModalProps } from "../../Modal/Modal.types";
import { AddQuestionFormProps } from "./AddQuestionForm";

export interface AddQuestionToExamProps
  extends Pick<ModalProps, "isOpen" | "onClose">,
    Pick<AddQuestionFormProps, "questions"> {
  examId?: string | null;
  afterUpdate: () => void;
}

export enum SubmissionStatus {
  SUCCESS = "success",
  ERROR = "error",
}

export type SubmissionState = {
  status: SubmissionStatus;
  detail?: string;
} | null;
