import { forwardRef } from "react";
import { useId } from "@reach/auto-id";
import { CheckboxProps } from "./Checkbox.types";
import {
  CheckboxWrapper,
  Error,
  HiddenInput,
  StyledLabel,
} from "./Checkbox.styles";

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ children, error, isRequired, ...props }, ref) => {
    const id = useId(props.id);

    return (
      <CheckboxWrapper>
        <HiddenInput type="checkbox" id={id} ref={ref} {...props} />

        <StyledLabel error={!!error} htmlFor={id} isRequired={isRequired}>
          {children}
        </StyledLabel>

        {error && <Error>{error}</Error>}
      </CheckboxWrapper>
    );
  }
);
