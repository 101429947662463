import { useGetHome } from "@api/home/Home.queries";

import { Loader } from "@components/Loader";
import { GenericErrorView } from "@components/ErrorView";
import { PageFooter } from "@components/PageFooter";

import { HomeMenu } from "./HomeMenu";
import { Hero } from "./Hero";
import { SectionList } from "./SectionList";

import { LoaderContainer } from "./Home.styles";

export function Home() {
  const { data, isLoading, isError } = useGetHome(undefined, { retry: false });

  if (isError) {
    return <GenericErrorView />;
  }

  if (isLoading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  return (
    <>
      <HomeMenu sections={data.data.attributes.sections} />

      <Hero data={data.data.attributes.hero} />

      <SectionList sections={data.data.attributes.sections} />

      <PageFooter />
    </>
  );
}
