import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Box } from "../../../../components/Box";
import { Button, ButtonVariants } from "../../../../components/Button";
import { Modal } from "../../../../components/Modal";
import { ModalProps } from "../../../../components/Modal/Modal.types";
import { Heading, Paragraph } from "../../../../components/Typography";
import { HeadingVariant } from "../../../../components/Typography/Heading/Heading.types";
import { Paths } from "../../../../utils/router";
import { IllustrationBox } from "./ExamCreatedModal.styles";

interface ExamCreatedModalProps extends Pick<ModalProps, "isOpen" | "onClose"> {
  examType?: string;
  level?: string;
  subject?: string;
  topic?: string;
  examId?: string;
}

export function ExamCreatedModal({
  isOpen,
  onClose,
  examType,
  level,
  subject,
  topic,
  examId,
}: ExamCreatedModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Box mb="l">
        <Heading as={HeadingVariant.H3} align="center">
          <FormattedMessage
            id="examCreatedModal.title"
            defaultMessage="New exam created"
          />
        </Heading>
      </Box>
      <IllustrationBox>
        <img src="/img/success-tick.svg" alt="Questions added" />
      </IllustrationBox>
      <Box mt="m">
        <Paragraph align="center">
          <FormattedMessage
            id="examCreatedModal.description"
            defaultMessage="Now you can add questions to this exam"
          />
        </Paragraph>
      </Box>
      <Box mt="l">
        <Box mb="s">
          <Paragraph align="center">
            <Link
              to={`${Paths.Questions}?${new URLSearchParams({
                examId: examId!,
                examType: examType!,
                level: level!,
                subject: subject!,
                topic: topic!,
              })}`}
              state={{ disabledFields: ["examType", "subject"] }}
            >
              <Button variant={ButtonVariants.Primary}>
                <FormattedMessage
                  id="examCreatedModal.goToQuestionsButton"
                  defaultMessage="Go to Questions by Topic"
                />
              </Button>
            </Link>
          </Paragraph>
        </Box>
        <Box>
          <Paragraph align="center">
            <FormattedMessage
              id="examCreatedModal.goToMyExams"
              defaultMessage="or go to {registerLink}"
              values={{
                registerLink: (
                  <Link to={Paths.MyExams}>
                    <FormattedMessage
                      id="examCreatedModal.myExamsLink"
                      defaultMessage="My Exams page"
                    />
                  </Link>
                ),
              }}
            />
          </Paragraph>
        </Box>
      </Box>
    </Modal>
  );
}
