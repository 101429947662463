import styled from "styled-components";
import { Box } from "../Box";
import { StrapiImage } from "../StrapiImage";

export const StyledFeatureBox = styled(Box).attrs({
  pt: "xl",
  pb: "xl",
  pl: "m",
  pr: "m",
})`
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 300px;
  border-top-right-radius: ${({ theme }) => theme.borderRadius.m};
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.m};
  text-align: center;
  border: ${({ theme }) => `1px solid ${theme.color.background.primary}`};
  background-color: ${({ theme }) => theme.color.background.light};
  flex-wrap: wrap;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    padding-top: ${({ theme }) => theme.spacing.l};
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const StyledIcon = styled(StrapiImage)`
  background-color: ${({ theme }) => theme.color.solid.white};
  display: flex;
  border-radius: 36px;

  & > img {
    padding: ${({ theme }) => theme.spacing.s};
    width: 72px;
    height: 72px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;
