import { useState, ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { Box } from "@components/Box";
import { Button, ButtonVariants } from "@components/Button";
import { Heading } from "@components/Typography";
import { HeadingVariant } from "@components/Typography/Heading/Heading.types";
import { AddPaperModal } from "@components/AddPaperModal";

import { Header, StyledPapersGrid } from "./PapersGrid.styles";

export interface PapersGridProps {
  children: ReactNode;
}

export function PapersGrid({ children }: PapersGridProps) {
  const [showAddPaper, setShowAddPaper] = useState(false);

  return (
    <>
      <Box mt="m">
        <Header gap="m">
          <Heading as={HeadingVariant.H4}>
            <FormattedMessage id="myPapers.title" defaultMessage="My Papers" />
          </Heading>

          <Button
            variant={ButtonVariants.Primary}
            isSmall
            onClick={() => setShowAddPaper(true)}
          >
            <FormattedMessage
              id="myPapers.addPaperButton"
              defaultMessage="Add a new paper"
            />
          </Button>
        </Header>

        <StyledPapersGrid>{children}</StyledPapersGrid>
      </Box>

      <AddPaperModal
        isOpen={showAddPaper}
        onClose={() => setShowAddPaper(false)}
      />
    </>
  );
}
