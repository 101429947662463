import { ComponentPropsWithoutRef } from "react";
import { Icons } from "../../icons";

export enum ButtonVariants {
  Primary = "primary",
  Secondary = "secondary",
  SecondaryWhite = "secondary_white",
  Tertiary = "tertiary",
}

export interface StyledButtonProps {
  block?: boolean;
  variant?: ButtonVariants;
  isSmall?: boolean;
  autoWidth?: boolean;
  iconRight?: Icons;
}

export const STYLED_BUTTON_PROPS_KEYS = [
  "block",
  "variant",
  "isSmall",
  "autoWidth",
  "iconRight",
];

export type ButtonProps = ComponentPropsWithoutRef<"button"> &
  StyledButtonProps;

export type ButtonLinkProps = ComponentPropsWithoutRef<"a"> & StyledButtonProps;
