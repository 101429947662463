import styled from "styled-components";

export const MyAccountRow = styled.div`
  display: flex;
  flex-direction: column;

  > * + * {
    margin-top: ${({ theme }) => theme.spacing.l};
  }

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row;

    > * + * {
      margin-top: 0;
      margin-left: ${({ theme }) => theme.spacing.l};
    }
  }
`;

export const ButtonsRow = styled.div`
  display: flex;
  justify-content: flex-end;

  margin-top: ${({ theme }) => theme.spacing.m};
`;
