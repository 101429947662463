import { useIntl } from "react-intl";
import * as yup from "yup";

export const useCreateNewPasswordFormValidation = (
  setPasswordStrength: (value: number) => void
) => {
  const { formatMessage } = useIntl();

  return yup.object({
    newPassword: yup
      .string()
      .max(
        30,
        formatMessage({
          id: "studentRegistrationForm.passwordTooLong",
          defaultMessage: "Password is too long, max 30 characters.",
        })
      )
      .test({
        message: formatMessage({
          defaultMessage:
            "Password must contain min 8 characters (capital letter and number) ",
          id: "studentRegistrationForm.passwordFieldHint",
        }),
        name: "strength",
        test: (value) => {
          if (!value) {
            setPasswordStrength(0);

            return false;
          }

          const tests = ["[a-z]+", "[A-Z]+", "[0-9]+", "^.{8,}"];
          let passed = 0;

          tests.forEach((test, index) => {
            if (new RegExp(tests[index]).test(value)) {
              passed += 1;
            }
          });

          setPasswordStrength((passed / tests.length) * 100);

          return passed === tests.length;
        },
      }),
    reNewPassword: yup.string().oneOf(
      [yup.ref("newPassword"), null],
      formatMessage({
        defaultMessage: "Passwords must match",
        id: "createNewPasswordForm.reNewPassword",
      })
    ),
  });
};
