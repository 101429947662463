import { FormattedMessage } from "react-intl";

import { Container, Ring } from "./ConsentButton.styles";

interface ConsentButtonProps {
  isLoading: boolean;
}

export function ConsentButton({ isLoading }: ConsentButtonProps) {
  return (
    <Container>
      <FormattedMessage
        id="cookieBox.consentButton"
        defaultMessage="I understand"
      />

      {isLoading && (
        <Ring>
          <div />
          <div />
          <div />
          <div />
        </Ring>
      )}
    </Container>
  );
}
