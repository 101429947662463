import { useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";

import { useGetUserSubjects } from "@api/paper/Paper.queries";
import { Paths } from "@utils/router";

import { DashboardLayout } from "@components/DashboardLayout";
import { PaperBox } from "@components/PaperBox";
import { PapersGrid } from "@components/PapersGrid";

import { Container } from "../Dashboard.styles";
import { PaperBoxWrapper } from "./ExamGuide.styles";

export function ExamGuide() {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { data } = useGetUserSubjects("active");

  return (
    <DashboardLayout
      title={formatMessage({
        id: "examGuide.title",
        defaultMessage: "Exam Guide",
      })}
      description={formatMessage({
        id: "examGuide.description",
        defaultMessage:
          "Here you can find all of your exam papers. Simply select an exam paper to view the relevant exam guide. Also you can redeem additional exam paper codes to gain access to all associated questions, solutions and marking schemes.",
      })}
      backgroundImage="/img/exam-guide-background.svg"
    >
      <Container>
        <PapersGrid>
          {data?.map(({ id, subject, level }) => (
            <PaperBoxWrapper
              key={`${id}-${level.id}`}
              onClick={() =>
                navigate(
                  generatePath(Paths.PaperExamGuide, { subjectLevelId: id })
                )
              }
            >
              <PaperBox
                paper={subject.name}
                type={subject.examType.shortName}
                level={level.name}
                coverImage={subject.icon}
                imageToBottom={false}
                disableHover
              />
            </PaperBoxWrapper>
          ))}
        </PapersGrid>
      </Container>
    </DashboardLayout>
  );
}
