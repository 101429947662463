import styled from "styled-components";

import { Row } from "@components/Row";

export const QuestionHeader = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row;
  }
`;

export const QuestionTitleWrapper = styled(Row)`
  > svg {
    flex-shrink: 0;
  }
`;

export const QuestionTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SelectForExamWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.s};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    margin: 3px 0 0 ${({ theme }) => theme.spacing.l};
  }
`;

export const TabContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
`;

export const Subtitle = styled.span`
  margin-right: ${({ theme }) => theme.spacing.s};
`;
