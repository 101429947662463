import { Box } from "../Box";
import { Heading, Paragraph } from "../Typography";
import { HeadingVariant } from "../Typography/Heading/Heading.types";
import {
  StyledIcon,
  StyledFeatureBox,
  Column,
  ContentContainer,
} from "./FeatureBox.styles";
import { FeatureBoxProps } from "./FeatureBox.types";

export function FeatureBox({ icon, title, description }: FeatureBoxProps) {
  return (
    <StyledFeatureBox>
      <Column>
        <Box mb="m">
          <StyledIcon url={icon.url} formats={icon.formats} />
        </Box>
        <ContentContainer>
          <Heading align="center" as={HeadingVariant.H6}>
            {title}
          </Heading>
          <Box mt="s">
            <Paragraph align="center">{description}</Paragraph>
          </Box>
        </ContentContainer>
      </Column>
    </StyledFeatureBox>
  );
}
