import { exportEndpointsEnum } from "../../utils/helpers";

enum PaperEndpointsEnum {
  Questions = `/questions/`,
  QuestionsFilters = `/questions/filters/`,
  UserSubjects = `/user/subjects/`,
  Solutions = `/solutions/`,
  SolutionsFilters = `/solutions/filters/`,
  MatchExamCode = `/match-exam-code/`,
  ExamGuide = `/exam-guide/`,
  Search = `/search/`
}

export const PaperEndpoints = exportEndpointsEnum<typeof PaperEndpointsEnum>(
  "paper",
  PaperEndpointsEnum
);
