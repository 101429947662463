import { StrapiImageResponse } from "./Strapi.types";

export enum HomeSectionTypes {
  ThreeImagesSection = "sections.three-images-section",
  TilesSection = "sections.tiles-section",
  VideoSection = "sections.video-section",
  ParagraphRowSection = "sections.paragraph-row-section",
  ParagraphColumnSection = "sections.paragraph-column-section",
}

export type HomeSectionCommon = {
  id: number;
  __component: HomeSectionTypes;
  menu?: {
    id: number;
    title: string;
    HTMLTagId: string;
  };
};

export enum HomeSectionAlign {
  left = "left",
  right = "right",
}

export type HomeSections = (
  | ThreeImagesSection
  | TilesSection
  | ParagraphRowSection
  | ParagraphColumnSection
  | VideoSection
)[];

export type HomeResponse = {
  data: {
    id: number;
    attributes: {
      createdAt: string;
      updatedAt: string;
      publishedAt: string;
      hero: HeroSection;
      sections: HomeSections;
    };
  };
};

export type HeroSection = {
  id: number;
  title: string;
  actionButtonTitle?: string;
  actionButtonURL?: string;
  heroImage: {
    data: {
      id: number;
      attributes: StrapiImageResponse;
    };
  };
};

export type ThreeImagesSection = HomeSectionCommon & {
  title: string;
  description: string;
  label: string;
  align: HomeSectionAlign;
  imageLeft: {
    data: { attributes: StrapiImageResponse };
  };
  imageCenter: {
    data: { attributes: StrapiImageResponse };
  };
  imageRight: {
    data: { attributes: StrapiImageResponse };
  };
};

export type TilesSectionItem = {
  id: number;
  title: string;
  description: string;
  icon: {
    data: { attributes: StrapiImageResponse };
  };
};

export type TilesSection = HomeSectionCommon & {
  title: string;
  description: string;
  label: string;
  tiles: Array<TilesSectionItem>;
};

export type Paragraph = {
  id: number;
  title: string;
  description: string;
};

export type ParagraphRowSection = HomeSectionCommon & {
  title: string;
  description: string;
  label: string;
  actionButtonTitle?: string;
  actionButtonURL?: string;
  image: {
    data: { attributes: StrapiImageResponse };
  };
  paragraphs: Array<Paragraph>;
};

export type ParagraphColumnSection = HomeSectionCommon & {
  title: string;
  label: string;
  actionButtonTitle?: string;
  actionButtonURL?: string;
  image: {
    data: { attributes: StrapiImageResponse };
  };
  columnLeft: Array<Paragraph>;
  columnRight: Array<Paragraph>;
};

export type VideoSection = HomeSectionCommon & {
  title: string;
  description?: string;
  label: string;
  vimeoVideoID: string;
  align: HomeSectionAlign;
};
