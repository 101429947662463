import { useRef } from "react";
import { FormattedMessage } from "react-intl";

import { ButtonAnchor, ButtonVariants } from "@components/Button";
import { MaxWidthContainer } from "@components/HomeLayout/HomeLayout.styles";

import { HeroProps } from "./Hero.types";
import {
  ColumnLeft,
  ColumnRight,
  Container,
  DevicesImage,
  DevicesImageContainer,
  HeroContainer,
  HeroHeading,
  HeroImage,
  HeroImageMobileContainer,
  PaperPlaneImage,
  ScrollDownButton,
  ScrollDownButtonContainer,
} from "./Hero.styles";

export function Hero({ data }: HeroProps) {
  const heroRef = useRef<HTMLDivElement>(null);

  const onScrollDownClick = () => {
    window.scroll({
      top: (heroRef.current?.nextSibling as HTMLElement).offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <MaxWidthContainer ref={heroRef}>
      <Container>
        <HeroContainer>
          <ColumnLeft>
            <HeroHeading>{data.title}</HeroHeading>

            <HeroImageMobileContainer>
              <HeroImage
                alt={data.heroImage.data.attributes.alternativeText}
                formats={data.heroImage.data.attributes.formats}
                url={data.heroImage.data.attributes.url}
              />
            </HeroImageMobileContainer>

            <DevicesImageContainer>
              <DevicesImage src="/img/home-hero-devices.svg" />
            </DevicesImageContainer>

            {data?.actionButtonTitle && data?.actionButtonURL && (
              <ButtonAnchor
                href={data.actionButtonURL}
                variant={ButtonVariants.Primary}
              >
                {data.actionButtonTitle}
              </ButtonAnchor>
            )}

            <ScrollDownButtonContainer>
              <ScrollDownButton
                iconRight="ArrowDown"
                variant={ButtonVariants.Tertiary}
                onClick={onScrollDownClick}
              >
                <FormattedMessage
                  id="homeHeroSection.scrollDownButton"
                  defaultMessage="Scroll down"
                />
              </ScrollDownButton>
            </ScrollDownButtonContainer>
          </ColumnLeft>

          <ColumnRight>
            <HeroImage
              alt={data.heroImage.data.attributes.alternativeText}
              formats={data.heroImage.data.attributes.formats}
              url={data.heroImage.data.attributes.url}
              fit="contain"
            />
          </ColumnRight>

          <PaperPlaneImage src="/img/paper-plane.svg" alt="Paper plane" />
        </HeroContainer>
      </Container>
    </MaxWidthContainer>
  );
}
