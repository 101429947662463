import { Box } from "@components/Box";
import { Heading, Paragraph } from "@components/Typography";
import { HeadingVariant } from "@components/Typography/Heading/Heading.types";

import { DescriptionContainer } from "./DefaultHeader.styles";

export interface DefaultHeaderProps {
  title: string;
  description?: string;
}

export function DefaultHeader({ title, description }: DefaultHeaderProps) {
  return (
    <>
      <Box>
        <Heading as={HeadingVariant.H3}>{title}</Heading>
      </Box>

      {!!description && (
        <DescriptionContainer>
          <Paragraph>{description}</Paragraph>
        </DescriptionContainer>
      )}
    </>
  );
}
