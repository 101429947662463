import { useIntl } from "react-intl";
import * as yup from "yup";

const codeRegex = /^[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}/i;

export const useAddPaperFormValidation = () => {
  const { formatMessage } = useIntl();

  return yup.object({
    code: yup
      .string()
      .matches(
        codeRegex,
        formatMessage({
          id: "addPaperForm.emailRequired",
          defaultMessage: "Please enter the correct paper code",
        })
      )
      .required(
        formatMessage({
          id: "addPaperForm.codeRequired",
          defaultMessage: "Paper code is a required field",
        })
      ),
  });
};
