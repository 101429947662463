import styled from "styled-components";

export const PDFWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;

  .react-pdf {
    &__Document {
      min-height: min-content;
      max-width: 100%;
    }

    &__Page {
      border: 1px solid ${({ theme }) => theme.color.stroke.grey};
      margin: 16px 0;
    }

    &__Page__canvas {
      margin: 0 auto;
    }

    &__Page__svg {
      & svg {
        max-width: 100%;
      }
    }

    &__Page__textContent,
    &__Page__annotations {
      display: none;
    }

    @media (${({ theme }) => theme.breakpoints.mobile}) {
      &__Page__canvas {
        width: 100% !important;
        height: auto !important;
      }
    }
  }
`;
