import { ReactNode } from "react";
import { useAccordion } from "../../Accordion";
import { ContentWrapper } from "./Content.styles";

interface PanelContentProps {
  children: ReactNode;
  index?: number;
}

export function PanelContent({ children, index = 0 }: PanelContentProps) {
  const { activeIndices } = useAccordion();

  if (!activeIndices[index]) {
    return null;
  }

  return <ContentWrapper>{children}</ContentWrapper>;
}
