import { useMemo, createContext, useContext, useState, ReactNode } from "react";
import {
  QuestionsFilters,
  QuestionsFiltersFilters,
} from "../../api/paper/Paper.types";

type Filters = Partial<Omit<QuestionsFilters, "page" | "perPage" | "ordering">>;

type QuestionsFiltersContextType = {
  filters: Filters;
  changeFilters: (filters: Partial<QuestionsFiltersFilters>) => void;
};

interface QuestionsFiltersProviderProps {
  children: ReactNode;
  defaultFilters?: Filters;
}

export const QuestionsFiltersContext =
  createContext<QuestionsFiltersContextType>({} as QuestionsFiltersContextType);

function QuestionsFiltersProvider({
  children,
  defaultFilters,
}: QuestionsFiltersProviderProps) {
  const [filters, setFilters] = useState<Filters>(defaultFilters || {});

  const changeFilters = (newFilters: Partial<QuestionsFiltersFilters>) => {
    setFilters(newFilters);
  };

  const providerValues = useMemo(
    () => ({
      filters,
      changeFilters,
    }),
    [filters]
  );

  return (
    <QuestionsFiltersContext.Provider value={providerValues}>
      {children}
    </QuestionsFiltersContext.Provider>
  );
}

const useQuestionsFilters = () => useContext(QuestionsFiltersContext);

export { QuestionsFiltersProvider, useQuestionsFilters };
