import { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

import { Paths } from "@utils/router";
import { useBreakpoints } from "@utils/use-breakpoints";

import { ButtonVariants } from "@components/Button";
import { MaxWidthContainer } from "@components/HomeLayout/HomeLayout.styles";
import { HamburgerButton, MenuLink } from "@components/Menu/Menu.styles";
import { MobileMenu } from "@components/Menu/MobileMenu";

import { Logo } from "./Logo";
import { MenuProps } from "./HomeMenu.types";
import {
  ButtonsContainer,
  Container,
  InnerContainer,
  LinksContainer,
  MenuButton,
  StyledLink,
} from "./HomeMenu.styles";

export function HomeMenu({ sections }: MenuProps) {
  const { isDesktopPlus } = useBreakpoints();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const mobileMenuItems = useMemo(
    () =>
      sections
        .filter((section) => section.menu)
        .map((item) => ({
          to: `#${item.menu?.HTMLTagId || ""}`,
          label: item.menu?.title || "",
        })),
    [sections]
  );

  const Buttons = (
    <ButtonsContainer>
      <MenuButton to={Paths.Login} variant={ButtonVariants.Secondary} isSmall>
        <FormattedMessage id="menu.loginButton" defaultMessage="Log in" />
      </MenuButton>

      <MenuButton to={Paths.StudentRegistration} isSmall>
        <FormattedMessage id="menu.registerButton" defaultMessage="Register" />
      </MenuButton>
    </ButtonsContainer>
  );

  if (isDesktopPlus) {
    return (
      <MaxWidthContainer>
        <Container>
          <InnerContainer>
            <Logo />

            <LinksContainer>
              {sections.map((section) => {
                if (section.menu) {
                  return (
                    <StyledLink
                      key={section.menu.HTMLTagId}
                      href={`#${section.menu.HTMLTagId}`}
                    >
                      <MenuLink isActive={false}>{section.menu.title}</MenuLink>
                    </StyledLink>
                  );
                }

                return null;
              })}
            </LinksContainer>

            {Buttons}
          </InnerContainer>
        </Container>
      </MaxWidthContainer>
    );
  }

  return (
    <MaxWidthContainer>
      <Container>
        <InnerContainer>
          <Logo />

          {Buttons}

          <HamburgerButton onClick={() => setIsMobileMenuOpen(true)} />

          <MobileMenu
            isOpen={isMobileMenuOpen && !isDesktopPlus}
            onClose={() => setIsMobileMenuOpen(false)}
            menuItems={mobileMenuItems}
            useAnchorTags
          />
        </InnerContainer>
      </Container>
    </MaxWidthContainer>
  );
}
