import styled, { css } from "styled-components";

import { Row } from "@components/Row";

import { MenuLinkProps } from "./Menu.types";

export const Container = styled.div`
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.m};
  box-shadow: ${({ theme }) => theme.shadow.s};
  background-color: ${({ theme }) => theme.color.solid.white};
`;

export const InnerContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: ${({ theme }) => theme.containerWidth};
`;

export const StyledLogo = styled.img`
  width: 137px;
  height: 36px;
`;

export const LinksWrapper = styled(Row)`
  flex-grow: 1;
  margin-right: ${({ theme }) => theme.spacing.xl};
`;

export const MenuLink = styled.span<MenuLinkProps>`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  font-weight: ${({ theme }) => theme.typography.weights.normal};
  color: ${({ theme }) => theme.color.solid.darkGrey};
  transition: color 0.25s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.color.solid.primary};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      font-weight: ${({ theme }) => theme.typography.weights.bold};
      color: ${({ theme }) => theme.color.solid.primary};
    `}
`;

export const HamburgerButtonWrapper = styled.div`
  @media (${({ theme }) => theme.breakpoints.tablet}) {
    margin-left: ${({ theme }) => theme.spacing.xl};
  }
`;

export const HamburgerButton = styled.button`
  border: 0;
  background: transparent;
  width: 40px;
  height: 40px;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='15' viewBox='0 0 16 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='0.5' width='16' height='2' rx='1' fill='%2388899F'/%3E%3Crect y='6.5' width='16' height='2' rx='1' fill='%2388899F'/%3E%3Crect y='12.5' width='16' height='2' rx='1' fill='%2388899F'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center center;
  padding: ${({ theme }) => theme.spacing.s};
`;

export const MenuContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-grow: 1;
  height: 100%;
  margin-left: ${({ theme }) => theme.spacing.xl};
`;
