import styled, { css } from "styled-components";
import { ParagraphProps, ParagraphVariants } from "./Paragraph.types";

export const Paragraph = styled.p<ParagraphProps>`
  margin: 0;
  color: ${({ color, theme }) =>
    color ? theme.color.solid[color] : theme.color.solid.darkGrey};
  text-align: ${({ align = "left" }) => align};

  ${({ variant = ParagraphVariants.Big }) => {
    switch (variant) {
      case ParagraphVariants.Big:
        return css`
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        `;
      case ParagraphVariants.Small:
        return css`
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.25px;
        `;
      default:
        return "";
    }
  }}
`;
