import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    min-height: 100%;
    flex-direction: row;
  }
`;

export const Column = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    justify-content: center;
    max-width: 50%;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;

  padding-top: ${({ theme }) => theme.spacing.l};
  padding-bottom: ${({ theme }) => theme.spacing.l};

  background-color: #f0f3ff;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
    align-items: center;
  }

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    position: absolute;
    top: 0;
    z-index: 1;

    flex-direction: row;
    align-items: flex-start;
    align-self: center;

    background-color: ${({ theme }) => theme.color.solid.white};
    width: 100%;
  }
`;

export const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  img {
    display: block;
  }

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    justify-content: space-around;
  }
`;

export const Logo = styled.img.attrs({
  src: "/img/logo-big.svg",
})`
  width: 110px;
  height: auto;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    width: 152px;
  }

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    width: 176px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;

  padding: ${({ theme: { spacing } }) =>
    `${spacing.l} ${spacing.m} ${spacing.xl}`};

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    padding-top: 154px;
  }
`;

export const ContentInner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    max-width: 504px;
  }

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    max-width: 412px;
  }
`;
