import { FormattedMessage } from "react-intl";

import { Box } from "@components/Box";
import { Button, ButtonVariants } from "@components/Button";
import { Modal } from "@components/Modal";
import { ModalProps } from "@components/Modal/Modal.types";
import { Heading, Paragraph } from "@components/Typography";
import { HeadingVariant } from "@components/Typography/Heading/Heading.types";
import { ButtonsRow } from "@components/ButtonsRow";

import { IllustrationBox } from "./DeleteConfimation.styles";

import { ReactComponent as QuestionMark } from "./question-mark.svg";

interface DeleteConfirmationProps
  extends Pick<ModalProps, "isOpen" | "onClose"> {
  onDelete: () => void;
}

export function DeleteConfirmation({
  isOpen,
  onClose,
  onDelete,
}: DeleteConfirmationProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Box mb="m">
        <Heading as={HeadingVariant.H3} align="center">
          <FormattedMessage
            id="deleteConfirmation.title"
            defaultMessage="Delete exam"
          />
        </Heading>
      </Box>
      <IllustrationBox pt="m" pb="m">
        <QuestionMark />
      </IllustrationBox>
      <Box>
        <Paragraph align="center">
          <FormattedMessage
            id="deleteConfirmation.description"
            defaultMessage="Are you sure you want to delete an exam? This cannot be undone."
          />
        </Paragraph>
      </Box>
      <ButtonsRow>
        <Button variant={ButtonVariants.Primary} block onClick={onDelete}>
          <FormattedMessage
            id="deleteConfirmation.deleteButton"
            defaultMessage="Yes, delete"
          />
        </Button>
        <Button
          type="button"
          onClick={onClose}
          variant={ButtonVariants.Secondary}
          block
        >
          <FormattedMessage
            id="deleteConfirmation.cancelButton"
            defaultMessage="Cancel"
          />
        </Button>
      </ButtonsRow>
    </Modal>
  );
}
