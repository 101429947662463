import { FormattedMessage } from "react-intl";
import { Link, generatePath } from "react-router-dom";

import { SearchResultResponse } from "@api/paper/Paper.types";
import { Paths } from "@utils/router";
import { Icon } from "@components/Icon";
import { Paragraph, Subtitle } from "@components/Typography";
import { SubtitleVariant } from "@components/Typography/Subtitle/Subtitle.types";
import { ParagraphVariants } from "@components/Typography/Paragraph/Paragraph.types";

import {
  DropdownContainer,
  EmptyStateWrapper,
  QuestionCaption,
  SeeAllResultsWrapper,
  StyledDropdown,
  StyledResult,
} from "./SuggestionsDropdown.styles";

interface SuggestionsDropdownProps {
  query: string;
  isOpen: boolean;
  suggestions?: SearchResultResponse;
}

export function SuggestionsDropdown({
  query,
  isOpen,
  suggestions,
}: SuggestionsDropdownProps) {
  if (!isOpen) {
    return null;
  }

  const hasMoreResults =
    suggestions && suggestions?.total > suggestions?.data.length;

  if (suggestions && suggestions.data.length > 0) {
    return (
      <DropdownContainer>
        <StyledDropdown>
          {suggestions.data.map(
            ({
              id,
              number,
              paper,
              section,
              year,
              topic: {
                subjectLevel: {
                  name: subjectLevelName,
                  shortName: subjectLevelShortName,
                  levelName: subjectLevelLevelName,
                },
              },
            }) => (
              <StyledResult key={id}>
                <QuestionCaption>
                  <FormattedMessage
                    id="suggestionsDropdown.questionCaption"
                    defaultMessage="Question"
                  />
                </QuestionCaption>
                <Link
                  to={`${Paths.Questions}?${new URLSearchParams({
                    questionId: id,
                  })}`}
                >
                  <FormattedMessage
                    id="suggestionsDropdown.questionTitle"
                    defaultMessage="{subjectLevelShortName} {subjectLevelName} {subjectLevelLevelName} Level Question {number}, Paper {paper}, Section {section}, {year}"
                    values={{
                      number,
                      paper,
                      section,
                      year,
                      subjectLevelShortName,
                      subjectLevelName,
                      subjectLevelLevelName,
                    }}
                  />
                </Link>
              </StyledResult>
            )
          )}

          {hasMoreResults && (
            <SeeAllResultsWrapper>
              <Link to={generatePath(Paths.SearchResults, { query })}>
                <FormattedMessage
                  id="suggestionsDropdown.seeAllResults"
                  defaultMessage="See all results"
                />
              </Link>
            </SeeAllResultsWrapper>
          )}
        </StyledDropdown>
      </DropdownContainer>
    );
  }

  return (
    <DropdownContainer>
      <StyledDropdown>
        <EmptyStateWrapper>
          <Icon icon="Search" color="grey" width={24} height={24} />
          <Subtitle
            variant={SubtitleVariant.Second}
            align="center"
            color="darkGrey"
            bold
          >
            <FormattedMessage
              id="suggestionsDropdown.noResultsTitle"
              defaultMessage="No results found"
            />
          </Subtitle>
          <Paragraph variant={ParagraphVariants.Small}>
            <FormattedMessage
              id="suggestionsDropdown.noResultsSubtitle"
              defaultMessage="Try adjusting your search to find what you’re looking for."
            />
          </Paragraph>
        </EmptyStateWrapper>
      </StyledDropdown>
    </DropdownContainer>
  );
}
