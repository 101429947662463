import styled from "styled-components";

import { Modal } from "@components/Modal";
import { InnerContainer } from "@components/Modal/Modal.styles";
import { ButtonsRow } from "@components/ButtonsRow";

export const StickyButtonsRow = styled(ButtonsRow)`
  position: sticky;
  bottom: -24px;
  padding: ${({ theme }) => theme.spacing.m} 0;
  background-color: ${({ theme }) => theme.color.solid.white};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    bottom: -56px;
  }
`;

export const StyledModal = styled(Modal)`
  ${InnerContainer} {
    display: block;
    overflow-y: scroll;
    justify-content: flex-start;

    @media (${({ theme }) => theme.breakpoints.tablet}) {
      padding-right: ${({ theme }) => theme.spacing.l};
      padding-left: ${({ theme }) => theme.spacing.l};
    }
  }
`;
