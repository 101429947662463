import { Menu, MenuButton } from "@reach/menu-button";
import "@reach/menu-button/styles.css";
import {
  DropdownButton,
  DropdownList,
  DropdownListItem,
  StyledMenu,
} from "./Dropdown.styles";
import { DropdownProps } from "./Dropdown.types";

export function Dropdown({ children, menuItems }: DropdownProps) {
  return (
    <StyledMenu>
      <Menu>
        {({ isExpanded }) => (
          <>
            <MenuButton>
              <DropdownButton isExpanded={isExpanded}>
                {children}
              </DropdownButton>
            </MenuButton>
            <DropdownList>
              {menuItems.map((menuItem) => (
                <DropdownListItem {...menuItem} />
              ))}
            </DropdownList>
          </>
        )}
      </Menu>
    </StyledMenu>
  );
}
