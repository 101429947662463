import styled from "styled-components";
import { LoaderProps } from "./Loader.types";

export const Loader = styled.div<LoaderProps>`
  z-index: 2;

  width: ${({ size = 48 }) => `${size}px`};
  height: ${({ size = 48 }) => `${size}px`};

  position: ${({ position = "relative" }) => position};
  top: calc(50% - ${({ size = 48 }) => `${size / 2}px`});
  left: calc(50% - ${({ size = 48 }) => `${size / 2}px`});

  border:  ${({ theme, variant = "primary", width = 5 }) =>
    `${width}px solid ${theme.color.solid[variant]}`};
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
`;
