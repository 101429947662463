import styled, { css } from "styled-components";

export const MaxWidthContainer = styled.div`
  max-width: ${({ theme }) =>
    parseInt(theme.containerWidth, 10) +
    2 * parseInt(theme.spacing.xl, 10)}px; /* 2 x padding xl */
  margin: 0 auto;
`;

export const StickToSideContainer = styled.div<{ stickLeft: boolean }>`
  padding-top: ${({ theme }) => theme.spacing.xl};
  margin-right: 0;
  margin-left: 0;
  padding-left: ${({ theme, stickLeft }) => (stickLeft ? 0 : theme.spacing.l)};
  padding-right: ${({ theme, stickLeft }) => (stickLeft ? theme.spacing.l : 0)};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    padding-top: ${({ theme }) => theme.spacing.xxl};
  }

  @media (${({ theme }) => theme.breakpoints.desktopPlus}) {
    padding-left: ${({ theme, stickLeft }) =>
      stickLeft ? 0 : theme.spacing.xl};
    padding-right: ${({ theme, stickLeft }) =>
      stickLeft ? theme.spacing.xl : 0};
  }

  @media (min-width: ${({ theme }) =>
      parseInt(theme.containerWidth, 10) +
      2 * parseInt(theme.spacing.xl, 10)}px) {
    ${({ stickLeft, theme }) =>
      stickLeft
        ? css`
            padding-left: 0;
            padding-right: calc((100vw - ${theme.containerWidth}) / 2);
          `
        : css`
            padding-left: calc((100vw - ${theme.containerWidth}) / 2);
            padding-right: 0;
          `}
  } ;
`;

export const StickToSideBackgroundContainer = styled.div<{
  stickLeft: boolean;
}>`
  background-color: ${({ theme }) => theme.color.background.light};
  border-top-left-radius: ${({ theme }) => theme.borderRadius.xl};
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.xl};
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  ${({ stickLeft }) =>
    stickLeft &&
    css`
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: ${({ theme }) => theme.borderRadius.xl};
      border-bottom-right-radius: ${({ theme }) => theme.borderRadius.xl};
    `}

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: ${({ stickLeft }) => (stickLeft ? "row-reverse" : "row")};
    display: flex;
  }
`;

export const StickToSideInnerContainer = styled.div<{ stickLeft: boolean }>`
  display: flex;
  flex-direction: column;
  max-width: ${({ theme }) => theme.containerWidth};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: ${({ stickLeft }) => (stickLeft ? "row-reverse" : "row")};
    justify-content: ${({ stickLeft }) =>
      stickLeft ? "flex-end" : "flex-start"};
  }
`;

export const StickToSideColumn = styled.div<{ stickLeft: boolean }>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding: ${({ theme }) => theme.spacing.l};
  padding-top: ${({ theme }) => theme.spacing.xl};
  flex: 1;

  @media (${({ theme }) => theme.breakpoints.desktopPlus}) {
    padding-left: ${({ theme, stickLeft }) =>
      stickLeft ? theme.spacing.l : theme.spacing.xxl};
    padding-right: ${({ theme, stickLeft }) =>
      stickLeft ? theme.spacing.xxl : theme.spacing.l};
  }
`;
