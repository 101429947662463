import { useCallback } from "react";

import { CaptionTextWrapper, StyledCaption } from "./FieldCaption.styles";
import { Icon } from "../Icon";
import {
  FieldCaptionProps,
  FieldCaptionSize,
  FieldCaptionVariant,
} from "./FieldCaption.types";

export function FieldCaption({
  children,
  variant,
  size = FieldCaptionSize.Small,
}: FieldCaptionProps) {
  const renderIcon = useCallback(() => {
    switch (variant) {
      case FieldCaptionVariant.Primary:
        return <Icon icon="Tick" color="primary" noBackground />;
      case FieldCaptionVariant.Valid:
        return <Icon icon="Tick" color="accept" noBackground />;
      case FieldCaptionVariant.Error:
        return <Icon icon="Alert" color="alert" noBackground />;
      case FieldCaptionVariant.Disabled:
        return <Icon icon="Info" color="darkGrey" noBackground />;
      case FieldCaptionVariant.Question:
        return <Icon icon="QuestionMark" color="primary" noBackground />;
      default:
        return <Icon icon="Info" color="darkGrey" noBackground />;
    }
  }, [variant]);

  return (
    <StyledCaption variant={variant} size={size}>
      {renderIcon()}
      <CaptionTextWrapper>{children}</CaptionTextWrapper>
    </StyledCaption>
  );
}
