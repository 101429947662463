import React, { ComponentType } from "react";
import { useId } from "@reach/auto-id";
import { GroupBase, OptionProps } from "react-select";

import { getVariant } from "@utils/style-utils";

import { FieldLabel } from "@components/FieldLabel";
import { Icon } from "@components/Icon";
import { FieldCaption, FieldVariant } from "@components/FieldCaption";

import { CustomOptionProps, SelectProps } from "./Select.types";
import {
  SelectWrapper,
  IconWrapper,
  StyledOption,
  StyledSelect,
} from "./Select.styles";

function CustomOption({
  children,
  data,
  innerProps,
  isDisabled,
  isFocused,
  isSelected,
}: CustomOptionProps) {
  if (isDisabled) {
    return null;
  }

  return (
    <StyledOption isSelected={isSelected} isFocused={isFocused} {...innerProps}>
      {data?.icon && (
        <IconWrapper isSelected={isSelected} isFocused={isFocused}>
          {" "}
          <Icon icon={data.icon} />
        </IconWrapper>
      )}
      {children}
    </StyledOption>
  );
}

function LoadingIndicator() {
  return <>Loading</>;
}

export const Select = React.forwardRef<
  any,
  Omit<SelectProps, "onChange"> & { onChange: (data: any) => void }
>(
  (
    {
      caption,
      className,
      defaultMenuIsOpen,
      defaultValue,
      errorMessage,
      filterOption,
      formatOptionLabel,
      hideButton = false,
      isDisabled,
      isLoading,
      isValid,
      isError,
      label,
      menuIsOpen,
      menuPlacement,
      menuPosition,
      menuShouldScrollIntoView,
      name,
      onBlur,
      onChange,
      onInputChange,
      options,
      placeholder,
      value,
      noOptionsMessage,
      onFocus,
      onKeyDown,
      isClearable,
      isSearchable,
      isRequired,
      maxWidth,
    },
    ref
  ) => {
    const id = useId();
    const variant = getVariant<Exclude<FieldVariant, FieldVariant.Primary>>({
      [FieldVariant.Disabled]: !!isDisabled,
      [FieldVariant.Error]: !!errorMessage || !!isError,
      [FieldVariant.Valid]: !!isValid,
      [FieldVariant.Question]: false,
    });

    return (
      <SelectWrapper maxWidth={maxWidth}>
        {label && (
          <FieldLabel htmlFor={id} isRequired={isRequired}>
            {label}
          </FieldLabel>
        )}

        <StyledSelect
          isClearable={isClearable}
          className={className}
          classNamePrefix="select"
          components={{
            LoadingIndicator,
            Option: CustomOption as ComponentType<
              OptionProps<unknown, boolean, GroupBase<unknown>>
            >,
          }}
          defaultMenuIsOpen={defaultMenuIsOpen}
          defaultValue={defaultValue}
          filterOption={filterOption}
          formatOptionLabel={formatOptionLabel}
          hideButton={hideButton}
          id={id}
          isDisabled={isLoading || isDisabled}
          isLoading={isLoading}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
          menuIsOpen={menuIsOpen}
          menuPlacement={menuPlacement}
          menuPosition={menuPosition}
          menuShouldScrollIntoView={menuShouldScrollIntoView}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onInputChange={onInputChange}
          options={options}
          placeholder={placeholder}
          variant={variant}
          value={value}
          ref={ref}
          noOptionsMessage={noOptionsMessage}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          isSearchable={isSearchable}
        />

        {(caption || errorMessage) && (
          <FieldCaption variant={variant}>
            {errorMessage || caption}
          </FieldCaption>
        )}
      </SelectWrapper>
    );
  }
);
