import { FallbackProps } from "react-error-boundary";

import { NotFound } from "@pages/notFound";

import { ErrorWithStatusCode } from "@utils/custom-error";

import { GenericErrorView } from "@components/ErrorView";

type ErrorBoundaryFallbackProps = FallbackProps | {
  error: ErrorWithStatusCode;
}

export function ErrorBoundaryFallback({
  error,
}: ErrorBoundaryFallbackProps) {
  switch (error instanceof ErrorWithStatusCode && error.statusCode) {
    case 404:
      return <NotFound />;

    default:
      return <GenericErrorView />;
  }
}
