import { MaxWidthContainer } from "@components/HomeLayout/HomeLayout.styles";
import { Heading, Overline, Paragraph } from "@components/Typography";
import { HeadingVariant } from "@components/Typography/Heading/Heading.types";
import { Box } from "@components/Box";
import { MarkdownParser } from "@components/MarkdownParser";

import { FeatureBoxesList } from "./FeatureBoxesList";

import { TilesSectionProps } from "./TilesSection.types";
import { Container, Content, InnerContainer } from "./TilesSection.styles";

export function TilesSectionComponent({ data }: TilesSectionProps) {
  return (
    <MaxWidthContainer>
      <Container>
        <InnerContainer>
          <Content>
            <Box mb="xs">
              <Overline>{data.label}</Overline>
            </Box>
            <Box mb="m">
              <Heading align="center" as={HeadingVariant.H2}>
                {data.title}
              </Heading>
            </Box>
            <Paragraph as="div" align="center">
              <MarkdownParser>{data.description}</MarkdownParser>
            </Paragraph>
          </Content>
          <FeatureBoxesList features={data.tiles} />
        </InnerContainer>
      </Container>
    </MaxWidthContainer>
  );
}
