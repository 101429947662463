import {
  Children,
  cloneElement,
  createContext,
  isValidElement,
  useCallback,
  useMemo,
  useState,
} from "react";
import { TabIndex } from "./Tab/Tab.types";

import { TabButton, TabsScroller, TabsWrapper } from "./Tabs.styles";
import { TabContextProps, TabsProps } from "./Tabs.types";

export const TabContext = createContext<TabContextProps>({
  selectedTab: 0,
  setSelectedTab: () => {},
});

export function Tabs({ defaultTab = 0, children, onChange }: TabsProps) {
  const [selectedTab, setSelectedTab] = useState<TabIndex>(defaultTab);
  const tabContextValue = useMemo(
    () => ({ selectedTab, setSelectedTab }),
    [selectedTab]
  );

  const childrenEnhanced = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, {
        isActive: child.props.index === selectedTab,
      });
    }

    return child;
  });

  const handleTabChange = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const index = e.currentTarget.value;

      setSelectedTab(index);

      if (onChange) {
        onChange(index);
      }
    },
    [onChange]
  );

  return (
    <TabContext.Provider value={tabContextValue}>
      <TabsScroller>
        <TabsWrapper>
          {Children.map(childrenEnhanced, (child) =>
            child?.props ? (
              <TabButton
                value={child.props.index}
                isActive={child.props.isActive!}
                onClick={handleTabChange}
              >
                {child.props.title}
              </TabButton>
            ) : null
          )}
        </TabsWrapper>
      </TabsScroller>

      {childrenEnhanced}
    </TabContext.Provider>
  );
}
