import styled, { SolidColors } from "styled-components";

export const EmptyListContainer = styled.div<{ variant?: SolidColors }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed
    ${({ variant = "primary", theme }) => theme.color.solid[variant]};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  margin-top: ${({ theme }) => theme.spacing.m};
  margin-bottom: ${({ theme }) => theme.spacing.m};
  padding: ${({ theme }) => `${theme.spacing.l} ${theme.spacing.m}`};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    padding: 56px 0;
  }

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    padding: 95px 0;
  }

  svg {
    max-width: 172px;
    height: auto;

    @media (${({ theme }) => theme.breakpoints.tablet}) {
      max-width: 100%;
    }
  }
`;
