import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  padding-right: ${({ theme }) => theme.spacing.m};
  padding-left: ${({ theme }) => theme.spacing.m};
  padding-top: ${({ theme }) => theme.spacing.xl};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    padding-right: ${({ theme }) => theme.spacing.l};
    padding-left: ${({ theme }) => theme.spacing.l};
    padding-top: ${({ theme }) => theme.spacing.xxl};
  }

  @media (${({ theme }) => theme.breakpoints.desktopPlus}) {
    padding-left: ${({ theme }) => theme.spacing.xl};
    padding-right: ${({ theme }) => theme.spacing.xl};
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Content = styled.div`
  max-width: 400px;
  text-align: center;
`;
