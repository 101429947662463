import {
  useEffect,
  useState,
  createContext,
  useCallback,
  useMemo,
  useContext,
} from "react";
import { useLocation } from "react-router-dom";
import throttle from "lodash.throttle";

import { Icon } from "@components/Icon";

import {
  BackToTopBottomOffset,
  BackToTopContextValue,
  BackToTopProps,
} from "./BackToTop.types";
import { Container, Inner, TopButton } from "./BackToTop.styles";

const SCROLL_TRESHOLD = 200;
const DEFAULT_BOTTOM_OFFSET = 80;

const BackToTopContext = createContext<BackToTopContextValue>({
  setBottomOffset: () => {},
});

function scrollToTop() {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
}

export function BackToTop({ children }: BackToTopProps) {
  const location = useLocation();

  const [bottomOffset, setBottomOffsetPrivate] =
    useState<BackToTopBottomOffset>(undefined);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = throttle(() => {
      setIsVisible(window.scrollY >= SCROLL_TRESHOLD);
    }, 200);

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setBottomOffsetPrivate(undefined);
  }, [location]);

  const setBottomOffset = useCallback<BackToTopContextValue["setBottomOffset"]>(
    (offset) => {
      setBottomOffsetPrivate(
        offset === true ? DEFAULT_BOTTOM_OFFSET : offset || undefined
      );
    },
    []
  );

  const contextValue = useMemo(
    () => ({
      setBottomOffset,
    }),
    [setBottomOffset]
  );

  return (
    <BackToTopContext.Provider value={contextValue}>
      {children}

      <Container offset={bottomOffset}>
        <Inner>
          <TopButton
            className={isVisible ? "visible" : ""}
            aria-label="Scroll to top"
            onClick={scrollToTop}
          >
            <Icon icon="ArrowUp" noBackground />
          </TopButton>
        </Inner>
      </Container>
    </BackToTopContext.Provider>
  );
}

export const useBackToTop = () => useContext(BackToTopContext);
