import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { yupResolver } from "@hookform/resolvers/yup";

import { CreateExamData } from "@api/exam-creator/ExamCreator.types";
import { useBreakpoints } from "@utils/use-breakpoints";

import { Button, ButtonVariants } from "@components/Button";
import { Heading } from "@components/Typography";
import { HeadingVariant } from "@components/Typography/Heading/Heading.types";
import { TopicSelect } from "@components/TopicSelect";
import { Box } from "@components/Box";
import { Input } from "@components/Input";
import { TextArea } from "@components/TextArea";

import { useCreateExamFormValidation } from "./CreateExamForm.validation";
import {
  ButtonsRow,
  Container,
  ExamNameWrapper,
} from "./CreateExamForm.styles";

interface CreateExamFormProps {
  onSubmit: (data: CreateExamData) => void;
  onCancel: () => void;
}

export function CreateExamForm({ onSubmit, onCancel }: CreateExamFormProps) {
  const { formatMessage } = useIntl();
  const { isMobile, isDesktop } = useBreakpoints();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<CreateExamData>({
    mode: "onChange",
    resolver: yupResolver(useCreateExamFormValidation()),
  });

  return (
    <>
      <Container>
        <Heading as={HeadingVariant.H5} color="primary">
          <FormattedMessage
            id="createExamForm.title"
            defaultMessage="Exam information"
          />
        </Heading>

        <Box>
          <Controller
            name="topic"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange } }) => (
              <TopicSelect onChange={onChange} />
            )}
          />
        </Box>

        <form id="create-exam-form" onSubmit={handleSubmit(onSubmit)}>
          <ExamNameWrapper>
            <Input
              isRequired
              {...register("title", { required: true })}
              label={formatMessage({
                id: "createExamForm.title",
                defaultMessage: "Exam name (max 200)",
              })}
              errorMessage={errors.title?.message}
            />
          </ExamNameWrapper>

          <Box>
            <TextArea
              rows={3}
              isRequired
              {...register("description", { required: true })}
              label={formatMessage({
                id: "createExamForm.description",
                defaultMessage: "Exam description (max 200)",
              })}
              error={errors.description?.message}
            />
          </Box>
        </form>
      </Container>

      <ButtonsRow gap="m" justify="center" reversed>
        <Button
          type="submit"
          form="create-exam-form"
          variant={ButtonVariants.Primary}
          isSmall={isMobile}
          disabled={!isValid}
          {...(isDesktop && { iconRight: "ArrowRight" })}
        >
          <FormattedMessage
            id="createExamForm.submitButton"
            defaultMessage="Create Exam"
          />
        </Button>
        <Button
          variant={ButtonVariants.Secondary}
          isSmall={isMobile}
          onClick={onCancel}
        >
          <FormattedMessage
            id="createExamForm.cancelButton"
            defaultMessage="Cancel"
          />
        </Button>
      </ButtonsRow>
    </>
  );
}
