import {
  Children,
  ReactElement,
  cloneElement,
  createContext,
  isValidElement,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import {
  AccordionContextProps,
  AccordionProps,
  ActiveIndices,
} from "./Accordion.types";

const AccordionContext = createContext<AccordionContextProps>({
  activeIndices: [],
  setActiveIndices: () => {},
});

export function Accordion({ children, defaultIndices }: AccordionProps) {
  const [activeIndices, setActiveIndices] = useState<ActiveIndices>(
    defaultIndices || new Array(Children.count(children)).fill(false)
  );

  useEffect(() => {
    if (!defaultIndices) {
      setActiveIndices(new Array(Children.count(children)).fill(false));
    }
  }, [children, defaultIndices]);

  const providerValues = useMemo(
    () => ({
      activeIndices,
      setActiveIndices,
    }),
    [activeIndices]
  );

  return (
    <AccordionContext.Provider value={providerValues}>
      {Children.map(children, (child, index) => {
        if (isValidElement(child)) {
          return cloneElement(child as ReactElement<{ index: number }>, {
            index,
          });
        }

        return child;
      })}
    </AccordionContext.Provider>
  );
}

export const useAccordion = () => useContext(AccordionContext);
