import printJS from "print-js";
import { useState } from "react";
import { generatePath, Link } from "react-router-dom";

import {
  useGetExamCreatorPDF,
  useRemoveExam,
} from "@api/exam-creator/ExamCreator.queries";
import { Exam } from "@api/exam-creator/ExamCreator.types";
import { Paths } from "@utils/router";
import { blobToBase64 } from "@utils/helpers";

import { Icon } from "@components/Icon";

import { DeleteConfirmation } from "./DeleteConfirmation";
import { PreviewModal } from "./PreviewModal";
import { Container, StyledButton } from "./ActionButtons.styles";

type ActionButtonsProps = Pick<Exam, "id"> & {
  className?: string;
};

export function ActionButtons({ id, className }: ActionButtonsProps) {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [pdfPreview, setPdfPreview] = useState<string | undefined>();
  const { refetch, data } = useGetExamCreatorPDF(id, {
    enabled: false,
  });
  const { mutate: removeExam } = useRemoveExam(id, {
    onSuccess: () => {
      setShowDeleteConfirmation(false);
    },
  });

  const getExamPDF = async () => {
    if (data) {
      return data;
    }

    const response = await refetch();

    return response.data;
  };

  const handlePreview = async () => {
    const pdf = await getExamPDF();

    const file = new Blob([pdf!], {
      type: "application/pdf",
    });

    const fileURL = await blobToBase64(file);

    setPdfPreview(fileURL as string);
  };

  const handlePrint = async () => {
    const pdf = await getExamPDF();

    const file = new Blob([pdf!], {
      type: "application/pdf",
    });
    const fileURL = URL.createObjectURL(file);

    printJS(fileURL);
  };

  const handleDownload = async () => {
    const pdf = await getExamPDF();
    const link = document.createElement("a");
    const file = new Blob([pdf!], {
      type: "application/pdf",
    });
    const fileURL = URL.createObjectURL(file);

    link.href = fileURL;
    link.download = `${id}.pdf`;

    link.click();
  };

  const handleDelete = () => {
    removeExam(id);
  };

  return (
    <Container className={className}>
      <StyledButton type="button" onClick={handlePreview}>
        <Icon icon="Eye" />
      </StyledButton>

      <StyledButton as={Link} to={generatePath(Paths.EditExam, { examId: id })}>
        <Icon icon="Pencil" />
      </StyledButton>

      <StyledButton type="button" onClick={handlePrint}>
        <Icon icon="Printer" />
      </StyledButton>

      <StyledButton type="button" onClick={handleDownload}>
        <Icon icon="Download" />
      </StyledButton>

      <StyledButton
        type="button"
        onClick={() => setShowDeleteConfirmation(true)}
      >
        <Icon icon="Trash" />
      </StyledButton>

      <DeleteConfirmation
        isOpen={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onDelete={handleDelete}
      />

      {!!pdfPreview && (
        <PreviewModal
          pdf={pdfPreview}
          onDownload={handleDownload}
          onClose={() => setPdfPreview(undefined)}
        />
      )}
    </Container>
  );
}
