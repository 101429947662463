import styled from "styled-components";

export const Container = styled.div`
  display: flex;
`;

export const StyledButton = styled.button`
  border: none;
  background: none;
  padding: 0;

  & {
    svg {
      stroke: ${({ theme }) => theme.color.solid.darkGrey};
    }

    &:hover,
    &:focus-visible {
      svg {
        stroke: ${({ theme }) => theme.color.solid.hover};
      }
    }

    & * {
      cursor: pointer;
    }
  }
`;
