import styled, { css, DefaultTheme } from "styled-components";

import { AlertVariant } from "./Alert.types";

const alertVariantStyles = (theme: DefaultTheme, variant: AlertVariant) =>
  ({
    [AlertVariant.Success]: css`
      background: ${theme.color.informative.acceptLight};
      border-color: ${theme.color.informative.accept};

      svg {
        stroke: ${theme.color.informative.accept};
      }
    `,
    [AlertVariant.Warning]: css`
      background: ${theme.color.informative.infoLight};
      border-color: ${theme.color.informative.info};

      svg {
        stroke: ${theme.color.informative.info};
      }
    `,
    [AlertVariant.Danger]: css`
      background: ${theme.color.informative.alertLight};
      border-color: ${theme.color.informative.alert};

      svg {
        stroke: ${theme.color.informative.alert};
      }
    `,
  }[variant]);

export const ChildrenWrapper = styled.div`
  display: block;
  flex-grow: 1;
  margin: 0 ${({ theme }) => theme.spacing.s};
  white-space: pre-line;
`;

export const StyledCloseButton = styled.button`
  display: flex;
  background: none;
  margin: 0;
  border: none;
  cursor: pointer;

  svg {
    stroke: ${({ theme }) => theme.color.solid.darkGrey} !important;

    &,
    & * {
      cursor: pointer;
    }
  }
`;

export const StyledAlert = styled.div<{ variant: AlertVariant }>`
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.color.solid.darkGrey};

  ${({ theme, variant }) => alertVariantStyles(theme, variant)}

  padding: ${({ theme }) => theme.spacing.s};

  border-width: 1px;
  border-style: solid;
  border-radius: 16px;

  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;

  > svg {
    flex-shrink: 0;
  }
`;
