import styled from "styled-components";

import { BackToTopBottomOffset } from "./BackToTop.types";

export const Container = styled.div<{ offset?: BackToTopBottomOffset }>`
  z-index: 1;
  position: sticky;
  bottom: ${({ offset, theme }) => (offset ? `${offset}px` : theme.spacing.mt)};
  padding: 0 ${({ theme }) => theme.spacing.m};
`;

export const Inner = styled.div`
  position: relative;
  width: 100%;
  max-width: ${({ theme }) => theme.containerWidth};
  margin: 0 auto;
`;

export const TopButton = styled.button.attrs({
  type: "button",
})`
  opacity: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.color.background.primary};
  padding: ${({ theme }) => theme.spacing.xxs};
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(110, 138, 247, 0.25);
  cursor: pointer;
  transition: opacity 200ms ease-in;
  pointer-events: none;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    width: 56px;
    height: 56px;
    padding: ${({ theme }) => theme.spacing.xs};
  }

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  > svg {
    width: 100%;
    height: 100%;
    stroke: ${({ theme }) => theme.color.solid.darkGrey};
  }

  * {
    cursor: pointer;
  }
`;
