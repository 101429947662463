import { useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useCreateExam } from "../../../api/exam-creator/ExamCreator.queries";
import { CreateExamResponse } from "../../../api/exam-creator/ExamCreator.types";
import { DashboardLayout } from "../../../components/DashboardLayout";
import { Container } from "../Dashboard.styles";
import { CreateExamForm } from "./CreateExamForm";
import { ExamCreatedModal } from "./ExamCreatedModal";
import { CancelCreationModal } from "./CancelCreationModal";
import { Paths } from "../../../utils/router";

export function ExamCreator() {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const [addedExam, setAddedExam] = useState<CreateExamResponse | undefined>(
    undefined
  );

  const [showCancelCreationModal, setShowCancelCreationModal] =
    useState<boolean>(false);

  const { mutate: createExam } = useCreateExam({
    onSuccess: setAddedExam,
  });

  return (
    <DashboardLayout
      title={formatMessage({
        id: "examCreator.title",
        defaultMessage: "Exam Creator",
      })}
      description={formatMessage({
        id: "examCreator.description",
        defaultMessage: "Here you can create a new exam",
      })}
      backgroundImage="/img/exam-creator-background.svg"
    >
      <Container>
        <CreateExamForm
          onSubmit={createExam}
          onCancel={() => setShowCancelCreationModal(true)}
        />
      </Container>

      <ExamCreatedModal
        isOpen={!!addedExam}
        examId={addedExam?.id}
        examType={addedExam?.topic.subjectLevel.subject.examType.id}
        level={addedExam?.topic.subjectLevel.level.id}
        subject={addedExam?.topic.subjectLevel.subject.id}
        topic={addedExam?.topic.id}
        onClose={() => navigate(Paths.MyExams)}
      />

      <CancelCreationModal
        isOpen={showCancelCreationModal}
        onClose={() => setShowCancelCreationModal(false)}
      />
    </DashboardLayout>
  );
}
