export type UserLoginData = {
  email: string;
  password: string;
};

export type CreateTokenResponse = {
  access: string;
  refresh: string;
};

export type RefreshTokenData = {
  refresh: string;
};

export type RefreshTokenResponse = {
  access: string;
  refresh: string;
};

export type VerifyTokenData = {
  token: string;
};

export type VerifyTokenResponse = {
  token: string;
};

export type BlacklistTokenData = {
  refresh: string;
};

export enum UserRoles {
  "Student" = "STUDENT",
  "Teacher" = "TEACHER",
}

export type RegisterData = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  under_16: boolean;
  gdprPolicy: boolean;
  marketingPolicy: boolean;
  parentEmail: string | null;
  teachingCouncilNumber: number;
  role: UserRoles;
  school: string;
};

export type RegisterResponse = {
  email: string;
  firstName: string;
  lastName: string;
  under_16: boolean;
  parentEmail: string;
  teachingCouncilNumber: number;
  gdprPolicy: boolean;
  marketingPolicy: boolean;
  role: UserRoles;
};

export type ResetPasswordData = {
  email: string;
};

export type ResetPasswordResponse = {
  email: string;
};

export type ResetPasswordConfirmData = {
  uid: string;
  token: string;
  newPassword: string;
  reNewPassword: string;
};

export type ResetPasswordConfirmResponse = {
  uid: string;
  token: string;
  newPassword: string;
  reNewPassword: string;
};

export type UserSchool = {
  id: string;
  name: string;
  rollNo: string;
};

export type UserData = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  lastLogin: string;
  under_16: boolean;
  role: UserRoles;
  parentEmail: string | null;
  phone: string | null;
  school: UserSchool;
  teachingCouncilNumber: number;
};

export type ResendPasswordTokenData = {
  uid: string;
  token: string;
};

export type ResendPasswordTokenErrorResponse = { token: string };

export type SetPasswordData = {
  currentPassword: string;
  newPassword: string;
  reNewPassword: string;
};

export enum AccountActivationResultType {
  ERROR = "ERROR",
  SELF_SUCCESS = "SELF_SUCCESS",
  PARENT_SUCCESS = "PARENT_SUCCESS",
  STUDENT_UNDER16_SUCCESS = "STUDENT_UNDER16_SUCCESS",
}

export type AccountActivationResultState = {
  accountActivation: {
    type: AccountActivationResultType;
    title?: string;
    message?: string;
  };
};

export function isAccountActivationResultState(
  state: any
): state is AccountActivationResultState {
  return state?.accountActivation !== undefined;
}
