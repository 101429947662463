import { useIntl } from "react-intl";
import * as yup from "yup";

export const useTeacherRegistrationValidation = (
  setPasswordStrength: (value: number) => void
) => {
  const { formatMessage } = useIntl();

  return yup.object({
    firstName: yup
      .string()
      .trim()
      .max(
        150,
        formatMessage({
          id: "teacherRegistrationForm.firstNameTooLong",
          defaultMessage: "Name is too long max. 150 characters",
        })
      )
      .required(
        formatMessage({
          id: "teacherRegistrationForm.firstNameRequired",
          defaultMessage: "First name is a required field",
        })
      ),
    lastName: yup
      .string()
      .trim()
      .max(
        150,
        formatMessage({
          id: "teacherRegistrationForm.lastNameTooLong",
          defaultMessage: "Last name is too long max. 150 characters",
        })
      )
      .required(
        formatMessage({
          id: "teacherRegistrationForm.lastNameRequired",
          defaultMessage: "Last name is a required field",
        })
      ),
    teachingCouncilNumber: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .typeError(
        formatMessage({
          id: "teacherRegistrationForm.teachingCouncilNumberTypeError",
          defaultMessage: "Please enter the correct teaching council number",
        })
      )
      .min(
        10000,
        formatMessage({
          id: "teacherRegistrationForm.teachingCouncilNumberMin",
          defaultMessage: "Please enter the correct teaching council number",
        })
      )
      .max(
        999999,
        formatMessage({
          id: "teacherRegistrationForm.teachingCouncilNumberMax",
          defaultMessage: "Please enter the correct teaching council number",
        })
      )
      .required(
        formatMessage({
          id: "teacherRegistrationForm.teachingCouncilNumberRequired",
          defaultMessage: "Teaching council number is required.",
        })
      ),
    school: yup
      .object()
      .typeError(
        formatMessage({
          id: "teacherRegistrationForm.schoolRequired",
          defaultMessage: "Please pick a school number from a dropdown",
        })
      )
      .required(
        formatMessage({
          id: "teacherRegistrationForm.schoolRequired",
          defaultMessage: "Please pick a school number from a dropdown",
        })
      ),
    email: yup
      .string()
      .trim()
      .max(
        254,
        formatMessage({
          id: "teacherRegistrationForm.emailTooLong",
          defaultMessage: "Email is too long max. 254 characters",
        })
      )
      .email(
        formatMessage({
          id: "teacherRegistrationForm.emailInvalid",
          defaultMessage: "Please enter a valid email address.",
        })
      )
      .required(
        formatMessage({
          id: "teacherRegistrationForm.emailRequired",
          defaultMessage: "Email is a required field",
        })
      ),
    password: yup
      .string()
      .max(
        30,
        formatMessage({
          id: "teacherRegistrationForm.passwordTooLong",
          defaultMessage: "Password is too long, max 30 characters.",
        })
      )
      .test({
        message: formatMessage({
          defaultMessage:
            "Password must contain min 8 characters (capital letter and number) ",
          id: "teacherRegistrationForm.passwordFieldHint",
        }),
        name: "strength",
        test: (value) => {
          if (!value) {
            setPasswordStrength(0);

            return false;
          }

          const tests = ["[a-z]+", "[A-Z]+", "[0-9]+", "^.{8,}"];
          let passed = 0;

          tests.forEach((test, index) => {
            if (new RegExp(tests[index]).test(value)) {
              passed += 1;
            }
          });

          setPasswordStrength((passed / tests.length) * 100);

          return passed === tests.length;
        },
      }),
    gdprPolicy: yup.bool().oneOf(
      [true],
      formatMessage({
        id: "teacherRegistrationForm.gdprPolicyRequired",
        defaultMessage: "Edco privacy policy must be checked.",
      })
    ),
  });
};
