import styled from "styled-components";

export const DescriptionContainer = styled.div`
  display: none;
  margin-top: ${({ theme }) => theme.spacing.m};
  white-space: pre-line;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    display: block;
  }
`;
