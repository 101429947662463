import styled from "styled-components";

export const NotificationContainer = styled.div`
  z-index: 50;

  display: flex;
  justify-content: center;

  position: fixed;
  top: ${({ theme }) => theme.spacing.l};
  left: 0;
  right: 0;

  margin: 0 auto;

  width: 100%;
  max-width: 432px;
`;
