import { useIntl } from "react-intl";
import * as yup from "yup";
import { UserRoles } from "../../../../api/auth/Auth.types";

export const useUserDetailsValidation = () => {
  const { formatMessage } = useIntl();

  return yup.object({
    firstName: yup
      .string()
      .trim()
      .max(
        150,
        formatMessage({
          id: "userDetailsForm.firstNameTooLong",
          defaultMessage: "Name is too long max. 150 characters",
        })
      )
      .required(
        formatMessage({
          id: "userDetailsForm.firstNameRequired",
          defaultMessage: "First name is a required field",
        })
      ),
    lastName: yup
      .string()
      .trim()
      .max(
        150,
        formatMessage({
          id: "userDetailsForm.lastNameTooLong",
          defaultMessage: "Last name is too long max. 150 characters",
        })
      )
      .required(
        formatMessage({
          id: "userDetailsForm.lastNameRequired",
          defaultMessage: "Last name is a required field",
        })
      ),
    email: yup
      .string()
      .trim()
      .max(
        254,
        formatMessage({
          id: "userDetailsForm.emailTooLong",
          defaultMessage: "Email is too long max. 254 characters",
        })
      )
      .email(
        formatMessage({
          id: "userDetailsForm.emailInvalid",
          defaultMessage: "Please enter a valid email address.",
        })
      )
      .required(
        formatMessage({
          id: "userDetailsForm.emailRequired",
          defaultMessage: "Email is a required field",
        })
      ),
    parentEmail: yup
      .string()
      .nullable()
      .max(
        254,
        formatMessage({
          id: "userDetailsForm.parentEmailTooLong",
          defaultMessage:
            "Parent/Guardian email is too long max. 254 characters",
        })
      )
      .email(
        formatMessage({
          id: "userDetailsForm.parentEmailInvalid",
          defaultMessage: "Please enter a valid email address.",
        })
      )
      .when("age", {
        is: (age: number) => age < 16,
        then: (schema) =>
          schema.required(
            formatMessage({
              id: "userDetailsForm.parentEmailRequired",
              defaultMessage: "Please enter parent/guardian email address.",
            })
          ),
        otherwise: (schema) => schema,
      })
      .test({
        name: "sameAsEmail",
        message: formatMessage({
          id: "userDetailsForm.parentEmailEqualToEmail",
          defaultMessage: "Parent e-mail can't be equal to e-mail.",
        }),
        test(value) {
          return this.parent.email !== value;
        },
      }),
    phone: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .nullable()
      .optional()
      .typeError(
        formatMessage({
          id: "userDetailsForm.phoneInvalid",
          defaultMessage: "Please enter a valid phone number.",
        })
      )
      .test({
        name: "maxLength",
        message: formatMessage({
          id: "userDetailsForm.phoneNumberMax",
          defaultMessage: "Please enter the correct phone number",
        }),
        test(value) {
          if (value) {
            return value.toString().length <= 20;
          }

          return true;
        },
      }),
    school: yup
      .object({
        value: yup.string(),
        label: yup.string(),
      })
      .typeError(
        formatMessage({
          id: "userDetailsForm.schoolTypeError",
          defaultMessage: "Please enter the correct school roll number",
        })
      )
      .when("role", {
        is: (role: UserRoles) => role === UserRoles.Teacher,
        then: (schema) =>
          schema.required(
            formatMessage({
              id: "userDetailsForm.schoolRequired",
              defaultMessage: "School roll number is required.",
            })
          ),
        otherwise: (schema) => schema.nullable().optional(),
      }),
    teachingCouncilNumber: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .typeError(
        formatMessage({
          id: "teacherRegistrationForm.teachingCouncilNumberTypeError",
          defaultMessage: "Please enter the correct teaching council number",
        })
      )
      .min(
        10000,
        formatMessage({
          id: "teacherRegistrationForm.teachingCouncilNumberMin",
          defaultMessage: "Please enter the correct teaching council number",
        })
      )
      .max(
        999999,
        formatMessage({
          id: "teacherRegistrationForm.teachingCouncilNumberMax",
          defaultMessage: "Please enter the correct teaching council number",
        })
      )
      .when("role", {
        is: (role: UserRoles) => role === UserRoles.Teacher,
        then: (schema) =>
          schema.required(
            formatMessage({
              id: "teacherRegistrationForm.teachingCouncilNumberRequired",
              defaultMessage: "Teaching council number is required.",
            })
          ),
        otherwise: (schema) => schema,
      }),
  });
};
