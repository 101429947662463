import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { StringParam, useQueryParams } from "use-query-params";

import { QuestionsFiltersProvider } from "@utils/questions-filters/questions-filters-context";

import { DashboardLayout } from "@components/DashboardLayout";
import { QuestionsFilters } from "@components/QuestionsFilters";
import { QuestionsList } from "@components/QuestionsList";
import { Box } from "@components/Box";
import { Heading } from "@components/Typography";
import { HeadingVariant } from "@components/Typography/Heading/Heading.types";

import { Container, FiltersContainer, FiltersInnerContainer } from "./Questions.styles";

export function Questions() {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const state = location.state as { disabledFields?: string[] };

  const [{ examId, questionId, ...query }] = useQueryParams({
    examType: StringParam,
    subject: StringParam,
    level: StringParam,
    topic: StringParam,
    examId: StringParam,
    questionId: StringParam,
  });

  const defaultFilters = Object.fromEntries(
    Object.entries(query).filter(([_, value]) => value)
  );

  const isSingleQuestion = !!questionId;

  const dashboardProps = useMemo(
    () =>
      isSingleQuestion
        ? {
            headerComponent: (
              <Box mt="m">
                <Heading as={HeadingVariant.H3}>
                  {formatMessage({
                    id: "questions.singleQuestionTitle",
                    defaultMessage: "Search result",
                  })}
                </Heading>
              </Box>
            ),
          }
        : {
            title: formatMessage({
              id: "questions.title",
              defaultMessage: "Questions by Topic",
            }),
            description: formatMessage({
              id: "questions.description",
              defaultMessage:
                "Here you can find all of the questions, solutions & marking schemes for exam papers. \nUse the drop-downs below to search by topic.",
            }),
          },
    [isSingleQuestion, formatMessage]
  );

  return (
    <DashboardLayout
      {...dashboardProps}
      backgroundImage="/img/questions-background.svg"
    >
      <QuestionsFiltersProvider defaultFilters={defaultFilters}>
        {!isSingleQuestion && (
          <FiltersContainer>
            <FiltersInnerContainer>
              <QuestionsFilters disabledFields={state?.disabledFields} />
            </FiltersInnerContainer>
          </FiltersContainer>
        )}

        <Container>
          <QuestionsList examId={examId} questionId={questionId} />
        </Container>
      </QuestionsFiltersProvider>
    </DashboardLayout>
  );
}
