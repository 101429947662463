import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { ConfigurationEndpoints } from "./endpoints";
import {
  FooterResponse,
  OtherPapersUrlResponse,
  PoliciesAndRegulationsResponse,
  SocialMediaResponse,
  WhereToBuyUrlResponse,
} from "./Configuration.types";
import { api } from "../api";
import { authApi } from "../authApi";

const getPoliciesAndRegulations = async () => {
  const { data } = await api.get(ConfigurationEndpoints.PoliciesAndRegulations);

  return data;
};

export const useGetPoliciesAndRegulations = (
  options?: UseQueryOptions<
    PoliciesAndRegulationsResponse,
    Error,
    PoliciesAndRegulationsResponse,
    ["configuration", string]
  >
) =>
  useQuery(
    ["configuration", "policiesAndRegulations"],
    getPoliciesAndRegulations,
    options
  );

const getOtherPapersUrl = async () => {
  const { data } = await authApi.get(ConfigurationEndpoints.OtherPapers);

  return data;
};

export const useGetOtherPapersUrl = (
  options?: UseQueryOptions<
    OtherPapersUrlResponse,
    Error,
    OtherPapersUrlResponse,
    ["configuration", string]
  >
) => useQuery(["configuration", "otherPapersUrl"], getOtherPapersUrl, options);

const getWhereToBuyUrl = async () => {
  const { data } = await authApi.get(ConfigurationEndpoints.WhereToBuy);

  return data;
};

export const useGetWhereToBuyUrl = (
  options?: UseQueryOptions<
    WhereToBuyUrlResponse,
    Error,
    WhereToBuyUrlResponse,
    ["configuration", string]
  >
) => useQuery(["configuration", "whereToBuyUrl"], getWhereToBuyUrl, options);

const getFooter = async () => {
  const { data } = await api.get(ConfigurationEndpoints.Footer);

  return data;
};

const footerQueriesStaleTime = 5 * 60 * 1000;

export const useGetFooter = (
  options?: UseQueryOptions<
    FooterResponse,
    Error,
    FooterResponse,
    ["configuration", string]
  >
) =>
  useQuery(["configuration", "footer"], getFooter, {
    ...options,
    staleTime: options?.staleTime ?? footerQueriesStaleTime,
  });

const getSocialMedia = async () => {
  const { data } = await api.get(ConfigurationEndpoints.SocialMedia);

  return data;
};

export const useGetSocialMedia = (
  options?: UseQueryOptions<
    SocialMediaResponse,
    Error,
    SocialMediaResponse,
    ["configuration", string]
  >
) =>
  useQuery(["configuration", "socialMedia"], getSocialMedia, {
    ...options,
    staleTime: options?.staleTime ?? footerQueriesStaleTime,
  });
