import axios from "axios";
import { camelCase, snakeCase } from "change-case";
import { caseConverter } from "../utils/case-converter";

export const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

api.interceptors.request.use(
  (config) => ({
    ...config,
    data: config.data ? caseConverter(config.data, snakeCase) : config.data,
    params: config.params
      ? caseConverter(config.params, snakeCase)
      : config.params,
  }),
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => ({
    ...response,
    data: caseConverter(response.data, camelCase),
  }),
  (error) => Promise.reject(error)
);
