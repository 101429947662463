import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";

import { authApi } from "../authApi";
import { ExamCreatorEndpoints } from "./endpoints";
import {
  CreateExamData,
  CreateExamResponse,
  EditQuestionData,
  ExamCreatorParams,
  ExamCreatorPDFResponse,
  ExamCreatorResponse,
  ExamsListParams,
  ExamsListResponse,
  GetExamResponse,
  GetSubjectExamsResponse,
  UpdateExamData,
  UpdateExamResponse,
} from "./ExamCreator.types";

const getExamCreator = async ({
  sortOrder,
  ordering,
  ...filters
}: ExamCreatorParams) => {
  const { data } = await authApi.get(ExamCreatorEndpoints.ExamCreator, {
    params: {
      ...filters,
      ordering: `${sortOrder}${ordering}`,
    },
  });

  return data;
};

export const useGetExamCreator = (
  filters: ExamCreatorParams,
  options?: UseQueryOptions<
    ExamCreatorResponse,
    Error,
    ExamCreatorResponse,
    [string, ExamCreatorParams | undefined]
  >
) => useQuery(["exams", filters], () => getExamCreator(filters), options);

const getExam = async (examId?: string) => {
  const { data } = await authApi.get(
    `${ExamCreatorEndpoints.ExamCreator}${examId}/`
  );

  return data;
};

export const useGetExam = (
  examId?: string,
  options?: UseQueryOptions<
    GetExamResponse,
    Error,
    GetExamResponse,
    [string, string | undefined]
  >
) => useQuery(["exams", examId], () => getExam(examId), options);

const getExamsList = async (params?: ExamsListParams) => {
  const { data } = await authApi.get(ExamCreatorEndpoints.List, {
    params,
  });

  return data;
};

export const useGetExamsList = (
  params?: ExamsListParams,
  options?: UseQueryOptions<
    ExamsListResponse,
    Error,
    ExamsListResponse,
    [string, ExamsListParams | undefined]
  >
) => useQuery(["exams", params], () => getExamsList(params), options);

const createExam = async (createExamData: CreateExamData) => {
  try {
    const { data } = await authApi.post<
      CreateExamData,
      AxiosResponse<CreateExamResponse>
    >(ExamCreatorEndpoints.ExamCreator, createExamData);

    return data;
  } catch (e: any) {
    return e.response;
  }
};

export const useCreateExam = (
  options?: UseMutationOptions<CreateExamResponse, Error, CreateExamData>
) => useMutation(["exams"], createExam, options);

const updateExam = async (updateExamData: UpdateExamData) => {
  const { id, ...examData } = updateExamData;

  const { data } = await authApi.patch<
    UpdateExamData,
    AxiosResponse<UpdateExamResponse>
  >(`${ExamCreatorEndpoints.ExamCreator}${id}/`, examData);

  return data;
};

export const useUpdateExam = (
  options?: UseMutationOptions<
    UpdateExamResponse,
    AxiosError<{ detail?: string }>,
    UpdateExamData
  >
) => useMutation(["exams"], updateExam, options);

const getExamCreatorPDF = async (teacherExamId?: string) => {
  const { data } = await authApi.get(
    `${ExamCreatorEndpoints.Pdf}${teacherExamId}/`,
    {
      responseType: "blob",
    }
  );

  return data;
};

export const useGetExamCreatorPDF = (
  teacherExamId: string,
  options?: UseQueryOptions<
    ExamCreatorPDFResponse,
    Error,
    ExamCreatorPDFResponse,
    ["exams", "pdf", string]
  >
) =>
  useQuery(
    ["exams", "pdf", teacherExamId],
    () => getExamCreatorPDF(teacherExamId),
    { staleTime: 1000 * 60 * 1, ...options }
  );

const removeExam = async (teacherExamId: string) => {
  try {
    const { data } = await authApi.delete<
      string,
      AxiosResponse<CreateExamResponse>
    >(`${ExamCreatorEndpoints.ExamCreator}${teacherExamId}/`);

    return data;
  } catch (e: any) {
    return e.response;
  }
};

export const useRemoveExam = (
  teacherExamId: string,
  options?: UseMutationOptions<CreateExamResponse, Error, string>
) => {
  const queryClient = useQueryClient();

  return useMutation(["exams", teacherExamId], removeExam, {
    ...options,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["exams"] });
      queryClient.refetchQueries({ queryKey: ["exams"] });
    },
  });
};

const editQuestion = async (
  variables: EditQuestionData,
  questionId: string
) => {
  const { data } = await authApi.patch<EditQuestionData, AxiosResponse<null>>(
    `${ExamCreatorEndpoints.Question}${questionId}/`,
    variables
  );

  return data;
};

export const useEditQuestion = (
  questionId: string,
  options?: UseMutationOptions<null, Error, EditQuestionData>
) =>
  useMutation(
    ["exams", "question", questionId],
    (variables) => editQuestion(variables, questionId),
    options
  );

const removeQuestionFromExam = async (questionId: string) => {
  const { data } = await authApi.delete<null, AxiosResponse<null>>(
    `${ExamCreatorEndpoints.Question}${questionId}/`
  );

  return data;
};

export const useRemoveQuestionFromExam = (
  questionId: string,
  examId?: string,
  options?: UseMutationOptions<null, Error>
) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: () => removeQuestionFromExam(questionId),
    onSuccess: (...props) => {
      if (options?.onSuccess) {
        options.onSuccess(...props);
      }
      queryClient.invalidateQueries(["exams", examId]);
    },
    ...options,
  });

  return mutation;
};

const getSubjectExams = async () => {
  const { data } = await authApi.get(`${ExamCreatorEndpoints.Subjects}`);

  return data;
};

export const useGetSubjectExams = (
  options?: UseQueryOptions<
    GetSubjectExamsResponse,
    Error,
    GetSubjectExamsResponse,
    ["subjects"]
  >
) => useQuery(["subjects"], getSubjectExams, options);
