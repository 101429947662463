import axios, { AxiosError, AxiosResponse } from "axios";
import Cookies from "js-cookie";
import { Paths } from "../../utils/router/routes/routes.types";
import {
  BlacklistTokenData,
  CreateTokenResponse,
  RefreshTokenData,
  RefreshTokenResponse,
  UserLoginData,
  VerifyTokenData,
  VerifyTokenResponse,
} from "./Auth.types";
import { AuthEndpoints } from "./endpoints";

enum Tokens {
  AccessToken = "accessToken",
  RefreshToken = "refreshToken",
}

class AuthService {
  // eslint-disable-next-line class-methods-use-this
  getAccessToken = () => localStorage.getItem(Tokens.AccessToken);

  // eslint-disable-next-line class-methods-use-this
  setAccessToken = (accessToken: string) => {
    localStorage.setItem(Tokens.AccessToken, accessToken);
  };

  // eslint-disable-next-line class-methods-use-this
  getRefreshToken = () => Cookies.get(Tokens.RefreshToken);

  // eslint-disable-next-line class-methods-use-this
  setRefreshToken = (refreshToken: string) => {
    Cookies.set(Tokens.RefreshToken, refreshToken);
  };

  isLoggedIn = () => !!this.getAccessToken() || !!this.getRefreshToken();

  // eslint-disable-next-line class-methods-use-this
  login = ({ email, password }: UserLoginData) =>
    axios
      .post<UserLoginData, AxiosResponse<CreateTokenResponse>>(
        AuthEndpoints.CreateToken,
        {
          email: email.toLocaleLowerCase(),
          password,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          const { data } = response;

          this.setAccessToken(data.access);
          this.setRefreshToken(data.refresh);
        }

        return response;
      });

  logout = () => {
    axios.post<BlacklistTokenData, AxiosResponse<string>>(
      AuthEndpoints.Blacklist,
      { refresh: this.getRefreshToken() }
    );

    this.setAccessToken("");
    this.setRefreshToken("");

    window.location.href = Paths.Login;
  };

  refreshToken = () =>
    axios
      .post<RefreshTokenData, AxiosResponse<RefreshTokenResponse>>(
        AuthEndpoints.RefreshToken,
        { refresh: this.getRefreshToken() }
      )
      .then(({ data }) => {
        this.setRefreshToken(data.refresh);
        this.setAccessToken(data.access);

        return data;
      })
      .catch((error) => {
        const responseCode = (error as AxiosError).response?.status;

        if (responseCode && responseCode >= 400) {
          this.logout();
        }
      });

  // eslint-disable-next-line class-methods-use-this
  verifyToken = async (token: VerifyTokenData) => {
    const response = await axios.post<
      VerifyTokenData,
      AxiosResponse<VerifyTokenResponse>
    >(AuthEndpoints.RefreshToken, token);

    // eslint-disable-next-line no-console
    console.log({ response });
  };
}

export default new AuthService();
