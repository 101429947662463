import styled from "styled-components";

export const ButtonsRow = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-gap: ${({ theme }) => theme.spacing.m};
  margin-top: ${({ theme }) => theme.spacing.ml};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-rows: 1fr;
    grid-template-columns: repeat(2, 1fr);

    > :first-child {
      order: 1;
    }
  }
`;
