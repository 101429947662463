import { exportEndpointsEnum } from "../../utils/helpers";

enum ConfigurationEndpointsEnum {
  PoliciesAndRegulations = `/policies-and-regulations/`,
  OtherPapers = `/other-papers/`,
  WhereToBuy = `/where-to-buy/`,
  Footer = `/footer/`,
  SocialMedia = `/social-media/`,
}

export const ConfigurationEndpoints = exportEndpointsEnum<
  typeof ConfigurationEndpointsEnum
>("configuration", ConfigurationEndpointsEnum);
