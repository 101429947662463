export enum FieldCaptionVariant {
  Primary = "primary",
  Error = "error",
  Valid = "valid",
  Disabled = "disabled",
  Question = "question",
}

export enum FieldCaptionSize {
  Big = "big",
  Small = "small",
}

export type StyledCaptionProps = {
  variant?: FieldCaptionVariant;
  size?: FieldCaptionSize;
};

export interface FieldCaptionProps extends StyledCaptionProps {
  children?: JSX.Element | string;
}
