import styled, { css } from "styled-components";

import { StyledRowProps } from "./Row.types";

export const StyledRow = styled.div<StyledRowProps>`
  display: flex;
  align-items: ${({ align }) => align || "center"};
  ${({ justify }) => justify && `justify-content: ${justify};`}

  ${({ gap, reversed }) =>
    gap &&
    css`
      > * + * {
        margin-${reversed ? "right" : "left"}: ${
      typeof gap === "number" ? `${gap}px` : ({ theme }) => theme.spacing[gap]
    };
      }
    `}
`;
