import styled from "styled-components";
import { StyledAvatarProps } from "./Avatar.types";

export const StyledAvatar = styled.span<StyledAvatarProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};

  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.color.solid.primary};
  border-radius: 50%;

  background-color: ${({ theme }) => theme.color.background.primary};
  color: ${({ theme }) => theme.color.solid.primary};

  font-weight: ${({ theme }) => theme.typography.weights.bold};
`;
