import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { SolidColors } from "styled-components";

import { Question as QuestionType, Topic } from "@api/paper/Paper.types";
import { useUser } from "@utils/user/user-context";

import { Icons } from "@icons";

import { Box } from "@components/Box";
import { Checkbox } from "@components/Checkbox";
import { Icon } from "@components/Icon";
import { Image } from "@components/Image";
import { QuestionTabs } from "@components/QuestionTabs";
import { Heading } from "@components/Typography";
import { HeadingVariant } from "@components/Typography/Heading/Heading.types";

import { Audio } from "./Audio";
import {
  QuestionHeader,
  QuestionTitle,
  QuestionTitleWrapper,
  SelectForExamWrapper,
  Subtitle,
  TabContent,
} from "./Question.styles";

interface QuestionProps extends Required<QuestionType> {}

interface LocationState {
  state?: {
    topic: Topic;
  };
}

export function Question({
  id,
  number,
  paper,
  section,
  year,
  marks,
  questionFile,
  audioFile,
  solution,
  markingSchema,
}: QuestionProps) {
  const { formatMessage } = useIntl();
  const { isTeacher } = useUser();
  const { register } = useFormContext(); // retrieve all hook methods
  const { state } = useLocation() as LocationState;

  const tabs = useMemo(
    () => [
      {
        key: "question",
        title: formatMessage({
          id: "questionsTab.questions",
          defaultMessage: "Questions",
        }),
        icon: "QuestionMark" as Icons,
        color: "primary" as SolidColors,
        children: (
          <TabContent key="questions">
            {questionFile?.map(({ id: questionFileId, file }) => (
              <Image key={questionFileId} src={file} />
            ))}
            {audioFile && <Audio src={audioFile} />}
          </TabContent>
        ),
      },
      {
        key: "solution",
        title: formatMessage({
          id: "questionsTab.solutions",
          defaultMessage: "Solutions",
        }),
        icon: "LightBulb" as Icons,
        color: "orange" as SolidColors,
        children: (
          <TabContent key="solution">
            {solution &&
              solution.solutionFile?.map(({ id: solutionFileId, file }) => (
                <Image key={solutionFileId} src={file} />
              ))}
          </TabContent>
        ),
      },
      {
        key: "markingScheme",
        title: formatMessage({
          id: "questionsTab.markingScheme",
          defaultMessage: "Marking scheme",
        }),
        icon: "Clipboard" as Icons,
        color: "purple" as SolidColors,
        children: (
          <TabContent key="markingScheme">
            {markingSchema &&
              markingSchema.markingSchemaFile?.map(
                ({ id: markingSchemaId, file }) => (
                  <Image key={markingSchemaId} src={file} />
                )
              )}
          </TabContent>
        ),
      },
    ],
    [audioFile, formatMessage, markingSchema, questionFile, solution]
  );

  return (
    <Box>
      <QuestionHeader>
        <QuestionTitleWrapper gap="m">
          <Icon icon="File" noBackground />
          <QuestionTitle>
            <Heading as={HeadingVariant.H5}>
              <FormattedMessage
                id="questionsList.questionTitle"
                defaultMessage="Question {number}, Paper {paper}, Section {section}, {year}"
                values={{
                  number,
                  paper,
                  section,
                  year,
                }}
              />
            </Heading>
            <div>
              {state?.topic && (
                <Subtitle>
                  <FormattedMessage
                    id="questionsList.topic"
                    defaultMessage="Topic: {topic}"
                    values={{ topic: state.topic.name }}
                  />
                </Subtitle>
              )}
              <Subtitle>
                <FormattedMessage
                  id="questionsList.marks"
                  defaultMessage="Marks: {marks}"
                  values={{ marks }}
                />
              </Subtitle>
            </div>
          </QuestionTitle>
        </QuestionTitleWrapper>

        {isTeacher && (
          <SelectForExamWrapper>
            <Checkbox {...register(id)}>
              <FormattedMessage
                id="question.selectForExamLabel"
                defaultMessage="Select for exam"
              />
            </Checkbox>
          </SelectForExamWrapper>
        )}
      </QuestionHeader>

      <QuestionTabs tabs={tabs} />
    </Box>
  );
}
