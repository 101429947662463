import { exportEndpointsEnum } from "../../utils/helpers";

enum ExamCreatorEndpointsEnum {
  ExamCreator = `/`,
  List = `/list/`,
  Pdf = `/pdf/`,
  Question = `/question/`,
  Subjects = `/subjects/`,
}

export const ExamCreatorEndpoints = exportEndpointsEnum<
  typeof ExamCreatorEndpointsEnum
>("exam-creator", ExamCreatorEndpointsEnum);
