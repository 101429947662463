import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormattedMessage, useIntl } from "react-intl";

import { UserRoles } from "@api/auth/Auth.types";
import { useGetPoliciesAndRegulations } from "@api/configuration/Configuration.queries";

import { SchoolSelect } from "@components/SchoolSelect";
import { Input } from "@components/Input";
import { Button, ButtonVariants } from "@components/Button";
import { Checkbox } from "@components/Checkbox";
import { Box } from "@components/Box";
import { PasswordInput } from "@components/PasswordInput";

import {
  TeacherRegistrationFormData,
  TeacherRegistrationFormProps,
} from "./TeacherRegistrationForm.types";
import { LinkWrapper, StyledForm } from "./TeacherRegistrationForm.styles";
import { useTeacherRegistrationValidation } from "./TeacherRegistrationForm.validation";

function NoOptionsMessage() {
  return (
    <FormattedMessage
      id="teacherRegistrationForm.noResultsFound"
      defaultMessage="No results found"
    />
  );
}

export function TeacherRegistrationForm({
  onSubmit,
  apiError,
}: TeacherRegistrationFormProps) {
  const { formatMessage } = useIntl();

  const [passwordStrength, setPasswordStrength] = useState(0);
  const { data: policiesAndRegulations } = useGetPoliciesAndRegulations();
  const {
    register,
    control,
    handleSubmit,
    setError,
    formState: { errors, isValid },
  } = useForm<TeacherRegistrationFormData>({
    mode: "onChange",
    resolver: yupResolver(
      useTeacherRegistrationValidation(setPasswordStrength)
    ),
    defaultValues: {
      role: UserRoles.Teacher,
      gdprPolicy: false,
      school: undefined,
    },
  });

  useEffect(() => {
    if (apiError) {
      setError("email", {
        type: "custom",
        message: apiError.response?.data.detail,
      });
    }
  }, [apiError, setError]);

  return (
    <StyledForm
      noValidate
      onSubmit={handleSubmit((formData) => {
        onSubmit({ ...formData, school: formData.school.value });
      })}
    >
      <Input
        {...register("firstName", { required: true })}
        label={formatMessage({
          id: "teacherRegistrationForm.firstName",
          defaultMessage: "First name",
        })}
        placeholder={formatMessage({
          id: "teacherRegistrationForm.firstNamePlaceholder",
          defaultMessage: "Enter your name",
        })}
        errorMessage={errors.firstName?.message}
        isRequired
      />

      <Input
        {...register("lastName", { required: true })}
        label={formatMessage({
          id: "teacherRegistrationForm.lastName",
          defaultMessage: "Surname",
        })}
        placeholder={formatMessage({
          id: "teacherRegistrationForm.lastNamePlaceholder",
          defaultMessage: "Enter your surname",
        })}
        errorMessage={errors.lastName?.message}
        isRequired
      />

      <Input
        {...register("teachingCouncilNumber", { required: true })}
        type="number"
        label={formatMessage({
          id: "teacherRegistrationForm.teachingCouncilNumber",
          defaultMessage: "Teaching Council Number",
        })}
        placeholder={formatMessage({
          id: "teacherRegistrationForm.teachingCouncilNumberPlaceholder",
          defaultMessage: "Enter your number",
        })}
        errorMessage={errors.teachingCouncilNumber?.message}
        isRequired
      />

      <Controller
        name="school"
        control={control}
        render={({
          field: { onChange, value, ref, name },
          fieldState: { error },
        }) => (
          <SchoolSelect
            name={name}
            label={formatMessage({
              id: "teacherRegistrationForm.schoolLabel",
              defaultMessage: "School Roll Number",
            })}
            placeholder={formatMessage({
              id: "teacherRegistrationForm.schoolPlaceholder",
              defaultMessage: "Enter your number",
            })}
            onChange={onChange}
            value={value}
            ref={ref}
            userRole={UserRoles.Teacher}
            errorMessage={error?.message}
            noOptionsMessage={NoOptionsMessage}
            isRequired
          />
        )}
      />

      <Input
        {...register("email", { required: true })}
        type="email"
        label={formatMessage({
          id: "teacherRegistrationForm.email",
          defaultMessage: "Email address",
        })}
        placeholder={formatMessage({
          id: "teacherRegistrationForm.emailPlaceholder",
          defaultMessage: "Enter email",
        })}
        errorMessage={errors.email?.message}
        isRequired
      />

      <PasswordInput
        {...register("password", { required: true })}
        progress={passwordStrength}
        label={formatMessage({
          id: "teacherRegistrationForm.password",
          defaultMessage: "Password",
        })}
        placeholder={formatMessage({
          id: "teacherRegistrationForm.passwordPlaceholder",
          defaultMessage: "Enter password",
        })}
        errorMessage={errors.password?.message}
        caption={formatMessage({
          id: "passwordInput.caption",
          defaultMessage: "Minimum 8 characters (capital letter and number)",
        })}
        isRequired
      />

      <Box mt="l" mb="m">
        <Checkbox
          {...register("gdprPolicy", { required: true })}
          error={errors.gdprPolicy?.message}
          isRequired
        >
          <FormattedMessage
            id="teacherRegistrationForm.gdpr"
            defaultMessage="I agree to the Edco privacy policy"
          />
        </Checkbox>

        <LinkWrapper>
          <a
            href={policiesAndRegulations?.gdprPolicy}
            target="_blank"
            rel="noreferrer"
          >
            <Button variant={ButtonVariants.Tertiary}>
              <FormattedMessage
                id="studentRegistrationForm.privacyPolicyLink"
                defaultMessage="Read privacy policy"
              />
            </Button>
          </a>
        </LinkWrapper>
      </Box>

      <Box mb="xl">
        <Checkbox
          {...register("marketingPolicy")}
          error={errors.marketingPolicy?.message}
        >
          <FormattedMessage
            id="teacherRegistrationForm.marketingPolicyAgree"
            defaultMessage="I agree to the Edco marketing policy"
          />
        </Checkbox>

        <LinkWrapper>
          <a
            href={policiesAndRegulations?.marketingPolicy}
            target="_blank"
            rel="noreferrer"
          >
            <Button variant={ButtonVariants.Tertiary}>
              <FormattedMessage
                id="studentRegistrationForm.privacyPolicyLink"
                defaultMessage="Read marketing policy"
              />
            </Button>
          </a>
        </LinkWrapper>
      </Box>

      <Box>
        <Button
          type="submit"
          variant={ButtonVariants.Primary}
          block
          iconRight="ArrowRight"
          disabled={!isValid}
        >
          <FormattedMessage
            id="teacherRegistrationForm.submitButton"
            defaultMessage="Register"
          />
        </Button>
      </Box>
    </StyledForm>
  );
}
